import { MdPictureAsPdf } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'

import { VscFilePdf } from 'react-icons/vsc'
import Swal from 'sweetalert2';
import { urlApiServer } from '../../Config/Config';
import { FaUserEdit, FaUserTimes, FaPencilAlt, FaTrashAlt, FaUserCog, FaUserMinus, FaPencilRuler, FaTrash, FaClipboardList } from 'react-icons/fa';
import '../../styles/Quotes.css'
import { NavLink } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import LoginContext from "../../Context/Logincontext"
import { RiFileList2Line } from 'react-icons/ri'


export const columnsQuotesAdmin = [
  {
    Header: "Nro",
    accessor: "nrocotizacion",
  },
  {
    Header: "Fecha de realización",
    accessor: "fecha",
    Cell: ({ value }) => {
      const fechaOriginal = new Date(value);
      const fechaFormateada = fechaOriginal.toLocaleDateString();
      return <span>{fechaFormateada}</span>;
    }
  },
  // {
  //   Header: "Fecha Entrega",
  //   accessor: "fechaentrega"
  // },
  {
    Header: "Nombre del asesor",
    accessor: "asesor"
  },
  // {
  //   Header: "Documento",
  //   accessor: "nitocc"
  // },
  // {
  //   Header: "Direccion",
  //   accessor: "direccion"
  // },
  {
    Header: "Nombre del cliente",
    accessor: "cliente"
  },
  {
    Header: "Tel del cliente",
    accessor: "telefono"
  },
  {
    Header: "Abono",
    accessor: "abono",
    Cell: ({ value }) => {
      const abono = value || 0;
      return <span>{abono}</span>;
    }
  },
  {
    Header: "Factura",
    accessor: "nrofactura",
    Cell: props => {

      let indexReturned = 0


      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index

        }
      })

      async function on() {
        // props.showLoader(indexReturned, props.row.original.nrocotizacion)
        // props.power()
        console.log("ORIGINAL: ", props.row)
        props.importantData(indexReturned)
        props.handleShowBill(props.row.original)
        // props.showLoader()
      }

      return (
        <>
          <div className='iconsPdf'>
            <NavLink onClick={on}><FaClipboardList className='iconPdf' /></NavLink>
          </div>
        </>
      )

      // const abono = value || 0;
      // return <span>{abono}</span>;
    }
  },
  {
    Header: "Pdf",
    Cell: props => {

      let indexReturned = 0


      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index

        }
      })

      async function on() {
        props.showLoader(indexReturned, props.row.original.nrocotizacion)
        // props.power()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
        // props.showLoader()
      }
      return (
        <>
          <div className='iconsPdf'>
            <NavLink onClick={on}><VscFilePdf className='iconPdf' /></NavLink>
          </div>
        </>
      )
    }
  },
  {
    Header: "Duplicar",
    Cell: props => {
      let productHistory = []
      let validate = 0

      const urlDuplicateQuotes = urlApiServer + "addquotes";
      const urlAddProduct = urlApiServer + "addhistoryProduct"

      const { infoUser, setInfoUser } = useContext(LoginContext)
      const createQuote = urlApiServer + "/addquotes";

      useEffect(() => {
        productHistory = []
        props.product.map((history) => {
          if (props.row.original.nrocotizacion == history.nrocotizacion) {
            productHistory.push(history)
          }
        })
      }, [props])



      async function duplicate() {
        let fechaMySQL
        if (props.row.original.fechaentrega !== null && props.row.original.fechaentrega !== '') {
          const fecha = new Date(props.row.original.fechaentrega); // Accede a la propiedad fechaentrega
          if (!isNaN(fecha.getTime())) { // Verifica si la fecha es válida
            fechaMySQL = fecha.toISOString().slice(0, 19).replace('T', ' ');
            // Ahora puedes usar fechaMySQL para lo que necesites
          } else {
            console.error("La fecha proporcionada no es válida.");
          }
        }
        const data = JSON.stringify({
          empresa: infoUser.company,
          payment: props.row.original.abono,
          seller: props.row.original.asesor,
          client: props.row.original.cliente,
          // contactName: props.row.original.contactName,
          contact: props.row.original.contacto,
          email: props.row.original.correo,
          youMay: props.row.original.debe,
          direction: props.row.original.direccion,
          typeDocument: props.row.original.typedocument,
          estado: "cotizado",
          date: fechaMySQL,
          image: props.row.original.image,
          itemEnd: props.row.original.itemfinal,
          citizenshipCard: props.row.original.nitocc,
          invoiceNumber: props.row.original.nrofactura,
          observation: props.row.original.observation,
          priceEnd: props.row.original.preciofinal,
          phone: props.row.original.telefono,
          time: props.row.original.tiempo,

        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
          method: 'POST',
          headers: registerHeaders,
          body: data,
          redirect: 'follow'
        }

        fetch(urlDuplicateQuotes, requesoption)
          .then((response) => response.json())
          .then((dataResponse) => {
            productHistory.map(async (item, index) => {
              const data = JSON.stringify({
                empresa: infoUser.company,
                producto: item.producto,
                material: item.material,
                caliber: item.calibre,
                long: item.largo,
                width: item.width,
                precioitem: item.precioitem,
                cantidadItem: item.cantidadItem,
                total: item.total,
                Priceiva: item.Priceiva,
                iva: item.iva,
                image_3d: item.image_3d,
                image_2d: item.image_2d,
                modeljson: JSON.parse(item.modeljson),
                price: item.precio,
                unit: item.unit,
              })
              const registerHeaders = new Headers();
              registerHeaders.append("Content-Type", "application/json")
              const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
              }

              await fetch(urlAddProduct, requesoption)
                .then((response) => response.json())
                .then((dataResponse) => {
                  validate = validate + 1
                  if (validate === 1) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Felicidades',
                      text: 'Has duplicado una cotizacion',
                      allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                      confirmButtonText: 'Aceptar',
                    }).then((resultConfirm) => {
                      if (resultConfirm.isConfirmed) {
                        props.functionNew()
                        productHistory = []
                        // validate = false
                      }
                    })
                  }
                })
            })
            // console.log("validate: ", validate)
          })
        validate = 0

      }
      return (
        <>
          <NavLink><HiDuplicate className='iconDuplicate' onClick={duplicate} /></NavLink>
        </>)
    }
  },
  {
    Header: "Remisión",
    Cell: props => {

      if (props.row.original.estado === 'entregado') {

        let indexReturned = 0

        props.data.map((itemTable, index) => {

          if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
            indexReturned = index

          }
        })

        async function on() {
          props.remission()
          props.importantData(indexReturned)
          props.id(props.row.original.nrocotizacion)
        }
        return (
          <>
            <div className='iconsPdf'>
              <NavLink onClick={on}><RiFileList2Line className='iconPdf' /></NavLink>
            </div>
          </>
        )
      }
    }
  }, {
    Header: "Editar",
    Cell: props => {

      let indexReturned = 0

      props.data.map((itemTable, index) => {

        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index
        }
      })

      async function on() {
        props.edit()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
      }
      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilAlt className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "Borrar-quotes";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {

        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {

            const data = JSON.stringify({
              empresa: infoUser.company,
              nrocotizacion: props.row.original.nrocotizacion,
              ImageDelete: props.row.original.image
            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar Cotizacion' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Cotizacion Eliminada',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }
      return (
        <>
          <NavLink><FaTrashAlt onClick={Eliminar} className='iconPdf' /></NavLink>
        </>)
    }
  },
  {
    Header: "Estado",
    Cell: props => {

      const urlState = urlApiServer + "editState";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      const urlRemission = urlApiServer + "remission";

      const [estado, setEstado] = useState('cotizado');
      const [botonColor, setBotonColor] = useState('#BF78B9');

      useEffect(() => {
        if (props.row.original.estado === 'cotizado') {
          setEstado('cotizado')
          setBotonColor('#BF78B9')
        } else if (props.row.original.estado === 'aprobado') {
          setEstado('aprobado')
          setBotonColor('#8D70D3')
        } else if (props.row.original.estado === 'terminado') {
          setEstado('terminado')
          setBotonColor('#D37070')
        } else if (props.row.original.estado === 'facturado') {
          setEstado('facturado')
          setBotonColor('#D3D270')
        } else {
          setEstado('entregado')
          setBotonColor('#70D378')
        }
      }, [props.row.original.estado])

      let siguienteEstado;
      if (estado === 'cotizado') {
        siguienteEstado = 'aprobado';
      } else if (estado === 'aprobado') {
        siguienteEstado = 'terminado';
      } else if (estado === 'terminado') {
        siguienteEstado = 'facturado';
      } else if (estado === 'facturado') {
        siguienteEstado = 'entregado';
      } else {
        siguienteEstado = 'cotizado';
      }

      const cambiarEstado = () => {
        Swal.fire({
          title: `¿Estás seguro de que deseas actualizar el estado a ${siguienteEstado}?`,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            let nuevoEstado, nuevoColor, nuevoEstadoEnum;
            if (estado === 'cotizado') {
              nuevoEstado = 'aprobado';
              nuevoColor = '#8D70D3';
              nuevoEstadoEnum = 2;
            } else if (estado === 'aprobado') {
              nuevoEstado = 'terminado';
              nuevoColor = '#D37070';
              nuevoEstadoEnum = 3;
            } else if (estado === 'terminado') {
              nuevoEstado = 'facturado';
              nuevoColor = '#D3D270';
              nuevoEstadoEnum = 4;
            } else if (estado === 'facturado') {
              nuevoEstado = 'entregado';
              nuevoColor = '#70D378';
              nuevoEstadoEnum = 5;
              const data = JSON.stringify({
                empresa: infoUser.company,
                nrocotizacion: props.row.original.nrocotizacion,
              })
              const registerHeaders = new Headers();
              registerHeaders.append("Content-Type", "application/json")

              const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
              }

              fetch(urlRemission, requesoption)
                .then((response) => response.json())
                .then((dataResponse) => {
                  props.functionNew()
                })
            } else {
              nuevoEstado = 'cotizado';
              nuevoColor = '#BF78B9';
              nuevoEstadoEnum = 1;
            }
            Swal.fire({
              title: 'Se actualizó el estado',
              text: `El estado ahora está (${nuevoEstado})`,
            });
            const data = JSON.stringify({
              empresa: infoUser.company,
              nrocotizacion: props.row.original.nrocotizacion,
              state: nuevoEstadoEnum
            });
            const registerHeaders = new Headers({
              "Content-Type": "application/json"
            });
            const requesoption = {
              method: 'PUT',
              headers: registerHeaders,
              body: data,
              redirect: 'follow'
            };
            fetch(urlState, requesoption)
              .then(response => response.json())
              .then((dataResponse) => {
                setEstado(nuevoEstado);
                setBotonColor(nuevoColor);
                props.functionNew()
              })
              .catch(err => console.log(err));
          } else {
            Swal.fire({
              title: 'Acción cancelada',
              text: 'No se ha actualizado el estado',
              icon: 'info'
            });
          }
        });
      };

      return (
        <div>
          <button style={{ backgroundColor: botonColor }} className='buttonState' onClick={cambiarEstado}>{estado}</button>
        </div>
      );

    }
  },
]

export const columnsQuotes = [
  {
    Header: "Nro",
    accessor: "nrocotizacion"
  },
  {
    Header: "Fecha de realización",
    accessor: "fecha",
    Cell: ({ value }) => {
      const fechaOriginal = new Date(value);
      const fechaFormateada = fechaOriginal.toLocaleDateString();
      return <span>{fechaFormateada}</span>;
    }
  },
  // {
  //   Header: "Fecha Entrega",
  //   accessor: "fechaentrega"
  // },
  {
    Header: "Nombre del asesor",
    accessor: "asesor"
  },
  // {
  //   Header: "Documento",
  //   accessor: "nitocc"
  // },
  // {
  //   Header: "Direccion",
  //   accessor: "direccion"
  // },
  {
    Header: "Nombre del cliente",
    accessor: "cliente"
  },
  {
    Header: "Tel del cliente",
    accessor: "telefono"
  },
  {
    Header: "Abono",
    accessor: "abono",
    Cell: props => {
      // const [abono, setAbono] = useState()

      // useEffect(() => {
      //   if (props.row.original.abono == "") {
      //     <p>0</p>
      //   } else {
      //     setAbono(new Intl.NumberFormat('es-MX').format(props.row.original.abono).replace(/,/g, '.'))
      //   }
      // }, [props])

      // return (
      //   <p>{abono}</p>
      // )

      let indexReturned = 0

      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index

        }
      })

      async function on() {
        props.showLoader()
        props.power()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
      }

      return (
        <>
          <div className='iconsPdf'>
            <NavLink onClick={on}><MdPictureAsPdf className='iconPdf' /></NavLink>
          </div>
        </>
      )

    }
  },
  {
    Header: "Factura",
    accessor: "nrofactura",
    Cell: ({ value }) => {
      const abono = value || 0;
      return <span>{abono}</span>;
    }
  },
  {
    Header: "Pdf",
    Cell: props => {

      let indexReturned = 0

      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index

        }
      })

      async function on() {
        props.showLoader()
        props.power()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
      }
      return (
        <>
          <div className='iconsPdf'>
            <NavLink onClick={on}><MdPictureAsPdf className='iconPdf' /></NavLink>
          </div>
        </>
      )
    }
  }, {
    Header: "Remisión",
    Cell: props => {

      if (props.row.original.estado === 'entregado') {

        let indexReturned = 0


        props.data.map((itemTable, index) => {

          if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
            indexReturned = index

          }
        })

        async function on() {
          props.remission()
          props.importantData(indexReturned)
          props.id(props.row.original.nrocotizacion)
        }
        return (
          <>
            <div className='iconsPdf'>
              <NavLink onClick={on}><RiFileList2Line className='iconPdf' /></NavLink>
            </div>
          </>
        )
      }
    }
  }, {
    Header: "Editar",
    Cell: props => {

      let indexReturned = 0

      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index
        }
      })

      async function on() {
        props.edit()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilAlt className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Estado",
    Cell: props => {

      const urlState = urlApiServer + "editState";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      const urlRemission = urlApiServer + "remission";

      const [estado, setEstado] = useState('cotizado');
      const [botonColor, setBotonColor] = useState('#BF78B9');

      useEffect(() => {
        if (props.row.original.estado === 'cotizado') {
          setEstado('cotizado')
          setBotonColor('#BF78B9')
        } else if (props.row.original.estado === 'aprobado') {
          setEstado('aprobado')
          setBotonColor('#8D70D3')
        } else if (props.row.original.estado === 'terminado') {
          setEstado('terminado')
          setBotonColor('#D37070')
        } else if (props.row.original.estado === 'facturado') {
          setEstado('facturado')
          setBotonColor('#D3D270')
        } else {
          setEstado('entregado')
          setBotonColor('#70D378')
        }
      }, [props.row.original.estado])

      let siguienteEstado;
      if (estado === 'cotizado') {
        siguienteEstado = 'aprobado';
      } else if (estado === 'aprobado') {
        siguienteEstado = 'terminado';
      } else if (estado === 'terminado') {
        siguienteEstado = 'facturado';
      } else if (estado === 'facturado') {
        siguienteEstado = 'entregado';
      } else {
        siguienteEstado = 'cotizado';
      }

      const cambiarEstado = () => {
        Swal.fire({
          title: `¿Estás seguro de que deseas actualizar el estado a ${siguienteEstado}?`,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            let nuevoEstado, nuevoColor, nuevoEstadoEnum;
            if (estado === 'cotizado') {
              nuevoEstado = 'aprobado';
              nuevoColor = '#8D70D3';
              nuevoEstadoEnum = 2;
            } else if (estado === 'aprobado') {
              nuevoEstado = 'terminado';
              nuevoColor = '#D37070';
              nuevoEstadoEnum = 3;
            } else if (estado === 'terminado') {
              nuevoEstado = 'facturado';
              nuevoColor = '#D3D270';
              nuevoEstadoEnum = 4;
            } else if (estado === 'facturado') {
              nuevoEstado = 'entregado';
              nuevoColor = '#70D378';
              nuevoEstadoEnum = 5;
              const data = JSON.stringify({
                empresa: infoUser.company,
                nrocotizacion: props.row.original.nrocotizacion,
              })
              const registerHeaders = new Headers();
              registerHeaders.append("Content-Type", "application/json")

              const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
              }

              fetch(urlRemission, requesoption)
                .then((response) => response.json())
                .then((dataResponse) => {
                  props.functionNew()
                })
            } else {
              nuevoEstado = 'cotizado';
              nuevoColor = '#BF78B9';
              nuevoEstadoEnum = 1;
            }
            Swal.fire({
              title: 'Se actualizó el estado',
              text: `El estado ahora está (${nuevoEstado})`,
            });
            const data = JSON.stringify({
              empresa: infoUser.company,
              nrocotizacion: props.row.original.nrocotizacion,
              state: nuevoEstadoEnum
            });
            const registerHeaders = new Headers({
              "Content-Type": "application/json"
            });
            const requesoption = {
              method: 'PUT',
              headers: registerHeaders,
              body: data,
              redirect: 'follow'
            };
            fetch(urlState, requesoption)
              .then(response => response.json())
              .then((dataResponse) => {
                setEstado(nuevoEstado);
                setBotonColor(nuevoColor);
                props.functionNew()
              })
              .catch(err => console.log(err));
          } else {
            Swal.fire({
              title: 'Acción cancelada',
              text: 'No se ha actualizado el estado',
              icon: 'info'
            });
          }
        });
      };

      return (
        <div>
          <button style={{ backgroundColor: botonColor }} className='buttonState' onClick={cambiarEstado}>{estado}</button>
        </div>
      );

    }
  },
]

export const columnsClientAdmin = [
  {
    Header: "Nombre",
    accessor: "cliente"
  },
  {
    Header: "Telefono",
    accessor: "telefono"
  },
  {
    Header: "Contacto",
    accessor: "contacto"
  },
  {
    Header: "Correo",
    accessor: "correo"
  },
  {
    Header: "Documento",
    accessor: "nitocc"
  },
  {
    Header: "Direccion",
    accessor: "direccion"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaUserCog className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Borrar",
    Cell: props => {
      const { infoUser, setInfoUser } = useContext(LoginContext)

      const urleliminar = urlApiServer + "deleteClient";

      async function Eliminar() {


        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {

            const data = JSON.stringify({
              Id: props.row.original.Id,
              company: infoUser.company
            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar cliente' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Cliente Eliminado',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }

      return (
        <>
          <NavLink><FaUserMinus onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsClient = [
  {
    Header: "Nombre",
    accessor: "cliente"
  },
  {
    Header: "Telefono",
    accessor: "telefono"
  },
  {
    Header: "Contacto",
    accessor: "contacto"
  },
  {
    Header: "Correo",
    accessor: "correo"
  },
  {
    Header: "Documento",
    accessor: "nitocc"
  },
  {
    Header: "Direccion",
    accessor: "direccion"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaUserCog className='iconEdit' /></NavLink>
        </>
      )
    }
  }
]

export const columnsUsers = [
  {
    Header: "Nombre",
    accessor: "nombre"
  },
  {
    Header: "Apellidos",
    accessor: "apellidos"
  },
  {
    Header: "Tipo de documento",
    accessor: "tipo"
  },
  {
    Header: "Nro. Documento",
    accessor: "cc"
  },
  {
    Header: "Correo",
    accessor: "correo"
  },
  {
    Header: "Telefono",
    accessor: "telefono"
  },
  {
    Header: "Roles",
    accessor: "roles"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaUserEdit className='iconEdit' /></NavLink>
        </>
      )
    }
  }, {
    Header: "Borrar",
    Cell: props => {
      const { infoUser, setInfoUser } = useContext(LoginContext)
      const urleliminar = urlApiServer + "borrarUsers";

      async function Eliminar() {


        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {

          if (resultConfirm.isConfirmed) {

            const data = JSON.stringify({
              empresa: infoUser.company,
              Id: props.row.original.Id,
            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar cliente' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Producto Eliminado',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }
      return (
        <>
          <NavLink><FaUserTimes onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsProductsAdmin = [
  {
    Header: "Productos",
    accessor: "producto"
  },
  {
    Header: "Material",
    accessor: "material"
  },
  {
    Header: "Metrolineal",
    accessor: "largo"
  },
  {
    Header: "Ancho",
    accessor: "ancho"
  },
  {
    Header: "grosor",
    accessor: "calibre"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilRuler className='iconEdit' /></NavLink>
        </>
      )
    }
  }, {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "borrarProducto";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {
        // console.log("props.row.original: ", props.row.original)

        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {
            const data = JSON.stringify({
              empresa: infoUser.company,
              Id: props.row.original.Id,
              image3d: props.row.original.preview,
              image2d: props.row.original.model

            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar Productos' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Producto Eliminado',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }

      return (
        <>
          <NavLink><FaTrash onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsProductsAdminGeneric = [
  {
    Header: "Productos",
    accessor: "producto"
  },
  {
    Header: "Precio",
    accessor: "precioitem"
  },
  {
    Header: "Cantidad",
    accessor: "cantidaditem"
  },
  {
    Header: "Unidad",
    accessor: "unit"
  },
  {
    Header: "Iva",
    accessor: "iva"
  },
  {
    Header: "Editar",
    Cell: props => {
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function on() {
        if (infoUser.companyType === "Generica") {
          props.id(props.row.original.Id, props.row.original)
        } else {
          props.edit()
          props.id(props.row.original.Id, props.row.original)
        }
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilRuler className='iconEdit' /></NavLink>
        </>
      )
    }
  }, {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "borrarProducto";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {


        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {

            // console.log("props.row.original: ", props.row.original)
            const data = JSON.stringify({
              empresa: infoUser.company,
              Id: props.row.original.Id,
              image3d: props.row.original.preview,
              image2d: props.row.original.model

            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar Productos' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Producto Eliminado',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }

      return (
        <>
          <NavLink><FaTrash onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsProducts = [
  {
    Header: "Productos",
    accessor: "producto"
  },
  {
    Header: "Material",
    accessor: "material"
  },
  {
    Header: "Metrolineal",
    accessor: "largo"
  },
  {
    Header: "Ancho",
    accessor: "ancho"
  },
  {
    Header: "grosor",
    accessor: "calibre"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilRuler className='iconEdit' /></NavLink>
        </>
      )
    }
  }
]

export const columnsTexture = [
  {
    Header: "Material",
    accessor: "texture",
    Cell: props => {
      return (
        <img src={urlApiServer + "viewTexture/" + props.value} alt="Textura" className='textureImageDimensions' />
      );
    }
  },
  {
    Header: "Nombre del material",
    accessor: "nameTexture"
  },
  {
    Header: "Largo",
    accessor: "length"
  },
  {
    Header: "Ancho",
    accessor: "width"
  },
  {
    Header: "Precio",
    accessor: "price"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilRuler className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "deleteTexture";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {

        const data = JSON.stringify({
          empresa: infoUser.company,
          Id: props.row.original.Id,
          ImageDelete: props.row.original.texture
        })
        const eliminarHeaders = new Headers();
        eliminarHeaders.append("Content-Type", "application/json")

        const requesoption = {
          method: 'DELETE',
          headers: eliminarHeaders,
          body: data,
          redirect: 'follow',
        }
        Swal.fire({
          title: 'Eliminar Imagen',
          text: "Los productos con este material ya no se veran con este material, ¿Seguro?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Eliminar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if (data.status === 200) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Textura Eliminada',
                    text: data.msj,
                  })
                }

                if (data.status === 304) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.msj,
                  })
                }
              })
            props.functionNew()
          }
        })

      }

      return (
        <>
          <NavLink><FaTrash onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsCompanyAdmin = [
  {
    Header: "Empresa",
    accessor: "nameCompany"
  },
  {
    Header: "Telefono",
    accessor: "companyPhone"
  },
  {
    Header: "Nit",
    accessor: "companyNit"
  },
  {
    Header: "Celular",
    accessor: "companyMobile"
  },
  {
    Header: "Direccion",
    accessor: "companyAddress"
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilRuler className='iconEdit' /></NavLink>
        </>
      )
    }
  }, {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "borrarProducto";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {
        // console.log("props.row.original: ", props.row.original)

        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {
            const data = JSON.stringify({
              empresa: infoUser.company,
              Id: props.row.original.Id,
              image3d: props.row.original.preview,
              image2d: props.row.original.model

            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar Productos' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Producto Eliminado',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }

      return (
        <>
          <NavLink><FaTrash onClick={Eliminar} className='iconPdf' /></NavLink>
        </>
      )
    }
  }
]

export const columnsBillsAdmin = [
  {
    Header: "Code",
    accessor: "Id"
  },
  {
    Header: "Nombre",
    accessor: "nombre"
  },
  {
    Header: "Cedula",
    accessor: "cedula"
  },
  {
    Header: "Direccion",
    accessor: "direccion"
  },
  {
    Header: "Ciudad",
    accessor: "ciudad"
  },
  {
    Header: "Factura",
    accessor: "nrofactura",
    Cell: props => {

      let indexReturned = 0


      props.data.map((itemTable, index) => {
        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index

        }
      })

      async function on() {
        // props.showLoader(indexReturned, props.row.original.nrocotizacion)
        // props.power()
        console.log("ORIGINAL: ", props.row)
        props.importantData(indexReturned)
        props.handleShowBill(props.row.original)
        // props.showLoader()
        props.id(props.row.original.Id)

      }

      return (
        <>
          <div className='iconsPdf'>
            <NavLink onClick={on}><FaClipboardList className='iconPdf' /></NavLink>
          </div>
        </>
      )

      // const abono = value || 0;
      // return <span>{abono}</span>;
    }
  },
  {
    Header: "Editar",
    Cell: props => {

      let indexReturned = 0

      props.data.map((itemTable, index) => {

        if (props.row.original.Id === itemTable.Id) {
          indexReturned = index
        }
      })

      async function on() {
        props.edit()
        props.importantData(indexReturned)
        props.id(props.row.original.Id)
      }
      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilAlt className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Borrar",
    Cell: props => {
      const urlremoveBill = urlApiServer + "removeBill";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {

        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta factura?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {

            const data = JSON.stringify({
              empresa: infoUser.company,
              id: props.row.original.Id,
            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urlremoveBill, requesoption).then(response => response.json())
              .then(data => {
                if (200 === data.status) {
                  Swal.fire({
                    icon: 'success',
                    title: data.msj,
                    text: 'Has eliminado este dato correctamente',
                  })
                }else{
                  Swal.fire({
                    icon: 'success',
                    title: data.msj,
                    text: 'El servidor no pudo eliminar este dato, prueba intentando mas tarde',
                  })
                }
              })
            props.handleRefreshPage()
          }
        })
      }
      return (
        <>
          <NavLink><FaTrashAlt onClick={Eliminar} className='iconPdf' /></NavLink>
        </>)
    }
  }
]

export const columnsBills = [
  {
    Header: "Code",
    accessor: "Id"
  },
  {
    Header: "Nombre",
    accessor: "nombre"
  },
  // {
  //   Header: "Telefono",
  //   accessor: "telefono"
  // },
  // {
  //   Header: "Cedula",
  //   accessor: "cedula"
  // },
  // {
  //   Header: "Direccion",
  //   accessor: "direccion"
  // },
  {
    Header: "Ciudad",
    accessor: "ciudad"
  },
  {
    Header: "Editar",
    Cell: props => {

      let indexReturned = 0

      props.data.map((itemTable, index) => {

        if (props.row.original.nrocotizacion === itemTable.nrocotizacion) {
          indexReturned = index
        }
      })

      async function on() {
        props.edit()
        props.importantData(indexReturned)
        props.id(props.row.original.nrocotizacion)
      }
      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaPencilAlt className='iconEdit' /></NavLink>
        </>
      )
    }
  },
  {
    Header: "Borrar",
    Cell: props => {
      const urleliminar = urlApiServer + "Borrar-quotes";
      const { infoUser, setInfoUser } = useContext(LoginContext)

      async function Eliminar() {

        Swal.fire({
          icon: 'warning',
          title: '¿Estas seguro?',
          text: '¿Seguro que quieres eliminar esta cotización?',
          allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
          confirmButtonText: 'Aceptar',
          showCancelButton: true
        }).then(async (resultConfirm) => {
          if (resultConfirm.isConfirmed) {

            const data = JSON.stringify({
              empresa: infoUser.company,
              nrocotizacion: props.row.original.nrocotizacion,
              ImageDelete: props.row.original.image
            })
            const eliminarHeaders = new Headers();
            eliminarHeaders.append("Content-Type", "application/json")

            const requesoption = {
              method: 'DELETE',
              headers: eliminarHeaders,
              body: data,
              redirect: 'follow',
            }

            await fetch(urleliminar, requesoption).then(response => response.json())
              .then(data => {
                if ('Borrar Cotizacion' === data.msj) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Cotizacion Eliminada',
                    text: 'Has eliminado este dato correctamente',
                  })
                }
              })
            props.functionNew()
          }
        })
      }
      return (
        <>
          <NavLink><FaTrashAlt onClick={Eliminar} className='iconPdf' /></NavLink>
        </>)
    }
  },
  {
    Header: "Editar",
    Cell: props => {

      async function on() {
        props.edit()
        props.id(props.row.original.Id)
      }

      return (
        <>
          <NavLink onClick={on} className='Textregister'><FaUserCog className='iconEdit' /></NavLink>
        </>
      )
    }
  }
]