import '../styles/Quotes.css'
import { columnsProducts, columnsProductsAdmin, columnsProductsAdminGeneric } from '../Component/Hooks/ColumTables'
import Table from '../Component/Tabla/Tabla'
import { useContext, useEffect, useState } from 'react'
import LoginContext from '../Context/Logincontext';
import { urlApiServer } from '../Config/Config'
import EditProduct3D from '../Component/Hooks/EditProduct3D'
import Swal from 'sweetalert2';
import { info } from 'numpy-matrix-js/lib/main/debug/export';
let splitImg = []

const Products = () => {

    const urlgetConfig = urlApiServer + "getConfig";
    const urlGetProduct = urlApiServer + "products"
    const [state, setState] = useState("0")
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [powerEdit, setPowerEdit] = useState(true)
    const [productGetTable, setProductGetTable] = useState([])
    const [id, setId] = useState()
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")


    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlGetProduct, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setProductGetTable(quotesResponse.reverse());
                })
                .catch(error => {
                });

        }())

    }, [state])


    async function handleName() {
        if (state === "0") {
            setState("1")
        } else {
            setState("0")
        }
    }

    async function edit() {
        if (powerEdit === true) {
            setPowerEdit(false)
        } else {
            setPowerEdit(true)
        }
    }

    async function Cerrar() {
        if (Cerrar) {
            setInfoUser({
                login: false
            })
        } else {
        }
    }

    async function handleData(id, product) {
        if (infoUser.companyType === "Generica") {
            setId(id)
            handleEditProduct(product, id)
        } else {
            setId(id)
        }
    }

    const handleEditProduct = async (productEdit, id) => {
        await Swal.fire({
            title: 'EDITAR PRODUCTO',
            html:
                `
          <div>
              <p>Producto:</p>
              <input type="text" id="producto" value="`+ productEdit.producto + `" class="swal2-input" placeholder="Producto">
          </div>
          <div>
              <p>Precio del producto:</p>
              <input type="text" id="precioitem" value="`+ productEdit.precioitem + `" class="swal2-input" placeholder="Precio del producto">
          </div>
          <div>
          <p>Cantidad:</p>
              <input type="text" id="cantidadItem" value="`+ productEdit.cantidaditem + `" class="swal2-input" placeholder="Cantidad">
          </div>
          <div>
            <p>Iva:</p>
            <select id="mySelectUnit" class="ivaSelect">
            <option value="`+ productEdit.unit + `" >Unidad: ` + productEdit.unit + `</option>
            <option value="U">U (Unidad)</option>
            <option value="M">M (Metros)</option>
            <option value="C">C (Centimetros)</option>
            <option value="P">P (Pulgadas)</option>
            <option value="K">K (Kilogramos)</option>
            <option value="G">G (Gramos)</option>
            <option value="L">L (Libras)</option>
            <option value="LT">LT (Litros)</option>
            <option value="R">R (Radianes)</option>
            <option value="R">S (Segundos)</option>
            <option value="H">H (Horas)</option>
            <option value="Dia">Dias</option>
            <option value="Mes">Meses</option>
            <option value="Año">Años</option>
            </select>
          </div>
          <div>
              <p>Iva:</p>
              <select id="mySelect" class="ivaSelect">
                  <option value="`+ productEdit.iva + `" >Iva: ` + productEdit.iva + `</option>
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="16">16%</option>
                  <option value="19">19%</option>
              </select>
          </div>
    
          `,
            showCancelButton: true,
            focusConfirm: false,
            preConfirm: () => {
                return [
                  /*0: */document.getElementById('producto').value,
                  /*5: */document.getElementById('cantidadItem').value,
                  /*6: */document.getElementById('precioitem').value,
                  /*8: */document.getElementById('mySelectUnit').value,
                  /*8: */document.getElementById('mySelect').value
                ]
            }
        }).then(async response => {
            const producto = response.value[0]
            const cantidad = response.value[1]
            const precio = response.value[2]
            const unit = response.value[3]
            const iva = response.value[4]

            // console.log("productEdit.id: ", id)
            if (response.value) {

                if (producto.length > 500) {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'El maximo de caracteres para el detalle es de 500',
                    })
                }

                if (precio.length > 100) {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'El maximo de caracteres para el precio es de 100',
                    })
                }
                const urlEdit = urlApiServer + "editproduct";
                const data = JSON.stringify({
                    empresa: infoUser.company,
                    Id: id,
                    product: producto,
                    material: productEdit.material,
                    long: productEdit.long,
                    caliber: productEdit.caliber,
                    price: precio,
                    iva: iva,
                    modeljson: null,
                    Model: null,
                    image: null,
                    ancho: null,
                    cantidad: cantidad,
                    unit: unit
                });

                const registerHeaders = new Headers();
                registerHeaders.append("Content-Type", "application/json");

                const requesoption = {
                    method: 'PUT',
                    headers: registerHeaders,
                    body: data,
                    redirect: 'follow'
                };

                await fetch(urlEdit, requesoption)
                    .then(response => response.json())
                    .then(async dataResponse => {
                        // sendfilePreview(file2d);
                        // sendfilePreview(props.request.image);
                        // props.fullBack();
                        if (dataResponse.status === 560) {
                            await Swal.fire({
                                icon: 'error',
                                text: 'Ha ocurrido un problema al intentar editar un producto.',
                                confirmButtonText: 'Aceptar',
                            }).then((result) => {
                            })
                        } else {
                            handleName()
                        }
                    });
            }
        })
    }


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (!event.target.closest('#nav1')) {

        }
    };

    return (
        <>

            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    (powerEdit === true) ? (
                        <>
                            {(infoUser.role === 'Administrador') ? (
                                <>
                                    <Table columns={(infoUser.companyType === "Generica") ? (columnsProductsAdminGeneric) : (columnsProductsAdmin)} data={productGetTable} edit={edit} function={handleName} id={handleData} />
                                </>
                            ) : (
                                <Table columns={columnsProducts} data={productGetTable} edit={edit} function={handleName} id={handleData} />
                            )}
                        </>
                    ) : (
                        (infoUser.companyType === "Marmol") && (<EditProduct3D data={productGetTable} edit={edit} function={handleName} id={id} />)
                    )
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )
}

export default Products