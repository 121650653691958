import { useRef, useEffect, useState, useContext } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FaBars } from 'react-icons/fa'
import '../Component/ProductsIn3djs/ProductsIn3djs.css';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { urlApiServer } from "../Config/Config";
import LoginContext from "../Context/Logincontext";
import texture1 from '../Textures/17538.jpeg';
import { NavLink } from "react-router-dom";
import { CgMaximize } from 'react-icons/cg'
import Swal from 'sweetalert2'

let Plans = {
  name: 'Mesa en L',
  coordinates:{
    thickeningOfTheMainModelCoordinates: {
    },
    mesonCoordinates: {
    },
    splashbackCoordinates: {
    },

  },
  Holes: {
  },
  Lines:{

  },
  Texts:{

  },
  measure: {
    thickness:0.5,
    highThickness:1,
    highsplashback: 0.7,
    long1:20 ,
    width1:7,
    widthWell:4,
    long2:21,
    width2:7,
    longWell:5,
    distanceX:2,
    distanceY:1.5,
    widthWell2:6,
    longWell2:4.5,
    distanceX2:1.5,
    distanceY2:3,
    regrown:0.5,
    splashbackThickness:0.2,
  },
  texture: 0,
  prices : 0,
  long: 0,
  pricesCalc: 0,
  width: 0
}

let backupPlans = {};

let prePlans = {};
const MesaenLGrande = () => {
  const [models, setModels] = useState([])
  const [selectModels, setSelectModels] = useState(false)
  const [mesurementText, setMesurementText] = useState()
  const [mesurementTextSpanish, setMesurementTextSpanish] = useState()
  const [mesurementNumber, setMesurementNumber] = useState()
  const [update, setUpdate] = useState(false)
  const { infoUser, setInfoUser } = useContext(LoginContext)
  const [textureSelect, setTextureSelect] = useState(texture1)
  const [textureName, setTextureName] = useState()
  const [showTexture, setShowTexture] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [modelingPlane, setModelingPlane] = useState(false);
  const [textureGetTable, setTextureGetTable] = useState([])
  const [isFullScreenComputer, setFullScreenComputer] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [prices, setPrices] = useState();
  const [saveTitleTextHigh, setSeveTitleTextHigh] = useState(undefined)
  const [saveTitleTextSpanishHigh, setSeveTitleTextSpanishHigh] = useState(undefined)
  const [savehigh, setSeveHigh] = useState(undefined)


  const Mesurements = useRef(null);
  const mountRef = useRef(null);
  const divRef = useRef(null);


  const urlgetModel = urlApiServer + 'premodels'
  const urlgetTexture = urlApiServer + "getTexture"
  const urlModels = urlApiServer + "addmodels";


  useEffect(() => {
    if ((infoUser.login === false) || (infoUser.role !== "SuperUsuario")) {
      window.location.href = '/login';
    }
  }, [])

  let preview = []
  let view = []
  let PreViewTexture = []
  let viewTexture = []



  const toggleFullScreen = () => {
    setFullScreen(!isFullScreen);
    closeFullscreen()
  };

  const openFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setFullScreenComputer(true);
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreenComputer(false);
  };



  const toggleFullScreenComputer = () => {
    if (isFullScreenComputer) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  };

  function fullQuit() {
    window.location.href = './Productos'
  }

  function exitBack() {
    setModelingPlane(!modelingPlane)
    if (modelingPlane === true) {
      setUpdate(!update)
    }
  }


  const handleClickExtent = (event) => {
    if (Mesurements.current && !Mesurements.current.contains(event.target)) {
      document.getElementsByClassName('Mesurement')[0].style.display = 'none';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickExtent);

    return () => {
      document.removeEventListener('mousedown', handleClickExtent);
    };
  }, []);

  useEffect(() => {
    (async function () {
      const InicioHeaders = new Headers();
      InicioHeaders.append("Content-Type", "application/json");
      InicioHeaders.append("company", infoUser.company);

      const requesoption = {
        method: 'GET',
        headers: InicioHeaders,
        redirect: 'follow'
      }

      await fetch(urlgetModel, requesoption)
        .then(response => response.json())
        .then(quotesResponse => {
          setModels(quotesResponse);
        })
        .catch(error => {
        });

    }())

  }, [])

  useEffect(() => {
    (async function () {
      const InicioHeaders = new Headers();
      InicioHeaders.append("Content-Type", "application/json");
      InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

      const requesoption = {
        method: 'GET',
        headers: InicioHeaders,
        redirect: 'follow'
      }

      await fetch(urlgetTexture, requesoption)
        .then(response => response.json())
        .then(quotesResponse => {
          setTextureGetTable(quotesResponse);
        })
        .catch(error => {
        });
    }())

  }, [])

  let res = models.filter((item, index) => {
    view.push(item.preview)

    return view.indexOf(item.preview) === index
  })

  let textureView = textureGetTable.filter((texture, index) => {
    viewTexture.push(texture.texture)

    return viewTexture.indexOf(texture.texture) === index
  })

  async function handleMesurement(e) {
    const mesurement = e.target.value
    setMesurementNumber(e.target.value)
    Plans.measure[mesurementText] = mesurement * 10
  }

  async function handleHeigth(e) {
    const heigth = e.target.value
    setSeveHigh(e.target.value)
    Plans.measure[saveTitleTextHigh] = heigth * 10
  }


  async function handleModel(item) {
    prePlans = JSON.parse(item.modeljson)
    Plans = JSON.parse(item.modeljson)
    setSelectModels(!selectModels)
  }

  async function handleTexture(textures) {
    setTextureSelect(urlApiServer + "viewTexture/" + textures.texture)
    setTextureName(textures.nameTexture)
    setPrices(parseFloat(textures.price) / parseFloat(textures.width) / parseFloat(textures.length))
    Plans.texture.push([textures.texture])
    Plans.prices.push(parseFloat(textures.price) / parseFloat(textures.width) / parseFloat(textures.length))
  }

  async function handleModelingPlane(file) {
    Swal.fire({
      title: 'Nombre del producto e iva',
      html:
        `
            <div>
              <p>Producto:</p>
              <input type="text" value="${Plans.name}" id="producto" class="swal2-input" placeholder="Producto">
            </div>
          `,
      showCancelButton: true,
      preConfirm: () => {
        return [
            /*0: */document.getElementById('producto').value,
        ]
      }
    }).then(async response => {
      const data = JSON.stringify({
        empresa: infoUser.company,
        name: response.value[0],
        modeljson: backupPlans,
        preview: file.name
      })

      const registerHeaders = new Headers();
      registerHeaders.append("Content-Type", "application/json")

      const requesoption = {
        method: 'POST',
        headers: registerHeaders,
        body: data,
        redirect: 'follow'
      }

      await fetch(urlModels, requesoption).then(response => response.json())
        .then(dataResponse => {
          sendfilePremodels(file)
        })
    })
  }

  const urlsubirPreview = urlApiServer + "Uploadfilepremodels";

  async function sendfilePremodels(file) {
    try {
      const formdata = new FormData();

      formdata.append("file", file);

      formdata.append(file.name, "foto");
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(urlsubirPreview, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    } catch (error) {
      console.log("Error: ", error)
    }
  }

  async function handleUpdate() {
    setUpdate(!update)
  }

  useEffect(() => {
    if (Plans.length === 0) {

    } else {
      Plans = {
        name: 'Mesa en L',
        coordinates:{
            thickeningOfTheMainModelCoordinates:{
                piece: 'not main',
                depth: Plans.measure.highThickness*2,
                points:[
                    [0, 0],
                    [Plans.measure.regrown*2, 0],
                    [Plans.measure.regrown*2, Plans.measure.width1*2 - Plans.measure.regrown*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2 + Plans.measure.regrown*2, Plans.measure.width1*2 - Plans.measure.regrown*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2 + Plans.measure.regrown*2, Plans.measure.long2*2 - Plans.measure.regrown*2],
                    [Plans.measure.long1*2, Plans.measure.long2*2 - Plans.measure.regrown*2],
                    [Plans.measure.long1*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.width1*2],
                    [0, Plans.measure.width1*2],
                    [0, 0]
                ]
            },
      
            mesonCoordinates:{
                piece: 'main',
                depth: Plans.measure.thickness*2,
                points: [
                    [0, 0],
                    [Plans.measure.long1*2, 0],
                    [Plans.measure.long1*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.width1*2],
                    [0, Plans.measure.width1*2],
                    [0, 0]
                ],
            },
      
            splashbackCoordinates:{
                piece: 'not main',
                depth: Plans.measure.highsplashback*2,
                points:[
                    [0, 0],
                    [Plans.measure.long1*2, 0],
                    [Plans.measure.long1*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.splashbackThickness*2, Plans.measure.long2*2],
                    [Plans.measure.long1*2 - Plans.measure.splashbackThickness*2, Plans.measure.splashbackThickness*2],
                    [0, Plans.measure.splashbackThickness*2],
                    [0, 0]
                ]
            },
      
        },
        Holes: {
          mesonHoles1:{
            type: "stove",
            points: [
            [Plans.measure.distanceX*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
            [Plans.measure.distanceX*2+Plans.measure.longWell*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
            [Plans.measure.distanceX*2+Plans.measure.longWell*2,Plans.measure.width1*2-Plans.measure.distanceY*2],
            [Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2],
            [Plans.measure.distanceX*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
            ]
          },
          mesonHoles2:{
          type: "dishwasher",
          points: [
            [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
            [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
            [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2],
            [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2],
            [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
          ]
          }
        },
        Lines:{
          line1:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2,
            line: [
              [0, 0],
              [0, -3],
              [Plans.measure.long1*2, -3],
              [Plans.measure.long1*2, 0],
              [0, 0],
            ]
          },
          line2:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2,
            line: [
              [Plans.measure.long1*2, 0],
              [Plans.measure.long1*2+5, 0],
              [Plans.measure.long1*2+5, Plans.measure.long2*2],
              [Plans.measure.long1*2, Plans.measure.long2*2],
              [Plans.measure.long1*2, 0]
            ]
          },
          line3:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.long1*2, Plans.measure.long2*2],
              [Plans.measure.long1*2, Plans.measure.long2*2+4],
              [Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2+4],
              [Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2],
              [Plans.measure.long1*2, Plans.measure.long2*2]
            ]
          },
          line4:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [0, 0],
              [-5, 0],
              [-5, Plans.measure.width1*2],
              [0, Plans.measure.width1*2],
              [0, 0]
            ]
          },
          line5:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
              [Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
            ]
          },
          line6:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2],
              [Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2],
            ]
          },
          line7:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
              [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2],
            ]
          },
          line8:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
              [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2],
            ]
          },
          line9:{
            rotate: false,
            depth: Plans.measure.highThickness*2,
            line: [
              [0, Plans.measure.width1*2],
              [0, Plans.measure.width1*2+6],
              [Plans.measure.regrown*2, Plans.measure.width1*2+6],
              [Plans.measure.regrown*2, Plans.measure.width1*2],
              [0, 0],
            ]
          },
          line10:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2,
            line: [
              [0, 0],
              [-5, 0],
              [-5, Plans.measure.splashbackThickness*2],
              [0, Plans.measure.splashbackThickness*2],
              [0, 0],
      
            ]
          },
          line11:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2), Plans.measure.long2*2],
              [Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2), Plans.measure.long2*2-Plans.measure.distanceY2*2],
            ]
          },
          line12:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2)],
              [Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2)],
            ]
          },
          line13:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [0, Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2)],
              [Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2)],
            ]
          },
          line14:{
            rotate: false,
            depth: Plans.measure.highThickness*2+Plans.measure.thickness*2,
            line: [
              [Plans.measure.distanceX*2+(Plans.measure.longWell*2/2), Plans.measure.width1*2],
              [Plans.measure.distanceX*2+(Plans.measure.longWell*2/2), Plans.measure.width1*2-Plans.measure.distanceY*2],
            ]
          },
          line15:{
            rotate: true,
            rotateX: Math.PI/2,
            rotateY: Math.PI/2,
            PositionX: Plans.measure.long1*2/2,
            PositionY: Plans.measure.width1*2,
            line: [
              [0, Plans.measure.highThickness*2],
              [0, Plans.measure.highThickness*2+Plans.measure.thickness*2],
              [6, Plans.measure.highThickness*2+Plans.measure.thickness*2],
              [6, Plans.measure.highThickness*2],
              [0, Plans.measure.highThickness*2],
            ]
          },
          line16:{
            rotate: true,
            rotateX: Math.PI/2,
            rotateY: Math.PI,
            PositionX: Plans.measure.long1*2-Plans.measure.width2*2,
            PositionY: Plans.measure.long2*2,
            line: [
              [0, 0],
              [0, Plans.measure.highThickness*2],
              [6, Plans.measure.highThickness*2],
              [6, 0],
              [0, 0]
            ]
          },
          line17:{
            rotate: true,
            rotateX: Math.PI/2,
            rotateY: Math.PI/2,
            PositionX: Plans.measure.long1*2,
            PositionY: Plans.measure.long2*2,
            line: [
              [0, Plans.measure.highThickness*2+Plans.measure.thickness*2],
              [0, Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2],
              [4, Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2],
              [4, Plans.measure.highThickness*2+Plans.measure.thickness*2],
              [0, Plans.measure.highThickness*2+Plans.measure.thickness*2],
            ]
          },
        },
        Texts:{
          text:{
            TitleText: 'long1',
            TitleTextSpanish: 'Largo 1',
            measureM: (Plans.measure.long1/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX: Plans.measure.long1*2/2,
            PositionY: -5,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2
          },
          text2:{
            TitleText: 'long2',
            TitleTextSpanish: 'Largo 2',
            measureM: (Plans.measure.long2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.long1*2+5,
            PositionY: Plans.measure.long2*2/2+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2
          },
          text3:{
            TitleText: 'width2',
            TitleTextSpanish: 'Ancho 2',
            measureM: (Plans.measure.width2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX: Plans.measure.long1*2-Plans.measure.splashbackThickness*2-Plans.measure.width2*2/2+2,
            PositionY: (Plans.measure.long2*2+Plans.measure.splashbackThickness*2+3.5),
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text4:{
            TitleText: 'distanceY2',
            TitleTextSpanish: 'Distancia posuelo en Y2',
            measureM: (Plans.measure.distanceY2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2),
            PositionY: Plans.measure.long2*2-(Plans.measure.distanceY2*2/2)+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text5:{
            TitleText: 'distanceX2',
            TitleTextSpanish: 'Distancia posuelo en X2',
            measureM: (Plans.measure.distanceX2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI,
            PositionX: Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2,
            PositionY: Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2),
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text6:{
            TitleText: 'width1',
            TitleTextSpanish: 'Ancho 1',
            measureM: (Plans.measure.width1/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: -5,
            PositionY: (Plans.measure.width1*2/2)+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text7:{
            TitleText: 'distanceX',
            TitleTextSpanish: 'Distancia posuelo en X',
            measureM: (Plans.measure.distanceX/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX: Plans.measure.distanceX*2/2+2,
            PositionY: Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2),
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text8:{
            TitleText: 'distanceY',
            TitleTextSpanish: 'Distancia posuelo en Y',
            measureM: (Plans.measure.distanceY/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.longWell*2/2+Plans.measure.distanceX*2,
            PositionY: Plans.measure.width1*2-Plans.measure.distanceY*2/2+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text9:{
            TitleText: 'longWell',
            TitleTextSpanish: 'Largo posuelo',
            measureM: (Plans.measure.longWell/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX: Plans.measure.distanceX*2+Plans.measure.widthWell*2,
            PositionY: Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text10:{
            TitleText: 'widthWell',
            TitleTextSpanish: 'Ancho posuelo',
            measureM: (Plans.measure.widthWell/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.distanceX*2+Plans.measure.longWell*2,
            PositionY: Plans.measure.width1*2-(Plans.measure.widthWell*2/2)-Plans.measure.distanceY*2+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text11:{
            TitleText: 'longWell2',
            TitleTextSpanish: 'Largo pusuelo 2',
            measureM: (Plans.measure.longWell2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI,
            PositionX: Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+(Plans.measure.longWell2*2/2)+2,
            PositionY: Plans.measure.long2*2-Plans.measure.widthWell2*2-Plans.measure.distanceY2*2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text12:{
            TitleText: 'widthWell2',
            TitleTextSpanish: 'Ancho posuelo 2',
            measureM: (Plans.measure.widthWell2/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,
            PositionY: Plans.measure.long2*2-Plans.measure.widthWell2*2-Plans.measure.distanceY2*2+(Plans.measure.widthWell2*2/2)+2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2
          },
          text13:{
            TitleText: 'regrown',
            TitleTextSpanish: 'Rengrueso',
            measureM: (Plans.measure.regrown/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX:Plans.measure.regrown*2+2,
            PositionY: Plans.measure.width1*2+6,
            PositionZ:Plans.measure.highThickness*2
          },
          text14:{
            TitleText: 'splashbackThickness',
            TitleTextSpanish: 'Salpicadero',
            measureM: (Plans.measure.splashbackThickness/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX:0,
            PositionY: Plans.measure.splashbackThickness*2/2,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2
          },
          text15:{
            TitleText: 'thickness',
            TitleTextSpanish: 'Alto meson',
            measureM: (Plans.measure.thickness/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: Math.PI,
            PositionX:(Plans.measure.long1*2/2)+2,
            PositionY: Plans.measure.width1*2+5,
            PositionZ: Plans.measure.highThickness*2+(Plans.measure.thickness*2/2)-1
          },
          text16:{
            TitleText: 'highThickness',
            TitleTextSpanish: 'Alto del rengrueso',
            measureM: (Plans.measure.highThickness/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.long1*2-Plans.measure.width2*2-5,
            PositionY: Plans.measure.long2*2+2,
            PositionZ: Plans.measure.highThickness*2/2-1
          },
          text17:{
            TitleText: 'highsplashback',
            TitleTextSpanish: 'Alto del salpicadero',
            measureM: (Plans.measure.highsplashback/10).toFixed(2),
            RotateX: Math.PI/2,
            RotateY: -Math.PI/2,
            PositionX: Plans.measure.long1*2,
            PositionY:  Plans.measure.long2*2+6,
            PositionZ: Plans.measure.highThickness*2+Plans.measure.thickness*2+(Plans.measure.highsplashback*2/2)
          },
        },
        measure: {
          thickness: Plans.measure.thickness,
          highThickness:Plans.measure.highThickness,
          highsplashback: Plans.measure.highsplashback,
          long1: Plans.measure.long1,
          width1: Plans.measure.width1,
          widthWell: Plans.measure.widthWell,
          long2: Plans.measure.long2,
          width2: Plans.measure.width2,
          longWell: Plans.measure.longWell,
          distanceX: Plans.measure.distanceX,
          distanceY: Plans.measure.distanceY,
          widthWell2: Plans.measure.widthWell2,
          longWell2: Plans.measure.longWell2,
          distanceX2: Plans.measure.distanceX2,
          distanceY2: Plans.measure.distanceY2,
          regrown: Plans.measure.regrown,
          splashbackThickness: Plans.measure.splashbackThickness,
        },
        texture: 0,
        prices : 0,
        long: (parseFloat(Plans.measure.long1) + parseFloat(Plans.measure.long2) - parseFloat(Plans.measure.width1))/10,
        pricesCalc: ((Plans.measure.long2*10)*(Plans.measure.width2*10)+((Plans.measure.long1*10)-Plans.measure.width2*10)*(Plans.measure.width1*10)),
        width: Plans.measure.width1/10 === Plans.measure.width2/10 ? Plans.measure.width1/10 : Math.max(Plans.measure.width1/10, Plans.measure.width2/10)
      }


      backupPlans.name = Plans.name;
      backupPlans.coordinates = {
        thickeningOfTheMainModelCoordinates: {
          piece: 'not main',
          depth: 'Plans.measure.highThickness*2',
          points:[
              '[0, 0]',
              '[Plans.measure.regrown*2, 0]',
              '[Plans.measure.regrown*2, Plans.measure.width1*2 - Plans.measure.regrown*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2 + Plans.measure.regrown*2, Plans.measure.width1*2 - Plans.measure.regrown*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2 + Plans.measure.regrown*2, Plans.measure.long2*2 - Plans.measure.regrown*2]',
              '[Plans.measure.long1*2, Plans.measure.long2*2 - Plans.measure.regrown*2]',
              '[Plans.measure.long1*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.width1*2]',
              '[0, Plans.measure.width1*2]',
              '[0, 0]'
          ]
        },
        mesonCoordinates:{
          piece: 'main',
          depth: 'Plans.measure.thickness*2',
          points: [
              '[0, 0]',
              '[Plans.measure.long1*2, 0]',
              '[Plans.measure.long1*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.width2*2, Plans.measure.width1*2]',
              '[0, Plans.measure.width1*2]',
              '[0, 0]'
          ],       
        },
        splashbackCoordinates:{
          piece: 'not main',
          depth: 'Plans.measure.highsplashback*2',
          points:[
              '[0, 0]',
              '[Plans.measure.long1*2, 0]',
              '[Plans.measure.long1*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.splashbackThickness*2, Plans.measure.long2*2]',
              '[Plans.measure.long1*2 - Plans.measure.splashbackThickness*2, Plans.measure.splashbackThickness*2]',
              '[0, Plans.measure.splashbackThickness*2]',
              '[0, 0]'
          ]      
        }
      }
      backupPlans.Holes = {
        mesonHoles1:{
          type: "stove",
          points: [
            '[Plans.measure.distanceX*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]',
            '[Plans.measure.distanceX*2+Plans.measure.longWell*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]',
            '[Plans.measure.distanceX*2+Plans.measure.longWell*2,Plans.measure.width1*2-Plans.measure.distanceY*2]',
            '[Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2]',
            '[Plans.measure.distanceX*2,Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]'
          ]
        },
        mesonHoles2:{
          type: "dishwasher",
          points: [
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
          ]
        }
      }
      
      backupPlans.Lines = {
        line1:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2',
          line: [
            '[0, 0]',
            '[0, -5]',
            '[Plans.measure.long1*2, -5]',
            '[Plans.measure.long1*2, 0]',
            '[0, 0]',
          ]
        },
        line2:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2',
          line: [
            '[Plans.measure.long1*2, 0]',
            '[Plans.measure.long1*2+5, 0]',
            '[Plans.measure.long1*2+5, Plans.measure.long2*2]',
            '[Plans.measure.long1*2, Plans.measure.long2*2]',
            '[Plans.measure.long1*2, 0]'
          ]
        },
        line3:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.long1*2, Plans.measure.long2*2]',
            '[Plans.measure.long1*2, Plans.measure.long2*2+4]',
            '[Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2+4]',
            '[Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2]',
            '[Plans.measure.long1*2, Plans.measure.long2*2]'
          ]
        },
        line4:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[0, 0]',
            '[-5, 0]',
            '[-5, Plans.measure.width1*2]',
            '[0, Plans.measure.width1*2]',
            '[0, 0]'
          ]
        },
        line5:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]',
            '[Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]',
          ]
        },
        line6:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2]',
            '[Plans.measure.distanceX*2+Plans.measure.longWell*2, Plans.measure.width1*2-Plans.measure.distanceY*2]',
          ]
        },
        line7:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2]',
          ]
        },
        line8:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2,Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-Plans.measure.widthWell2*2]',
          ]
        },
        line9:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2',
          line: [
            '[0, Plans.measure.width1*2]',
            '[0, Plans.measure.width1*2+6]',
            '[Plans.measure.regrown*2, Plans.measure.width1*2+6]',
            '[Plans.measure.regrown*2, Plans.measure.width1*2]',
            '[0, 0]',
          ]
        },
        line10:{
          rotate: false,
          depth: Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2,
          line: [
            '[0, 0]',
            '[-5, 0]',
            '[-5, Plans.measure.splashbackThickness*2]',
            '[0, Plans.measure.splashbackThickness*2]',
            '[0, 0]',
    
          ]
        },
        line11:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2), Plans.measure.long2*2]',
            '[Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2), Plans.measure.long2*2-Plans.measure.distanceY2*2]',
          ]
        },
        line12:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.long1*2-Plans.measure.width2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2)]',
            '[Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2, Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2)]',
          ]
        },
        line13:{
          rotate: false,
          depth: 'Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[0, Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2)]',
            '[Plans.measure.distanceX*2, Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2)]',
          ]
        },
        line14:{
          rotate: false,
          depth:' Plans.measure.highThickness*2+Plans.measure.thickness*2',
          line: [
            '[Plans.measure.distanceX*2+(Plans.measure.longWell*2/2), Plans.measure.width1*2]',
            '[Plans.measure.distanceX*2+(Plans.measure.longWell*2/2), Plans.measure.width1*2-Plans.measure.distanceY*2]',
          ]
        },
        line15:{
          rotate: true,
          rotateX: 'Math.PI/2',
          rotateY: 'Math.PI/2',
          PositionX: 'Plans.measure.long1*2/2',
          PositionY: 'Plans.measure.width1*2',
          line: [
            '[0, Plans.measure.highThickness*2]',
            '[0, Plans.measure.highThickness*2+Plans.measure.thickness*2]',
            '[6, Plans.measure.highThickness*2+Plans.measure.thickness*2]',
            '[6, Plans.measure.highThickness*2]',
            '[0, Plans.measure.highThickness*2]',
          ]
        },
        line16:{
          rotate: true,
          rotateX: 'Math.PI/2',
          rotateY: 'Math.PI',
          PositionX: 'Plans.measure.long1*2-Plans.measure.width2*2',
          PositionY: 'Plans.measure.long2*2',
          line: [
            '[0, 0]',
            '[0, Plans.measure.highThickness*2]',
            '[6, Plans.measure.highThickness*2]',
            '[6, 0]',
            '[0, 0]'
          ]
        },
        line17:{
          rotate: true,
          rotateX: 'Math.PI/2',
          rotateY: 'Math.PI/2',
          PositionX: 'Plans.measure.long1*2',
          PositionY: 'Plans.measure.long2*2',
          line: [
            '[0, Plans.measure.highThickness*2+Plans.measure.thickness*2]',
            '[0, Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2]',
            '[4, Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2]',
            '[4, Plans.measure.highThickness*2+Plans.measure.thickness*2]',
            '[0, Plans.measure.highThickness*2+Plans.measure.thickness*2]',
          ]
        },
      }
      
      backupPlans.Texts = {
        text:{
          TitleText: 'long1',
          TitleTextSpanish: 'Largo 1',
          measureM: '(Plans.measure.long1/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: 'Plans.measure.long1*2/2',
          PositionY: '-5',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2'
        },
        text2:{
          TitleText: 'long2',
          TitleTextSpanish: 'Largo 2',
          measureM: '(Plans.measure.long2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.long1*2+5',
          PositionY: 'Plans.measure.long2*2/2+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2'
        },
        text3:{
          TitleText: 'width2',
          TitleTextSpanish: 'Ancho 2',
          measureM: '(Plans.measure.width2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: 'Plans.measure.long1*2-Plans.measure.splashbackThickness*2-Plans.measure.width2*2/2+2',
          PositionY: '(Plans.measure.long2*2+Plans.measure.splashbackThickness*2+3.5)',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text4:{
          TitleText: 'distanceY2',
          TitleTextSpanish: 'Distancia posuelo en Y2',
          measureM: '(Plans.measure.distanceY2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.long1*2-Plans.measure.splashbackThickness*2-(Plans.measure.width2*2/2)',
          PositionY: 'Plans.measure.long2*2-(Plans.measure.distanceY2*2/2)+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text5:{
          TitleText: 'distanceX2',
          TitleTextSpanish: 'Distancia posuelo en X2',
          measureM: '(Plans.measure.distanceX2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI',
          PositionX: 'Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2',
          PositionY: 'Plans.measure.long2*2-Plans.measure.distanceY2*2-(Plans.measure.longWell2*2/2)',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text6:{
          TitleText: 'width1',
          TitleTextSpanish: 'Ancho 1',
          measureM: '(Plans.measure.width1/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: '-5',
          PositionY: '(Plans.measure.width1*2/2)+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text7:{
          TitleText: 'distanceX',
          TitleTextSpanish: 'Distancia posuelo en X',
          measureM: '(Plans.measure.distanceX/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: 'Plans.measure.distanceX*2/2+2',
          PositionY: 'Plans.measure.width1*2-Plans.measure.distanceY*2-(Plans.measure.widthWell*2/2)',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text8:{
          TitleText: 'distanceY',
          TitleTextSpanish: 'Distancia posuelo en Y',
          measureM: '(Plans.measure.distanceY/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.longWell*2/2+Plans.measure.distanceX*2',
          PositionY: 'Plans.measure.width1*2-Plans.measure.distanceY*2/2+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text9:{
          TitleText: 'longWell',
          TitleTextSpanish: 'Largo posuelo',
          measureM: '(Plans.measure.longWell/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: 'Plans.measure.distanceX*2+Plans.measure.widthWell*2',
          PositionY: 'Plans.measure.width1*2-Plans.measure.distanceY*2-Plans.measure.widthWell*2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text10:{
          TitleText: 'widthWell',
          TitleTextSpanish: 'Ancho posuelo',
          measureM: '(Plans.measure.widthWell/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.distanceX*2+Plans.measure.longWell*2',
          PositionY: 'Plans.measure.width1*2-(Plans.measure.widthWell*2/2)-Plans.measure.distanceY*2+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text11:{
          TitleText: 'longWell2',
          TitleTextSpanish: 'Largo pusuelo 2',
          measureM: '(Plans.measure.longWell2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI',
          PositionX: 'Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+(Plans.measure.longWell2*2/2)+2',
          PositionY: 'Plans.measure.long2*2-Plans.measure.widthWell2*2-Plans.measure.distanceY2*2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text12:{
          TitleText: 'widthWell2',
          TitleTextSpanish: 'Ancho posuelo 2',
          measureM: '(Plans.measure.widthWell2/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.long1*2-Plans.measure.width2*2+Plans.measure.distanceX2*2+Plans.measure.longWell2*2',
          PositionY: 'Plans.measure.long2*2-Plans.measure.widthWell2*2-Plans.measure.distanceY2*2+(Plans.measure.widthWell2*2/2)+2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2'
        },
        text13:{
          TitleText: 'regrown',
          TitleTextSpanish: 'Rengrueso',
          measureM: '(Plans.measure.regrown/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: 'Plans.measure.regrown*2+2',
          PositionY: 'Plans.measure.width1*2+6',
          PositionZ: 'Plans.measure.highThickness*2'
        },
        text14:{
          TitleText: 'splashbackThickness',
          TitleTextSpanish: 'Salpicadero',
          measureM: '(Plans.measure.splashbackThickness/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: '0',
          PositionY: 'Plans.measure.splashbackThickness*2/2',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+Plans.measure.highsplashback*2'
        },
        text15:{
          TitleText: 'thickness',
          TitleTextSpanish: 'Alto meson',
          measureM: '(Plans.measure.thickness/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: 'Math.PI',
          PositionX: '(Plans.measure.long1*2/2)+2',
          PositionY: 'Plans.measure.width1*2+5',
          PositionZ: 'Plans.measure.highThickness*2+(Plans.measure.thickness*2/2)-1'
        },
        text16:{
          TitleText: 'highThickness',
          TitleTextSpanish: 'Alto del rengrueso',
          measureM: '(Plans.measure.highThickness/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.long1*2-Plans.measure.width2*2-5',
          PositionY: 'Plans.measure.long2*2+2',
          PositionZ: 'Plans.measure.highThickness*2/2-1'
        },
        text17:{
          TitleText: 'highsplashback',
          TitleTextSpanish: 'Alto del salpicadero',
          measureM: '(Plans.measure.highsplashback/10).toFixed(2)',
          RotateX: 'Math.PI/2',
          RotateY: '-Math.PI/2',
          PositionX: 'Plans.measure.long1*2',
          PositionY:  'Plans.measure.long2*2+6',
          PositionZ: 'Plans.measure.highThickness*2+Plans.measure.thickness*2+(Plans.measure.highsplashback*2/2)'
        },
      }
      backupPlans.measure = { ...Plans.measure };
      backupPlans.texture = Plans.texture;
      backupPlans.prices = Plans.prices;
      backupPlans.long = '(parseFloat(Plans.measure.long1) + parseFloat(Plans.measure.long2) - parseFloat(Plans.measure.width1))/10'
      backupPlans.pricesCalc = '((Plans.measure.long2*10)*(Plans.measure.width2*10)+((Plans.measure.long1*10)-Plans.measure.width2*10)*(Plans.measure.width1*10))'
      backupPlans.width = 'Plans.measure.width1/10 === Plans.measure.width2/10 ? Plans.measure.width1/10 : Math.max(Plans.measure.width1/10, Plans.measure.width2/10)'    


      prePlans = prePlans
      Plans = Plans

      const preCoordinate = prePlans.coordinates;

      for (const key in preCoordinate) {
        const coordinateProperty = preCoordinate[key];

        const depthExpression = coordinateProperty.depth;
        const evaluatedDepth = eval(depthExpression);

        const points = coordinateProperty.points.map(poin => {
          const point = poin;
          const evaluatedExpr = eval(point);
          return evaluatedExpr;
        });
        Plans.coordinates[key].points = points;
        Plans.coordinates[key].depth = evaluatedDepth;

      }

      const preText = prePlans.Texts;

      for (const key in preText) {

        const preTextProperty = preText[key];
        const PositionY = preTextProperty.PositionY
        const evaluatedPositionY = eval(PositionY);
        Plans.Texts[key].PositionY = evaluatedPositionY

        const PositionX = preTextProperty.PositionX
        const evaluatedPositionX = eval(PositionX);
        Plans.Texts[key].PositionX = evaluatedPositionX

        const PositionZ = preTextProperty.PositionZ
        const evaluatedPositionZ = eval(PositionZ);
        Plans.Texts[key].PositionZ = evaluatedPositionZ

        const RotateX = preTextProperty.RotateX
        const evaluatedRotateX = eval(RotateX);
        Plans.Texts[key].RotateX = evaluatedRotateX

        const RotateY = preTextProperty.RotateY
        const evaluatedRotatey = eval(RotateY);
        Plans.Texts[key].RotateY = evaluatedRotatey

        const measureM = preTextProperty.measureM
        const evaluatedmeasureM = eval(measureM);
        Plans.Texts[key].measureM = evaluatedmeasureM
      }

      const preLines = prePlans.Lines;

      for (const key in preLines) {
        const preLinesProperty = preLines[key];

        const depth = preLinesProperty.depth
        const evaluateddepth = eval(depth);
        Plans.Lines[key].depth = evaluateddepth

        const lines = preLinesProperty.line.map(lin => {
          const line = lin;
          const evaluatedExpr = eval(line);
          return evaluatedExpr;
        });
        Plans.Lines[key].line = lines
      }

      const preHoles = prePlans.Holes;

      for (const key in preHoles) {
        const preHolesProperty = preHoles[key];
        const holes = preHolesProperty.map(hol => {
          const hole = hol;
          const evaluatedExpr = eval(hole);
          return evaluatedExpr;
        })
        Plans.Holes[key] = holes
      }

      prePlans.measure = Plans.measure;
      prePlans.prices = Plans.prices;
      prePlans.texture = Plans.texture;

      const currentRef = mountRef.current;
      const { clientWidth: width, clientHeight: height } = currentRef;
      var secundaryGroup = new THREE.Group()
      var principalGroup = new THREE.Group()

      const raycaster = new THREE.Raycaster();
      document.getElementById("shot").addEventListener('click', takeScreenshot);

      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x727272)

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.VSMShadowMap;
      currentRef.appendChild(renderer.domElement);

      const coordinates = Plans.coordinates;
      let zPosition = 0;
      
      for (const figureName in coordinates) {
          const figure = coordinates[figureName];
          if(figure.type === "circle"){
            const figureDepth = figure.depth;
            const figureRadius = figure.circleRadius;
            const figureBezier = figure.circleBezier;
            // const figureCoordinates = figure.points;
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(textureSelect);
            const circleShape = new THREE.Shape();
            const circleRadius = figureRadius;
            
            const cp = circleRadius * figureBezier; // Valor para aproximar un círculo con una curva de Bézier
            circleShape.moveTo(circleRadius, 0);
            circleShape.bezierCurveTo(circleRadius, cp, cp, circleRadius, 0, circleRadius);
            circleShape.bezierCurveTo(-cp, circleRadius, -circleRadius, cp, -circleRadius, 0);
            circleShape.bezierCurveTo(-circleRadius, -cp, -cp, -circleRadius, 0, -circleRadius);
            circleShape.bezierCurveTo(cp, -circleRadius, circleRadius, -cp, circleRadius, 0);
    
            const extrudeSettings = {
              depth: figureDepth, // Profundidad
              bevelEnabled: false,
            };
    
            const geometry = new THREE.ExtrudeGeometry(circleShape, extrudeSettings);
    
            const materialObject = new THREE.MeshStandardMaterial();
            materialObject.map = texture;
            for (const lineName in Plans.Lines) {
              const lines = Plans.Lines[lineName];
              if (lines.rotate) {
                var linesPositionX = lines.PositionX;
                var linesPositionY = lines.PositionY;
                var linesRotateX = lines.rotateX
                var linesRotateY = lines.rotateY
              } else {
                var linesDepth = lines.depth;
              }
              const lineCoordinates = lines.line

              const measurementLines = new THREE.Shape();
              const [firstX, firstY] = lineCoordinates[0];

              measurementLines.moveTo(firstX, firstY);

              lineCoordinates.slice(1).forEach(([x, y]) => {
                measurementLines.lineTo(x, y);
              });

              measurementLines.lineTo(firstX, firstY);

              const measurementLineExtrudeSettings = {
                steps: 1,
                depth: 0,
                bevelEnabled: false,
                bevelThickness: 1,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 1
              };

              const measurementLineGeometry = new THREE.ExtrudeGeometry(measurementLines, measurementLineExtrudeSettings);

              const measurementLineMaterial = new THREE.LineDashedMaterial({
                color: 0x000000,
                linewidth: 1,
                scale: 3,
                dashSize: 0.5,
                gapSize: 2
              });


            const measurementLineMesh = new THREE.LineSegments(measurementLineGeometry, measurementLineMaterial);
              if (lines.rotate) {
                measurementLineMesh.rotateX(linesRotateX);
                measurementLineMesh.rotateY(linesRotateY);
              }
              measurementLineMesh.computeLineDistances();
              if (lines.rotate) {
                measurementLineMesh.position.set(linesPositionX, linesPositionY, 0)
              } else {
                measurementLineMesh.position.set(0, 0, linesDepth)
              }
              scene.add(measurementLineMesh);
              secundaryGroup.add(measurementLineMesh)
            }

            for (const TextName in Plans.Texts) {
              const Texts = Plans.Texts[TextName];
              const Mesurement = Texts.measureM;
              const RotateX = Texts.RotateX;
              const RotateY = Texts.RotateY;
              const PositionX = Texts.PositionX;
              const PositionY = Texts.PositionY;
              const PositionZ = Texts.PositionZ;
              const id = Texts.TitleText;
              const idSpanish = Texts.TitleTextSpanish
              const TitleTextHeight = Texts.TitleTextHeight
              const TitleTextSpanishHeight = Texts.TitleTextSpanishHeight
              const height = Texts.height

              const loaderTextLong = new FontLoader();
              loaderTextLong.load(
                './models/fonts/helvetiker_bold.typeface.json',
                function (font) {
                  const geometryTextLong = new TextGeometry(Mesurement.toString() + ' m', {
                    font: font,
                    size: 1,
                    height: 0,
                    text: Mesurement,
                    text1: id,
                    text1Spanish: idSpanish,
                    TitleTextHeight: TitleTextHeight,
                    TitleTextSpanishHeight: TitleTextSpanishHeight,
                    height2: height
                  });
                  const material = new THREE.MeshBasicMaterial({ color: 0x000000 }); // Material de ejemplo
                  const textMesh = new THREE.Mesh(geometryTextLong, material);

                  textMesh.position.set(PositionX, PositionY, PositionZ)

                  textMesh.rotation.x = RotateX
                  textMesh.rotation.y = RotateY

                  scene.add(textMesh);
                  secundaryGroup.add(textMesh)

                  const bbox = new THREE.Box3().setFromObject(textMesh);
                  const size = new THREE.Vector3();
                  bbox.getSize(size);

                  const hitboxGeometry = new THREE.BoxGeometry(6, 4, 0.6);
                  const hitboxMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
                  const hitboxMesh = new THREE.Mesh(hitboxGeometry, hitboxMaterial);

                  hitboxMesh.userData = {
                    textMesh: textMesh,
                    text: Mesurement,
                    text1: id,
                    text1Spanish: idSpanish,
                    TitleTextHeight: TitleTextHeight,
                    TitleTextSpanishHeight: TitleTextSpanishHeight,
                    height2: height
                  };

                  hitboxMesh.position.set(PositionX, PositionY, PositionZ)
                  hitboxMesh.rotation.x = RotateX
                  hitboxMesh.rotation.y = RotateY
                  secundaryGroup.add(hitboxMesh);

                  let selectedObject = null;

                  renderer.domElement.addEventListener('click', onClick, false);

                  function onClick(event) {

                    const mouse = {
                      x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
                      y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
                    };

                    if (selectedObject) {
                      selectedObject.userData.textMesh.material.color.set('#000000');
                      selectedObject = null;
                    }

                    raycaster.setFromCamera(mouse, camera);

                    const intersects = raycaster.intersectObjects(secundaryGroup.children, true);

                    if (intersects.length > 0) {
                      for (const intersection of intersects) {
                        const object = intersection.object;

                        if (object.type === 'Mesh' && object.geometry.type === 'BoxGeometry') {
                          const object = intersection.object;
                          if (selectedObject) {

                            if (selectedObject !== object) {
                              selectedObject.userData.textMesh.material.color.set('#000000');
                              selectedObject = null;
                            }
                          }

                          selectedObject = object;

                          selectedObject.userData.textMesh.material.color.set('#ff0000');
                          document.getElementsByClassName('Mesurement')[0].style.display = 'block';
                          const textMesurement = selectedObject.userData.text1
                          const numberMesurement = selectedObject.userData.text
                          const textMesurementSpanish = selectedObject.userData.text1Spanish
                          const TitleTextHeight = selectedObject.userData.TitleTextHeight
                          const TitleTextSpanishHeight = selectedObject.userData.TitleTextSpanishHeight
                          const height = selectedObject.geometry.userData.height2
                          inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height)
                          function inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height) {
                            setMesurementText(textMesurement)
                            setMesurementNumber(numberMesurement)
                            setMesurementTextSpanish(textMesurementSpanish)
                            setSeveTitleTextHigh(TitleTextHeight)
                            setSeveTitleTextSpanishHigh(TitleTextSpanishHeight)
                            setSeveHigh(height)
                          }
                        }

                      }
                    }
                  }

                },
                function (xhr) {
                  // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                function (err) {
                  // console.log('An error happened');
                }
              )
            }

            const circleMesh = new THREE.Mesh(geometry, materialObject);
            circleMesh.castShadow = true;
            circleMesh.receiveShadow = true;
            secundaryGroup.add(circleMesh)
            secundaryGroup.position.set(circleRadius,circleRadius,0)
            principalGroup.add(secundaryGroup)
          }else{
              const figureDepth = figure.depth;
              const figureCoordinates = figure.points;
              const textureLoader = new THREE.TextureLoader();
              const texture = textureLoader.load(textureSelect);

              texture.wrapS = THREE.RepeatWrapping;
              texture.wrapT = THREE.RepeatWrapping;
              texture.repeat.set(2, 2);

              const materialObject = new THREE.MeshStandardMaterial();
              materialObject.map = texture;

              const shape = new THREE.Shape();

              const [firstX, firstY] = figureCoordinates[0];

              shape.moveTo(firstX, firstY);

              figureCoordinates.slice(1).forEach(([x, y]) => {
                shape.lineTo(x, y);
              });

              shape.lineTo(firstX, firstY);

              if (figure.piece === 'main') {
                for (const holeName in Plans.Holes) {
                  const holeCoordinates = Plans.Holes[holeName].points;
                  const holeShape = new THREE.Shape();

                  const [holeFirstX, holeFirstY] = holeCoordinates[0];

                  holeShape.moveTo(holeFirstX, holeFirstY);

                  holeCoordinates.slice(1).forEach(([x, y]) => {
                    holeShape.lineTo(x, y);
                  });

                  holeShape.lineTo(holeFirstX, holeFirstY);

                  shape.holes.push(holeShape);
                }
              }

              for (const lineName in Plans.Lines) {
                const lines = Plans.Lines[lineName];
                if (lines.rotate) {
                  var linesPositionX = lines.PositionX;
                  var linesPositionY = lines.PositionY;
                  var linesRotateX = lines.rotateX
                  var linesRotateY = lines.rotateY
                } else {
                  var linesDepth = lines.depth;
                }
                const lineCoordinates = lines.line

                const measurementLines = new THREE.Shape();
                const [firstX, firstY] = lineCoordinates[0];

                measurementLines.moveTo(firstX, firstY);

                lineCoordinates.slice(1).forEach(([x, y]) => {
                  measurementLines.lineTo(x, y);
                });

                measurementLines.lineTo(firstX, firstY);

                const measurementLineExtrudeSettings = {
                  steps: 1,
                  depth: 0,
                  bevelEnabled: false,
                  bevelThickness: 1,
                  bevelSize: 1,
                  bevelOffset: 0,
                  bevelSegments: 1
                };

                const measurementLineGeometry = new THREE.ExtrudeGeometry(measurementLines, measurementLineExtrudeSettings);

                const measurementLineMaterial = new THREE.LineDashedMaterial({
                  color: 0x000000,
                  linewidth: 1,
                  scale: 3,
                  dashSize: 0.5,
                  gapSize: 2
                });


                const measurementLineMesh = new THREE.LineSegments(measurementLineGeometry, measurementLineMaterial);
                if (lines.rotate) {
                  measurementLineMesh.rotateX(linesRotateX);
                  measurementLineMesh.rotateY(linesRotateY);
                }
                measurementLineMesh.computeLineDistances();
                if (lines.rotate) {
                  measurementLineMesh.position.set(linesPositionX, linesPositionY, 0)
                } else {
                  measurementLineMesh.position.set(0, 0, linesDepth)
                }
                scene.add(measurementLineMesh);
                secundaryGroup.add(measurementLineMesh)
              }

              for (const TextName in Plans.Texts) {
                const Texts = Plans.Texts[TextName];
                const Mesurement = Texts.measureM;
                const RotateX = Texts.RotateX;
                const RotateY = Texts.RotateY;
                const PositionX = Texts.PositionX;
                const PositionY = Texts.PositionY;
                const PositionZ = Texts.PositionZ;
                const id = Texts.TitleText;
                const idSpanish = Texts.TitleTextSpanish
                const TitleTextHeight = Texts.TitleTextHeight
                const TitleTextSpanishHeight = Texts.TitleTextSpanishHeight
                const height = Texts.height

                const loaderTextLong = new FontLoader();
                loaderTextLong.load(
                  './models/fonts/helvetiker_bold.typeface.json',
                  function (font) {
                    const geometryTextLong = new TextGeometry(Mesurement.toString() + ' m', {
                      font: font,
                      size: 1,
                      height: 0,
                      text: Mesurement,
                      text1: id,
                      text1Spanish: idSpanish,
                      TitleTextHeight: TitleTextHeight,
                      TitleTextSpanishHeight: TitleTextSpanishHeight,
                      height2: height
                    });
                    const material = new THREE.MeshBasicMaterial({ color: 0x000000 }); // Material de ejemplo
                    const textMesh = new THREE.Mesh(geometryTextLong, material);

                    textMesh.position.set(PositionX, PositionY, PositionZ)

                    textMesh.rotation.x = RotateX
                    textMesh.rotation.y = RotateY

                    scene.add(textMesh);
                    secundaryGroup.add(textMesh)

                    const bbox = new THREE.Box3().setFromObject(textMesh);
                    const size = new THREE.Vector3();
                    bbox.getSize(size);

                    const hitboxGeometry = new THREE.BoxGeometry(6, 4, 0.6);
                    const hitboxMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
                    const hitboxMesh = new THREE.Mesh(hitboxGeometry, hitboxMaterial);

                    hitboxMesh.userData = {
                      textMesh: textMesh,
                      text: Mesurement,
                      text1: id,
                      text1Spanish: idSpanish,
                      TitleTextHeight: TitleTextHeight,
                      TitleTextSpanishHeight: TitleTextSpanishHeight,
                      height2: height
                    };

                    hitboxMesh.position.set(PositionX, PositionY, PositionZ)
                    hitboxMesh.rotation.x = RotateX
                    hitboxMesh.rotation.y = RotateY
                    secundaryGroup.add(hitboxMesh);

                    let selectedObject = null;

                    renderer.domElement.addEventListener('click', onClick, false);

                    function onClick(event) {

                      const mouse = {
                        x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
                        y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
                      };

                      if (selectedObject) {
                        selectedObject.userData.textMesh.material.color.set('#000000');
                        selectedObject = null;
                      }

                      raycaster.setFromCamera(mouse, camera);

                      const intersects = raycaster.intersectObjects(secundaryGroup.children, true);

                      if (intersects.length > 0) {
                        for (const intersection of intersects) {
                          const object = intersection.object;

                          if (object.type === 'Mesh' && object.geometry.type === 'BoxGeometry') {
                            const object = intersection.object;
                            if (selectedObject) {

                              if (selectedObject !== object) {
                                selectedObject.userData.textMesh.material.color.set('#000000');
                                selectedObject = null;
                              }
                            }

                            selectedObject = object;

                            selectedObject.userData.textMesh.material.color.set('#ff0000');
                            document.getElementsByClassName('Mesurement')[0].style.display = 'block';
                            const textMesurement = selectedObject.userData.text1
                            const numberMesurement = selectedObject.userData.text
                            const textMesurementSpanish = selectedObject.userData.text1Spanish
                            const TitleTextHeight = selectedObject.userData.TitleTextHeight
                            const TitleTextSpanishHeight = selectedObject.userData.TitleTextSpanishHeight
                            const height = selectedObject.geometry.userData.height2
                            inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height)
                            function inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height) {
                              setMesurementText(textMesurement)
                              setMesurementNumber(numberMesurement)
                              setMesurementTextSpanish(textMesurementSpanish)
                              setSeveTitleTextHigh(TitleTextHeight)
                              setSeveTitleTextSpanishHigh(TitleTextSpanishHeight)
                              setSeveHigh(height)
                            }
                          }

                        }
                      }
                    }

                  },
                  function (xhr) {
                    // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                  },
                  function (err) {
                    // console.log('An error happened');
                  }
                )
              }

              const geometryObject = new THREE.ExtrudeGeometry(shape, {
                steps: 1,
                depth: figureDepth,
                bevelEnabled: false
              });

              const mesh = new THREE.Mesh(geometryObject, materialObject);

              mesh.castShadow = true;
              mesh.receiveShadow = true;

              const containerObject = new THREE.Object3D();
              containerObject.add(mesh);

              containerObject.position.set(0, 0, zPosition);

              const box = new THREE.Box3().setFromObject(mesh);
              const size = new THREE.Vector3();
              box.getSize(size);
              const textureScale = new THREE.Vector2(size.x, size.y);
              textureScale.divideScalar(Math.max(200, 200));

              texture.wrapS = THREE.RepeatWrapping;
              texture.wrapT = THREE.RepeatWrapping;
              texture.repeat.copy(textureScale);

              scene.add(containerObject);
              secundaryGroup.add(containerObject);
              secundaryGroup.position.set(0,0,0)
              principalGroup.add(secundaryGroup)
              zPosition += figureDepth;
          }
      }

      const floorGeometry = new THREE.PlaneGeometry(200, 200);
      const floorMaterial = new THREE.MeshStandardMaterial({
        color: 0x909090,
        antialias: true
      });
      const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial);
      floorMesh.position.z = -1;
      floorMesh.receiveShadow = true;
      floorMesh.castShadow = true;
      scene.add(floorMesh);

      const spotLight = new THREE.SpotLight(0xffffff, 1);
      spotLight.name = 'Spot Light';
      spotLight.angle = 1;
      spotLight.penumbra = 0.4;
      spotLight.position.set(90, 80, 150);
      spotLight.target.position.set(2, 20, 0);
      spotLight.castShadow = true;
      spotLight.shadow.camera.near = 90;
      spotLight.shadow.camera.far = 450;
      spotLight.shadow.mapSize.width = 512;
      spotLight.shadow.mapSize.height = 512;

      scene.add(spotLight.target);
      scene.add(spotLight);
      // scene.add( new THREE.CameraHelper( spotLight.shadow.camera ) );


      const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
      scene.add(ambientLight);

      principalGroup.add(floorMesh)
      principalGroup.add(ambientLight)
      principalGroup.add(spotLight)
      principalGroup.add(spotLight.target)
      principalGroup.add(OrbitControls)
      scene.add(principalGroup);
      principalGroup.rotation.z = 5 * Math.PI / 7
      principalGroup.position.set(34, 0, 0)

      const camera = new THREE.PerspectiveCamera(80, width / height, 0.1);
      camera.position.set(0, -24, 16);
      var groupCamera = new THREE.Group()
      groupCamera.add(camera);
      scene.add(groupCamera);
      scene.add(camera);


      const resize = () => {
        renderer.setSize(currentRef.clientWidth, currentRef.clientHeight);
        camera.aspect = currentRef.clientWidth / currentRef.clientHeight;
        camera.updateProjectionMatrix();
      };
      window.addEventListener("resize", resize);


      const orbitControls = new OrbitControls(camera, renderer.domElement);
      orbitControls.enableRotate = false;

      console.log("Antes de renderer.render")

      var previousInputPosition = {
        x: 0,
        y: 0
      };

      var isTwoFingerTouch = false;

      function handleInputDown(event) {
        if (event.type === 'touchstart') {

          if (event.touches.length === 1) {
            previousInputPosition = {
              x: event.touches[0].clientX,
              y: event.touches[0].clientY
            };
          } else {
            isTwoFingerTouch = true;
          }
        } else if (event.type === 'mousedown') {
          previousInputPosition = {
            x: event.clientX,
            y: event.clientY
          };
        }

        document.addEventListener('touchmove', handleInputMove, false);
        document.addEventListener('mousemove', handleInputMove, false);
      }

      function handleInputUp(event) {
        document.removeEventListener('touchmove', handleInputMove, false);
        document.removeEventListener('mousemove', handleInputMove, false);

        if (event.type === 'touchend') {
          isTwoFingerTouch = false;
        }
      }

      function handleInputMove(event) {
        var currentInputPosition = {
          x: 0,
          y: 0
        };

        if (event.type === 'touchmove') {
          currentInputPosition = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
          };
        } else if (event.type === 'mousemove') {
          currentInputPosition = {
            x: event.clientX,
            y: event.clientY
          };
        }

        if (!isTwoFingerTouch) {
          var deltaMove = {
            x: currentInputPosition.x - previousInputPosition.x,
            y: currentInputPosition.y - previousInputPosition.y
          };
          principalGroup.rotation.z += deltaMove.x * 0.01;
        }

        previousInputPosition = currentInputPosition;
      }

      document.addEventListener('touchstart', handleInputDown, false);
      document.addEventListener('mousedown', handleInputDown, false);
      document.addEventListener('touchend', handleInputUp, false);
      document.addEventListener('mouseup', handleInputUp, false);

      async function takeScreenshot() {
        renderer.render(scene, camera);
        let imgData = renderer.domElement.toDataURL("image/png");

        const response = await fetch(imgData);
        const blob = await response.blob();
        const date = new Date();
        const timestamp = date.getTime();

        // Crear un nuevo objeto de tipo File
        const file = new File([blob], "Modelo3D_" + timestamp + ".png", { type: "image/png" });

        handleModelingPlane(file)
      }

      //Animate the scene
      const animate = () => {
        orbitControls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };
      animate();


      return () => {
        window.removeEventListener("resize", resize);
        currentRef.removeChild(renderer.domElement);
      };
    }
  }, [update, textureSelect, selectModels]);


  function mostrarNav() {
    if (document.getElementsByClassName('predefinedCategory')[0].style.display === 'block') {
      document.getElementsByClassName('predefinedCategory')[0].style.display = 'none';
      document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'none';
      document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'none';
    } else {
      document.getElementsByClassName('predefinedCategory')[0].style.display = 'block';
      document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'blur(5px)';
      document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'blur(5px)';
    }
  }

  return (
    <>
      <div className="fullscreen-overlay">
        <div className='optionsWindowEditor'>
          <NavLink onClick={toggleFullScreenComputer}><CgMaximize className='CgMaximizeInMinimize' /></NavLink>
        </div>
      </div>
      <div ref={divRef} className="containerProductoIn3djsGenerate">
        <div
          className='Contenedor3D'
          ref={mountRef}
          style={{ height: "100vh" }}>
        </div>
        <div id="objectInfo" className="Mesurement" ref={Mesurements}>
          <div className="form__groupMeasure">
            <input type="number" onChange={handleMesurement} className="form__inputMeasure" id="name" value={mesurementNumber} placeholder={mesurementTextSpanish} style={{ color: 'black' }} />
            <label className="form__labelMeasure">{mesurementTextSpanish}</label>
          </div>
          {(
            saveTitleTextHigh === undefined && saveTitleTextSpanishHigh === undefined && savehigh === undefined
          ) ? (
            ''
          ) : (
            <div className="form__groupMeasure">
              <input type="number" onChange={handleHeigth} value={savehigh} className="form__inputMeasure" id="name" placeholder={saveTitleTextSpanishHigh} style={{ color: 'black' }} />
              <label className="form__labelMeasure">{saveTitleTextSpanishHigh}</label>
            </div>

          )}
          <NavLink onClick={handleUpdate} className='Textregister'><button className="button neon_Effect" style={{ margin: '0', marginLeft: '55px' }}><p>Actualizar</p></button></NavLink>
        </div>
        <NavLink className="TextPlans" to="/Agregar">
          <button className="button neon_Effect">
            <p>Atrás</p>
          </button>
        </NavLink>
        <NavLink className="TextPlans2" id="shot">
          <button className="button neon_Effect">
            <p>Guardar</p>
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default MesaenLGrande;