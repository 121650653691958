import React, { useContext, useEffect, useState } from 'react'
import LoginContext from "../Context/Logincontext"
import Nav1 from '../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import "./../styles/AddProduct.css"
import { urlApiServer } from '../Config/Config'
import '../styles/AddClient.css'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
import CloseButton from '../Component/CloseButton'
import LogoComponent from '../Component/LogoComponent'

let splitImg = []

const AddClient = () => {

    const urlClient = urlApiServer + "addClient";
    const urlgetConfig = urlApiServer + "getConfig";

    const [name, setName] = useState('')
    const [lastNameOne, setLastNameOne] = useState('')
    const [citizenshipCard, setCitizenshipCard] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [direction, setDirection] = useState(null)
    const [contact, setContact] = useState(null)
    const [typeDocument, setTypeDocument] = useState(null)

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")

    if (lastNameOne === undefined || lastNameOne === '') {
        var fullName = (name !== undefined ? name : '')
    } else {
        var fullName = (name !== undefined ? name : '') + ' ' + (lastNameOne !== undefined ? lastNameOne : '');
    }
    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())
    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);
            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    async function handleName(e) {
        const name = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setName(name)
        // }
    }

    async function handleLastNameOne(e) {
        // const lastNameOne = e.target.value.replace(/[^A-Z a-z]/g, "");
        const lastNameOne = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setLastNameOne(lastNameOne)
    }

    async function handleCitizenshipCard(e) {
        let value = e.target.value.replace(/[^\d-_]/g, '');
        e.target.value = value;
        setCitizenshipCard(value);
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }

    async function handleEmail(e) {
        const email = e.target.value
        setEmail(email)
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
    }

    async function handleDocumentType(e) {
        let valueType = e.target.value
        console.log("valueType: ", valueType)
        if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
            setTypeDocument(valueType)
        }
    }

    async function handleContact(e) {
        const contact = e.target.value
        setContact(contact)
    }

    async function uploadProduct() {

        if (lastNameOne === undefined || lastNameOne === '') {
            if (name.slice(0, 1) === ' ' || name.slice(-1) === ' ') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Por favor, le solicito verificar cuidadosamente que al final o al principio del nombre no haya presencia de espacios innecesarios.',
                    confirmButtonText: 'Aceptar',
                });
                return; // Detener la ejecución de la función
            }
        } else {
            if (name.slice(0, 1) === ' ' || name.slice(-1) === ' ' || lastNameOne.slice(0, 1) === ' ' || lastNameOne.slice(-1) === ' ') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Por favor, le solicito verificar cuidadosamente que al final o al principio del nombre o apellido no haya presencia de espacios innecesarios.',
                    confirmButtonText: 'Aceptar',
                });
                return; // Detener la ejecución de la función
            }
        }

        // if((name === '')||(lastNameOne === '')){
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: '¡Advertencia!',
        //         text: 'Recuerda llenar los campos de "Nombre" y "Apellido" ',
        //         allowOutsideClick: false,
        //     })
        // }

        if ((citizenshipCard === null) || (citizenshipCard === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de "Numero de documento"',
                allowOutsideClick: false,
            })
        }

        // if((phone === null)||(phone === '')){
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: '¡Advertencia!',
        //         text: 'Recuerda llenar el campo de "Telefono"',
        //         allowOutsideClick: false,
        //     })
        // }

        // if((email === null)||(email === '')){
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: '¡Advertencia!',
        //         text: 'Recuerda llenar el campo de "Correo"',
        //         allowOutsideClick: false, 
        //     })
        // }
        if ((typeDocument === null) || (typeDocument === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda seleccionar un "Tipo de documento"',
                allowOutsideClick: false,
            })
        }

        if ((direction === null) || (direction === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de "Direccion"',
                allowOutsideClick: false,
            })
        }

        if ((contact === null) || (contact === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de "Direccion"',
                allowOutsideClick: false,
            })
        }

        const data = JSON.stringify({
            empresa: infoUser.company,
            name: fullName,
            numbreDocument: citizenshipCard,
            phone: phone,
            email: email,
            direction: direction,
            contact: contact,
            typeDocument: typeDocument,
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        await fetch(urlClient, requesoption).then(response => response.json())
            .then(dataResponse => {
                if (300 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'success',
                        title: '¡Hecho!',
                        text: dataResponse.msj,
                        allowOutsideClick: false,
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = './Clientes';
                        }
                    })
                }
                if (101 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: dataResponse.msj,
                        confirmButtonText: 'Aceptar',
                    })
                }
            })
    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    <>
                        <div className="containerHeardANDTable">
                            <div className="HeardANDFilter">
                                <LogoComponent />
                                <div className='containerButtonAdd'>
                                    <div className="navigationContainer">
                                        <div className="pageTitle">CREAR CLIENTE</div>
                                    </div>
                                </div>
                                <div className='closeIntContainer'>
                                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                        <ImExit  className='iconNav'/>
                                    </NavLink> */}
                                    <CloseButton />
                                </div>
                            </div>
                        </div>
                        <div id='overallContainer' className='overallContainer'>
                            <Nav1 />
                            <div className='drawerFormAddCuotes'>
                                <form className='ContainerClient'>
                                    <div className='containerInfo'>
                                        <div className='infoOne'>
                                            <p className='personalInformation'>INFORMACIÓN PERSONAL</p>
                                            <div className='containerNameClient'>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={name}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleName}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre del cliente <em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={lastNameOne}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleLastNameOne}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Apellidos del cliente</label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="nitSelect"
                                                        id="nitSelect"
                                                        className="inputCLient"
                                                        placeholder=""
                                                        list="drawfemails2"
                                                        onChange={handleDocumentType}
                                                        value={(typeDocument === '') ? ('') : (typeDocument)}
                                                    />
                                                    <label htmlFor="name">Tipo de documento: <em className='Obligatory'>*</em>  </label>
                                                    <datalist id="drawfemails2" value="nitSelect">
                                                        <option value="NIT">NIT</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                    </datalist>
                                                    {/* <select
                                                        name="Type"
                                                        id="Type"
                                                        className="inputSelectNT"
                                                    >
                                                        <option value="Type">Tipo de documento:</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                    </select> */}
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        onWheel={(e) => e.target.blur()}
                                                        name="citizenshipCard"
                                                        id="citizenshipCard"
                                                        onChange={handleCitizenshipCard}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Numero de documento <em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        onChange={handleDirection}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Dirección <em className='Obligatory'>*</em> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='infoTwo'>
                                            <p className='personalInformation'>CONTACTO</p>
                                            <div className='containerContactClient'>
                                                <div className="inputClientContainer2">
                                                    <input
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        name="Phone"
                                                        id="Phone"
                                                        onChange={handlePhone}
                                                        className="inputCLient2"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Telefono</label>
                                                </div>
                                                <div className="inputClientContainer2">
                                                    <input
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        name="Contact"
                                                        id="Contact"
                                                        onChange={handleContact}
                                                        className="inputCLient2"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Celular <em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer2">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        onChange={handleEmail}
                                                        className="inputCLient2"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Correo electronico</label>
                                                </div>
                                            </div>
                                            <div className="buttonContainerAddClient">
                                                <NavLink className='Textregister' to="/Clientes"><button className="button neon_Effect"><p>Atras</p></button></NavLink>
                                                <NavLink onClick={uploadProduct} className='Textregister'><button className="button neon_Effect"><p>Agregar</p></button></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddClient