import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./../../styles/AddProduct.css"
import '../../styles/AddClient.css'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
// import '../styles/AddBills.css'
import '../../styles/AddBills.css'
import LoginContext from '../../Context/Logincontext'
import Nav1 from '../../Component/Nav/Nav1'
import CloseButton from '../../Component/CloseButton'
import LogoComponent from '../../Component/LogoComponent'
import { urlApiServer } from '../../Config/Config'
import { validationBills } from '../validations/Validations'

let splitImg = []
const AddTable = (props) => {

    const urlTable = urlApiServer + "addTable";
    const urlUpdateBill = urlApiServer + "updateBill";
    const urlGetCompanys = urlApiServer + "getCompanys";

    const urlgetConfig = urlApiServer + "getConfig";
    const [nameTable, setNameTable] = useState('')
    const [DNI, setDNI] = useState('')
    const [citizenshipCard, setCitizenshipCard] = useState(null)
    const [phone, setPhone] = useState(null)
    const [city, setCity] = useState('')
    const [quantity, setQuantity] = useState('')
    const [unitValue, setUnitValue] = useState(null)
    const [reason, setReason] = useState(null)
    const [detail, setDetail] = useState(null)
    const [direction, setDirection] = useState(null)
    const [contact, setContact] = useState(null)
    const [company, setCompany] = useState(null)
    const [date, setDate] = useState(null)
    const [companys, setCompanys] = useState([])

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [inputs, setInputs] = useState([{ id: 1, notNull: false, type: "VARCHAR", nameColumn: "", value: "", default: "", nameTable: nameTable, company: company, }]);
    const [checkBox, setCheckBox] = useState([]);

    const [isChecked, setIsChecked] = useState([]);

    // if (lastNameOne === undefined || lastNameOne === '') {
    //     var fullName = (name !== undefined ? name : '')
    // } else {
    //     var fullName = (name !== undefined ? name : '') + ' ' + (lastNameOne !== undefined ? lastNameOne : '');
    // }
    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })

            await fetch(urlGetCompanys, requesoption).then(response => response.json()).then(quotesResponse => {
                console.log("Respuesta de companys: ", quotesResponse)
                setCompanys(quotesResponse)
            })
        }())
    }, [])

    useEffect(() => {
        console.log("inputs: ", inputs)
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);
            }
        }
    }, [oldConfig, infoUser, inputs])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    async function handleNameTable(e) {
        const name = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setNameTable(name)
        // }
    }

    async function handleDNI(e) {
        const dni = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setDNI(dni)
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }

    async function handleCity(e) {
        const city = e.target.value
        setCity(city)
    }

    async function handleDate(e) {
        const city = e.target.value
        setDate(city)
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
    }

    async function handleUnitValue(e) {
        const unitValue = e.target.value
        setUnitValue(unitValue)
    }

    async function handleReason(e) {
        const reason = e.target.value
        setReason(reason)
    }

    async function handleDetail(e) {
        const detail = e.target.value
        setDetail(detail)
    }

    async function handleQuantity(e) {
        const quantity = e.target.value
        setQuantity(quantity)
    }

    async function handleCompany(e) {
        let valueType = e.target.value
        // console.log("valueType: ", valueType)
        // if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
        setCompany(valueType)
        // }
    }

    // async function handleReturn() {
    //     if(props.id){
    //         props.edit()
    //     }
    // }

    const addInput = () => {
        const newId = inputs.length > 0 ? inputs[inputs.length - 1].id + 1 : 1;
        setInputs([...inputs, { id: newId, notNull: false, type: "VARCHAR", nameColumn: "", value: "", default: "" }]);
    };

    const removeInput = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
    };

    const toggleCheckbox = (id) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, notNull: !input.notNull } : input
        ));
    };

    const handleSelectChange = (id, value) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, type: value } : input
        ));
    };

    const handleTextChange = (id, value) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, nameColumn: value } : input
        ));
    };

    const handleValueChange = (id, value) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, value: value } : input
        ));
    };

    const handleDefaultChange = (id, value) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, default: value } : input
        ));
    };

    async function addNewBill() {
        console.log("Inputs agregados: ", inputs)

        // let validateValues = [
        //     { access: name, vadidate: false, msj: "Recuerda llenar el campo de 'Nombre'" },
        //     { access: phone, vadidate: false, msj: "Recuerda llenar el campo de 'Telefono'" },
        //     { access: DNI, vadidate: false, msj: "Recuerda llenar el campo de 'Numero de Documento'" },
        //     { access: direction, vadidate: false, msj: "Recuerda llenar el campo de 'Direccion'" },
        //     { access: typeDocument, vadidate: false, msj: "Recuerda llenar el campo de 'Tipo de Documento'" },
        //     { access: city, vadidate: false, msj: "Recuerda llenar el campo de 'Ciudad'" },
        //     { access: date, vadidate: false, msj: "Recuerda llenar el campo de 'Fecha'" },
        //     { access: quantity, vadidate: false, msj: "Recuerda llenar el campo de 'Cantidad'" },
        //     { access: unitValue, vadidate: false, msj: "Recuerda llenar el campo de 'Valor unidad'" },
        //     { access: reason, vadidate: false, msj: "Recuerda llenar el campo de 'Razon'" },
        //     { access: detail, vadidate: false, msj: "Recuerda llenar el campo de 'Detalle'" },
        // ]

        // let validate = await validationBills(validateValues)
        // if (validate.status) {
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: '¡Advertencia!',
        //         text: validate.item.msj,
        //         allowOutsideClick: false,
        //     })
        // }

        const data = JSON.stringify({
            empresa: company,
            nameTable: nameTable,
            columns: inputs,
            // id: props.id ? props.id : null,
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(urlTable, requesoption).then(response => response.json())
            .then(dataResponse => {
                console.log("dataResponse: ", dataResponse)
                if (200 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'success',
                        title: '¡Hecho!',
                        text: dataResponse.msj,
                        allowOutsideClick: false,
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = './Facturas';
                        }
                    })
                }
                if (400 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: dataResponse.msj,
                        confirmButtonText: 'Aceptar',
                    })
                }
            })
    }

    return (
        <>
            {(infoUser.login === true) ? (
                <>
                    <div className="containerHeardANDTable">
                        <div className="HeardANDFilter">
                            <LogoComponent />
                            <div className='containerButtonAdd'>
                                <div className="navigationContainer">
                                    <div className="pageTitle">AGREGAR TABLAS A EMPRESA</div>
                                </div>
                            </div>
                            <div className='closeIntContainer'>
                                {/* <NavLink onClick={Cerrar} className='closeButton'>
                                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                        <ImExit  className='iconNav'/>
                                    </NavLink> */}
                                <CloseButton />
                            </div>
                        </div>
                    </div>
                    <div id='overallContainer' className='overallContainer'>
                        <Nav1 />
                        <div className='drawerFormAddCuotes'>
                            <form className='ContainerClient'>
                                <div className='containerInfo'>
                                    <div className='infoOne infoOneAddBill'>
                                        <p className='personalInformation'>REGISTRO MYSQL</p>
                                        <div className='containerNameClient'>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={nameTable}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleNameTable}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Nombre de Tabla<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    name="nitSelect"
                                                    id="nitSelect"
                                                    className="inputCLient"
                                                    placeholder=""
                                                    list="drawfemails2"
                                                    onChange={handleCompany}
                                                    value={(company === '') ? ('') : (company)}
                                                />
                                                <label htmlFor="name">Empresa: <em className='Obligatory'>*</em>  </label>
                                                <datalist id="drawfemails2" value="nitSelect">
                                                    {/* <option value="NIT">NIT</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option> */}
                                                    {
                                                        companys.map((item) => {
                                                            return <option value={item.nameCompany} >{item.nameCompany.split("_")[1]}</option>
                                                        })
                                                    }
                                                </datalist>
                                            </div>


                                        </div>
                                        <div className="">
                                            {/* <div> */}
                                            <NavLink onClick={addInput}>Más</NavLink>
                                            <NavLink onClick={removeInput}>Menos</NavLink>
                                            {inputs.map((input) => (
                                                <div key={input.id} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
                                                    <div className='inputClientContainer'>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            className="inputCLient"
                                                            placeholder=""
                                                            value={input.nameColumn}
                                                            onChange={(e) => handleTextChange(input.id, e.target.value)}
                                                        />
                                                        <label htmlFor="name">Nombre de columna<em className='Obligatory'>*</em> </label>
                                                    </div>
                                                    <div className='inputClientContainer'>
                                                        Not Null?
                                                        <input
                                                            type="checkbox"
                                                            checked={input.notNull}
                                                            onChange={() => toggleCheckbox(input.id)}
                                                            style={{ marginLeft: '10px' }}
                                                        />
                                                        {/* <label htmlFor="">Not Null?<em className='Obligatory'>*</em> </label> */}
                                                    </div>
                                                    {/* <div className='columnNameTable'> */}
                                                    <select
                                                        value={input.type}
                                                        onChange={(e) => handleSelectChange(input.id, e.target.value)}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        <option value="VARCHAR">VARCHAR</option>
                                                        <option value="INT">INT</option>
                                                    </select>
                                                    <div className='inputClientContainer'>
                                                        <input
                                                            className='inputCLient'
                                                            type="text"
                                                            value={input.value}
                                                            onChange={(e) => handleValueChange(input.id, e.target.value)}
                                                        />
                                                        <label htmlFor="name">Valor<em className='Obligatory'>*</em> </label>
                                                    </div>
                                                    <div className='inputClientContainer'>
                                                        <input
                                                            type="text"
                                                            className='inputCLient'
                                                            value={input.default}
                                                            onChange={(e) => handleDefaultChange(input.id, e.target.value)}
                                                        />
                                                        <label htmlFor="name">Default<em className='Obligatory'>*</em> </label>

                                                    </div>

                                                    <button onClick={() => removeInput(input.id)} style={{ marginLeft: '10px' }}>Menos</button>
                                                </div>
                                            ))}
                                            {/* </div> */}
                                        </div>
                                        <div className="containerLongText">
                                            <NavLink className='Textregister' to="/Menu_Configuracion"><button className="button neon_Effect" ><p>Atras</p></button></NavLink>
                                            <NavLink onClick={addNewBill} className='Textregister'><button className="button neon_Effect"><p>Crear</p></button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddTable