import { useEffect, useState, useMemo, useContext } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";

import { NavLink } from "react-router-dom";
import LoginContext from "../../../Context/Logincontext";
import CloseButton from "../../../Component/CloseButton";

const TablaCompany = (props) => {
  const { infoUser, setInfoUser } = useContext(LoginContext)
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);
  const edit = useMemo(() => props.edit, [props.edit]);
  const id = useMemo(() => props.id, [props.id]);
  const saveDataTable = useMemo(() => props.saveDataTable, [props.saveDataTable]);
  const importantData = useMemo(() => props.importantData, [props.importantData]);
  const table = useTable(
    {
      columns,
      data,
      saveDataTable,
      //   functionNew,
      //   power,
      //   getUpdateRemission,
      //   remission,
      edit,
      id,
      importantData,
      initialState: {
        pageSize: 5,
        pageIndex: 0
      }
    },
    useGlobalFilter,
    usePagination
  );

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
    state: { pageIndex, pageSize }
  } = table;






  return (
    <>
      <div className="container-Table">
        {page.length > 0 && ( // Verificar si hay elementos en la página
          <div className="cardContainer">
            {headerGroups.map((headerGroup) => (
              <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()}>
                <div className="containerCardHeardCell">
                  <div className="cardHeaderCell paddingLeft" style={{ width: '30%' }}{...headerGroup.headers[0].getHeaderProps()}>
                    {headerGroup.headers[0].render("Header")}
                  </div>
                  <div className="cardHeaderCell center" style={{ width: '30%' }}{...headerGroup.headers[1].getHeaderProps()}>
                    {headerGroup.headers[1].render("Header")}
                  </div>
                  <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[2].getHeaderProps()}>
                    {headerGroup.headers[2].render("Header")}
                  </div>
                  <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[3].getHeaderProps()}>
                    {headerGroup.headers[3].render("Header")}
                  </div>
                  <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[4].getHeaderProps()}>
                    {headerGroup.headers[4].render("Header")}
                  </div>
                  <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[5].getHeaderProps()}>
                    {headerGroup.headers[5].render("Header")}
                  </div>

                  <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[6].getHeaderProps()}>
                    {headerGroup.headers[6].render("Header")}
                  </div>
                  {/* <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[6].getHeaderProps()}>
                    {headerGroup.headers[7].render("Header")}
                  </div> */}

                </div>
              </div>
            ))}
            <div className="rowContainer">
              <div className="containerRow">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div className="card" {...row.getRowProps()}>
                      <div className="cardContentRow">
                        <div className="cardContentCell paddingLeft" style={{ width: '30%' }} {...row.cells[0].getCellProps()}>
                          {row.cells[0].render("Cell")}
                        </div>
                        <div className="cardContentCell center" style={{ width: '30%' }} {...row.cells[1].getCellProps()}>
                          {row.cells[1].render("Cell")}
                        </div>
                        <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[2].getCellProps()}>
                          {row.cells[2].render("Cell")}
                        </div>
                        <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[3].getCellProps()}>
                          {row.cells[3].render("Cell")}
                        </div>
                        <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[4].getCellProps()}>
                          {row.cells[4].render("Cell")}
                        </div>
                        <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[5].getCellProps()}>
                          {row.cells[5].render("Cell")}
                        </div>

                        <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[6].getCellProps()}>
                          {row.cells[6].render("Cell")}
                        </div>

                        {/* <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[6].getCellProps()}>
                          {row.cells[7].render("Cell")}
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TablaCompany;