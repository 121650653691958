import { useEffect, useState, useMemo, useContext } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { columnsCompanyAdmin } from "../../Component/Hooks/ColumTables";

import { NavLink } from "react-router-dom";
import LoginContext from "../../Context/Logincontext";
import CloseButton from "../../Component/CloseButton";
import TablaCompany from "./TablaCompany/TablaCompany";
import { urlApiServer } from "../../Config/Config";

const urlGetCompanys = urlApiServer + "getCompanys"
const CompanyView = (props) => {
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [quotesGetTable, setQuotesGetTable] = useState([])
    const [dataImportant, setDataImportant] = useState()

    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlGetCompanys, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setQuotesGetTable(quotesResponse.reverse());
                })
                .catch(error => {
                });
        }())

    }, [])

    function handleUpdate() {

    }

    function edit() {

    }

    async function handleData(e) {
        setDataImportant(quotesGetTable[e])
    }

    function handleId(){
        
    }

    return (
        <>
            <div className="containerLogoAdd">
                <p className='titleWithoutCompany'>Configuracion</p>
                <div className='closeIntContainer'>
                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                        <ImExit  className='iconNav'/>
                    </NavLink> */}
                    <CloseButton />
                </div>
            </div>
            <div id='overallContainerLogin' className='overallContainerLogin'>
                <div className='mainContainerCompany'>
                    <div className='containerInfoConfig'>
                        <TablaCompany columns={columnsCompanyAdmin} data={quotesGetTable} function={handleUpdate} edit={edit} importantData={handleData} id={handleId} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanyView;