import { useContext, useEffect, useState } from 'react'
import { columnsBills, columnsQuotesAdmin, columnsBillsAdmin } from '../Component/Hooks/ColumTables'
import Loader from '../Component/Loader';
import LoginContext from '../Context/Logincontext';
import Table from '../Component/Tabla/Tabla'
import { urlApiServer } from '../Config/Config';
import Swal from 'sweetalert2'
import AddBills from './AddBills';
import { convertJson } from '../Component/Hooks/Bill/functions/ConvertJson';
import Contribution from '../Component/Hooks/Bill/Contribution';
const Bills = () => {

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [billsGetTable, setBillsGetTable] = useState([])
    const [dataImportant, setDataImportant] = useState()
    const [id, setId] = useState()
    const [disabledBottom, setDisabledBottom] = useState(false);
    const [powerEdit, setPowerEdit] = useState(true)
    const [refreshPage, setRefreshPage] = useState(false)
    const [dataBill, setDataBill] = useState({});
    const [showBill, setShowBill] = useState(false);
    const [oldConfig, setOldConfig] = useState("")
    const [signature, setSignature] = useState("")

    const urlGetBills = urlApiServer + "bills"
    const urlGetBillById = urlApiServer + "billById"

    let splitSignature = []

    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlGetBills, requesoption)
                .then(response => response.json())
                .then(billsResponse => {
                    setBillsGetTable(billsResponse.data.reverse());
                })
                .catch(error => {
                    return Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: "Ha ocurrido un error al realizar la peticion",
                        confirmButtonText: 'Aceptar',
                    })
                });
        }())
    }, [refreshPage])


    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo, signature } = foundConfig;
                setSignature(signature)
            }
        }
    }, [oldConfig, infoUser])

    if (signature) {
        splitSignature = signature.split("|")
    }

    async function handleData(e) {
        setDataImportant(billsGetTable[e])
    }

    async function handleId(e) {
        setId(e)
    }

    async function handleRefreshPage() {
        setRefreshPage(!refreshPage)
    }

    async function showLoader(data1, data2) {
        setId(data2)
        setDataImportant(billsGetTable[data1])
        setDisabledBottom(true)
    }

    async function edit() {
        if (powerEdit === true) {
            setPowerEdit(false)
        } else {
            setPowerEdit(true)
        }
    }

    async function handleBill(_) {
        let historyValidation = null
        const dataJson = JSON.stringify({
            empresa: infoUser.company,
            id: _.Id,
        })

        const InicioHeaders = new Headers();
        InicioHeaders.append("Content-Type", "application/json");
        InicioHeaders.append("company", infoUser.company);
        InicioHeaders.append("id", _.Id);

        const requesoption = {
            method: 'GET',
            headers: InicioHeaders,
            redirect: 'follow'
        };
        await fetch(urlGetBillById, requesoption).then(response => response.json())
            .then(dataResponse => {
                historyValidation = dataResponse.data
                // if (200 === dataResponse.status) {
                //     return Swal.fire({
                //         icon: 'success',
                //         title: '¡Hecho!',
                //         text: dataResponse.msj,
                //         allowOutsideClick: false,
                //         confirmButtonText: 'Aceptar',
                //     }).then((result) => {
                //         if (result.isConfirmed) {
                //             window.location.href = './Facturas';
                //         }
                //     })
                // }
                // if (400 === dataResponse.status) {
                //     return Swal.fire({
                //         icon: 'error',
                //         title: '¡Error!',
                //         text: dataResponse.msj,
                //         confirmButtonText: 'Aceptar',
                //     })
                // }
            })

        let data = {
            nro: _.Id,
            name: _.nombre,
            company: "empresa_"+_.razon,
            identification: _.cedula,
            typeIdentification: _.tipo_identificacion,
            nitocc: _.nit_cliente,
            address: _.direccion,
            phone: _.telefono,
            city: _.ciudad,
            companyClient: _.mostrar,
            nameClient: _.nombre_cliente,
            bankAccount: _.cuenta_banco,
            bank: _.banco,
            signature: _.firma,
            positionCompany: _.posicion,
            products: historyValidation,
            billPage: true
        }
        await setDataBill(convertJson(data))
        setId(_.Id)
        handleShowBill()
    }

    async function handleShowBill(e) {
        setShowBill(!showBill)
    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    (!showBill) ? (

                        (powerEdit === true) ? (
                            // (remissionValidate === true) ? (
                            <>

                                {(infoUser.role === 'Administrador') ? (
                                    <>
                                        <Table columns={columnsBillsAdmin} data={billsGetTable} importantData={handleData} id={handleId} showLoader={showLoader} edit={edit} handleRefreshPage={handleRefreshPage} handleShowBill={handleBill}/>
                                    </>
                                ) : (
                                    <>
                                        <Table columns={columnsBills} data={billsGetTable} importantData={handleData} id={handleId} showLoader={showLoader} edit={edit} handleRefreshPage={handleRefreshPage} />
                                    </>
                                )}
                            </>
                            // ) : (<Remission pdf={dataImportant} remission={remission} dataRemission={dataRemission} historyResponse={historyProduct} logo={splitImg} signature={splitSignature} id={id} company={company} />)
                        ) : (<AddBills data={dataImportant} edit={edit} type="edit" id={id} />)
                    ) : (<Contribution data={dataBill} signature={splitSignature} handleShowBill={handleShowBill} />)
                )
            ) : (<></>)}
        </>
    )
}

export default Bills