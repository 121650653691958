import { useContext, useEffect, useState } from "react"
import LoginContext from "../../Context/Logincontext"
import Nav1 from '../../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import "../../styles/AddProduct.css"
import { urlApiServer } from '../../Config/Config'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
import Loader from "../Loader"
import Resizer from 'react-image-file-resizer';
import CloseButton from "../CloseButton"
import LogoComponent from "../LogoComponent"

let splitImg = []

const EditTexture = (props) => {
    const urlTexureEdit = urlApiServer + "editTexture";
    const urlgetConfig = urlApiServer + "getConfig";
    const urlsubir = urlApiServer + "UploadfileTexture";

    const [texture, setTexture] = useState()
    const [Long, setLong] = useState()
    const [width, setWidth] = useState()
    const [price, setPrice] = useState()
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [hasImage, setHasImage] = useState(false);
    const [image, setImage] = useState([])
    const [imageTest, setImageTest] = useState("")
    const [oldImage, setOldImage] = useState("")
    const [textureGetTable, setTextureGetTable] = useState([])
    const [disabledBottom, setDisabledBottom] = useState(false);

    const urlgetTexture = urlApiServer + "getTexture"

    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlgetTexture, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setTextureGetTable(quotesResponse.reverse());
                })
                .catch(error => {
                });
        }())

    }, [])

    useEffect(() => {
        const TextureData = props.data.find(data => data.Id === props.id);
        if (TextureData) {
            const { length, nameTexture, price, texture, width } = TextureData;
            setLong(parseFloat(length))
            setWidth(parseFloat(width))
            setPrice(parseFloat(price))
            setTexture(nameTexture)
            setImageTest(texture)
            setOldImage(texture)
        }
    }, [textureGetTable])

    async function off() {
        props.edit()
    }

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }


    async function handleTexture(e) {
        const texture = e.target.value
        setTexture(texture)
    }

    async function handleLong(e) {
        const Long = e.target.value
        setLong(Long)
    }

    async function handleWidth(e) {
        const width = e.target.value
        setWidth(width)
    }

    async function handlePrice(e) {
        const price = e.target.value
        setPrice(price)
    }

    async function uploadProduct() {


        if ((texture === null) || (texture === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Nombre de material" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if ((imageTest === "") || (imageTest === undefined)) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor escoge una imagen antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if ((Long === null) || (Long === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Largo" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if ((width === null) || (width === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Ancho" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if ((price === null) || (price === '')) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Valor" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        const data = JSON.stringify({
            empresa: infoUser.company,
            texture: texture,
            image: imageTest,
            Long: Long,
            width: width,
            price: price,
            Id: props.id,
            oldImage: oldImage
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'PUT',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        await fetch(urlTexureEdit, requesoption).then(response => response.json())
            .then(dataResponse => {
                // image.map((file) => {
                sendfile(image)
                // })            
                if ('Actualizado producto' === dataResponse.msj) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Dato editado',
                        text: 'Has editado este dato correctamente',
                        allowOutsideClick: false,
                    })
                    props.function()
                    props.edit()
                }
            })
    }

    async function sendfile(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    async function handleImagen(e) {
        setDisabledBottom(true)
        const image = e.target.files[0]
        try {
            // console.log("antes image: ", image.name)
            Resizer.imageFileResizer(
                image,
                1000, // nuevo ancho
                1000, // nuevo alto
                'JPEG', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    // console.log("compressedFile: ", compressedFile)
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    // console.log("compressedImageURL: ", compressedImageURL)
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    // console.log("Url blob: ", blob)
                    const file = new File([blob], timestamp + "_" + image.name, { type: "image/png" });
                    // console.log("file: ", file)
                    setImageTest(file.name)
                    setHasImage(file !== undefined ? true : false);
                    setImage(file)
                    setDisabledBottom(false)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage(false);
        }
    }

    return (
        <>
            {(infoUser.login === true) ? (
                <>
                    <div className="containerHeardANDTable">
                        <div className="HeardANDFilter">
                            <LogoComponent />
                            <div className='containerButtonMaker'>
                                <div className="navigationContainer">
                                    <div className="pageTitle">CREAR MATERIAL</div>
                                </div>
                            </div>
                            <div className='filterContainer'>
                                <div className="containerFilter">
                                </div>
                            </div>
                            <div className='closeIntContainer'>
                                {/* <NavLink onClick={Cerrar} className='closeButton'>
                                    <span className="tooltipProductMenos">Cerrar Sesión</span>
                                    <ImExit  className='iconNav'/>
                                </NavLink> */}
                                <CloseButton />
                            </div>
                        </div>
                    </div>
                    {
                        (disabledBottom) && (
                            <div className="backgroundLoader">
                                <Loader />
                            </div>


                        )
                    }
                    <div id="overallContainer" className="overallContainer">
                        <Nav1 />
                        <div className="drawerFormAddCuotes">
                            <form className="ContainerProduct">
                                <div className="containerInfoProduct">
                                    <div className="infoTwoAddProduct">
                                        <div className="containeProductAdd">
                                            <div className="inputProductContainer">
                                                <input
                                                    type="text"
                                                    name="Product"
                                                    id="Product"
                                                    onChange={handleTexture}
                                                    value={texture}
                                                    className="inputProducts"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Nombre del material</label>
                                            </div>
                                            <div className={`file-upload ${hasImage ? 'has-image' : ''}`}>
                                                <label for="file-input" class="upload-label">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                    <span className="upload-text">{hasImage ? 'Material almacenado' : 'Haz clic para cargar un material'}</span>
                                                </label>
                                                <input id="file-input" onChange={handleImagen} type="file" class="file-input" />
                                                <div class="progress-bar"></div>
                                            </div>
                                            <div className="inputProductContainer">
                                                <input
                                                    type="text"
                                                    name="Product"
                                                    id="Product"
                                                    onChange={handleLong}
                                                    value={Long}
                                                    className="inputProducts"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Largo</label>
                                            </div>
                                            <div className="inputProductContainer">
                                                <input
                                                    type="text"
                                                    name="Product"
                                                    id="Product"
                                                    onChange={handleWidth}
                                                    value={width}
                                                    className="inputProducts"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Ancho</label>
                                            </div>
                                            <div className="inputProductContainer">
                                                <input
                                                    type="text"
                                                    name="Product"
                                                    id="Product"
                                                    value={price}
                                                    onChange={handlePrice}
                                                    className="inputProducts"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Valor</label>
                                            </div>
                                        </div>
                                        <div className="buttonContainerProduct">
                                            <NavLink className="Textregister" onClick={off}>
                                                <button className="button neon_Effect">
                                                    <p>Atrás</p>
                                                </button>
                                            </NavLink>
                                            <NavLink onClick={uploadProduct} className="Textregister">
                                                <button className="button neon_Effect">
                                                    <p>Agregar</p>
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default EditTexture