import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./../../styles/AddProduct.css"
import '../../styles/AddClient.css'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
// import '../styles/AddBills.css'
import '../../styles/AddBills.css'
import LoginContext from '../../Context/Logincontext'
import Nav1 from '../../Component/Nav/Nav1'
import CloseButton from '../../Component/CloseButton'
import LogoComponent from '../../Component/LogoComponent'
import { urlApiServer } from '../../Config/Config'
import { validationBills } from '../validations/Validations'

let splitImg = []
const AddUserAdmin = (props) => {

    const urlAdminUser = urlApiServer + "addAdminUser";
    const urlUpdateBill = urlApiServer + "updateBill";
    const urlGetCompanys = urlApiServer + "getCompanys";

    const urlgetConfig = urlApiServer + "getConfig";
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [DNI, setDNI] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatWord] = useState(null)
    const [reason, setReason] = useState(null)
    const [detail, setDetail] = useState(null)
    const [direction, setDirection] = useState(null)
    const [contact, setContact] = useState(null)
    const [type, setType] = useState('')
    const [date, setDate] = useState(null)
    const [companys, setCompanys] = useState([])

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    // const [inputs, setInputs] = useState([{ id: 1, notNull: false, type: "VARCHAR", nameColumn: "", value: "", default: "", nameTable: nameTable, company: company, }]);
    const [checkBox, setCheckBox] = useState([]);

    const [isChecked, setIsChecked] = useState([]);

    // if (lastNameOne === undefined || lastNameOne === '') {
    //     var fullName = (name !== undefined ? name : '')
    // } else {
    //     var fullName = (name !== undefined ? name : '') + ' ' + (lastNameOne !== undefined ? lastNameOne : '');
    // }
    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })

            await fetch(urlGetCompanys, requesoption).then(response => response.json()).then(quotesResponse => {
                console.log("Respuesta de companys: ", quotesResponse)
                setCompanys(quotesResponse)
            })
        }())
    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);
            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    async function handleName(e) {
        const name = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setName(name)
        // }
    }

    async function handleLastName(e) {
        const lastName = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setLastName(lastName)
    }

    async function handleDNI(e) {
        const dni = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setDNI(dni)
    }

    async function handleEmail(e) {
        // const email = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setEmail(e.target.value)
    }

    async function handlePhone(e) {
        const phone = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setPhone(phone)
    }

    async function handlePassword(e) {
        const pass = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setPassword(pass)
    }

    async function handleRepeatPassword(e) {
        const repeatPass = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setRepeatWord(repeatPass)
    }


    async function handleType(e) {
        let valueType = e.target.value
        // console.log("valueType: ", valueType)
        // if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
        setType(valueType)
        // }
    }



    async function addNewBill() {
        if(password !== repeatPassword){
            return Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: "Las contraseñas no coinciden",
                confirmButtonText: 'Aceptar',
            })
        }
        let validateValues = [
            { access: name, vadidate: false, msj: "Recuerda llenar el campo de 'Nombre'" },
            { access: lastName, vadidate: false, msj: "Recuerda llenar el campo de 'Apellidos'" },
            { access: phone, vadidate: false, msj: "Recuerda llenar el campo de 'Telefono'" },
            { access: DNI, vadidate: false, msj: "Recuerda llenar el campo de 'Numero de Documento'" },
            { access: direction, vadidate: false, msj: "Recuerda llenar el campo de 'Direccion'" },
            { access: type, vadidate: false, msj: "Recuerda llenar el campo de 'Tipo de Documento'" },
            { access: password, vadidate: false, msj: "Recuerda llenar el campo de 'Contraseña'" }

        ]

        let validate = await validationBills(validateValues)
        if (validate.status) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: validate.item.msj,
                allowOutsideClick: false,
            })
        }

        const data = JSON.stringify({
            name: name,
            lastName: lastName,
            DNI: DNI,
            type: type,
            email: email,
            phone: phone,
            password: password,
            checkPassword: repeatPassword
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(urlAdminUser, requesoption).then(response => response.json())
            .then(dataResponse => {
                console.log("dataResponse: ", dataResponse)
                if (200 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'success',
                        title: '¡Hecho!',
                        text: dataResponse.msj,
                        allowOutsideClick: false,
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = './Facturas';
                        }
                    })
                }
                if (400 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: dataResponse.msj,
                        confirmButtonText: 'Aceptar',
                    })
                }
            })
    }

    return (
        <>
            {(infoUser.login === true) ? (
                <>
                    <div className="containerHeardANDTable">
                        <div className="HeardANDFilter">
                            <LogoComponent />
                            <div className='containerButtonAdd'>
                                <div className="navigationContainer">
                                    <div className="pageTitle">AGREGAR ADMINISTRADOR</div>
                                </div>
                            </div>
                            <div className='closeIntContainer'>
                                {/* <NavLink onClick={Cerrar} className='closeButton'>
                                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                        <ImExit  className='iconNav'/>
                                    </NavLink> */}
                                <CloseButton />
                            </div>
                        </div>
                    </div>
                    <div id='overallContainer' className='overallContainer'>
                        <Nav1 />
                        <div className='drawerFormAddCuotes'>
                            <form className='ContainerClient'>
                                <div className='containerInfo'>
                                    <div className='infoOne infoOneAddBill'>
                                        <p className='personalInformation'>REGISTRO ADMIN</p>
                                        <div className='containerNameClient'>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={name}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleName}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Nombre<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={lastName}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleLastName}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Apellido<em className='Obligatory'>*</em> </label>
                                            </div>

                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={DNI}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleDNI}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Cedula<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    name="nitSelect"
                                                    id="nitSelect"
                                                    className="inputCLient"
                                                    placeholder=""
                                                    list="drawfemails2"
                                                    onChange={handleType}
                                                    value={(type === '') ? ('') : (type)}
                                                />
                                                <label htmlFor="name">Tipo de Cedula: <em className='Obligatory'>*</em>  </label>
                                                <datalist id="drawfemails2" value="nitSelect">
                                                    <option value="NIT">NIT</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                </datalist>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={email}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleEmail}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Correo<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={phone}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handlePhone}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Telefono<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={password}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handlePassword}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Contraseña<em className='Obligatory'>*</em> </label>
                                            </div>
                                            <div className="inputClientContainer">
                                                <input
                                                    type="text"
                                                    value={repeatPassword}
                                                    name="Name"
                                                    id="Name"
                                                    onChange={handleRepeatPassword}
                                                    className="inputCLient"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Repetir Contraseña<em className='Obligatory'>*</em> </label>
                                            </div>

                                        </div>
                                        <div className="containerLongText">
                                            <NavLink className='Textregister' to="/Menu_Configuracion"><button className="button neon_Effect" ><p>Atras</p></button></NavLink>
                                            <NavLink onClick={addNewBill} className='Textregister'><button className="button neon_Effect"><p>Crear</p></button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddUserAdmin