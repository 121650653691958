import React, { useState } from "react"
import jsPDF from "jspdf"
import { NavLink } from "react-router-dom"
import '../../styles/Remission.css'

let historyValidation = []
let remissionValidation = []
let DataForTheQuote = []
let markerWaterPadding = [] 

const Remission = (props) => {

    const [historyProduct, setHistoryProduct] = useState(props.historyResponse)
    const [dataRemission, setDataRemission] = useState(props.dataRemission)


    async function off (){
        historyValidation = []
        remissionValidation= []
        props.remission()
        DataForTheQuote = []
        markerWaterPadding = []
    }

    historyProduct.map((history)=>{
        if(props.id == history.nrocotizacion){
            historyValidation.push(history)
        }
    })
    markerWaterPadding.push(58*historyValidation.length)

    dataRemission.map((remission)=>{
        if(props.id == remission.nrocotizacion){
            remissionValidation.push(remission)
        }
    })

    const createPDF = async () => {
        var margin = 1 
        var doc = new jsPDF('p','pt', 'letter')
        var scale = (doc.internal.pageSize.width - margin * 2) / document.querySelector("#conect").scrollWidth
        doc.html (document.querySelector("#conect"),{
                x: margin,
                y: margin,
                html2canvas: {
                    scale: scale,
                },
                callback: function(pdf){
                    pdf.save("Remisíon.pdf");
                }
            }
        )
    };

    if(props.pdf.fechaentrega === null){
    }else{
        var deliveryDate = props.pdf.fechaentrega;
        var dateDelivery = new Date(deliveryDate);
        var formattedDateDelivery = dateDelivery.toLocaleDateString('es-ES'); 
    }

    var currentDate = remissionValidation[0].fecha;
    var date = new Date(currentDate);
    var day = date.getDate(); 
    var month = date.getMonth() + 1; 
    var year = date.getFullYear();
    
    
    return (
        <div id='overallContainerAdd' className='overallContainerPdf'>
            <div id='conect' className="containerGeneralRemission">
                <div className='containerLogoRemission'>
                    <div className='containerImageLogoRemission'>
                        <div className="containerTextAddressRemission">
                            <p className="textAddressRemission" style={{letterSpacing: '0.01px'}}>{props.company[0].companyAddres}</p>
                            <p className="textAddressRemission" style={{letterSpacing: '0.01px'}}>Telefono: {props.company[0].companyPhone}</p>
                            <p className="textAddressRemission" style={{letterSpacing: '0.01px'}}>Celular: {props.company[0].companyMobile}</p>
                            <p className="textAddressRemission" style={{letterSpacing: '0.01px'}}>NIT: {props.company[0].companyNit}</p>
                        </div>
                    </div>
                    <div className="containerTableRemission">
                        <div className="expedition">
                            <div className="expeditionTittle">EXPEDIDA</div>
                            <div className="expeditionDate">
                                <div className="expeditionDay">
                                    <p className="expeditionTextDay">DÍA</p>
                                    <p className="expeditionNumberDay">{day}</p>
                                </div>
                                <div className="expeditionMonth">
                                    <p className="expeditionTextMonth">MES</p>
                                    <p className="expeditionNumberMonth">{month}</p>
                                </div>
                                <div className="expeditionYear">
                                    <p className="expeditionTextYear">AÑO</p>
                                    <p className="expeditionNumbertYear">{year}</p>
                                </div>
                            </div>
                        </div>
                        <div className="containerRemission">
                            <p className="tittleTextRemission">REMISIÓN</p>
                            <div style={{display: 'flex', flexDirection: 'row'}}><p className="numberRemission">NO.</p><div style={{color: 'red', fontSize: '23px'}}>{props.pdf.nrocotizacion}</div></div>
                        </div>
                    </div>
                </div>
                <div className="referralPersonalData">
                    <div className="sirClient">Señor(es):<di className="lineBottom">{props.pdf.cliente}</di></div>
                    <div className="sirClient2">
                        Telefono:<di className="lineBottom2">{props.pdf.telefono}</di>
                        Nit:<di className="lineBottom3">{props.pdf.nitocc}</di>
                    </div>
                    <div className="sirClient3">Dirección:<di className="lineBottom4">{props.pdf.direccion}</di></div>
                </div>
                <div className="ContainerReferralProductData">
                    <div className="containerWatermark">
                    </div> 
                    <div className="referralProductData">
                        <div className="grupTittleReferralProductData">
                            <div className="quantityRemisiion" style={{backgroundColor: 'grey'}}>CANT.</div>
                            <div className="articleRemisiion" style={{backgroundColor: 'grey'}}>ARTICULO.</div>
                            <div className="unitValueRemisiion" style={{backgroundColor: 'grey'}}>VR. UNIT.</div>
                             <div className="fullValueRemisiion" style={{backgroundColor: 'grey'}}>VR.TOTAL.</div>
                        </div>
                        {historyValidation.map((data, index) => {
                            return(
                                <div className="grupReferralProductData">
                                    <div className="quantityRemisiion">{new Intl.NumberFormat('es-MX').format(data.cantidadItem).replace(/,/g, '.')}</div>
                                    <div className="articleRemisiion">{data.producto} de {data.material} con medida de {data.largo} * {data.ancho} con un calibre de {data.calibre}</div>
                                    <div className="unitValueRemisiion"></div>
                                    <div className="fullValueRemisiion"></div>
                                </div>
                            )
                        })}
                        <div className="grupSigning">
                            <div className="signingRemission">
                                <div className="signingTop1">Firma {(props.company[0].nameCompany).replace(/_/g, ' ')}:</div>
                                <div className="signingTop2">Firma Cliente</div>
                            </div>
                            <div className="fullRemission">
                                <div>Total:</div>
                            </div>
                            <div className="emptyRemission"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttonContainerPdf">
                <NavLink to='/Cotizaciones' className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                <button className="button neon_Effect" onClick={createPDF}>Descargar</button>
            </div>
        </div>
    )

}

export default Remission