import React, { useContext, useEffect, useState } from 'react'
import './styles.css'

const Loader = () => {


    return (
        <div id="page">
            <div id="container">
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="h3">Cargando..</div>
            </div>
        </div>
    )
}

export default Loader