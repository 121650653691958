import { useRef, useEffect, useState, useContext } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FaBars } from 'react-icons/fa'
import '../ProductsIn3djs/ProductsIn3djs.css';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { urlApiServer } from "../../Config/Config";
import LoginContext from "../../Context/Logincontext";
import texture1 from '../../Textures/17538.jpeg';
import { NavLink } from "react-router-dom";
import ModelPlans from '../ModelPlans/ModelPlans'
import { CgMaximize } from 'react-icons/cg'
import Swal from 'sweetalert2'


let Plans = {}

let prePlans = {};

const EditProduct3D = (props) => {
  const [models, setModels] = useState([])
  const [selectModels, setSelectModels] = useState(false)
  const [mesurementText, setMesurementText] = useState()
  const [mesurementTextSpanish, setMesurementTextSpanish] = useState()
  const [mesurementNumber, setMesurementNumber] = useState()
  const [update, setUpdate] = useState(false)
  const { infoUser, setInfoUser } = useContext(LoginContext)
  const [textureSelect, setTextureSelect] = useState(texture1)
  const [textureName, setTextureName] = useState()
  const [showTexture, setShowTexture] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [modelingPlane, setModelingPlane] = useState(false);
  const [textureGetTable, setTextureGetTable] = useState([])
  const [isFullScreenComputer, setFullScreenComputer] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [prices, setPrices] = useState();
  const [id, setId] = useState(null)
  const [iva, setIva] = useState(null)
  const [requestData, setRequestData] = useState(null)
  const [saveTitleTextHigh, setSeveTitleTextHigh] = useState(undefined)
  const [saveTitleTextSpanishHigh, setSeveTitleTextSpanishHigh] = useState(undefined)
  const [savehigh, setSeveHigh] = useState(undefined)
  const [firstTouch, setFirsTouch] = useState(false)
  const [newScene, setNewScene] = useState(null)
  const [newCamera, setNewCamera] = useState(null)
  const [newRender, setNewRender] = useState(null)
  const [dataEdit, setDataEdit] = useState(null)

  const Mesurements = useRef(null);
  const mountRef = useRef(null);
  const divRef = useRef(null);


  const urlgetModel = urlApiServer + 'products'
  const urlgetTexture = urlApiServer + "getTexture"
  const urlModels = urlApiServer + "addmodels";

  let preview = []
  let view = []
  let PreViewTexture = []
  let viewTexture = []
  let renderer
  let camera
  let scene

  useEffect(() => {
    const ModelseData = props.data.find(data => data.Id === props.id);
    if (ModelseData) {
      const { Id, modeljson, iva, producto } = ModelseData;
      setDataEdit({
        iva: iva,
        producto: producto
      })
      setId(Id)
      Plans = JSON.parse(modeljson);
      prePlans = JSON.parse(modeljson);
      prePlans.nameTexture = Plans.nameTexture
    }
  }, [])

  async function off() {
    props.edit()
  }

  const toggleFullScreen = () => {
    setFullScreen(!isFullScreen);
    closeFullscreen()
  };

  const openFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setFullScreenComputer(true);
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreenComputer(false);
  };



  const toggleFullScreenComputer = () => {
    if (isFullScreenComputer) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  };

  function fullQuit() {
    window.location.href = './Productos'
  }

  function exitBack() {
    setModelingPlane(!modelingPlane)
    if (modelingPlane === true) {
      setUpdate(!update)
    }
  }


  const handleClickExtent = (event) => {
    if (Mesurements.current && !Mesurements.current.contains(event.target)) {
      document.getElementsByClassName('Mesurement')[0].style.display = 'none';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickExtent);

    return () => {
      document.removeEventListener('mousedown', handleClickExtent);
    };
  }, []);

  useEffect(() => {
    (async function () {
      const InicioHeaders = new Headers();
      InicioHeaders.append("Content-Type", "application/json");
      InicioHeaders.append("company", infoUser.company);

      const requesoption = {
        method: 'GET',
        headers: InicioHeaders,
        redirect: 'follow'
      }

      await fetch(urlgetModel, requesoption)
        .then(response => response.json())
        .then(quotesResponse => {
          setModels(quotesResponse);
        })
        .catch(error => {
          //console.log(error);
        });

    }())

  }, [])

  useEffect(() => {
    (async function () {
      const InicioHeaders = new Headers();
      InicioHeaders.append("Content-Type", "application/json");
      InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

      const requesoption = {
        method: 'GET',
        headers: InicioHeaders,
        redirect: 'follow'
      }

      await fetch(urlgetTexture, requesoption)
        .then(response => response.json())
        .then(quotesResponse => {
          setTextureGetTable(quotesResponse);
        })
        .catch(error => {
        });
    }())

  }, [])

  let res = models.filter((item, index) => {
    view.push(item.preview)

    return view.indexOf(item.preview) === index
  })

  res.reverse()

  let textureView = textureGetTable.filter((texture, index) => {
    viewTexture.push(texture.texture)

    return viewTexture.indexOf(texture.texture) === index
  })

  async function handleMesurement(e) {
    const mesurement = e.target.value
    setMesurementNumber(e.target.value)
    Plans.measure[mesurementText] = mesurement * 10
  }

  async function handleHeigth(e) {
    const heigth = e.target.value
    setSeveHigh(e.target.value)
    Plans.measure[saveTitleTextHigh] = heigth * 10
  }

  async function handleModel(item) {
    setId(item.Id)
    setIva(item.iva)
    prePlans = JSON.parse(item.modeljson)
    Plans = JSON.parse(item.modeljson)
    setTextureSelect(item.texture)
    setTextureName(item.nameTexture)
    setSelectModels(!selectModels)
  }

  async function handleTexture(textures) {
    setTextureSelect(urlApiServer + "viewTexture/" + textures.texture)
    setTextureName(textures.nameTexture)
    Plans.nameTexture = textures.nameTexture
    setPrices(parseFloat(textures.price) / parseFloat(textures.width) / parseFloat(textures.length * 100))
    Plans.texture = textures.texture
    Plans.prices = parseFloat(textures.price) / parseFloat(textures.width) / parseFloat(textures.length * 100)
  }

  let mainFigureDepth = null;

  for (const figureKey in Plans.coordinates) {
    if (Plans.coordinates.hasOwnProperty(figureKey)) {
      const figure = Plans.coordinates[figureKey];
      if (figure.piece === 'main') {
        mainFigureDepth = figure.depth;
        break;
      }
    }
  }

  if (typeof prePlans.long === 'string') {
    try {
      Plans.long = eval(prePlans.long);
    } catch (error) {
    }
  }

  if (typeof prePlans.pricesCalc === 'string') {
    try {
      Plans.pricesCalc = eval(prePlans.pricesCalc);
    } catch (error) {
    }
  }

  if (typeof prePlans.width === 'string') {
    try {
      Plans.width = eval(prePlans.width);
    } catch (error) {
    }
  }

  async function handleModelingPlane(file) {
    const ancho = Plans.width;
    console.log("Plans: ", props)
    if (Plans.name === undefined) {
      return Swal.fire('Selecciona un modelo primero')
    }

    if (textureSelect === texture1) {
      return Swal.fire('Selecciona una textura primero')
    }
    Swal.fire({
      title: 'Nombre del producto e iva',
      html:
        `
          <div>
            <p>Producto:</p>
            <input type="text" id="producto" value="${dataEdit.producto}" class="swal2-input" placeholder="Producto">
          </div>
          <div>
          <p>Iva:</p>
            <select id="mySelect2" class="ivaSelect" >
              <option value="${dataEdit.iva}">Iva:${dataEdit.iva}</option>
              <option value="0">0%</option>
              <option value="5">5%</option>
              <option value="16">16%</option>
              <option value="19">19%</option>
            </select>
          </div>
        `,
      showCancelButton: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return [
          /*0: */document.getElementById('producto').value,
          /*1: */document.getElementById('mySelect2').value,
        ]
      }
    }).then(async response => {
      // console.log("response.value[1] : ", response.value)
      if (response.value[1] === undefined || response.value[1] === 'null') {
        return Swal.fire('Ingrese un iva valido')
      }

      if (response.value[0].length > 500) {
        return Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El maximo de caracteres para el nombre del producto es de 500',
        })
      }

      let requestData = {
        empresa: infoUser.company,
        product: response.value[0],
        material: prePlans.nameTexture,
        long: Plans.long,
        caliber: mainFigureDepth,
        price: Plans.pricesCalc * Plans.prices,
        iva: response.value[1],
        ancho: ancho,
        image: file,
        Model: null,
      }
      setRequestData(requestData)
      setModelingPlane(!modelingPlane)
      if (modelingPlane === true) {
        setUpdate(!update)
      }
    })
  }

  const urlsubirPreview = urlApiServer + "Uploadfilepremodels";

  async function sendfilePremodels(file) {
    try {
      const formdata = new FormData();

      formdata.append("file", file);

      formdata.append(file.name, "foto");
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(urlsubirPreview, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    } catch (error) {
    }
  }

  async function handleUpdate() {
    setUpdate(!update)
    document.getElementsByClassName('Mesurement')[0].style.display = 'none';
  }

  useEffect(() => {
    if (Plans.length === 0) {

    } else {

      prePlans = prePlans
      Plans = Plans
      setTextureSelect((urlApiServer + "viewTexture/" + Plans.texture))

      const preCoordinate = prePlans.coordinates;

      for (const key in preCoordinate) {
        if (preCoordinate[key].type === "circle") {
          const coordinateProperty = preCoordinate[key];
          const depthExpression = coordinateProperty.depth;
          const evaluatedDepth = eval(depthExpression);
          Plans.coordinates[key].depth = evaluatedDepth;
          const circleRadiusExpression = coordinateProperty.circleRadius;
          const evaluatedCircleRadius = eval(circleRadiusExpression);
          Plans.coordinates[key].circleRadius = evaluatedCircleRadius;
          const circleBezierExpression = coordinateProperty.circleBezier;
          const evaluatedCircleBezier = eval(circleBezierExpression);
          Plans.coordinates[key].circleBezier = evaluatedCircleBezier;
        } else {
          const coordinateProperty = preCoordinate[key];
          const depthExpression = coordinateProperty.depth;
          const evaluatedDepth = eval(depthExpression);
          const points = coordinateProperty.points.map(poin => {
            const point = poin;
            const evaluatedExpr = eval(point);
            return evaluatedExpr;
          });
          Plans.coordinates[key].points = points;
          Plans.coordinates[key].depth = evaluatedDepth;
        }
      }

      const preText = prePlans.Texts;

      for (const key in preText) {

        const preTextProperty = preText[key];
        const PositionY = preTextProperty.PositionY
        const evaluatedPositionY = eval(PositionY);
        Plans.Texts[key].PositionY = evaluatedPositionY

        const PositionX = preTextProperty.PositionX
        const evaluatedPositionX = eval(PositionX);
        Plans.Texts[key].PositionX = evaluatedPositionX

        const PositionZ = preTextProperty.PositionZ
        const evaluatedPositionZ = eval(PositionZ);
        Plans.Texts[key].PositionZ = evaluatedPositionZ

        const RotateX = preTextProperty.RotateX
        const evaluatedRotateX = eval(RotateX);
        Plans.Texts[key].RotateX = evaluatedRotateX

        const RotateY = preTextProperty.RotateY
        const evaluatedRotatey = eval(RotateY);
        Plans.Texts[key].RotateY = evaluatedRotatey

        const measureM = preTextProperty.measureM
        const evaluatedmeasureM = eval(measureM);
        Plans.Texts[key].measureM = evaluatedmeasureM
      }

      const preLines = prePlans.Lines;

      for (const key in preLines) {
        const preLinesProperty = preLines[key];

        const depth = preLinesProperty.depth
        const evaluateddepth = eval(depth);
        Plans.Lines[key].depth = evaluateddepth

        const PositionX = preLinesProperty.PositionX
        const evaluatedPositionX = eval(PositionX);
        Plans.Lines[key].PositionX = evaluatedPositionX


        const PositionY = preLinesProperty.PositionY
        const evaluatedPositionY = eval(PositionY);
        Plans.Lines[key].PositionY = evaluatedPositionY

        const rotateX = preLinesProperty.rotateX
        const evaluatedRotateX = eval(rotateX);
        Plans.Lines[key].rotateX = evaluatedRotateX


        const rotateY = preLinesProperty.rotateY
        const evaluatedRotateY = eval(rotateY);
        Plans.Lines[key].rotateY = evaluatedRotateY

        const lines = preLinesProperty.line.map(lin => {
          const line = lin;
          const evaluatedExpr = eval(line); // Evaluación de la expresión
          return evaluatedExpr;
        });
        Plans.Lines[key].line = lines
      }

      const preHoles = prePlans.Holes;

      for (const key in preHoles) {

        const preHolesProperty = preHoles[key];

        const holes = preHolesProperty.points.map(hol => {
          const hole = hol;
          const evaluatedExpr = eval(hole);
          return evaluatedExpr;
        })
        Plans.Holes[key].points = holes
      }

      prePlans.measure = Plans.measure;
      prePlans.prices = Plans.prices;
      prePlans.texture = Plans.texture;
      prePlans.nameTexture = Plans.nameTexture

      const currentRef = mountRef.current;
      const { clientWidth: width, clientHeight: height } = currentRef;

      var secundaryGroup = new THREE.Group()
      var principalGroup = new THREE.Group()

      const raycaster = new THREE.Raycaster();

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x727272)

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.VSMShadowMap;
      currentRef.appendChild(renderer.domElement);

      const coordinates = Plans.coordinates;
      let zPosition = 0;

      for (const figureName in coordinates) {
        const figure = coordinates[figureName];
        if (figure.type === "circle") {
          const figureDepth = figure.depth;
          const figureRadius = figure.circleRadius;
          const figureBezier = figure.circleBezier;
          // const figureCoordinates = figure.points;
          const textureLoader = new THREE.TextureLoader();
          const texture = textureLoader.load(textureSelect);

          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(2, 2);

          const circleShape = new THREE.Shape();
          const circleRadius = figureRadius;

          const cp = circleRadius * figureBezier; // Valor para aproximar un círculo con una curva de Bézier
          circleShape.moveTo(circleRadius, 0);
          circleShape.bezierCurveTo(circleRadius, cp, cp, circleRadius, 0, circleRadius);
          circleShape.bezierCurveTo(-cp, circleRadius, -circleRadius, cp, -circleRadius, 0);
          circleShape.bezierCurveTo(-circleRadius, -cp, -cp, -circleRadius, 0, -circleRadius);
          circleShape.bezierCurveTo(cp, -circleRadius, circleRadius, -cp, circleRadius, 0);

          const extrudeSettings = {
            depth: figureDepth, // Profundidad
            bevelEnabled: false,
          };

          const geometry = new THREE.ExtrudeGeometry(circleShape, extrudeSettings);

          const materialObject = new THREE.MeshStandardMaterial();
          materialObject.map = texture;
          for (const lineName in Plans.Lines) {
            const lines = Plans.Lines[lineName];
            if (lines.rotate) {
              var linesPositionX = lines.PositionX;
              var linesPositionY = lines.PositionY;
              var linesRotateX = lines.rotateX
              var linesRotateY = lines.rotateY
            } else {
              var linesDepth = lines.depth;
            }
            const lineCoordinates = lines.line

            const measurementLines = new THREE.Shape();
            const [firstX, firstY] = lineCoordinates[0];

            measurementLines.moveTo(firstX, firstY);

            lineCoordinates.slice(1).forEach(([x, y]) => {
              measurementLines.lineTo(x, y);
            });

            measurementLines.lineTo(firstX, firstY);

            const measurementLineExtrudeSettings = {
              steps: 1,
              depth: 0,
              bevelEnabled: false,
              bevelThickness: 1,
              bevelSize: 1,
              bevelOffset: 0,
              bevelSegments: 1
            };

            const measurementLineGeometry = new THREE.ExtrudeGeometry(measurementLines, measurementLineExtrudeSettings);

            const measurementLineMaterial = new THREE.LineDashedMaterial({
              color: 0x000000,
              linewidth: 1,
              scale: 3,
              dashSize: 0.5,
              gapSize: 2
            });


            const measurementLineMesh = new THREE.LineSegments(measurementLineGeometry, measurementLineMaterial);
            if (lines.rotate) {
              measurementLineMesh.rotateX(linesRotateX);
              measurementLineMesh.rotateY(linesRotateY);
            }
            measurementLineMesh.computeLineDistances();
            if (lines.rotate) {
              measurementLineMesh.position.set(linesPositionX, linesPositionY, 0)
            } else {
              measurementLineMesh.position.set(0, 0, linesDepth)
            }
            scene.add(measurementLineMesh);
            secundaryGroup.add(measurementLineMesh)
          }

          for (const TextName in Plans.Texts) {
            const Texts = Plans.Texts[TextName];
            const Mesurement = Texts.measureM;
            const RotateX = Texts.RotateX;
            const RotateY = Texts.RotateY;
            const PositionX = Texts.PositionX;
            const PositionY = Texts.PositionY;
            const PositionZ = Texts.PositionZ;
            const id = Texts.TitleText;
            const idSpanish = Texts.TitleTextSpanish
            const TitleTextHeight = Texts.TitleTextHeight
            const TitleTextSpanishHeight = Texts.TitleTextSpanishHeight
            const height = Texts.height

            const loaderTextLong = new FontLoader();
            loaderTextLong.load(
              './models/fonts/helvetiker_bold.typeface.json',
              function (font) {
                const geometryTextLong = new TextGeometry(Mesurement.toString() + ' m', {
                  font: font,
                  size: 1,
                  height: 0,
                  text: Mesurement,
                  text1: id,
                  text1Spanish: idSpanish,
                  TitleTextHeight: TitleTextHeight,
                  TitleTextSpanishHeight: TitleTextSpanishHeight,
                  height2: height
                });
                const material = new THREE.MeshBasicMaterial({ color: 0x000000 }); // Material de ejemplo
                const textMesh = new THREE.Mesh(geometryTextLong, material);

                textMesh.position.set(PositionX, PositionY, PositionZ)

                textMesh.rotation.x = RotateX
                textMesh.rotation.y = RotateY

                scene.add(textMesh);
                secundaryGroup.add(textMesh)

                const bbox = new THREE.Box3().setFromObject(textMesh);
                const size = new THREE.Vector3();
                bbox.getSize(size);

                const hitboxGeometry = new THREE.BoxGeometry(6, 4, 0.6);
                const hitboxMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
                const hitboxMesh = new THREE.Mesh(hitboxGeometry, hitboxMaterial);

                hitboxMesh.userData = {
                  textMesh: textMesh,
                  text: Mesurement,
                  text1: id,
                  text1Spanish: idSpanish,
                  TitleTextHeight: TitleTextHeight,
                  TitleTextSpanishHeight: TitleTextSpanishHeight,
                  height2: height
                };

                hitboxMesh.position.set(PositionX, PositionY, PositionZ)
                hitboxMesh.rotation.x = RotateX
                hitboxMesh.rotation.y = RotateY
                secundaryGroup.add(hitboxMesh);

                let selectedObject = null;

                renderer.domElement.addEventListener('click', onClick, false);

                function onClick(event) {

                  const mouse = {
                    x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
                    y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
                  };

                  if (selectedObject) {
                    selectedObject.userData.textMesh.material.color.set('#000000');
                    selectedObject = null;
                  }

                  raycaster.setFromCamera(mouse, camera);

                  const intersects = raycaster.intersectObjects(secundaryGroup.children, true);

                  if (intersects.length > 0) {
                    for (const intersection of intersects) {
                      const object = intersection.object;

                      if (object.type === 'Mesh' && object.geometry.type === 'BoxGeometry') {
                        const object = intersection.object;
                        if (selectedObject) {

                          if (selectedObject !== object) {
                            selectedObject.userData.textMesh.material.color.set('#000000');
                            selectedObject = null;
                          }
                        }

                        selectedObject = object;

                        selectedObject.userData.textMesh.material.color.set('#ff0000');
                        document.getElementsByClassName('Mesurement')[0].style.display = 'block';
                        const textMesurement = selectedObject.userData.text1
                        const numberMesurement = selectedObject.userData.text
                        const textMesurementSpanish = selectedObject.userData.text1Spanish
                        const TitleTextHeight = selectedObject.userData.TitleTextHeight
                        const TitleTextSpanishHeight = selectedObject.userData.TitleTextSpanishHeight
                        const height = selectedObject.geometry.userData.height2
                        inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height)
                        function inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height) {
                          setMesurementText(textMesurement)
                          setMesurementNumber(numberMesurement)
                          setMesurementTextSpanish(textMesurementSpanish)
                          setSeveTitleTextHigh(TitleTextHeight)
                          setSeveTitleTextSpanishHigh(TitleTextSpanishHeight)
                          setSeveHigh(height)
                        }
                      }

                    }
                  }
                }

              },
              function (xhr) {
                // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
              },
              function (err) {
                // console.log('An error happened');
              }
            )
          }

          const circleMesh = new THREE.Mesh(geometry, materialObject);
          circleMesh.castShadow = true;
          circleMesh.receiveShadow = true;
          const box = new THREE.Box3().setFromObject(circleMesh);
          const size = new THREE.Vector3();
          box.getSize(size);
          const textureScale = new THREE.Vector2(size.x, size.y);
          textureScale.divideScalar(Math.max(200, 200));

          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.copy(textureScale);
          secundaryGroup.add(circleMesh)
          secundaryGroup.position.set(circleRadius, circleRadius, 0)
          principalGroup.add(secundaryGroup)
        } else {
          const figureDepth = figure.depth;
          const figureCoordinates = figure.points;
          const textureLoader = new THREE.TextureLoader();
          const texture = textureLoader.load(textureSelect);

          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(2, 2);

          const materialObject = new THREE.MeshStandardMaterial();
          materialObject.map = texture;

          const shape = new THREE.Shape();

          const [firstX, firstY] = figureCoordinates[0];

          shape.moveTo(firstX, firstY);

          figureCoordinates.slice(1).forEach(([x, y]) => {
            shape.lineTo(x, y);
          });

          shape.lineTo(firstX, firstY);
          if (figure.piece === 'main') {
            for (const holeName in Plans.Holes) {
              if (Plans.Holes[holeName].points === undefined) {

              } else {
                const holeCoordinates = Plans.Holes[holeName].points;
                const holeShape = new THREE.Shape();

                const [holeFirstX, holeFirstY] = holeCoordinates[0];

                holeShape.moveTo(holeFirstX, holeFirstY);

                holeCoordinates.slice(1).forEach(([x, y]) => {
                  holeShape.lineTo(x, y);
                });

                holeShape.lineTo(holeFirstX, holeFirstY);

                shape.holes.push(holeShape);
              }
            }
          }

          for (const lineName in Plans.Lines) {
            const lines = Plans.Lines[lineName];
            if (lines.rotate) {
              var linesPositionX = lines.PositionX;
              var linesPositionY = lines.PositionY;
              var linesRotateX = lines.rotateX
              var linesRotateY = lines.rotateY
            } else {
              var linesDepth = lines.depth;
            }
            const lineCoordinates = lines.line

            const measurementLines = new THREE.Shape();
            const [firstX, firstY] = lineCoordinates[0];

            measurementLines.moveTo(firstX, firstY);

            lineCoordinates.slice(1).forEach(([x, y]) => {
              measurementLines.lineTo(x, y);
            });

            measurementLines.lineTo(firstX, firstY);

            const measurementLineExtrudeSettings = {
              steps: 1,
              depth: 0,
              bevelEnabled: false,
              bevelThickness: 1,
              bevelSize: 1,
              bevelOffset: 0,
              bevelSegments: 1
            };

            const measurementLineGeometry = new THREE.ExtrudeGeometry(measurementLines, measurementLineExtrudeSettings);

            const measurementLineMaterial = new THREE.LineDashedMaterial({
              color: 0x000000,
              linewidth: 1,
              scale: 3,
              dashSize: 0.5,
              gapSize: 2
            });


            const measurementLineMesh = new THREE.LineSegments(measurementLineGeometry, measurementLineMaterial);
            if (lines.rotate) {
              measurementLineMesh.rotateX(linesRotateX);
              measurementLineMesh.rotateY(linesRotateY);
            }
            measurementLineMesh.computeLineDistances();
            if (lines.rotate) {
              measurementLineMesh.position.set(linesPositionX, linesPositionY, 0)
            } else {
              measurementLineMesh.position.set(0, 0, linesDepth)
            }
            scene.add(measurementLineMesh);
            secundaryGroup.add(measurementLineMesh)
          }

          for (const TextName in Plans.Texts) {
            const Texts = Plans.Texts[TextName];
            const Mesurement = Texts.measureM;
            const RotateX = Texts.RotateX;
            const RotateY = Texts.RotateY;
            const PositionX = Texts.PositionX;
            const PositionY = Texts.PositionY;
            const PositionZ = Texts.PositionZ;
            const id = Texts.TitleText;
            const idSpanish = Texts.TitleTextSpanish
            const TitleTextHeight = Texts.TitleTextHeight
            const TitleTextSpanishHeight = Texts.TitleTextSpanishHeight
            const height = Texts.height

            const loaderTextLong = new FontLoader();
            loaderTextLong.load(
              './models/fonts/helvetiker_bold.typeface.json',
              function (font) {
                const geometryTextLong = new TextGeometry(Mesurement.toString() + ' m', {
                  font: font,
                  size: 1,
                  height: 0,
                  text: Mesurement,
                  text1: id,
                  text1Spanish: idSpanish,
                  TitleTextHeight: TitleTextHeight,
                  TitleTextSpanishHeight: TitleTextSpanishHeight,
                  height2: height
                });
                const material = new THREE.MeshBasicMaterial({ color: 0x000000 }); // Material de ejemplo
                const textMesh = new THREE.Mesh(geometryTextLong, material);

                textMesh.position.set(PositionX, PositionY, PositionZ)

                textMesh.rotation.x = RotateX
                textMesh.rotation.y = RotateY

                scene.add(textMesh);
                secundaryGroup.add(textMesh)

                const bbox = new THREE.Box3().setFromObject(textMesh);
                const size = new THREE.Vector3();
                bbox.getSize(size);

                const hitboxGeometry = new THREE.BoxGeometry(6, 4, 0.6);
                const hitboxMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
                const hitboxMesh = new THREE.Mesh(hitboxGeometry, hitboxMaterial);

                hitboxMesh.userData = {
                  textMesh: textMesh,
                  text: Mesurement,
                  text1: id,
                  text1Spanish: idSpanish,
                  TitleTextHeight: TitleTextHeight,
                  TitleTextSpanishHeight: TitleTextSpanishHeight,
                  height2: height
                };

                hitboxMesh.position.set(PositionX, PositionY, PositionZ)
                hitboxMesh.rotation.x = RotateX
                hitboxMesh.rotation.y = RotateY
                secundaryGroup.add(hitboxMesh);

                let selectedObject = null;

                renderer.domElement.addEventListener('click', onClick, false);

                function onClick(event) {

                  const mouse = {
                    x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
                    y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
                  };

                  if (selectedObject) {
                    selectedObject.userData.textMesh.material.color.set('#000000');
                    selectedObject = null;
                  }

                  raycaster.setFromCamera(mouse, camera);

                  const intersects = raycaster.intersectObjects(secundaryGroup.children, true);

                  if (intersects.length > 0) {
                    for (const intersection of intersects) {
                      const object = intersection.object;

                      if (object.type === 'Mesh' && object.geometry.type === 'BoxGeometry') {
                        const object = intersection.object;
                        if (selectedObject) {

                          if (selectedObject !== object) {
                            selectedObject.userData.textMesh.material.color.set('#000000');
                            selectedObject = null;
                          }
                        }

                        selectedObject = object;

                        selectedObject.userData.textMesh.material.color.set('#ff0000');
                        document.getElementsByClassName('Mesurement')[0].style.display = 'block';
                        const textMesurement = selectedObject.userData.text1
                        const numberMesurement = selectedObject.userData.text
                        const textMesurementSpanish = selectedObject.userData.text1Spanish
                        const TitleTextHeight = selectedObject.userData.TitleTextHeight
                        const TitleTextSpanishHeight = selectedObject.userData.TitleTextSpanishHeight
                        const height = selectedObject.geometry.userData.height2
                        inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height)
                        function inputMesurement(textMesurement, numberMesurement, textMesurementSpanish, TitleTextHeight, TitleTextSpanishHeight, height) {
                          setMesurementText(textMesurement)
                          setMesurementNumber(numberMesurement)
                          setMesurementTextSpanish(textMesurementSpanish)
                          setSeveTitleTextHigh(TitleTextHeight)
                          setSeveTitleTextSpanishHigh(TitleTextSpanishHeight)
                          setSeveHigh(height)
                        }
                      }

                    }
                  }
                }

              },
              function (xhr) {
                // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
              },
              function (err) {
                // console.log('An error happened');
              }
            )
          }

          const geometryObject = new THREE.ExtrudeGeometry(shape, {
            steps: 1,
            depth: figureDepth,
            bevelEnabled: false
          });

          const mesh = new THREE.Mesh(geometryObject, materialObject);

          mesh.castShadow = true;
          mesh.receiveShadow = true;

          const containerObject = new THREE.Object3D();
          containerObject.add(mesh);

          containerObject.position.set(0, 0, zPosition);

          const box = new THREE.Box3().setFromObject(mesh);
          const size = new THREE.Vector3();
          box.getSize(size);
          const textureScale = new THREE.Vector2(size.x, size.y);
          textureScale.divideScalar(Math.max(400, 400));

          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.copy(textureScale);

          scene.add(containerObject);
          secundaryGroup.add(containerObject)
          secundaryGroup.position.set(0, 0, 0)
          principalGroup.add(secundaryGroup);

          zPosition += figureDepth;
        }
      }

      const floorGeometry = new THREE.PlaneGeometry(200, 200);
      const floorMaterial = new THREE.MeshStandardMaterial({
        color: 0x909090,
        antialias: true
      });
      const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial);
      floorMesh.position.z = -1;
      floorMesh.receiveShadow = true;
      floorMesh.castShadow = true;
      scene.add(floorMesh);

      const spotLight = new THREE.SpotLight(0xffffff, 1);
      spotLight.name = 'Spot Light';
      spotLight.angle = 1;
      spotLight.penumbra = 0.4;
      spotLight.position.set(90, 80, 150);
      spotLight.target.position.set(2, 20, 0);
      spotLight.castShadow = true;
      spotLight.shadow.camera.near = 90;
      spotLight.shadow.camera.far = 450;
      spotLight.shadow.mapSize.width = 512;
      spotLight.shadow.mapSize.height = 512;

      scene.add(spotLight.target);
      scene.add(spotLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
      scene.add(ambientLight);

      principalGroup.add(floorMesh)
      principalGroup.add(ambientLight)
      principalGroup.add(spotLight)
      principalGroup.add(spotLight.target)
      principalGroup.add(OrbitControls)
      scene.add(principalGroup);
      principalGroup.rotation.z = 5 * Math.PI / 7
      principalGroup.position.set(34, 0, 0)

      camera = new THREE.PerspectiveCamera(80, width / height, 0.1);
      camera.position.set(0, -24, 16);
      var groupCamera = new THREE.Group()
      groupCamera.add(camera);
      scene.add(groupCamera);
      scene.add(camera);


      //Resize canvas
      const resize = () => {
        renderer.setSize(currentRef.clientWidth, currentRef.clientHeight);
        camera.aspect = currentRef.clientWidth / currentRef.clientHeight;
        camera.updateProjectionMatrix();
      };
      window.addEventListener("resize", resize);


      const orbitControls = new OrbitControls(camera, renderer.domElement);
      orbitControls.enableRotate = false;


      var previousInputPosition = {
        x: 0,
        y: 0
      };

      var isTwoFingerTouch = false;

      function handleInputDown(event) {
        if (event.type === 'touchstart') {

          if (event.touches.length === 1) {
            previousInputPosition = {
              x: event.touches[0].clientX,
              y: event.touches[0].clientY
            };
          } else {
            isTwoFingerTouch = true;
          }
        } else if (event.type === 'mousedown') {
          previousInputPosition = {
            x: event.clientX,
            y: event.clientY
          };
        }

        document.addEventListener('touchmove', handleInputMove, false);
        document.addEventListener('mousemove', handleInputMove, false);
      }

      function handleInputUp(event) {
        document.removeEventListener('touchmove', handleInputMove, false);
        document.removeEventListener('mousemove', handleInputMove, false);

        if (event.type === 'touchend') {
          isTwoFingerTouch = false;
        }
      }

      function handleInputMove(event) {
        var currentInputPosition = {
          x: 0,
          y: 0
        };

        if (event.type === 'touchmove') {
          currentInputPosition = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
          };
        } else if (event.type === 'mousemove') {
          currentInputPosition = {
            x: event.clientX,
            y: event.clientY
          };
        }

        if (!isTwoFingerTouch) {
          var deltaMove = {
            x: currentInputPosition.x - previousInputPosition.x,
            y: currentInputPosition.y - previousInputPosition.y
          };
          principalGroup.rotation.z += deltaMove.x * 0.01;
        }

        previousInputPosition = currentInputPosition;
      }

      document.addEventListener('touchstart', handleInputDown, false);
      document.addEventListener('mousedown', handleInputDown, false);
      document.addEventListener('touchend', handleInputUp, false);
      document.addEventListener('mouseup', handleInputUp, false);

      setNewRender(renderer);
      setNewCamera(camera)
      setNewScene(scene)

      const animate = () => {
        orbitControls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };
      animate();


      return () => {
        window.removeEventListener("resize", resize);
        currentRef.removeChild(renderer.domElement);
      };
    }
  }, [update, textureSelect, selectModels]);


  async function takeScreenshot() {

    let nameImg = "Modelo3D_"

    if (newRender.domElement.offsetWidth <= 1023) {
      newCamera.position.set(2.8, -29, 23)
      nameImg = "Modelo3D_Mobile_"
    }

    newRender.render(newScene, newCamera);
    let imgData = newRender.domElement.toDataURL("image/png");

    const response = await fetch(imgData);
    const blob = await response.blob();
    const date = new Date();
    const timestamp = date.getTime();

    // console.log("props.edit: ", props.edit)

    const file = new File([blob], nameImg + timestamp + ".png", { type: "image/png" });

    handleModelingPlane(file)
  }

  function mostrarNav() {
    if (document.getElementsByClassName('predefinedCategory')[0].style.display === 'block') {

      if (!firstTouch) {
        document.getElementsByClassName('predefinedCategory')[0].style.display = 'block';
        document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'blur(5px)';
        document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'blur(5px)';
        setFirsTouch(true)
      } else {
        document.getElementsByClassName('predefinedCategory')[0].style.display = 'none';
        document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'none';
        document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'none';
      }
    } else {
      if (!firstTouch) {

        document.getElementsByClassName('predefinedCategory')[0].style.display = 'none';
        document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'none';
        document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'none';
        setFirsTouch(true)

      } else {
        document.getElementsByClassName('predefinedCategory')[0].style.display = 'block';
        document.getElementsByClassName('optionNotView')[0].style.backdropFilter = 'blur(5px)';
        document.getElementsByClassName('predefinedCategory')[0].style.backdropFilter = 'blur(5px)';
      }
    }
  }

  function options(e) {
    const selectOption = e.target.value
    // console.log("Value: ", e.target.value)

    if (selectOption === "Productos") {
      setShowTexture(false)
      return setShowModel(!showModel)
    }

    if (selectOption === "Material") {
      setShowModel(false)
      return setShowTexture(!showTexture)
    }

  }

  return (
    (modelingPlane === false) ? (
      <>
        <div className="fullscreen-overlay">
          <div className='optionsWindowEditor'>
            <NavLink onClick={toggleFullScreenComputer}><CgMaximize className='CgMaximizeInMinimize' /></NavLink>
          </div>
        </div>
        <div ref={divRef} className="containerProductoIn3djsGenerate">
          <div className="containerOptions">
            <div className="optionNotView">
              <button className='divIcon'>
                <FaBars className='faBarsIconView' onClick={mostrarNav} />
              </button>
            </div>
            <div className="predefinedCategory">
              <select id="mySelect" className="styleButtonsOptions margin-top-pixel" onChange={(e) => { options(e) }}>
                <option value="null">Opciones</option>
                <option value="Productos">Productos</option>
                <option value="Material">Materiales</option>
              </select>
              <div className="containerResponseOptions">

                {showModel && (
                  res.map((item, index) => {
                    let nameImage = item.preview
                    // console.log("nameImage: ", nameImage)
                    if (nameImage.split("_")[1] === "Mobile") {
                      nameImage = "size_" + nameImage
                    }
                    preview.push(nameImage.split(","))
                    if (item.preview) {
                      view.push(item.view)
                      return (
                        <>
                          <div className="containerImages" key={index} onClick={() => handleModel(item)}>
                            <img src={urlApiServer + "preview/" + preview[index]} alt="Gallery" className="imagePreviewModel" />
                            <p>{item.producto}</p>
                          </div>
                        </>
                      )
                    }
                  })
                )}
                {showTexture && (
                  textureGetTable.map((textures, index) => {
                    const nameTexture = textures.texture
                    PreViewTexture.push(nameTexture.split(","))
                    if (textures.texture) {
                      viewTexture.push(textures.texture)
                      return (
                        <>
                          <div className="containerImages" key={index} onClick={() => handleTexture(textures)}>
                            <img src={urlApiServer + "viewTexture/" + PreViewTexture[index]} alt={textures.nameTexture} className="imagePreviewModel" />
                            <p>{textures.nameTexture}</p>
                          </div>
                        </>
                      )
                    }
                  })
                )}
              </div>

            </div>
          </div>
          <div
            className='Contenedor3D'
            ref={mountRef}
            style={{ height: "100vh" }}>
          </div>
          <div id="objectInfo" className="Mesurement" ref={Mesurements}>
            <div className="form__groupMeasure">
              <input type="number" onChange={handleMesurement} className="form__inputMeasure" id="name" value={mesurementNumber} placeholder={mesurementTextSpanish} style={{ color: 'black' }} />
              <label className="form__labelMeasure">{mesurementTextSpanish}</label>
            </div>
            {(
              saveTitleTextHigh === undefined && saveTitleTextSpanishHigh === undefined && savehigh === undefined
            ) ? (
              ''
            ) : (
              <div className="form__groupMeasure">
                <input type="number" onChange={handleHeigth} value={savehigh} className="form__inputMeasure" id="name" placeholder={saveTitleTextSpanishHigh} style={{ color: 'black' }} />
                <label className="form__labelMeasure">{saveTitleTextSpanishHigh}</label>
              </div>
            )}
            <NavLink onClick={handleUpdate} className='Textregister'><button className="button neon_Effect" style={{ margin: '0', marginLeft: '55px' }}><p>Actualizar</p></button></NavLink>
          </div>
          <NavLink className="TextPlans" onClick={off}>
            <button className="button neon_Effect">
              <p>Atrás</p>
            </button>
          </NavLink>
          <NavLink className="TextPlans2" onClick={takeScreenshot}>
            <button className="button neon_Effect">
              <p>Guardar</p>
            </button>
          </NavLink>
        </div>
      </>
    ) : (<ModelPlans Plans={Plans} prePlans={prePlans} back={handleModelingPlane} fullBack={fullQuit} exitBack={exitBack} id={id} request={requestData} validate='editProduct' />)
  );
};

export default EditProduct3D;