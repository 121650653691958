import logo from '../../Assest/logo.png'
import { useContext, useState, useEffect } from "react"
import LoginContext from "../../Context/Logincontext"
import Nav1 from '../../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import { urlApiServer } from '../../Config/Config'
import '../../styles/AddClient.css'
import Swal from 'sweetalert2'

const EditClient = (props) => {

    const urlClient = urlApiServer + "editClient";

    const [name, setName] = useState("");
    const [lastNameOne, setLastNameOne] = useState("");
    const [citizenshipCard, setCitizenshipCard] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [direction, setDirection] = useState("");
    const [contact, setContact] = useState("");
    const [typeDocument, setTypeDocument] = useState("")
    const { infoUser, setInfoUser } = useContext(LoginContext)

    async function off() {
        props.edit()
    }

    useEffect(() => {
        const productData = props.data.find(data => data.Id === props.id);
        if (productData) {
            const { cliente, contacto, correo, direccion, nitocc, telefono, typedocument } = productData;
            setName(cliente);
            setCitizenshipCard(nitocc);
            setPhone(telefono);
            setEmail(correo);
            setDirection(direccion);
            setContact(contacto);
            setTypeDocument(typedocument)
        }
    }, [props.data, props.id]);


    async function handleName(e) {
        const name = e.target.value
        if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
            setName(name)
        }
    }

    async function handleCitizenshipCard(e) {
        let value = e.target.value.replace(/[^\d-_]/g, '');
        e.target.value = value;
        setCitizenshipCard(value);
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }

    async function handleDocumentType(e) {
        let valueType = e.target.value
        if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
            setTypeDocument(valueType)
        }
    }

    async function handleEmail(e) {
        const email = e.target.value
        setEmail(email)
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
    }

    async function handleContact(e) {
        const contact = e.target.value
        setContact(contact)
    }

    async function uploadProduct() {

        if (name.slice(0, 1) === ' ' || name.slice(-1) === ' ') {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Por favor, le solicito verificar cuidadosamente que al final o al principio del nombre no haya presencia de espacios innecesarios.',
                confirmButtonText: 'Aceptar',
            });
            return; // Detener la ejecución de la función
        }

        if ((contact === undefined) || (contact === '') || ((contact === null))) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de Celular',
                allowOutsideClick: false,
            })
        }


        if ((citizenshipCard === undefined) || (citizenshipCard === '') || ((citizenshipCard === null))) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de Numero de documento',
                allowOutsideClick: false,
            })
        }

        if ((direction === undefined) || (direction === '') || ((direction === null))) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de Dirección',
                allowOutsideClick: false,
            })
        }

        if ((name === undefined) || (name === '') || ((name === null))) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Recuerda llenar el campo de Nombre del cliente',
                allowOutsideClick: false,
            })
        }

        const data = JSON.stringify({
            empresa: infoUser.company,
            id: props.id,
            name: name,
            numbreDocument: citizenshipCard,
            phone: phone,
            email: email,
            direction: direction,
            contact: contact,
            typeDocument: typeDocument
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'PUT',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        await fetch(urlClient, requesoption).then(response => response.json())
            .then(dataResponse => {
                if ('Actualizado cliente' === dataResponse.msj) {
                    Swal.fire({
                        allowOutsideClick: false,
                        icon: 'success',
                        title: 'Dato editado',
                        text: 'Has editado este dato correctamente',
                    })
                    props.function()
                    props.edit()
                }
            })


    }

    return (
        <div id='overallContainerAdd' className='overallContainerAdd'>
            {(infoUser.login === true) ? (
                <>
                    <div className='drawerFormAddCuotes'>
                        <form className='ContainerClient'>
                            <div className='containerInfo'>
                                <div className='infoOne'>
                                    <p className='personalInformation'>Informacion Personal</p>
                                    <div className='containerNameClient'>
                                        <div className="inputClientContainer">
                                            <input
                                                type="text"
                                                value={name}
                                                name="Name"
                                                id="Name"
                                                onChange={handleName}
                                                className="inputCLient"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Nombre del cliente</label>
                                        </div>
                                        <div className="inputClientContainer">
                                            <input
                                                type="text"
                                                name="nitSelect"
                                                id="nitSelect"
                                                className="inputCLient"
                                                placeholder=""
                                                list="drawfemails2"
                                                onChange={handleDocumentType}
                                                value={typeDocument}

                                            />
                                            <label htmlFor="name">Tipo de documento:</label>
                                            <datalist id="drawfemails2" value="nitSelect">
                                                <option value="NIT">NIT</option>
                                                <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                            </datalist>
                                            {/* <select
                                                    name="Type"
                                                    id="Type"
                                                    className="inputSelectNT"
                                                >
                                                    <option value="Type">Tipo de documento:</option>
                                                    <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                    <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                    <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                </select> */}
                                        </div>
                                        <div className="inputClientContainer">
                                            <input
                                                type="text"
                                                onWheel={(e) => e.target.blur()}
                                                name="citizenshipCard"
                                                id="citizenshipCard"
                                                onChange={handleCitizenshipCard}
                                                value={citizenshipCard}
                                                className="inputCLient"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Numero de documento</label>
                                        </div>
                                        <div className="inputClientContainer">
                                            <input
                                                type="text"
                                                name="direction"
                                                id="direction"
                                                onChange={handleDirection}
                                                value={direction}
                                                className="inputCLient"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Dirección</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='infoTwo'>
                                    <p className='personalInformation'>Contacto</p>
                                    <div className='containerContactClient'>
                                        <div className="inputClientContainer2">
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                name="Phone"
                                                id="Phone"
                                                value={phone}
                                                onChange={handlePhone}
                                                className="inputCLient2"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Telefono</label>
                                        </div>
                                        <div className="inputClientContainer2">
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                name="Contact"
                                                id="Contact"
                                                value={contact}
                                                onChange={handleContact}
                                                className="inputCLient2"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Celular</label>
                                        </div>
                                        <div className="inputClientContainer2">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                onChange={handleEmail}
                                                value={email}
                                                className="inputCLient2"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Correo electronico</label>
                                        </div>
                                    </div>
                                    <div className="buttonContainerAddClient">
                                        <NavLink className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                                        <NavLink onClick={uploadProduct} className='Textregister'><button className="button neon_Effect"><p>Actualizar</p></button></NavLink>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            ) : (window.location.href = './Iniciar_Sesión')}
        </div>
    )

}

export default EditClient