
export const validationBills = async (props) => {
    let status = false;
    let items = {};
    props.map((item) => {
        if ((item.access === '') || (item.access === null)) {
            status = true
            items = item
        }
    })
    return {status: status, item: items}
}