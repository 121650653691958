import React, { useEffect, useState } from 'react'

export const dataLogin = localStorage.getItem("infoUser")?
                            JSON.parse(localStorage.getItem("infoUser"))
                            :{
                                login : false,
                                Id: null,
                                name: "unknow",
                                email: "unknow",
                                role: "unknow",
                                company: "unknow",
                                companyType: "unknow",
                                typeIdentification: "unknow",
                                identification: "unknow",
                                nit: "unknow",
                                address: "unknow",
                                phone:"unknow",
                                city: "unknow",
                                bankAccount: "unknow",
                                bank: "unknow",
                                positionCompany: "unknow",
                                colorNeonAndButtons: "Red",
                                backgroundColorIcons: "Red",
                                colorIcons: "white",
                                tittleColor: "white",
                                letterColor: "white",
                                mainColor: [82, 81, 81, 0.691],
                                secondaryColor: [82, 81, 81, 0.3],
                                logo: "defaultLogo2.png",
                                backgroundImg: "default.jpeg",
                            }
const LoginContext = React.createContext({})

export function LoginContextProvider({children}){
    const [infoUser, setInfoUser] = useState(dataLogin)

    useEffect(()=>{
        localStorage.setItem("infoUser", JSON.stringify(infoUser))
    },[infoUser])

    return(
        <LoginContext.Provider value={{infoUser, setInfoUser}}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginContext