import logo from '../../Assest/logo.png'
import LoginContext from '../../Context/Logincontext';
import Nav1 from "../../Component/Nav/Nav1"
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {AiFillCloseCircle} from 'react-icons/ai'
import {SiThreedotjs} from 'react-icons/si'
import {BsBoxSeam} from "react-icons/bs"
import "../../styles/Add.css"
import {ImExit} from 'react-icons/im'
import CloseButton from '../../Component/CloseButton';


const AddTest = () =>{

    const {infoUser, setInfoUser} = useContext(LoginContext)

    async function Cerrar(){
        window.location.href = './';
        setInfoUser({
            login : false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
      }

    return(
        <>
            {(infoUser.role !== 'SuperUsuario')?(window.location.href = './Crear_Empresa'):(
                <>
                    {/* <div className="containerLogoAdd">
                            <img src={logo} alt="Logo" className='logoAdd'/> 
                            <p className='tittleAdd'>AGREGAR</p>
                            <NavLink  onClick={Cerrar} className='closeButton'>
                                <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                <ImExit  className='iconNav'/>
                            </NavLink>
                            <CloseButton />
                        </div> */}
                        <div id='overallContainerAddCompany' className='overallContainerAddCompany'>
                            {(infoUser.login === true)?(
                                <>
                                    <div className='drawerFormLogin'>
                                        <div className='ContainerCompany'>
                                            <div className='containerAdd'>
                                                <div className='containerIconAddCompany'>
                                                    <NavLink to="/Barraconsalpicadero" className='navIconAddCompany'>
                                                        <SiThreedotjs className='iconAdd'/>
                                                        <p>Barra con salpicadero</p>
                                                    </NavLink>
                                                    <NavLink to="/Barra" className='navIconAddCompany'>
                                                        <SiThreedotjs className='iconAdd'/>
                                                        <p>Barra</p>
                                                    </NavLink>
                                                    <NavLink to="/Mesaredonda" className='navIconAddCompany'>
                                                        <SiThreedotjs className='iconAdd'/>
                                                        <p>Mesa redonda</p>
                                                    </NavLink>
                                                    <NavLink to="/Mesaenlgrande" className='navIconAddCompany'>
                                                        <SiThreedotjs className='iconAdd'/>
                                                        <p>Mesa en l</p>
                                                    </NavLink>
                                                    <NavLink to="/Menu_Configuracion" className='navIconAddCompany'>
                                                        <AiFillCloseCircle className='iconAdd'/>
                                                        <p>Atras</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ):(window.location.href = './Iniciar_Sesión')}
                            {/* <Nav1/> */}
                     </div>
                </>
            )}
        </>
        )
}

export default AddTest