import { useTable, usePagination, useGlobalFilter } from "react-table";
import { useEffect, useState, useMemo, useContext } from "react";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Filter from "./Filter";
import { ImExit } from 'react-icons/im'
import { NavLink } from 'react-router-dom';
import "./Table.css";
import { urlApiServer } from "../../Config/Config";
import LoginContext from "../../Context/Logincontext";
import Nav from "../Nav/Nav1";
import Loader from "../Loader";
import CloseButton from "../CloseButton";
import LogoComponent from "../LogoComponent";
import Swal from "sweetalert2";

let splitImg = []

export default function Table(props) {
  const { infoUser, setInfoUser } = useContext(LoginContext)
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);
  const saveDataTable = useMemo(() => props.saveDataTable, [props.saveDataTable]);
  const functionNew = useMemo(() => props.function, [props.function]);
  const handleRefreshPage = useMemo(() => props.handleRefreshPage, [props.handleRefreshPage]); 
  const handleShowBill = useMemo(() => props.handleShowBill, [props.handleShowBill]); 
  const power = useMemo(() => props.power, [props.power]);
  const showLoader = useMemo(() => props.showLoader, [props.showLoader]);
  const importantData = useMemo(() => props.importantData, [props.importantData]);
  const edit = useMemo(() => props.edit, [props.edit]);
  const product = useMemo(() => props.product, [props.product]);
  const id = useMemo(() => props.id, [props.id]);
  const getUpdateRemission = useMemo(() => props.getUpdateRemission, [props.getUpdateRemission])
  const remission = useMemo(() => props.remission, [props.remission])
  const [actualPage, setActualPage] = useState(0);
  const urlgetConfig = urlApiServer + "getConfig";
  const [oldConfig, setOldConfig] = useState("")
  const [logo, setLogo] = useState("")
  const [size, setSize] = useState("width")
  const [disabledBottom, setDisabledBottom] = useState(true);
  const [reload, setReload] = useState(false);



  useEffect(() => {
    (async function () {

      const InicioHeaders = new Headers();
      InicioHeaders.append("Content-Type", "application/json")

      const requesoption = {
        method: 'GET',
        headers: InicioHeaders,
        redirect: 'follow'
      }
      await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
        setOldConfig(quotesResponse)
      })
    }())
  }, [reload])

  useEffect(() => {
    // console.log("infoUser: ", infoUser.name)
    if (oldConfig) {
      const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
      if (foundConfig) {
        const { logo } = foundConfig;
        setLogo(logo);

      }
    }
  }, [oldConfig, infoUser])

  if (logo) {
    splitImg = logo.split("|")
  }

  const handleProductGeneric = async () => {
    await Swal.fire({
      title: 'CREAR PRODUCTO',
      html:
        `
      <div>
          <p>Producto:</p>
          <input type="text" id="producto" class="swal2-input" placeholder="Producto">
      </div>
      <div>
          <p>Precio del producto:</p>
          <input type="text" id="precioitem" class="swal2-input" placeholder="Precio del producto">
      </div>
      <div>
      <p>Cantidad:</p>
          <input type="text" id="cantidadItem" class="swal2-input" placeholder="Cantidad">
      </div>
      <div>
        <p>Unidad:</p>
        <select id="mySelectUnit" class="ivaSelect">
        <option value="">Unidad:</option>
        <option value="U">U (Unidad)</option>
        <option value="M">M (Metros)</option>
        <option value="C">C (Centimetros)</option>
        <option value="P">P (Pulgadas)</option>
        <option value="K">K (Kilogramos)</option>
        <option value="G">G (Gramos)</option>
        <option value="L">L (Libras)</option>
        <option value="LT">LT (Litros)</option>
        <option value="R">R (Radianes)</option>
        <option value="R">S (Segundos)</option>
        <option value="H">H (Horas)</option>
        <option value="Dia/s">Dias</option>
        <option value="Mes/es">Meses</option>
        <option value="Año/s">Años</option>
        </select>
      </div>
      <div>
          <p>Iva:</p>
          <select id="mySelect" class="ivaSelect">
              <option value="default" >Iva:</option>
              <option value="0">0%</option>
              <option value="5">5%</option>
              <option value="16">16%</option>
              <option value="19">19%</option>
          </select>
      </div>

      `,
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        return [
              /*0: */document.getElementById('producto').value,
              /*5: */document.getElementById('cantidadItem').value,
              /*6: */document.getElementById('precioitem').value,
              /*8: */document.getElementById('mySelectUnit').value,
              /*8: */document.getElementById('mySelect').value
        ]
      }
    }).then(async response => {
      // console.log("response.value: ", response.value)
      if (response.value) {
        const producto = response.value[0]
        const cantidad = response.value[1]
        const precio = response.value[2]
        const unidad = response.value[3]
        const iva = response.value[4]

        if (producto.length > 500) {
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El maximo de caracteres para el detalle es de 500',
          })
        }

        if (precio.length > 100) {
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El maximo de caracteres para el precio es de 100',
          })
        }

        const urlProducto = urlApiServer + "addproduct";
        const data = JSON.stringify({
          empresa: infoUser.company,
          product: producto,
          material: null,
          long: null,
          caliber: null,
          price: precio,
          iva: iva,
          modeljson: null,
          Model: null,
          image: null,
          ancho: null,
          unit: unidad,
          cantidad: cantidad,
        })
        // console.log("Imagen: ", props.request.image)
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
          method: 'POST',
          headers: registerHeaders,
          body: data,
          redirect: 'follow'
        }

        await fetch(urlProducto, requesoption).then(response => response.json())
          .then(async dataResponse => {
            if (dataResponse.status === 560) {
              await Swal.fire({
                icon: 'error',
                text: 'Ha ocurrido un problema al intentar crear un producto, si el error persiste contacte con un administrador de la plataforma',
                confirmButtonText: 'Aceptar',
              }).then((result) => {
              })
            } else {
              props.function()
            }
          })

      }
    })
  }

  async function Cerrar() {
    window.location.href = './';
    setInfoUser({
      login: false,
      name: "unknow",
      email: "unknow",
      role: "unknow",
      company: "unknow",
      colorNeonAndButtons: "Red",
      backgroundColorIcons: "Red",
      colorIcons: "white",
      tittleColor: "white",
      letterColor: "white",
      mainColor: [82, 81, 81, 0.691],
      secondaryColor: [82, 81, 81, 0.3],
      logo: "defaultLogo.png",
      backgroundImg: "default.jpeg",
    })
  }

  let addButtonContent;
  switch (window.location.pathname) {
    case '/Productos':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">PRODUCTOS</div>
          <div className="containerBottonHeard">
            {
              (infoUser.companyType === "Generica") ? (
                <NavLink onClick={handleProductGeneric}>
                  <button className="createButton">Crear</button>
                </NavLink>
              ) : (
                <NavLink to="/Agregar_Productos">
                  <button className="createButton">Crear</button>
                </NavLink>
              )
            }
          </div>
        </div>
      );
      break;
    case '/Cotizaciones':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">COTIZACIONES</div>
          <div className="containerBottonHeard">
            <NavLink to="/Agregar_Cotizacion">
              <button className="createButton">Crear</button>
            </NavLink>
          </div>
        </div>
      );
      break;
    case '/Facturas':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">Facturas</div>
          <div className="containerBottonHeard">
            <NavLink to="/Agregar_Factura">
              <button className="createButton">Crear</button>
            </NavLink>
          </div>
        </div>
      );
      break;
    // case '/Agregar_Factura':
    //   addButtonContent = (
    //     <div className="navigationContainer">
    //       <div className="pageTitle">Crear Factura</div>
    //       <div className="containerBottonHeard">
    //       </div>
    //     </div>
    //   );
    //   break;
    case '/Clientes':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">CLIENTES</div>
          <div className="containerBottonHeard">
            <NavLink to="/Agregar_Clientes">
              <button className="createButton">Crear</button>
            </NavLink>
          </div>
        </div>
      );
      break;
    case '/Texturas':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">MATERIAL</div>
          <div className="containerBottonHeard">
            <NavLink to="/Agregar_Textura">
              <button className="createButton">Crear</button>
            </NavLink>
          </div>
        </div>
      );
      break;
    case '/Usuarios':
      addButtonContent = (
        <div className="navigationContainer">
          <div className="pageTitle">USUARIOS</div>
        </div>
      );
      break;
    default:
      addButtonContent = null;
      break;
  }

  useEffect(() => {
    gotoPage(actualPage);
  }, [props]);

  const table = useTable(
    {
      columns,
      data,
      saveDataTable,
      product,
      functionNew,
      handleRefreshPage,
      handleShowBill,
      power,
      showLoader,
      getUpdateRemission,
      remission,
      edit,
      id,
      importantData,
      initialState: {
        pageSize: 5,
        pageIndex: 0
      }
    },
    useGlobalFilter,
    usePagination
  );

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
    state: { pageIndex, pageSize }
  } = table;

  let tables;
  switch (window.location.pathname) {
    case '/Productos':
      tables = (
        <div className="container-Table">
          {page.length >= 0 && ( // Verificar si hay elementos en la página
            <div className="cardContainer">
              {headerGroups.map((headerGroup, index) => (
                <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()} key={index}>
                  <div className="containerCardHeardCell">
                    <div className="cardHeaderCell paddingLeft" style={{ width: '30%' }}{...headerGroup.headers[0].getHeaderProps()}>
                      {headerGroup.headers[0].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '30%' }}{...headerGroup.headers[1].getHeaderProps()}>
                      {headerGroup.headers[1].render("Header")}
                    </div>
                    {(infoUser.role === 'Administrador') ? (
                      <>
                        <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[2].getHeaderProps()}>
                          {headerGroup.headers[2].render("Header")}
                        </div>
                        <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[3].getHeaderProps()}>
                          {headerGroup.headers[3].render("Header")}
                        </div>
                        <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[4].getHeaderProps()}>
                          {headerGroup.headers[4].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[5].getHeaderProps()}>
                          {headerGroup.headers[5].render("Header")}
                        </div>
                        {
                          (infoUser.companyType === "Marmol") ? (
                            <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[6].getHeaderProps()}>
                              {headerGroup.headers[6].render("Header")}
                            </div>
                          ) : (
                            <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[6].getHeaderProps()}>
                              {headerGroup.headers[6].render("Header")}
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <>
                        <div className="cardHeaderCell" style={{ width: '12%' }}{...headerGroup.headers[2].getHeaderProps()}>
                          {headerGroup.headers[2].render("Header")}
                        </div>
                        <div className="cardHeaderCell" style={{ width: '12%' }}{...headerGroup.headers[3].getHeaderProps()}>
                          {headerGroup.headers[3].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '12%' }}{...headerGroup.headers[4].getHeaderProps()}>
                          {headerGroup.headers[4].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '12%' }}{...headerGroup.headers[5].getHeaderProps()}>
                          {headerGroup.headers[5].render("Header")}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}


              {/* <div className="cardHeaderRow">
                <div className="containerCardHeardCell">
                  <div className="cardHeaderCell paddingLeft" style={{ width: '30%' }}>
                    Productos
                  </div>
                  <div className="cardHeaderCell center" style={{ width: '30%' }}>
                    {headerGroup.headers[1].render("Header")}
                  </div>

                  <div className="cardHeaderCell" style={{ width: '9%' }}>
                    {headerGroup.headers[2].render("Header")}
                  </div>
                  <div className="cardHeaderCell" style={{ width: '9%' }}>
                    {headerGroup.headers[3].render("Header")}
                  </div>
                  <div className="cardHeaderCell" style={{ width: '9%' }}>
                    {headerGroup.headers[4].render("Header")}
                  </div>
                  <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[5].getHeaderProps()}>
                    {headerGroup.headers[5].render("Header")}
                  </div>
                      <div className="cardHeaderCell center" style={{ width: '9%' }}{...headerGroup.headers[6].getHeaderProps()}>
                        {headerGroup.headers[6].render("Header")}
                      </div>
                </div>
              </div> */}



              <div className="rowContainer">
                <div className="containerRow">
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <div className="card" {...row.getRowProps()} key={index}>
                        <div className="cardContentRow">
                          <div className="cardContentCell paddingLeft" style={{ width: '30%' }} {...row.cells[0].getCellProps()}>
                            {row.cells[0].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '30%' }} {...row.cells[1].getCellProps()}>
                            {row.cells[1].render("Cell")}
                          </div>
                          {(infoUser.role === 'Administrador') ? (
                            <>
                              <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[2].getCellProps()}>
                                {row.cells[2].render("Cell")}
                              </div>
                              <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[3].getCellProps()}>
                                {row.cells[3].render("Cell")}
                              </div>
                              <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[4].getCellProps()}>
                                {row.cells[4].render("Cell")}
                              </div>
                              <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[5].getCellProps()}>
                                {row.cells[5].render("Cell")}
                              </div>
                              {
                                (infoUser.companyType === "Marmol") ? (
                                  <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[6].getCellProps()}>
                                    {row.cells[6].render("Cell")}
                                  </div>
                                ) : (
                                  <div className="cardContentCell center" style={{ width: '9%' }} {...row.cells[6].getCellProps()}>
                                    {row.cells[6].render("Cell")}
                                  </div>
                                )
                              }
                            </>
                          ) : (
                            <>
                              <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[2].getCellProps()}>
                                {row.cells[2].render("Cell")}
                              </div>
                              <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[3].getCellProps()}>
                                {row.cells[3].render("Cell")}
                              </div>
                              <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[4].getCellProps()}>
                                {row.cells[4].render("Cell")}
                              </div>
                              <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[5].getCellProps()}>
                                {row.cells[5].render("Cell")}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      );
      break;
    case '/Cotizaciones':
      tables = (
        <div className="container-Table">
          {page.length >= 0 && (
            <div className="cardContainer">
              {headerGroups.map((headerGroup, index) => (
                <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()}>
                  <div className="containerCardHeardCell">
                    <div className="cardHeaderCell center" style={{ width: '6%' }}{...headerGroup.headers[0].getHeaderProps()}>
                      {headerGroup.headers[0].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '13%' }}{...headerGroup.headers[1].getHeaderProps()}>
                      {headerGroup.headers[1].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '14.5%' }}{...headerGroup.headers[2].getHeaderProps()}>
                      {headerGroup.headers[2].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '14.5%' }}{...headerGroup.headers[3].getHeaderProps()}>
                      {headerGroup.headers[3].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '11%' }}{...headerGroup.headers[4].getHeaderProps()}>
                      {headerGroup.headers[4].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '6.8%' }}{...headerGroup.headers[5].getHeaderProps()}>
                      {headerGroup.headers[5].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '7%' }}{...headerGroup.headers[6].getHeaderProps()}>
                      {headerGroup.headers[6].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '4.6%' }}{...headerGroup.headers[7].getHeaderProps()}>
                      {headerGroup.headers[7].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '6%' }}{...headerGroup.headers[8].getHeaderProps()}>
                      {headerGroup.headers[8].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '6%' }}{...headerGroup.headers[8].getHeaderProps()}>
                      {headerGroup.headers[9].render("Header")}
                    </div>
                    {(infoUser.role === 'Administrador') ? (
                      <>
                        <div className="cardHeaderCell center" style={{ width: '6.3%' }}{...headerGroup.headers[9].getHeaderProps()}>
                          {headerGroup.headers[10].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '7.2%' }}{...headerGroup.headers[10].getHeaderProps()}>
                          {headerGroup.headers[11].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '7.2%' }}{...headerGroup.headers[10].getHeaderProps()}>
                          {headerGroup.headers[12].render("Header")}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[9].getHeaderProps()}>
                          {headerGroup.headers[9].render("Header")}
                        </div>
                        <div className="cardHeaderCell center" style={{ width: '7.2%' }}{...headerGroup.headers[10].getHeaderProps()}>
                          {headerGroup.headers[10].render("Header")}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <div className="rowContainer">
                <div className="containerRow">
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <div className="card" {...row.getRowProps()}>
                        <div className="cardContentRow">
                          <div className="cardContentCell center" style={{ width: '6%' }} {...row.cells[0].getCellProps()}>
                            {row.cells[0].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '13%' }} {...row.cells[1].getCellProps()}>
                            {row.cells[1].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '14.5%' }} {...row.cells[2].getCellProps()}>
                            {row.cells[2].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '14.5%' }} {...row.cells[3].getCellProps()}>
                            {row.cells[3].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '11%' }} {...row.cells[4].getCellProps()}>
                            {row.cells[4].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '6.8%' }} {...row.cells[5].getCellProps()}>
                            {row.cells[5].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '7%' }} {...row.cells[6].getCellProps()}>
                            {row.cells[6].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '4.6%' }} {...row.cells[7].getCellProps()}>
                            {row.cells[7].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '6%' }} {...row.cells[8].getCellProps()}>
                            {row.cells[8].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '6%' }} {...row.cells[8].getCellProps()}>
                            {row.cells[9].render("Cell")}
                          </div>
                          {(infoUser.role === 'Administrador') ? (
                            <>
                              <div className="cardContentCell center" style={{ width: '6.3%' }} {...row.cells[9].getCellProps()}>
                                {row.cells[10].render("Cell")}
                              </div>
                              <div className="cardContentCell center" style={{ width: '7.2%' }} {...row.cells[10].getCellProps()}>
                                {row.cells[11].render("Cell")}
                              </div>
                              <div className="cardContentCell center" style={{ width: '7.2%' }} {...row.cells[10].getCellProps()}>
                                {row.cells[12].render("Cell")}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[9].getCellProps()}>
                                {row.cells[9].render("Cell")}
                              </div>
                              <div className="cardContentCell center" style={{ width: '7.2%' }} {...row.cells[10].getCellProps()}>
                                {row.cells[10].render("Cell")}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )
          }
        </div >
      );
      break;
    case '/Usuarios':
      tables = (
        <div className="container-Table">
          {page.length >= 0 && (
            <div className="cardContainer">
              {headerGroups.map((headerGroup, index) => (
                <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()} key={index}>
                  <div className="containerCardHeardCell">
                    <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[0].getHeaderProps()}>
                      {headerGroup.headers[0].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[1].getHeaderProps()}>
                      {headerGroup.headers[1].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '14%' }}{...headerGroup.headers[2].getHeaderProps()}>
                      {headerGroup.headers[2].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '13%' }}{...headerGroup.headers[3].getHeaderProps()}>
                      {headerGroup.headers[3].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '30%' }}{...headerGroup.headers[4].getHeaderProps()}>
                      {headerGroup.headers[4].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '12%' }}{...headerGroup.headers[5].getHeaderProps()}>
                      {headerGroup.headers[5].render("Header")}
                    </div>
                    <div className="cardHeaderCell" style={{ width: '12%' }}{...headerGroup.headers[6].getHeaderProps()}>
                      {headerGroup.headers[6].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[7].getHeaderProps()}>
                      {headerGroup.headers[7].render("Header")}
                    </div>
                    <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[8].getHeaderProps()}>
                      {headerGroup.headers[8].render("Header")}
                    </div>
                  </div>
                </div>
              ))}
              <div className="rowContainer">
                <div className="containerRow">
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <div className="card" {...row.getRowProps()} key={index}>
                        <div className="cardContentRow">
                          <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[0].getCellProps()}>
                            {row.cells[0].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[1].getCellProps()}>
                            {row.cells[1].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '14%' }} {...row.cells[2].getCellProps()}>
                            {row.cells[2].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '13%' }} {...row.cells[3].getCellProps()}>
                            {row.cells[3].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '30%' }} {...row.cells[4].getCellProps()}>
                            {row.cells[4].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[5].getCellProps()}>
                            {row.cells[5].render("Cell")}
                          </div>
                          <div className="cardContentCell" style={{ width: '12%' }} {...row.cells[6].getCellProps()}>
                            {row.cells[6].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[7].getCellProps()}>
                            {row.cells[7].render("Cell")}
                          </div>
                          <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[8].getCellProps()}>
                            {row.cells[8].render("Cell")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      );
      break;
    case '/Clientes':
      tables = (
        <>
          <div className="container-Table">
            {page.length >= 0 && (
              <div className="cardContainer">
                {headerGroups.map((headerGroup, index) => (
                  <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()} key={index}>
                    <div className="containerCardHeardCell">
                      <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[0].getHeaderProps()}>
                        {headerGroup.headers[0].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[1].getHeaderProps()}>
                        {headerGroup.headers[1].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[2].getHeaderProps()}>
                        {headerGroup.headers[2].render("Header")}
                      </div>
                      {(infoUser.role === 'Administrador') ? (
                        <>
                          <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '13%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                          <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[7].getHeaderProps()}>
                            {headerGroup.headers[7].render("Header")}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="cardHeaderCell" style={{ width: '30%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '4%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
                <div className="rowContainer">
                  <div className="containerRow">
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <div className="card" {...row.getRowProps()} key={index}>
                          <div className="cardContentRow">
                            <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[0].getCellProps()}>
                              {row.cells[0].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[1].getCellProps()}>
                              {row.cells[1].render("Cell").props.cell.value !== "no existe" && row.cells[1].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[2].getCellProps()}>
                              {row.cells[2].render("Cell")}
                            </div>
                            {(infoUser.role === 'Administrador') ? (
                              <>
                                <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell").props.cell.value !== "no existe" && row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '13%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                                <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[7].getCellProps()}>
                                  {row.cells[7].render("Cell")}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="cardContentCell" style={{ width: '30%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '4%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div >
        </>
      );
      break;
    case '/Facturas':
      tables = (
        <>
          <div className="container-Table">
            {page.length >= 0 && (
              <div className="cardContainer">
                {headerGroups.map((headerGroup, index) => (
                  <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()} key={index}>
                    <div className="containerCardHeardCell">
                      <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[0].getHeaderProps()}>
                        {headerGroup.headers[0].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[1].getHeaderProps()}>
                        {headerGroup.headers[1].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[2].getHeaderProps()}>
                        {headerGroup.headers[2].render("Header")}
                      </div>
                      {(infoUser.role === 'Administrador') ? (
                        <>
                          <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '13%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                          <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[7].getHeaderProps()}>
                            {headerGroup.headers[7].render("Header")}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="cardHeaderCell" style={{ width: '30%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '4%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
                <div className="rowContainer">
                  <div className="containerRow">
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <div className="card" {...row.getRowProps()} key={index}>
                          <div className="cardContentRow">
                            <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[0].getCellProps()}>
                              {row.cells[0].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[1].getCellProps()}>
                              {row.cells[1].render("Cell").props.cell.value !== "no existe" && row.cells[1].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[2].getCellProps()}>
                              {row.cells[2].render("Cell")}
                            </div>
                            {(infoUser.role === 'Administrador') ? (
                              <>
                                <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell").props.cell.value !== "no existe" && row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '13%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                                <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[7].getCellProps()}>
                                  {row.cells[7].render("Cell")}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="cardContentCell" style={{ width: '30%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '4%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div >
        </>
      );
      break;
    case '/Texturas':
      tables = (
        <>
          <div className="container-Table">
            {page.length >= 0 && (
              <div className="cardContainer">
                {headerGroups.map((headerGroup, index) => (
                  <div className="cardHeaderRow" {...headerGroup.getHeaderGroupProps()} key={index}>
                    <div className="containerCardHeardCell">
                      <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[0].getHeaderProps()}>
                        {headerGroup.headers[0].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[1].getHeaderProps()}>
                        {headerGroup.headers[1].render("Header")}
                      </div>
                      <div className="cardHeaderCell" style={{ width: '10%' }}{...headerGroup.headers[2].getHeaderProps()}>
                        {headerGroup.headers[2].render("Header")}
                      </div>
                      {/* {(infoUser.role === 'Administrador') ? (
                        <> */}
                          <div className="cardHeaderCell" style={{ width: '20%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '9%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '13%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                          {/* <div className="cardHeaderCell center" style={{ width: '5%' }}{...headerGroup.headers[7].getHeaderProps()}>
                            {headerGroup.headers[7].render("Header")}
                          </div> */}
                        {/* </>
                      ) : (
                        <>
                          <div className="cardHeaderCell" style={{ width: '30%' }}{...headerGroup.headers[3].getHeaderProps()}>
                            {headerGroup.headers[3].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[4].getHeaderProps()}>
                            {headerGroup.headers[4].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '15%' }}{...headerGroup.headers[5].getHeaderProps()}>
                            {headerGroup.headers[5].render("Header")}
                          </div>
                          <div className="cardHeaderCell" style={{ width: '4%' }}{...headerGroup.headers[6].getHeaderProps()}>
                            {headerGroup.headers[6].render("Header")}
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                ))}
                <div className="rowContainer">
                  <div className="containerRow">
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <div className="card" {...row.getRowProps()} key={index}>
                          <div className="cardContentRow">
                            <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[0].getCellProps()}>
                              {row.cells[0].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[1].getCellProps()}>
                              {row.cells[1].render("Cell").props.cell.value !== "no existe" && row.cells[1].render("Cell")}
                            </div>
                            <div className="cardContentCell" style={{ width: '10%' }} {...row.cells[2].getCellProps()}>
                              {row.cells[2].render("Cell")}
                            </div>
                            {/* {(infoUser.role === 'Administrador') ? (
                              <> */}
                                <div className="cardContentCell" style={{ width: '20%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell").props.cell.value !== "no existe" && row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '9%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '13%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                                {/* <div className="cardContentCell center" style={{ width: '5%' }} {...row.cells[7].getCellProps()}>
                                  {row.cells[7].render("Cell")}
                                </div> */}
                              {/* </>
                            ) : (
                              <>
                                <div className="cardContentCell" style={{ width: '30%' }} {...row.cells[3].getCellProps()}>
                                  {row.cells[3].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[4].getCellProps()}>
                                  {row.cells[4].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '15%' }} {...row.cells[5].getCellProps()}>
                                  {row.cells[5].render("Cell")}
                                </div>
                                <div className="cardContentCell" style={{ width: '4%' }} {...row.cells[6].getCellProps()}>
                                  {row.cells[6].render("Cell")}
                                </div>
                              </>
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div >
        </>
      );
      break;
    default:
      tables = null;
      break;
  }


  async function handleImageLoad(event) {
    const image = event.target;
    if ((image.width / image.height) < 4 / 3) {
      // setAcomodar("ancho")
      setSize("width")
    } else {
      setSize("heigth")
      // setAcomodar("largo")
    }

  }

  return (
    <div className="containerHeardANDTable">
      <div className="HeardANDFilter">
        <LogoComponent />
        <div className="textAndFilter">
          <div className='containerButtonMaker'>
            {addButtonContent}
          </div>
          <div className='filterContainer'>
            <div className="containerFilter">
              <Filter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          </div>
        </div>

        <div className='closeIntContainer'>
          {/* <NavLink onClick={Cerrar} className='closeButton'>
            <span className="tooltipProductMenos">Cerrar Sesión       </span>
            <ImExit className='iconNav' />
          </NavLink> */}
          <CloseButton />
        </div>
      </div>
      <div id='overallContainer' className='overallContainer'>
        <Nav />
        <div id='overallContainerTable' className='overallContainerTable'>
          <div className="contenedorgeneral-Table">
            {tables}
          </div>
          <div className="paginationContainer">
            <div className="pagination">
              <span>
                Página&nbsp;
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{" "}
              </span>
              <div>
                <button
                  className="paginationButton"
                  onClick={() => {
                    gotoPage(0);
                    setActualPage(0);
                  }}
                  disabled={!canPreviousPage}
                >
                  <BiFirstPage className="page-controller" />
                </button>{" "}
                <button
                  className="paginationButton"
                  onClick={() => {
                    previousPage();
                    setActualPage(actualPage - 1);
                  }}
                  disabled={!canPreviousPage}
                >
                  <MdKeyboardArrowLeft className="page-controller" />
                </button>{" "}
                <button
                  className="paginationButton"
                  onClick={() => {
                    nextPage();
                    setActualPage(actualPage + 1);
                  }}
                  disabled={!canNextPage}
                >
                  <MdKeyboardArrowRight className="page-controller" />
                </button>{" "}
                <button
                  className="paginationButton"
                  onClick={() => {
                    gotoPage(pageCount - 1);
                    setActualPage(pageCount - 1);
                  }}
                  disabled={!canNextPage}
                >
                  <BiLastPage className="page-controller" />
                </button>{" "}
              </div>
              <p className="textNameUserSmall">{infoUser.name}</p>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="pageSizeView"
              >
                {[5, 10, 15].map((pageSize, index) => (
                  <option key={index} value={pageSize}>
                    {pageSize !== 15 ? `Mostrar ${pageSize}` : `Mostrar todo`}
                  </option>
                ))}
              </select>
            </div>
            <div className="containerNameUser">
              <p className="textNameUser">{infoUser.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}