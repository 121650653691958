import { useContext, useEffect, useState } from "react"
import { AiTwotoneDelete } from 'react-icons/ai';
import LoginContext from "../Context/Logincontext"
import Nav1 from '../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import "./../styles/AddProduct.css"
import { urlApiServer } from '../Config/Config'
import '../styles/AddQuotes.css'
import { AiFillEdit } from 'react-icons/ai'
import moment from 'moment';
import { CgMaximize } from 'react-icons/cg'
import { GrClose } from "react-icons/gr"
import { ImExit } from 'react-icons/im'
import Swal from 'sweetalert2'
import ProductsIn3djs from '../Component/ProductsIn3djs/ProductsIn3djs';
import { validateAddQuote } from '../functions/addQuotesValidate';
import CloseButton from "../Component/CloseButton";
import LogoComponent from "../Component/LogoComponent";
import AddProductGeneric from "../Component/Hooks/AddProductGeneric";
import AddProductMarmol from "../Component/Hooks/AddProductMarmol";

let splitImg = []
let saveProduct = []

let saveExtradate = []
let saveItemPrice = []
const initialValue = 0;
const initialValue2 = 0;
let saveQuantityItem = []
let saveObject = []
let ivaProdut = 0;
let dataJustDeleted = []
let oldData = 0;
let oldIva = 0;


let sellerText = null
let citizenshipCardText = null
let directionText = null
let contactText = null
let phoneText = null
let clientText = null
let emailText = null



const AddQuotes = (props) => {

    const urlGetQuotes = urlApiServer + "addquotes";
    const urlGetQuotesMaker = urlApiServer + "addquotesandclien";
    const urlGetProduct = urlApiServer + "products";
    const urlsubir = urlApiServer + "Uploadfile";
    const urlgetClient = urlApiServer + "client"
    const urlpostHistoryProduct = urlApiServer + "addhistoryProduct";
    const urlsubirPreview = urlApiServer + "Uploadfilepreview";

    const urlgetConfig = urlApiServer + "getConfig";


    const [date, setDate] = useState()
    const [seller, setSeller] = useState(sellerText ? sellerText : '')
    const [citizenshipCard, setCitizenshipCard] = useState(citizenshipCardText ? citizenshipCardText : "no existe")
    const [direction, setDirection] = useState(directionText ? directionText : "no existe")
    const [contact, setContact] = useState(contactText ? contactText : "no existe")
    const [phone, setPhone] = useState(phoneText ? phoneText : "no existe")
    const [client, setClient] = useState(clientText ? clientText : '')
    const [typeDocument, setTypeDocument] = useState(clientText ? clientText : '')

    const [email, setEmail] = useState(emailText ? emailText : "no existe")
    const [emailValidation, setEmailValidation] = useState('correo invalido');
    const [product, setProduct] = useState(null)
    const [material, setMaterial] = useState(null)
    const [caliber, setCaliber] = useState(null)
    const [long, setLong] = useState(null)
    const [width, setWidth] = useState(null)
    const [weight, setWeight] = useState(null)
    const [priceByWeight, setPriceByWeight] = useState(null)
    const [quantityItem, setQuantityItem] = useState(null)
    const [newupdateView, setNewUpdateView] = useState(true);
    const [isFullScreen, setFullScreen] = useState(false);
    const [itemPrice, setItemPrice] = useState(0)
    const [payment, setPayment] = useState('')
    const [operationMatRest, setOperationMatRest] = useState()
    const [operationMatMultiplications, setOperationMatMultiplications] = useState()
    const [productGetTable, setProductGetTable] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [Imagen, setImagen] = useState([])
    const [Imagetest, setImageTest] = useState("")
    const [priceEnd, setPriceEnd] = useState(0)
    const [itemEnd, setItemEnd] = useState()
    const [id, setId] = useState()
    const [isFullScreenComputer, setFullScreenComputer] = useState(false);
    const [toggled, setToggled] = useState(false)
    const [userAddButton, setUserAddButton] = useState()
    const [optionsDataClient, setOptionsDataClient] = useState('opciones')
    const [clientGetTable, setClientGetTable] = useState([])
    const [indexClient, setIndexClient] = useState('pendiente')
    const [indexProduct, setIndexProduct] = useState('pendiente')
    const [deletoglee, setDeletogle] = useState(false)
    const [iva, setIva] = useState(0)
    const [priceIva, setPriceIva] = useState(0)
    const [observation, setObservation] = useState("sin observacion")
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [hasImage, setHasImage] = useState(false);
    const [reload, setReload] = useState(false)
    const [time, setTime] = useState(false)

    // const [newupdateView, setNewUpdateView] = useState(true);

    useEffect(() => {
        // console.log("Recargando")
        // console.log("Save product: ", saveProduct)
    }, [reload])

    const Reload = async (item) => {
        saveProduct = item
        // console.log("newupdateView: ", saveProduct)

        if (reload) {
            setNewUpdateView(false);
            setReload(false);

        } else {
            setNewUpdateView(true);
            setReload(true);

        }
    }

    const reloadMarmol = async (id, save) => {
        // console.log("id:", id)
        setId(id)
        // saveProduct = save


        // if (reload) {
        //     setNewUpdateView(false);
        //     setReload(false);

        // } else {
        //     setNewUpdateView(true);
        //     setReload(true);

        // }
        // toggleFullScreen()
        setFullScreen(!isFullScreen);


    }

    // console.log("infoUser: ", infoUser.companyType)
    const toggleFullScreen = () => {
        console.log("ejecutando toggleFullScreen")
        if (citizenshipCardText) {
            setCitizenshipCard(citizenshipCardText)
            citizenshipCardText = null
        }

        if (clientText) {
            setClient(clientText)
            clientText = null
        }

        if (phoneText) {
            setPhone(phoneText)
            phoneText = null
        }

        if (sellerText) {
            setSeller(sellerText)
            sellerText = null
        }

        if (contactText) {
            setContact(contactText)
            contactText = null
        }

        if (directionText) {
            setDirection(directionText)
            directionText = null
        }

        if (emailText) {
            setEmail(emailText)
            emailText = null
        }

        setFullScreen(!isFullScreen);
        console.log("isFullScreenComputer: ", isFullScreenComputer)
        closeFullscreen()
        setId()
    };

    const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const openFullscreen = () => {
        const element = document.documentElement;
        // console.log("element.requestFullscreen: ", element.requestFullscreen)
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
        setFullScreenComputer(true);
    };

    const closeFullscreen = () => {
        const element = document.documentElement;
        if (document.exitFullscreen) {
            if(isFullScreenComputer){
                document.exitFullscreen();
            }
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setFullScreenComputer(false);
    };

    const toggleFullScreenComputer = () => {
        if (isFullScreenComputer) {
            closeFullscreen();
        } else {
            openFullscreen();
        }
    };

    const handleDivClick = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())
    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.pathname);
            Swal.fire({
                title: '¡Atención!',
                text: 'No puedes retroceder en esta página.',
                icon: 'warning',
                confirmButtonText: 'OK',
            });
        };
    }, []);

    const object = Object.assign({}, props)

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }


    useEffect(() => {
    }, [itemEnd])

    if (props.enteredData === undefined) {
    } else {
        saveObject.push(object)
    }

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlgetClient, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setClientGetTable(quotesResponse);
                })
                .catch(error => {
                });
        }())

    }, [])

    useEffect(() => {
        clientGetTable.map((info, index) => {
            if (client === info.cliente) {
                setIndexClient(index)
                setCitizenshipCard(clientGetTable[index].nitocc)
                setDirection(clientGetTable[index].direccion)
                setContact(clientGetTable[index].contacto)
                setPhone(clientGetTable[index].telefono)
                setClient(clientGetTable[index].cliente)
                setEmail(clientGetTable[index].correo)
                setTypeDocument(clientGetTable[index].typedocument)

            }
        })
        setSeller(infoUser.name)
    }, [client])

    useEffect(() => {
        productGetTable.map((info, index) => {

            if (product === info.producto) {
                setIndexProduct(index)
                setMaterial(productGetTable[index].material)
                setLong(productGetTable[index].largo)
                setWidth(productGetTable[index].ancho)
                setCaliber(productGetTable[index].calibre)
                setItemPrice(productGetTable[index].precioitem)
            }
        })
    }, [product])


    useEffect(() => {
    }, [saveProduct])

    async function deleteProduct(value) {
    }

    useEffect(() => {
    }, [itemPrice])

    useEffect(() => {
    }, [weight])

    useEffect(() => {
    }, [priceByWeight])

    useEffect(() => {
    }, [quantityItem])

    async function saveQuotes(data) {
        saveProduct.push(data)
        // console.log("saveProduct: ", saveProduct)
        const totalPrice = saveProduct.reduce((acc, item) => acc + item.total, 0);
        setPriceEnd(totalPrice)
    }


    async function editQuotes(data) {

        let updateArraySaveProduct = saveProduct.slice();
        updateArraySaveProduct[id] = data;
        saveProduct = updateArraySaveProduct;

        const totalPrice = saveProduct.reduce((acc, item) => acc + item.total, 0);
        setPriceEnd(totalPrice)
        // console.log("saveProduct: ", saveProduct)
    }

    async function priceEndFunction(totalPrice) {
        // console.log("totalPrice: ", totalPrice)
        setPriceEnd(totalPrice)
    }

    useEffect(() => {
    }, [saveProduct])

    async function list() {

        saveProduct.map((item, index) => {

            saveObject.push(item)

        })
        if (toggled) {
            setToggled(false)
        } else {
            setToggled(true)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [observation]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // El usuario ha presionado la tecla 'Enter'
            // console.log('Tecla Enter presionada:', observation);
            setObservation(observation + '\n')
        }
    };

    useEffect(() => {
    }, [priceEnd])
    useEffect(() => {
    }, [itemEnd])

    async function handleDate(e) {
        const date = e.target.value;
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        setDate(formattedDate);
    }

    const formatSellerName = (name) => {
        const words = name.split(' ');

        const formattedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1).toLowerCase();
            return `${firstLetter}${restOfWord}`;
        });

        return formattedWords.join(' ');
    }

    async function handleSeller(e) {
        const seller = e.target.value.replace(/[^A-Z a-z]/g, "");
        const formattedValue = formatSellerName(seller);
        setSeller(formattedValue)
        sellerText = formattedValue
    }

    async function handleCitizenshipCard(e) {
        const citizenshipCard = e.target.value.replace(/[^0-9-_]/g, '');
        setCitizenshipCard(citizenshipCard);
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
        directionText = direction
    }

    async function handleContact(e) {
        const contact = e.target.value
        setContact(contact)
        contactText = contact
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
        phoneText = phone
    }

    const formatClientName = (name) => {
        const words = name.split(' ');

        const formattedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1).toLowerCase();
            return `${firstLetter}${restOfWord}`;
        });

        return formattedWords.join(' ');
    }

    async function handleClient(e) {
        const client = e.target.value
        if (/^[A-Za-z.\s]+$/.test(client) || client === '') {
            setClient(client)
            clientText = client
        }

    }

    async function handleTypeDocument(e) {
        const documentType = e.target.value
        // if (/^[A-Za-z.\s]+$/.test(client) || client === '') {
        setTypeDocument(documentType)
        // clientText = client
        // }

    }

    useEffect(() => {
        const matMultiplications = parseFloat(itemPrice) * parseFloat(weight)
        setPriceByWeight(matMultiplications)
    }, [itemPrice, weight])

    useEffect(() => {
        const matMultiplications = priceByWeight * quantityItem
        setOperationMatMultiplications(matMultiplications)
    }, [priceByWeight, quantityItem])

    async function handleItemPrice(e) {
        const itemPrice = e.target.value
        setItemPrice(itemPrice || null)
    }

    async function handlepayment(e) {
        const payment = e.target.value
        setPayment(payment)
    }

    useEffect(() => {
        // console.log("operation: ", operationMatRest)
        const parsedPriceEnd = +priceEnd
        const parsedPayment = +payment
        if (!isNaN(parsedPriceEnd) && !isNaN(parsedPayment)) {
            const matRest = parsedPriceEnd - parsedPayment
            setOperationMatRest(matRest)
        }
    }, [priceEnd, payment])

    async function handleEmail(e) {
        const emailValue = e.target.value
        setEmail(emailValue);

        const validation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
        if (validation.test(email)) {
            setEmailValidation("correo valido")
            emailText = emailValue
        } else {
            setEmailValidation("correo invalido")
        }
    }

    async function handleInvoiceNumber(e) {
        const invoiceNumber = e.target.value
        setInvoiceNumber(invoiceNumber)
    }

    async function handleObservation(e) {
        const observation = e.target.value
        setObservation(observation)
    }

    async function handleTime(e) {
        const times = e.target.value
        setTime(times)
    }

    useEffect(() => {
        setPriceIva(Math.round(operationMatMultiplications * (1 + iva * 0.01)))
    }, [operationMatMultiplications, iva, itemPrice])


    async function addClient() {
        if (!client || client === "Cliente") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo del nombre del cliente',
            })
        } else if (citizenshipCard === "no existe") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo del numero de documento',
            })
        } else if (contact === "no existe") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo de el numero de celular del cliente',
            })
        } else if (phone === "no existe") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo el numero de telefono del cliente',
            })
        } else if (direction === "no existe") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo de la direccion del clinete',
            })
        } else if (emailValidation === 'correo invalido') {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Tu correo esta en un formato invalido',
            })
        } else if (email === "no existe") {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegurate de llenar el campo de el email del cliente y de que el formato sea el correcto',
            })
        } else {
            let objectExtraData = {
                "cliente": client,
                "celular": contact,
                "telefono": phone,
                "direccion": direction,
                "correo": email,
                "vendedor": seller,
                "citizenshipCard": citizenshipCard
            }

            saveExtradate.push(objectExtraData)
            setUserAddButton(saveExtradate[saveExtradate.length - 1])
            Swal.fire({
                icon: 'success',
                title: 'Cliente guardado',
                text: 'Datos del cliente guardados correctamente',
            })
        }
    }

    async function handleImagen(e) {
        let ImageArray = []
        let ImageArrayTest = []
        const Image = e.target.files
        for (const property in Image) {
            ImageArray.push(Image[property])
            ImageArrayTest.push(Image[property].name)
        }
        ImageArray.pop()
        ImageArray.pop()
        ImageArrayTest.pop()
        ImageArrayTest.pop()

        const ImageString = ImageArrayTest.toString()
        setImageTest(ImageString)
        setHasImage(ImageArray.length > 0);

        setImagen(ImageArray)
    }

    async function sendfile(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }


    async function sendfilePreview(file) {

        try {
            const formdata = new FormData();

            formdata.append("file", file);

            formdata.append(file.name, "foto");
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(urlsubirPreview, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    async function uploadProduct() {
        // console.log("Ingresando aqui uploadProduct")
        let validateNew = 0
        if (saveProduct.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegúrate de contar con al menos un producto registrado',
            })
        } else {
            let validate = {
                empresa: infoUser.company,
                date: date,
                seller: seller,
                // documentType, typeDocument,
                citizenshipCard: citizenshipCard,
                direction: direction,
                contact: contact,
                phone: phone,
                client: client,
                payment: payment,
                email: email,
                youMay: operationMatRest,
                invoiceNumber: invoiceNumber,
                image: Imagetest,
                priceEnd: 0,
                itemEnd: 5,
                observation: observation,
                typeDocument: typeDocument,
                time: time
            }

            const res = validateAddQuote(validate)
            if (res === "quit") {
                return Swal.fire({
                    icon: 'error',
                    title: 'Algo salio mal...',
                    text: 'Recuerda primero seleccionar o crea un cliente',
                })
            }
            const data = JSON.stringify({
                empresa: infoUser.company,
                date: date,
                seller: seller,
                citizenshipCard: citizenshipCard,
                direction: direction,
                contact: contact,
                phone: phone,
                client: client,
                payment: payment,
                email: email,
                youMay: operationMatRest,
                invoiceNumber: invoiceNumber,
                image: Imagetest,
                priceEnd: 0,
                itemEnd: 5,
                observation: observation,
                typeDocument: typeDocument,
                time: time

            })
            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            }
            fetch(urlGetQuotes, requesoption).then(response => response.json())
                .then(dataResponse => {
                    if(dataResponse.status !== 430){
                        Imagen.map((file) => {
                            sendfile(file)
                        })
                        saveProduct.map((item) => {
    
                            const Product = JSON.stringify({
                                empresa: infoUser.company,
                                producto: item.producto,
                                material: item.material,
                                caliber: item.caliber,
                                long: item.long,
                                width: item.ancho,
                                precioitem: item.precioitem,
                                cantidadItem: item.cantidadItem,
                                total: item.total,
                                iva: item.iva,
                                Priceiva: item.Priceiva,
                                image_3d: (infoUser.companyType === "Generica") ? (null) : (item.image_3d.name),
                                image_2d: (infoUser.companyType === "Generica") ? (null) : (item.image_2d.name),
                                modeljson: item.modeljson,
                                price: item.price,
                                unit: (infoUser.companyType === "Generica") ? (item.unit) : ("")
                            })
                            if (infoUser.companyType !== "Generica") {
                                // sendfilePreview(item.image_2d)
                                // sendfilePreview(item.image_3d)
                            }
                            const registerHeaders2 = new Headers();
                            registerHeaders2.append("Content-Type", "application/json")
    
                            const requesoption2 = {
                                method: 'POST',
                                headers: registerHeaders2,
                                body: Product,
                                redirect: 'follow'
                            }
    
                            fetch(urlpostHistoryProduct, requesoption2).then(response => response.json())
                                .then(dataResponse => {
                                    if (300 === dataResponse.status) {
                                        validateNew = validateNew + 1
    
                                        if (validateNew === 1) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Felicidades',
                                                text: 'Has creado una nueva cotizacion',
                                                confirmButtonText: 'Aceptar',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.href = './Cotizaciones';
                                                    saveProduct = []
                                                }
                                            })
                                        }
                                    } else if (560 === dataResponse.status) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error!',
                                            text: 'Algo salio mal',
                                        })
                                    }
                                })
                        })
                    }else{
                        Swal.fire({
                            icon: 'warning',
                            title: 'Advertencia!',
                            text: dataResponse.msj,
                        })   
                    }
                })
        }
    }
    async function uploadProductMaker() {
        // console.log("Ingresando aqui uploadProductMaker")
        let validateNew = 0
        if (saveProduct.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Asegúrate de contar con al menos un producto registrado',
            })
        } else {

            let validate = {
                empresa: infoUser.company,
                date: date,
                seller: seller,
                // typeDocument, typeDocument,
                citizenshipCard: citizenshipCard,
                direction: direction,
                contact: contact,
                phone: phone,
                client: client,
                payment: payment,
                email: email,
                youMay: operationMatRest,
                invoiceNumber: invoiceNumber,
                image: Imagetest,
                priceEnd: 0,
                itemEnd: 5,
                observation: observation
            }

            const res = validateAddQuote(validate)
            if (res === "quit") {
                return Swal.fire({
                    icon: 'error',
                    title: 'Algo salio mal...',
                    text: 'Recuerda llenar todos los campos del usuario',
                })
            }

            const data = JSON.stringify({
                empresa: infoUser.company,
                date: date,
                seller: seller,
                citizenshipCard: citizenshipCard,
                direction: direction,
                contact: contact,
                phone: phone,
                client: client,
                payment: payment,
                email: email,
                youMay: operationMatRest,
                invoiceNumber: invoiceNumber,
                image: Imagetest,
                priceEnd: 0,
                itemEnd: 5,
                observation: observation,
                typeDocument: typeDocument,
                time: time
            })
            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            }
            fetch(urlGetQuotesMaker, requesoption).then(response => response.json())
                .then(dataResponse => {
                    if(dataResponse.status !== 430){
                        Imagen.map((file) => {
                            sendfile(file)
                        })
                        saveProduct.map((item) => {
                            const Product = JSON.stringify({
                                empresa: infoUser.company,
                                producto: item.producto,
                                material: item.material,
                                caliber: item.caliber,
                                long: item.long,
                                width: item.ancho,
                                precioitem: item.precioitem,
                                cantidadItem: item.cantidadItem,
                                total: item.total,
                                iva: item.iva,
                                Priceiva: item.priceIva,
                                image_3d: (infoUser.companyType === "Generica") ? (null) : (item.image_3d.name),
                                image_2d: (infoUser.companyType === "Generica") ? (null) : (item.image_2d.name),
                                modeljson: item.modeljson,
                                price: item.price,
                                unit: (infoUser.companyType === "Generica") ? (item.unit) : ("")
    
                            })
                            // sendfilePreview(item.image_2d)
                            // sendfilePreview(item.image_3d)
                            const registerHeaders2 = new Headers();
                            registerHeaders2.append("Content-Type", "application/json")
    
                            const requesoption2 = {
                                method: 'POST',
                                headers: registerHeaders2,
                                body: Product,
                                redirect: 'follow'
                            }
    
                            fetch(urlpostHistoryProduct, requesoption2).then(response => response.json())
                                .then(dataResponse => {
                                    if (300 === dataResponse.status) {
                                        validateNew = validateNew + 1
    
                                        if (validateNew === 1) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Felicidades',
                                                text: 'Has Creado una nueva cotizacion',
                                                confirmButtonText: 'Aceptar',
                                                allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.href = './Cotizaciones';
                                                    saveProduct = []
                                                }
                                            })
                                        }
                                    } else if (560 === dataResponse.status) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error!',
                                            text: 'Algo salio mal al intentar crear la cotizacion, si el error persiste pongase en contacto con el administrador de la plataforma',
                                        })
                                    } else if (101 === dataResponse.status) {
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Advertencia!',
                                            text: 'Este ' + citizenshipCard + ' documenta ya esta registrado',
                                        })
                                    }
                                })
                        })
                        validateNew = 0
                    }else{
                        Swal.fire({
                            icon: 'warning',
                            title: 'Advertencia!',
                            text: dataResponse.msj+", si el error persiste contacte con un administrador de la plataforma",
                        }) 
                    }
                })
        }
    }

    useEffect(() => {
        // console.log("saveProdcut: ", saveProduct)
        if (newupdateView === false) {
            setNewUpdateView(true)
        }
    }, [newupdateView])

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlGetProduct, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setProductGetTable(quotesResponse);
                })
                .catch(error => {
                });

        }())

    }, [])

    async function maker() {
        setOptionsDataClient('maker')
    }
    async function existente() {
        setOptionsDataClient('existente')
    }
    async function clossDataUsers() {
        sellerText = null
        citizenshipCardText = null
        directionText = null
        contactText = null
        phoneText = null
        clientText = null
        emailText = null
        setSeller("")
        setCitizenshipCard("")
        setDirection("")
        setContact("")
        setPhone("")
        setClient("")
        setTypeDocument("")
        setEmail("")
        setOptionsDataClient('opciones')
    }

    useEffect(() => {
        setDeletogle(true)
    }, [deletoglee])


    function returnNormalScreen() {
        setFullScreen(false)
    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    <>
                        {(isFullScreen === true || isFullScreenComputer === true) ? (
                            <div className="fullscreen-overlay">
                                <div className='optionsWindowEditor'>
                                    <NavLink onClick={toggleFullScreenComputer}><CgMaximize className='CgMaximizeInMinimize' /></NavLink>
                                    <NavLink onClick={toggleFullScreen}><GrClose className='GrCloseInMinimize' /></NavLink>

                                </div>
                                <div>
                                    <ProductsIn3djs function={saveQuotes} editQuotes={editQuotes} returnNormalScreenFuction={returnNormalScreen} id={id} saveProduct={saveProduct[id]} />
                                </div>
                            </div>
                        ) : (<>
                            <div className="containerHeardANDTable">
                                <div className="HeardANDFilter">
                                    <LogoComponent />
                                    <div className='containerButtonAdd'>
                                        <div className="navigationContainer">
                                            <div className="pageTitle">CREAR COTIZACIÓN</div>
                                        </div>
                                    </div>
                                    <div className='closeIntContainer'>
                                        {/* <NavLink onClick={Cerrar} className='closeButton'>
                                            <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                            <ImExit className='iconNav' />
                                        </NavLink> */}
                                        <CloseButton />
                                    </div>
                                </div>
                            </div>
                            <div id='overallContainer' className='overallContainer'>
                                <Nav1 />
                                <div className='drawerFormAddCuotes'>
                                    <form className='ContainerAddCuotes'>
                                        {(optionsDataClient === 'opciones') ? (
                                            <div className='containerQuote3'>
                                                <div className='infoOneQuotes'>
                                                    <p className='texProducttitule'>DATOS CLIENTE - VENDEDOR</p>
                                                    <div className='containeQuotestAdd'>
                                                        <div className='rightQuotes'>
                                                            <NavLink onClick={maker} className='Textregister'><button className="button neon_Effect"><p>crear</p></button></NavLink>
                                                        </div>
                                                        <div className='leftQuotes'>
                                                            <NavLink onClick={existente} className='Textregister'><button className="button neon_Effect"><p>Existente</p></button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ) : (
                                            (optionsDataClient === 'maker') ? (
                                                <div className='containerQuotes'>
                                                    <div className='infoOneQuotes'>
                                                        <p className='texProducttitule'>DATOS DEL CLIENTE</p>
                                                        <div className='containeQuotestAdd'>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    value={client}
                                                                    autocomplete="off"
                                                                    name="client"
                                                                    id="client"
                                                                    onChange={handleClient}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                />
                                                                <label htmlFor="name">Nombre del cliente <em className='Obligatory'>*</em></label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    name="client"
                                                                    // id="client"
                                                                    onChange={handleTypeDocument}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    list="drawfemails2"
                                                                    value={typeDocument}
                                                                />
                                                                <label htmlFor="name">Tipo de documento <em className='Obligatory'>*</em></label>
                                                                <datalist id="drawfemails2" >
                                                                    <option value="">Selecciona una opcion:</option>
                                                                    <option value="NIT">NIT</option>
                                                                    <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                                    <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                                    <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                                </datalist>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    onWheel={(e) => e.target.blur()}
                                                                    name="CitizenshipCard"
                                                                    id="CitizenshipCard"
                                                                    onChange={handleCitizenshipCard}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    value={citizenshipCard}

                                                                />
                                                                <label htmlFor="name">Documento<em className='Obligatory'>*</em></label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="number"
                                                                    autocomplete="off"
                                                                    onWheel={(e) => e.target.blur()}
                                                                    name="Contact"
                                                                    id="Contact"
                                                                    onChange={handleContact}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    value={contact}

                                                                />
                                                                <label htmlFor="name">Celular <em className='Obligatory'>*</em></label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="number"
                                                                    autocomplete="off"
                                                                    onWheel={(e) => e.target.blur()}
                                                                    name="phone"
                                                                    id="phone"
                                                                    onChange={handlePhone}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    value={phone}

                                                                />
                                                                <label htmlFor="name">Telefono</label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    name="direction"
                                                                    id="direction"
                                                                    onChange={handleDirection}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    value={(direction === "no existe") ? ("") : (direction)}
                                                                />
                                                                <label htmlFor="name">Dirección <em className='Obligatory'>*</em></label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    name="Email"
                                                                    id="Email"
                                                                    onChange={handleEmail}
                                                                    className="inputquotes"
                                                                    placeholder=" "
                                                                    value={(email === "no existe") ? ("") : (email)}

                                                                />
                                                                <label htmlFor="name">Correo electronico</label>
                                                            </div>
                                                            <div className="inputQuotesContainer">
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    name="seller"
                                                                    id="seller"
                                                                    onChange={handleSeller}
                                                                    className="inputquotes"
                                                                    value={seller}
                                                                    placeholder=" "
                                                                />
                                                                <label htmlFor="name">Nombre del vendedor <em className='Obligatory'>*</em></label>
                                                            </div>
                                                        </div>
                                                        <div className='containerButtonAddlist'>
                                                            <NavLink onClick={clossDataUsers} className='Textregister'><button className="button neon_Effect"><p>Cerrar</p></button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                (indexClient === 'pendiente') ? (
                                                    <>
                                                        <div className='containerQuotes'>
                                                            <div className='infoOneQuotes'>
                                                                <p className='texProducttitule'>DATOS CLIENTE - VENDEDOR</p>
                                                                <div className='containeQuotestAdd'>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            // value={client}
                                                                            autocomplete="off"
                                                                            name="client"
                                                                            id="clientSelect"
                                                                            onChange={handleClient}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                            list="drawfemails"
                                                                        />
                                                                        <label htmlFor="name">Nombre del cliente</label>
                                                                        <datalist id="drawfemails" value="clientSelect">
                                                                            {
                                                                                clientGetTable
                                                                                    .sort((a, b) => a.cliente.localeCompare(b.cliente)) // Ordenar alfabéticamente
                                                                                    .map((client, index) => {
                                                                                        return (
                                                                                            <option key={index}>{client.cliente}</option>
                                                                                        )
                                                                                    })
                                                                            }
                                                                        </datalist>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="typeDocument"
                                                                            id="typedocument"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onChange={handleTypeDocument}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                            list="drawfemails2"
                                                                        // value={typeDocument}
                                                                        />
                                                                        <label htmlFor="name">Tipo de documento</label>
                                                                        <datalist id="drawfemails2" >
                                                                            <option value="">Selecciona una opcion:</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                                            <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                                            <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                                        </datalist>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="CitizenshipCard"
                                                                            id="CitizenshipCard"
                                                                            onChange={handleCitizenshipCard}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Documento <em className='Obligatory'>*</em></label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="number"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="Contact"
                                                                            id="Contact"
                                                                            onChange={handleContact}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Celular <em className='Obligatory'>*</em></label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="number"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="phone"
                                                                            id="phone"
                                                                            onChange={handlePhone}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Telefono</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="direction"
                                                                            id="direction"
                                                                            onChange={handleDirection}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Direccion <em className='Obligatory'>*</em></label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="Email"
                                                                            id="Email"
                                                                            onChange={handleEmail}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Correo electronico</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="seller"
                                                                            id="seller"
                                                                            onChange={handleSeller}
                                                                            className="inputquotes"
                                                                            value={seller}
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Nombre del vendedor <em className='Obligatory'>*</em></label>
                                                                    </div>
                                                                </div>
                                                                <div className='containerButtonAddlist'>
                                                                    <NavLink onClick={clossDataUsers} className='Textregister'><button className="button neon_Effect"><p>Cerrar</p></button></NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='containerQuotes'>
                                                            <div className='infoOneQuotes'>
                                                                <p className='texProducttitule'>DATOS CLIENTE - VENDEDOR</p>
                                                                <div className='containeQuotestAdd'>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="client"
                                                                            id="clientSelect"
                                                                            onChange={handleClient}
                                                                            value={client}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                            list="drawfemails"
                                                                        />
                                                                        <label htmlFor="name">Nombre del cliente</label>
                                                                        <datalist id="drawfemails" value="clientSelect">
                                                                            {
                                                                                clientGetTable
                                                                                    .sort((a, b) => a.cliente.localeCompare(b.cliente)) // Ordenar alfabéticamente
                                                                                    .map((client, index) => {
                                                                                        return (
                                                                                            <option key={index}>{client.cliente}</option>
                                                                                        )
                                                                                    })
                                                                            }
                                                                        </datalist>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="typeDocument"
                                                                            id="typedocument"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onChange={handleTypeDocument}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                            list="drawfemails2"
                                                                            value={(clientGetTable[indexClient].typedocument === null) ? ("") : (clientGetTable[indexClient].typedocument)}
                                                                        />
                                                                        <label htmlFor="name">Tipo de documento</label>
                                                                        <datalist id="drawfemails2" >
                                                                            <option value="">Selecciona una opcion:</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                                            <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                                            <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                                        </datalist>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="CitizenshipCard"
                                                                            id="CitizenshipCard"
                                                                            onChange={handleCitizenshipCard}
                                                                            className="inputquotes"
                                                                            value={clientGetTable[indexClient].nitocc}

                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Documento</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="number"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="Contact"
                                                                            id="Contact"
                                                                            onChange={handleContact}
                                                                            value={clientGetTable[indexClient].contacto} className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Celular</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="number"
                                                                            autocomplete="off"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="phone"
                                                                            id="phone"
                                                                            onChange={handlePhone}
                                                                            value={clientGetTable[indexClient].telefono}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Telefono</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="direction"
                                                                            id="direction"
                                                                            onChange={handleDirection}
                                                                            className="inputquotes"
                                                                            value={clientGetTable[indexClient].direccion}
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Dirección</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="Email"
                                                                            id="Email"
                                                                            value={clientGetTable[indexClient].correo}
                                                                            onChange={handleEmail}
                                                                            className="inputquotes"
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Correo electronico</label>
                                                                    </div>
                                                                    <div className="inputQuotesContainer">
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="seller"
                                                                            id="seller"
                                                                            onChange={handleSeller}
                                                                            className="inputquotes"
                                                                            value={seller}
                                                                            placeholder=" "
                                                                        />
                                                                        <label htmlFor="name">Nombre del vendedor</label>
                                                                    </div>
                                                                </div>
                                                                <div className='containerButtonAddlist'>
                                                                    <NavLink onClick={clossDataUsers} className='Textregister'><button className="button neon_Effect"><p>Cerrar</p></button></NavLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            )
                                        )}
                                    </form>

                                    {
                                        (infoUser.companyType === "Generica") ? (
                                            <AddProductGeneric saveProduct={saveProduct} saveItemPrice={saveItemPrice} oldData={oldData} oldIva={oldIva} toggleFullScreen={toggleFullScreen} Reload={Reload} priceEndFunction={priceEndFunction} />
                                        ) : (
                                            <AddProductMarmol saveProduct={saveProduct} saveItemPrice={saveItemPrice} oldData={oldData} oldIva={oldIva} toggleFullScreen={toggleFullScreen} Reload={reloadMarmol} />
                                        )
                                    }

                                    <form className='ContainerAddCuotes'>
                                        <div className='containerQuotes2'>
                                            <div className='infoOneQuotes'>
                                                <p className='texProducttitule'>DATOS COTIZACIÓN (Opcional)</p>
                                                <div className='containeQuotestAdd'>
                                                    {/* {(priceEnd === 0) ? ("") : ( */}
                                                        <div className="inputQuotesContainer">
                                                            <input
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                name="payment"
                                                                id="payment"
                                                                onChange={handlepayment}
                                                                autocomplete="off"
                                                                className="inputquotes"
                                                                placeholder=" "
                                                            />
                                                            <label htmlFor="name">Abono</label>
                                                        </div>
                                                    {/* )} */}
                                                    <div className="inputQuotesContainer">
                                                        <input
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            name="invoiceNumber"
                                                            id="invoiceNumber"
                                                            onChange={handleInvoiceNumber}
                                                            className="inputquotes"
                                                            placeholder=" "
                                                        />
                                                        <label htmlFor="name">Numero de factura</label>
                                                    </div>
                                                    <div className="inputQuotesContainer">
                                                        <input
                                                            type="datetime-local"
                                                            name="installment"
                                                            id="installment"
                                                            onChange={handleDate}
                                                            className="inputTimerQuotes"
                                                            placeholder=" "
                                                        />
                                                        <label htmlFor="name">Fecha de factura</label>
                                                    </div>

                                                    <div className="inputQuotesContainer">
                                                        <input
                                                            type="text"
                                                            // name="time"
                                                            // id="time"
                                                            onChange={handleTime}
                                                            className="textareaQuotes"
                                                            placeholder=" "
                                                        ></input>
                                                        <label htmlFor="quote">Tiempo de entrega</label>
                                                    </div>
                                                    <div className="inputQuotesContainer">
                                                        <textarea
                                                            rows="4"
                                                            cols="50"
                                                            name="observacion"
                                                            id="observacion"
                                                            onChange={handleObservation}
                                                            className="textareaQuotes textareaQuotesHeight"
                                                            placeholder=" "
                                                            value={observation}
                                                        ></textarea>
                                                        <label htmlFor="quote observationHeigth">Escriba aquí su observación</label>
                                                    </div>
                                                </div>
                                                <div className='itemPricesContainer'>
                                                    <div className='rightQuotes'>
                                                        <p className='matText'>Total: {formatter.format(priceEnd)}</p>
                                                    </div>
                                                    <div className='leftQuotes'>
                                                        <p className='matText'>Debe: {formatter.format(operationMatRest)}</p>
                                                    </div>
                                                </div>
                                                <div className="buttonContainerAddQuotes">
                                                    {(optionsDataClient === 'maker') ? (
                                                        <>
                                                            <NavLink className='Textregister' to="/Cotizaciones"><button className="button neon_Effect"><p>Volver</p></button></NavLink>
                                                            <NavLink onClick={uploadProductMaker} className='Textregister'><button className="button neon_Effect"><p>Cotizar</p></button></NavLink>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NavLink className='Textregister' to="/Cotizaciones"><button className="button neon_Effect"><p>Volver</p></button></NavLink>
                                                            <NavLink onClick={uploadProduct} className='Textregister'><button className="button neon_Effect"><p>Cotizar</p></button></NavLink>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>)}
                    </>
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddQuotes
