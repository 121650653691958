import { useEffect, useState, useContext } from "react"
import { AiTwotoneDelete } from 'react-icons/ai';
import Nav1 from '../../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import { urlApiServer } from '../../Config/Config'
import '../../styles/AddQuotes.css'
import { AiFillEdit } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
import LoginContext from '../../Context/Logincontext';
import moment from 'moment';
import { CgMaximize } from 'react-icons/cg'
import { GrClose } from "react-icons/gr"
import ProductsIn3djs from '../ProductsIn3djs/ProductsIn3djs';
import CloseButton from "../CloseButton";
import LogoComponent from "../LogoComponent";
import AddProductGeneric from "./AddProductGeneric";
import AddProductMarmol from "./AddProductMarmol";
import EditProductGeneric from "./EditProductGeneric";


let saveProduct = []
let saveExtradate = []
let saveItemPrice = []
const initialValue = 0;
const initialValue2 = 0;
let saveQuantityItem = []
let precioitem = []
let sumaTotal = []
let historyValidation = []
let oldData = 0;
let oldIvaVAlidation = 0;
let oldIvaSave = 0
let ivaProdut = 0;
let splitImg = []
let ivaMatOperation = []
let oldIva = 0;



const EditQuotes = (props) => {
    const urlActualizarcotizacion = urlApiServer + "editQuotes";
    const urlgethistoryProduct = urlApiServer + "edithistoryProduct";
    const urlGetProduct = urlApiServer + "products";
    const urlgetConfig = urlApiServer + "getConfig";
    const urledit = urlApiServer + "editHistorialProducto";

    const [date, setDate] = useState(props.pdf.fecha ? moment(props.pdf.fecha).format('YYYY-MM-DDTHH:mm') : null)
    const [seller, setSeller] = useState(props.pdf.asesor)
    const [citizenshipCard, setCitizenshipCard] = useState(props.pdf.nitocc)
    const [direction, setDirection] = useState(props.pdf.direccion)
    const [contact, setContact] = useState(props.pdf.contacto)
    const [phone, setPhone] = useState(props.pdf.telefono)
    const [client, setClient] = useState(props.pdf.cliente)
    const [product, setProduct] = useState(null)
    const [material, setMaterial] = useState(null)
    const [caliber, setCaliber] = useState(null)
    const [long, setLong] = useState(null)
    const [width, setWidth] = useState(null)
    const [weight, setWeight] = useState(null)
    const [priceByWeight, setPriceByWeight] = useState(null)
    const [quantityItem, setQuantityItem] = useState(null)
    const [showButton, setShowButton] = useState(false);
    const [logo, setLogo] = useState("")
    const [itemPrice, setItemPrice] = useState(null)
    const [payment, setPayment] = useState(props.pdf.abono)
    const [email, setEmail] = useState()
    const [operationMatMultiplications, setOperationMatMultiplications] = useState(props.pdf.total)
    const [productG, setProductG] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState(props.pdf.nrofactura)
    const [Imagen, setImagen] = useState([])
    const [Imagetest, setImageTest] = useState("")
    const [priceEnd, setPriceEnd] = useState(props.pdf.preciofinal)
    const [itemEnd, setItemEnd] = useState()
    const [historyProduct, setHistoryProduct] = useState(props.historyResponse)
    const [deletoglee, setDeletogle] = useState(false)
    const [edit, setEdit] = useState();
    const [updateView, setUpdateView] = useState(true);
    const [productGetTable, setProductGetTable] = useState([])
    const [indexProduct, setIndexProduct] = useState('pendiente')
    const [priceIva, setPriceIva] = useState()
    const [observation, setObservation] = useState(props.pdf.observation)
    const [rest, setRest] = useState()
    const [priceOldIva, setPriceOldIva] = useState()
    const [iva, setIva] = useState("")
    const [editClientInQuotes, setEditClientInQuotes] = useState(false)
    const [oldConfig, setOldConfig] = useState("")
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [hasImage, setHasImage] = useState(false);
    const [isFullScreenComputer, setFullScreenComputer] = useState(false);
    const [isFullScreen, setFullScreen] = useState(false);
    const [newupdateView, setNewUpdateView] = useState(true);
    const [reload, setReload] = useState(false)
    const [typeDocument, setTypeDocument] = useState(props.pdf.typedocument)
    const [time, setTime] = useState(props.pdf.tiempo)

    const [id, setId] = useState()

    const toggleFullScreen = () => {
        setFullScreen(!isFullScreen);
        closeFullscreen()
        setId()
        setEdit()
    };

    const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const openFullscreen = () => {
        const element = document.documentElement;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
        setFullScreenComputer(true);
    };

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            if (isFullScreenComputer) {
                document.exitFullscreen();
            }
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setFullScreenComputer(false);
    };

    const toggleFullScreenComputer = () => {
        console.log("isFullScreenComputer: ", isFullScreenComputer)
        if (isFullScreenComputer) {
            closeFullscreen();
        } else {
            openFullscreen();
        }
    };

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())

    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);
            }
        }
    }, [oldConfig, infoUser])


    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.pathname);
            Swal.fire({
                title: '¡Atención!',
                text: 'No puedes retroceder en esta página.',
                icon: 'warning',
                confirmButtonText: 'OK',
            });
        };
    }, []);

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlGetProduct, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setProductGetTable(quotesResponse);
                })
                .catch(error => {
                });

        }())

    }, [])

    useEffect(() => {
        productGetTable.map((info, index) => {
            if (product === info.producto) {
                setIndexProduct(index)
                setMaterial(productGetTable[index].material)
                setLong(productGetTable[index].largo)
                setWidth(productGetTable[index].ancho)
                setCaliber(productGetTable[index].calibre)
                setItemPrice(productGetTable[index].precioitem)
                setIva(productGetTable[index].iva)
            }
        })
        if (seller == '') {
            setSeller(infoUser.name)
        }
    }, [product])

    useEffect(() => {
        const precioFinalRecivido = ivaMatOperation.reduce(
            (operationMatMultiplications, saveItemPrice) => operationMatMultiplications + saveItemPrice,
            initialValue
        );
        setProductG(precioFinalRecivido)
        setPriceIva(productG)
        const additionItemPrice = sumaTotal.reduce(
            (operationMatMultiplications, saveItemPrice) => operationMatMultiplications + saveItemPrice,
            initialValue
        );
        setItemEnd(additionItemPrice)
        const result = priceIva - parseFloat(payment);
        setRest(result);
        setEmail(props.pdf.correo)
    }, [productG])

    async function off() {

        Swal.fire({
            icon: 'warning',
            title: '¿Estás seguro de que quieres volver atrás?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                historyValidation = []
                precioitem = []
                sumaTotal = []
                ivaMatOperation = []
                props.edit()
                props.function()
            }
        })
    }

    useEffect(() => {
        historyValidation = []
        historyProduct.map((history) => {
            if (props.pdf.nrocotizacion == history.nrocotizacion) {
                historyValidation.push(history)

                sumaTotal.push(parseFloat(history.cantidadItem))
                ivaMatOperation.push(parseFloat(history.total))
                precioitem.push(parseFloat(history.cantidadItem) * parseFloat(history.precioitem))
            }
        })
    }, [])

    async function saveQuotes(data) {
        saveProduct.push(data)
        const totalPrice = saveProduct.reduce((acc, item) => acc + item.total, 0);
        setPriceIva(totalPrice + priceIva)

    }

    const urlsubirPreview = urlApiServer + "Uploadfilepreview";

    async function sendfilePreview(file) {
        try {
            const formdata = new FormData();

            formdata.append("file", file);

            formdata.append(file.name, "foto");
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(urlsubirPreview, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    async function editQuotes(data) {
        if (edit === 'Edit') {
            const edit = JSON.stringify({
                Id: data.id,
                empresa: data.empresa,
                producto: data.producto,
                material: data.material,
                caliber: data.caliber,
                long: data.long,
                ancho: data.ancho,
                precioitem: data.precioitem,
                cantidadItem: data.cantidadItem,
                nrocotizacion: historyValidation[id].nrocotizacion,
                total: data.total,
                Priceiva: data.Priceiva,
                iva: data.iva,
                image_3d: (infoUser.companyType === "Generica") ? (null) : (data.image_3d.name),
                image_2d: (infoUser.companyType === "Generica") ? (null) : (data.image_2d.name),
                price: data.price,
                modeljson: data.modeljson,
            })
            const ActualizarHeaders = new Headers();
            ActualizarHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'PUT',
                headers: ActualizarHeaders,
                body: edit,
                redirect: 'follow'
            }

            fetch(urledit, requesoption).then(response => response.json())
                .then(res => {
                    if (infoUser.companyType === "Marmol") {
                        sendfilePreview(data.image_3d)
                        sendfilePreview(data.image_2d)
                    }
                    if ('Actualizado producto' === res.msj) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Dato editado',
                            html: 'Los datos se han guardado exitosamente.',
                            showCancelButton: true,
                            focusConfirm: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        });
                        props.function()
                    }
                })
            let newProductArray = {
                "Id": historyValidation[id].Id,
                "producto": data.producto,
                "material": data.material,
                "calibre": data.caliber,
                'largo': data.long,
                'ancho': data.ancho,
                'precio': data.price,
                'cantidadItem': data.cantidadItem,
                "precioitem": data.precioitem,
                "iva": data.iva,
                "total": data.total,
                "modeljson": JSON.stringify(data.modeljson),
                "nrocotizacion": historyValidation[id].nrocotizacion,
                'image_3d': data.image_3d,
                'image_2d': data.image_2d
            }
            oldData = historyValidation[id].total
            oldIvaVAlidation = historyValidation[id].iva
            let updateArray = historyValidation.slice();
            updateArray[id] = newProductArray;
            historyValidation = updateArray;

            const currentPrice = priceIva
            const newPrice = currentPrice + parseFloat(historyValidation[id].total);
            setPriceIva(newPrice);
            const updatedPrice = newPrice - oldData;
            setPriceIva(updatedPrice);
        } else {
            oldData = saveProduct[id].total
            oldIvaSave = saveProduct[id].iva
            let updateArraySaveProduct = saveProduct.slice();
            updateArraySaveProduct[id] = data;
            saveProduct = updateArraySaveProduct;

            const currentPrice = priceIva;
            const newPrice = currentPrice + parseFloat(saveProduct[id].total);
            setPriceIva(newPrice);
            const updatedPrice = newPrice - oldData;
            setPriceIva(updatedPrice);
        }
    }

    useEffect(() => {
        setRest(priceIva - payment)
    }, [priceIva, payment])

    async function handleDate(e) {
        const date = e.target.value;
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        setDate(formattedDate);
    }

    const formatSellerName = (name) => {
        const words = name.split(' ');

        const formattedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1).toLowerCase();
            return `${firstLetter}${restOfWord}`;
        });

        return formattedWords.join(' ');
    }

    async function handleSeller(e) {
        const seller = e.target.value.replace(/[^A-Z a-z]/g, "");
        const formattedValue = formatSellerName(seller);
        setSeller(formattedValue)
    }

    async function handleCitizenshipCard(e) {
        const citizenshipCard2 = e.target.value.replace(/[^\d-_]/g, '');
        e.target.value = citizenshipCard2;
        setCitizenshipCard(citizenshipCard2)
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
    }

    async function handleContact(e) {
        const contact = e.target.value
        setContact(contact)
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }

    const formatClientName = (name) => {
        const words = name.split(' ');

        const formattedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1).toLowerCase();
            return `${firstLetter}${restOfWord}`;
        });

        return formattedWords.join(' ');
    }

    async function handleClient(e) {
        const client = e.target.value.replace(/[^A-Z a-z]/g, "");
        const formattedValueClient = formatClientName(client);
        setClient(formattedValueClient)
    }

    async function handleProduct(e) {
        const product = e.target.value
        setProduct(product)
    }

    async function handleMaterial(e) {
        const material = e.target.value
        setMaterial(material)
    }

    async function handleCaliber(e) {
        const inputVal = e.target.value;
        const regex = /^[0-9., /"]*$/;
        if (inputVal === '' || regex.test(inputVal)) {
            setCaliber(inputVal);
        }
    }

    async function handleLong(e) {
        const long = e.target.value
        setLong(long)
    }

    async function handleWidth(e) {
        const width = e.target.value
        setWidth(width)
    }

    async function handleWeight(e) {
        const weight = e.target.value
        setWeight(weight)
    }

    useEffect(() => {
        const matMultiplications = itemPrice * weight
        setPriceByWeight(matMultiplications)
    }, [itemPrice, weight])

    async function handleQuantityItem(e) {
        const quantityItem = e.target.value
        setQuantityItem(quantityItem)
    }

    useEffect(() => {
        const matMultiplications = priceByWeight * quantityItem
        setOperationMatMultiplications(matMultiplications)
    }, [priceByWeight, quantityItem])

    async function handleItemPrice(e) {
        const itemPrice = e.target.value
        setItemPrice(itemPrice)
    }

    async function handlepayment(e) {
        const payment = e.target.value
        setPayment(payment)
        const matRest = priceIva - payment
        setRest(matRest)
    }


    async function handleEmail(e) {
        const email = e.target.value
        setEmail(email)
    }

    async function handleInvoiceNumber(e) {
        const invoiceNumber = e.target.value
        setInvoiceNumber(invoiceNumber)
    }

    async function handleType(e) {
        const iva = e.target.value
        setIva(iva)
    }

    async function handleObservation(e) {
        const observation = e.target.value
        console.log("Observation: ", observation.length)
        if (observation.length >= 500) {
            Swal.fire({
                icon: 'warning',
                text: 'solo se aceptan 500 caracteres como maximo',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
            }).then((result) => { })
        } else {
            setObservation(observation)
        }

    }

    async function handleTime(e) {
        const times = e.target.value
        setTime(times)
    }

    useEffect(() => {
        setPriceOldIva(Math.round(operationMatMultiplications * (1 + iva * 0.01)))
    }, [operationMatMultiplications, iva, itemPrice])

    async function handleImagen(e) {
        let ImageArray = []
        let ImageArrayTest = []
        const Image = e.target.files
        for (const property in Image) {
            ImageArray.push(Image[property])
            ImageArrayTest.push(Image[property].name)
        }
        ImageArray.pop()
        ImageArray.pop()
        ImageArrayTest.pop()
        ImageArrayTest.pop()

        const ImageString = ImageArrayTest.toString()
        setImageTest(ImageString)
        setHasImage(ImageArray.length > 0);

        setImagen(ImageArray)
    }

    async function handleTypeDocument(e) {
        const documentType = e.target.value
        // if (/^[A-Za-z.\s]+$/.test(client) || client === '') {
        setTypeDocument(documentType)
        // clientText = client
        // }

    }

    async function update() {
        let validateNew = false
        if (editClientInQuotes === true) {
            const data = JSON.stringify({
                empresa: infoUser.company,
                nrocotizacion: props.pdf.nrocotizacion,
                cliente: client,
                documento: citizenshipCard,
                contacto: contact,
                telefono: phone,
                direccion: direction,
                correo: email,
                vendedor: seller,
                abono: payment,
                factura: invoiceNumber,
                tiempo: date,
                imagen: Imagetest,
                preciofinal: priceIva,
                itemfinal: itemEnd,
                debe: rest,
                observation: observation,
                typeDocument: typeDocument,
                time: time
            })
            const ActualizarHeaders = new Headers();
            ActualizarHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'PUT',
                headers: ActualizarHeaders,
                body: data,
                redirect: 'follow'
            }

            fetch(urlActualizarcotizacion, requesoption).then(response => response.json())
                .then(data => {
                    if ('Actualizado Cotizacion' === data.msj) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Felicidades',
                            text: 'Has Creado una nueva cotizacion',
                            confirmButtonText: 'Aceptar',
                        }).then((resultConfirm) => {
                            if (resultConfirm.isConfirmed) {
                                saveProduct = []
                                historyValidation = []
                                precioitem = []
                                sumaTotal = []
                                ivaMatOperation = []
                                window.location.reload();
                                props.edit()
                            }
                        })
                    }
                    saveProduct.map((item) => {
                        const Product = JSON.stringify({
                            empresa: infoUser.company,
                            nrocotizacion: item.nrocotizacion,
                            producto: item.producto,
                            material: item.material,
                            caliber: item.caliber,
                            long: item.long,
                            width: item.ancho,
                            precioitem: item.precioitem,
                            cantidadItem: item.cantidadItem,
                            total: item.total,
                            iva: item.iva,
                            Priceiva: item.priceIva,
                            image_3d: (infoUser.companyType === "Generica") ? (null) : (item.image_3d.name),
                            image_2d: (infoUser.companyType === "Generica") ? (null) : (item.image_2d.name),
                            modeljson: item.modeljson,
                            price: (infoUser.companyType === "Generica") ? (item.precio) : (item.price),
                            unit: (infoUser.companyType === "Generica") ? (item.unit) : (null)
                        })
                        const registerHeaders2 = new Headers();
                        registerHeaders2.append("Content-Type", "application/json")

                        const requesoption2 = {
                            method: 'PUT',
                            headers: registerHeaders2,
                            body: Product,
                            redirect: 'follow'
                        }

                        fetch(urlgethistoryProduct, requesoption2).then(response => response.json())
                            .then(dataResponse => {
                                if (infoUser.companyType === "Marmol") {
                                    sendfilePreview(item.image_3d)
                                    sendfilePreview(item.image_2d)
                                }
                                if (300 === dataResponse.status) {
                                    validateNew = true
                                } else if (560 === dataResponse.status) {
                                    validateNew = false
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error!',
                                        text: 'Algo salio mal',
                                    })
                                }
                            })
                    })
                    if (validateNew) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Felicidades',
                            text: 'Has editado una cotizacion',
                            confirmButtonText: 'Aceptar',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                saveProduct = []
                                historyValidation = []
                                precioitem = []
                                sumaTotal = []
                                ivaMatOperation = []
                                props.edit()
                            }
                        })
                    }

                })
            validateNew = false
        } else {

            const data = JSON.stringify({
                empresa: infoUser.company,
                nrocotizacion: props.pdf.nrocotizacion,
                cliente: client ? client : props.pdf.cliente,
                documento: citizenshipCard ? citizenshipCard : props.pdf.nitocc,
                contacto: contact ? contact : props.pdf.contacto,
                telefono: phone ? phone : props.pdf.telefono,
                direccion: direction ? direction : props.pdf.direccion,
                correo: email ? email : props.pdf.correo,
                vendedor: seller ? seller : props.pdf.asesor,
                abono: payment,
                factura: invoiceNumber,
                tiempo: date,
                imagen: Imagetest,
                preciofinal: priceIva,
                itemfinal: itemEnd,
                debe: rest,
                observation: observation,
                typeDocument: typeDocument,
                time: time

            })
            const ActualizarHeaders = new Headers();
            ActualizarHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'PUT',
                headers: ActualizarHeaders,
                body: data,
                redirect: 'follow'
            }

            fetch(urlActualizarcotizacion, requesoption).then(response => response.json())
                .then(data => {
                    if ('Actualizado Cotizacion' === data.msj) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Felicidades',
                            allowOutsideClick: false,
                            text: 'Has editado una cotizacion',
                            confirmButtonText: 'Aceptar',
                        }).then((resultConfirm) => {
                            if (resultConfirm.isConfirmed) {
                                saveProduct = []
                                historyValidation = []
                                precioitem = []
                                sumaTotal = []
                                ivaMatOperation = []
                                window.location.reload();
                                props.edit()
                            }
                        })
                    }
                    // console.log("historyValidation: ", historyValidation)
                    // console.log("saveProduct: ", saveProduct)
                    saveProduct.map((item) => {

                        const Product = JSON.stringify({
                            empresa: infoUser.company,
                            nrocotizacion: props.pdf.nrocotizacion,
                            producto: item.producto,
                            material: item.material,
                            caliber: item.caliber,
                            long: item.long,
                            width: item.ancho,
                            precioitem: item.precioitem,
                            cantidadItem: item.cantidadItem,
                            total: item.total,
                            iva: item.iva,
                            Priceiva: item.priceIva,
                            image_3d: (infoUser.companyType === "Generica") ? (null) : (item.image_3d.name),
                            image_2d: (infoUser.companyType === "Generica") ? (null) : (item.image_2d.name),
                            modeljson: item.modeljson,
                            price: (infoUser.companyType === "Generica") ? (item.precio) : (item.price),
                            unit: (infoUser.companyType === "Generica") ? (item.unit) : (null),

                        })
                        const registerHeaders2 = new Headers();
                        registerHeaders2.append("Content-Type", "application/json")

                        const requesoption2 = {
                            method: 'POST',
                            headers: registerHeaders2,
                            body: Product,
                            redirect: 'follow'
                        }

                        fetch(urlgethistoryProduct, requesoption2).then(response => response.json())
                            .then(dataResponse => {
                                if (infoUser.companyType === "Marmol") {
                                    sendfilePreview(item.image_3d)
                                    sendfilePreview(item.image_2d)
                                }

                                if (300 === dataResponse.status) {
                                    validateNew = true
                                } else if (560 === dataResponse.status) {
                                    validateNew = false
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error!',
                                        text: 'Algo salio mal',
                                    })
                                }
                            })
                    })
                    if (validateNew) {

                        Swal.fire({
                            icon: 'success',
                            title: 'Felicidades',
                            text: 'Has editado una cotizacion',
                            allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                            confirmButtonText: 'Aceptar',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                saveProduct = []
                                historyValidation = []
                                precioitem = []
                                sumaTotal = []
                                ivaMatOperation = []
                                props.edit()
                            }
                        })
                    }
                })
            validateNew = false
        }
    }
    useEffect(() => {
        setDeletogle(true)
    }, [deletoglee])

    useEffect(() => {
    }, [historyProduct])
    useEffect(() => {
    }, [historyValidation])

    useEffect(() => {
        if (updateView === false) {
            setUpdateView(true)
        }
    }, [updateView])

    function returnNormalScreen() {
        setFullScreen(false)
    }

    async function priceEndFunction(totalPrice) {
        setPriceIva(totalPrice)
    }

    const reloadMarmol = async (id, save) => {
        setId(id)
        // saveProduct = save


        // if (reload) {
        //     setNewUpdateView(false);
        //     setReload(false);

        // } else {
        //     setNewUpdateView(true);
        //     setReload(true);

        // }
        // toggleFullScreen()
        setFullScreen(!isFullScreen);


    }

    const reloadEdit = async (item) => {
        // saveProduct = item
        historyValidation = item
        const totalPrice = historyValidation.reduce((acc, item) => acc + parseFloat(item.total), 0);
        // props.priceEndFunction(totalPrice)
        setPriceIva(totalPrice)


        if (reload) {
            setNewUpdateView(false);
            setReload(false);

        } else {
            setNewUpdateView(true);
            setReload(true);

        }
    }

    const reloadAdd = async (item) => {
        saveProduct.push(item)
        // historyValidation = item

        if (reload) {
            setNewUpdateView(false);
            setReload(false);

        } else {
            setNewUpdateView(true);
            setReload(true);

        }
    }

    return (
        <>
            {(isFullScreen === true || isFullScreenComputer === true) ? (
                <div className="fullscreen-overlay">
                    <div className='optionsWindowEditor'>
                        <NavLink onClick={toggleFullScreenComputer}><CgMaximize className='CgMaximizeInMinimize' /></NavLink>
                        <NavLink onClick={toggleFullScreen}><GrClose className='GrCloseInMinimize' /></NavLink>
                    </div>
                    <div>
                        <ProductsIn3djs function={saveQuotes} editQuotes={editQuotes} returnNormalScreenFuction={returnNormalScreen} id={id} saveProduct={saveProduct[id]} edit={edit} modeljson={historyValidation[id]} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="containerHeardANDTable">
                        <div className="HeardANDFilter">
                            <LogoComponent />
                            <div className='containerButtonAdd'>
                                <div className="navigationContainer">
                                    <div className="pageTitle">EDITAR COTIZACIÓN</div>
                                </div>
                            </div>
                            <div className='closeIntContainer'>
                                {/* <NavLink onClick={Cerrar} className='closeButton'>
                                    <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                    <ImExit className='iconNav' />
                                </NavLink> */}
                                <CloseButton />
                            </div>
                        </div>
                    </div>
                    <div id='overallContainer' className='overallContainer'>
                        <Nav1 />
                        <div className='drawerFormAddCuotes'>
                            <form className='ContainerAddCuotes'>
                                <div className='containerQuotes'>
                                    <div className='infoOneQuotes'>
                                        <p className='texProducttitule'>DATOS CLIENTE - VENDEDOR</p>
                                        <div className='containeQuotestAdd'>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    value={client}
                                                    autocomplete="off"
                                                    name="client"
                                                    id="client"
                                                    onChange={handleClient}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Nombre del cliente</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    name="client"
                                                    // id="client"
                                                    onChange={handleTypeDocument}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                    list="drawfemails2"
                                                    value={typeDocument}
                                                />
                                                <label htmlFor="name">Tipo de documento <text className='Obligatory'>*</text></label>
                                                <datalist id="drawfemails2" >
                                                    <option value="">Selecciona una opcion:</option>
                                                    <option value="NIT">NIT</option>
                                                    <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                    <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                    <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                </datalist>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    value={citizenshipCard}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="CitizenshipCard"
                                                    id="CitizenshipCard"
                                                    onChange={handleCitizenshipCard}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Numero de documento</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    onWheel={(e) => e.target.blur()}
                                                    name="Contact"
                                                    value={contact}
                                                    id="Contact"
                                                    onChange={handleContact}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Numero de Celular</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    value={phone}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="phone"
                                                    id="phone"
                                                    onChange={handlePhone}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Numero de telefono</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    name="direction"
                                                    value={direction}
                                                    id="direction"
                                                    onChange={handleDirection}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Dirección</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    name="Email"
                                                    id="Email"
                                                    value={email}
                                                    onChange={handleEmail}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Correo electronico</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    name="seller"
                                                    id="seller"
                                                    onChange={handleSeller}
                                                    className="inputquotes"
                                                    value={seller}
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Nombre del vendedor</label>
                                            </div>
                                        </div>
                                        <div className='containerButtonAddlist'>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {
                                (infoUser.companyType === "Generica") ? (
                                    <EditProductGeneric saveProduct={saveProduct} edit="Edit" historyValidation={historyValidation} saveItemPrice={saveItemPrice} oldData={oldData} oldIva={oldIva} toggleFullScreen={toggleFullScreen} ReloadEdit={reloadEdit} ReloadAdd={reloadAdd} priceEndFunction={priceEndFunction} />
                                ) : (
                                    <form className='ContainerAddCuotes'>
                                        <div className='containerQuotes'>
                                            <div className='infoOneQuotes'>
                                                <p className='texProducttitule'>PRODUCTOS</p>
                                                <div className="containerTable">
                                                    <table className="minimal-table">
                                                        <tr>
                                                            <th>Productos</th>
                                                            <th>Material</th>
                                                            <th>Calibre</th>
                                                            <th>Largo</th>
                                                            <th>Ancho</th>
                                                            <th>Precio Unidad</th>
                                                            <th>Cantidad</th>
                                                            <th>Total</th>
                                                            <th>Iva</th>
                                                            <th>Total + iva</th>
                                                            <th>Eliminar</th>
                                                            <th>Editar</th>
                                                        </tr>
                                                        {historyValidation.map((product, index) => {
                                                            const urleliminar = urlApiServer + "borrarHistorialProducto";

                                                            async function Eliminar() {
                                                                Swal.fire({
                                                                    title: '¿Está seguro?',
                                                                    text: "Esta acción no se puede deshacer.",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Sí, eliminar',
                                                                    cancelButtonText: 'Cancelar'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        // Código a ejecutar si el usuario hace clic en Aceptar
                                                                        const data = JSON.stringify({
                                                                            empresa: infoUser.company,
                                                                            Id: historyValidation[index].Id,
                                                                        })
                                                                        const eliminarHeaders = new Headers();
                                                                        eliminarHeaders.append("Content-Type", "application/json")

                                                                        const requesoption = {
                                                                            method: 'DELETE',
                                                                            headers: eliminarHeaders,
                                                                            body: data,
                                                                            redirect: 'follow',
                                                                        }

                                                                        fetch(urleliminar, requesoption).then(response => response.json())
                                                                            .then(data => {
                                                                                if ('Borrar Productos' === data.msj) {
                                                                                    Swal.fire({
                                                                                        icon: 'success',
                                                                                        title: 'Producto Eliminado',
                                                                                        text: 'Has eliminado este dato correctamente',
                                                                                    })
                                                                                    props.function()
                                                                                    setPriceIva(priceIva - historyValidation[index].total)
                                                                                    let deleteProductArray = historyValidation.slice();
                                                                                    if (index > -1) {
                                                                                        deleteProductArray.splice(index, 1);
                                                                                    }
                                                                                    historyValidation = deleteProductArray;
                                                                                }
                                                                            })
                                                                    }
                                                                })
                                                            }
                                                            async function Edit1(index) {
                                                                oldData = historyValidation[index].total
                                                                oldIvaVAlidation = historyValidation[index].iva
                                                                setId(index)
                                                                setFullScreen(true)
                                                                setEdit('Edit')
                                                            }

                                                            return (
                                                                <tr>
                                                                    <td>{product.producto}</td>
                                                                    <td>{product.material}</td>
                                                                    <td>{product.calibre}</td>
                                                                    <td>{product.largo}</td>
                                                                    <td>{product.ancho}</td>
                                                                    <td>${Math.round(product.precio)}</td>
                                                                    <td>{product.cantidadItem}</td>
                                                                    <td>${Math.round(product.precioitem)}</td>
                                                                    <td>{product.iva}%</td>
                                                                    <td>${Math.round(product.total)}</td>
                                                                    <td><AiTwotoneDelete className='deleteButtonListProduct' onClick={() => { Eliminar(index) }} /></td>
                                                                    <td><AiFillEdit className='iconEdit' onClick={() => { Edit1(index) }} /></td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {saveProduct.map((product, index) => {
                                                            async function saveProducts(index) {

                                                                Swal.fire({
                                                                    icon: 'question',
                                                                    text: 'estás seguro que deseas eliminar este item?',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Aceptar',
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        setDeletogle(true)
                                                                        if (deletoglee === true) {

                                                                            saveItemPrice = []
                                                                            setPriceIva(priceIva - saveProduct[index].priceIva)
                                                                            saveItemPrice.push(priceIva - saveProduct[index].priceIva)
                                                                            delete saveProduct[index]
                                                                            saveProduct = saveProduct.filter(function (el) {
                                                                                return el != null;
                                                                            });
                                                                            setDeletogle(false)

                                                                        }
                                                                        async function Edit(index) {
                                                                            oldData = saveProduct[index].total
                                                                            oldIva = saveProduct[index].iva
                                                                            setId(index)
                                                                            setFullScreen(true)
                                                                        }
                                                                    }
                                                                })

                                                            }
                                                            async function Edit(index) {
                                                                oldData = saveProduct[index].Priceiva
                                                                oldIva = saveProduct[index].iva
                                                                setId(index)
                                                                setFullScreen(true)
                                                            }


                                                            return (
                                                                <tr>
                                                                    <td>{product.producto}</td>
                                                                    <td>{product.material}</td>
                                                                    <td>{product.caliber}</td>
                                                                    <td>{product.long}</td>
                                                                    <td>{product.ancho}</td>
                                                                    <td>${Math.round(product.price)}</td>
                                                                    <td>{product.cantidadItem}</td>
                                                                    <td>${Math.round(product.precioitem)}</td>
                                                                    <td>{product.iva}%</td>
                                                                    <td>${Math.round(product.total)}</td>
                                                                    <td><AiTwotoneDelete className='deleteButtonListProduct' onClick={() => { saveProducts(index) }} /></td>
                                                                    <td><AiFillEdit className='iconEdit' onClick={() => { Edit(index) }} /></td>

                                                                </tr>
                                                            )
                                                        })}
                                                    </table>

                                                </div>
                                                <div className='buttonContainerAddQuotes2'>
                                                    <NavLink onClick={toggleFullScreen} className='Textregister'><button className="buttonQuotes neon_Effect"><p>Agregar Producto</p></button></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </form>)
                            }

                            <form className='ContainerAddCuotes'>
                                <div className='containerQuotes2'>
                                    <div className='infoOneQuotes'>
                                        <p className='texProducttitule'>COTIZACIÓN</p>
                                        <div className='containeQuotestAdd'>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    name="payment"
                                                    id="payment"
                                                    onChange={handlepayment}
                                                    autocomplete="off"
                                                    className="inputquotes"
                                                    value={payment}
                                                    placeholder=" "
                                                />
                                                <label htmlFor="name">Abono</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    name="invoiceNumber"
                                                    id="invoiceNumber"
                                                    onChange={handleInvoiceNumber}
                                                    className="inputquotes"
                                                    placeholder=" "
                                                    value={invoiceNumber}
                                                />
                                                <label htmlFor="name">Numero de factura</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="datetime-local"
                                                    name="installment"
                                                    id="installment"
                                                    onChange={handleDate}
                                                    className="inputTimerQuotes"
                                                    placeholder=" "
                                                    value={date}
                                                />
                                                <label htmlFor="name">Fecha de factura</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <input
                                                    type="text"
                                                    // name="time"
                                                    // id="time"
                                                    onChange={handleTime}
                                                    className="textareaQuotes"
                                                    placeholder=" "
                                                    value={time}
                                                ></input>
                                                <label htmlFor="quote">Tiempo de entrega</label>
                                            </div>
                                            <div className="inputQuotesContainer">
                                                <textarea
                                                    rows="4"
                                                    cols="50"
                                                    name="observacion"
                                                    id="observacion"
                                                    onChange={handleObservation}
                                                    className="textareaQuotes textareaQuotesHeight"
                                                    placeholder=" "
                                                    value={observation}
                                                ></textarea>
                                                <label htmlFor="quote observationHeigth">Escriba aquí su observación</label>
                                            </div>
                                        </div>
                                        <div className='itemPricesContainer'>

                                            {(showButton === false) ? (
                                                <>
                                                    <div className='rightQuotes'>
                                                        <p className='matText'>Total: {formatter.format(priceIva)}</p>
                                                    </div><div className='leftQuotes'>
                                                        <p className='matText'>Debe: {formatter.format(rest)}</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='rightQuotes'>
                                                    <p className='matText'>Para visualizar el total y el cuanto debe, por favor seleccione el botón de recarga</p>
                                                    <p className='matText'>que se encuentra en la tabla de productos.</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="buttonContainerAddQuotes">
                                            <NavLink className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                                            <NavLink className='Textregister'><button className="button neon_Effect" onClick={update}><p>Actualizar</p></button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    )

}

export default EditQuotes