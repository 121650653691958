import { useContext, useEffect, useState } from "react"
import LoginContext from "../Context/Logincontext"
import Nav1 from '../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import "./../styles/AddProduct.css"
import { urlApiServer } from '../Config/Config'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
import Resizer from 'react-image-file-resizer';
import Loader from "../Component/Loader"
import CloseButton from "../Component/CloseButton"
import LogoComponent from "../Component/LogoComponent"


let splitImg = []

const AddTexture = () => {

    const urlTexure = urlApiServer + "addTextures";
    const urlgetConfig = urlApiServer + "getConfig";
    const urlsubir = urlApiServer + "UploadfileTexture";

    const [texture, setTexture] = useState(null)
    const [Long, setLong] = useState(null)
    const [width, setWidth] = useState(null)
    const [price, setPrice] = useState(null)
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [hasImage, setHasImage] = useState(false);
    const [image, setImage] = useState([])
    const [imageTest, setImageTest] = useState("")
    const [disabledBottom, setDisabledBottom] = useState(false);

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }


    async function handleTexture(e) {
        const texture = e.target.value
        setTexture(texture)
    }

    async function handleLong(e) {
        const Long = e.target.value
        setLong(Long)
    }

    async function handleWidth(e) {
        const width = e.target.value
        setWidth(width)
    }

    async function handlePrice(e) {
        const price = e.target.value
        setPrice(price)
    }

    async function uploadProduct() {


        if (texture === null) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Nombre de material" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if (imageTest === "") {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor escoge una imagen antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if (Long === null) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Largo" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if (width === null) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Ancho" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        if (price === null) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: 'Por favor llena el campo de "Valor" antes de continuar',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
            })
        }

        const data = JSON.stringify({
            empresa: infoUser.company,
            texture: texture,
            image: imageTest,
            Long: Long,
            width: width,
            price: price
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        await fetch(urlTexure, requesoption).then(response => response.json())
            .then(dataResponse => {
                // image.map((file) => {
                sendfile(image)
                // })
                if (300 === dataResponse.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Felicidades',
                        text: 'Has creado un nuevo material',
                        allowOutsideClick: false,
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = './Texturas';
                        }
                    })
                }
            })
    }


    async function sendfile(file) {
        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    async function handleImagen(e) {
        setDisabledBottom(true)
        const image = e.target.files[0]
        try {
            Resizer.imageFileResizer(
                image,
                1000, // nuevo ancho
                1000, // nuevo alto
                'JPEG', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    // console.log("compressedFile: ", compressedFile)
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    // console.log("compressedImageURL: ", compressedImageURL)
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    // console.log("Url blob: ", blob)
                    const file = new File([blob], timestamp + "_" + image.name, { type: "image/png" });
                    // console.log("file: ", file)
                    setImageTest(file.name)
                    setHasImage(file !== undefined ? true : false);
                    setImage(file)
                    setDisabledBottom(false)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage(false);
        }
    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    <>
                        <div className="containerHeardANDTable">
                            <div className="HeardANDFilter">
                                <LogoComponent />
                                <div className='containerButtonMaker'>
                                    <div className="navigationContainer">
                                        <div className="pageTitle">CREAR MATERIAL</div>
                                    </div>
                                </div>
                                <div className='filterContainer'>
                                    <div className="containerFilter">
                                    </div>
                                </div>
                                <div className='closeIntContainer'>
                                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                        <ImExit className='iconNav' />
                                    </NavLink> */}
                                    <CloseButton />

                                </div>
                            </div>
                        </div>
                        {
                            (disabledBottom) && (
                                <div className="backgroundLoader">
                                    <Loader />
                                </div>


                            )
                        }
                        <div id="overallContainer" className="overallContainer">
                            <Nav1 />
                            <div className="drawerFormAddCuotes">
                                <form className="ContainerProduct">
                                    <div className="containerInfoProduct">
                                        <div className="infoTwoAddProduct">
                                            <div className="containeProductAdd">
                                                <div className="inputProductContainer">
                                                    <input
                                                        type="text"
                                                        name="Product"
                                                        id="Product"
                                                        onChange={handleTexture}
                                                        className="inputProducts"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre del material</label>
                                                </div>
                                                <div className={`file-upload ${hasImage ? 'has-image' : ''}`}>
                                                    <label for="file-input" class="upload-label">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                        <span className="upload-text">{hasImage ? 'Material almacenado' : 'Haz clic para cargar un materia'}</span>
                                                    </label>
                                                    <input id="file-input" onChange={handleImagen} type="file" class="file-input" />
                                                    <div class="progress-bar"></div>
                                                </div>
                                                <div className="inputProductContainer">
                                                    <input
                                                        type="text"
                                                        name="Product"
                                                        id="Product"
                                                        onChange={handleLong}
                                                        className="inputProducts"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Largo (m)</label>
                                                </div>
                                                <div className="inputProductContainer">
                                                    <input
                                                        type="text"
                                                        name="Product"
                                                        id="Product"
                                                        onChange={handleWidth}
                                                        className="inputProducts"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Ancho (cm)</label>
                                                </div>
                                                <div className="inputProductContainer">
                                                    <input
                                                        type="text"
                                                        name="Product"
                                                        id="Product"
                                                        onChange={handlePrice}
                                                        className="inputProducts"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Valor</label>
                                                </div>
                                            </div>
                                            <div className="buttonContainerProduct">
                                                <NavLink className="Textregister" to="/Texturas">
                                                    <button className="button neon_Effect">
                                                        <p>Atrás</p>
                                                    </button>
                                                </NavLink>
                                                <NavLink onClick={uploadProduct} className="Textregister">
                                                    <button className="button neon_Effect" disabled={disabledBottom}>
                                                        <p>Agregar</p>
                                                    </button>
                                                </NavLink>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddTexture