import React, { useEffect, useState } from 'react'
import logo from '../Assest/LogoAppcotizaTransp.png'
import { NavLink } from 'react-router-dom'
import { urlApiServer } from '../Config/Config'
import Swal from 'sweetalert2'
import invisible from '../Assest/invisibleKey.png'
import visible from '../Assest/visibleKey.png'

const ForgottenPassword = () => {

    const [email, setEmail] = useState()
    const [verificationCode, setVerificationCode] = useState(false);
    const [code, setCode] = useState('');
    const [validCheck, setValidCheck] = useState('');
    const [visibleKey1, setVisibleKey1] = useState(true)
    const [visibleKey2, setVisibleKey2] = useState(true)
    const [newPassword, setNewPassword] = useState()
    const [confirmNewPassword, setConfirmNewPassword] = useState()
    const [counter, setCounter] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const urlRecoveryPassword = urlApiServer + "recoverPassword"
    const urlcodechecking = urlApiServer + "codeChecking"
    const urlchangerPassword = urlApiServer + "changerPassword"

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handleValidatingEmail = async (e) => {
        // console.log(users[0].correo)
        // console.log(email)
        // const foundUser = users.find((user) => user.correo === email);
        // if (foundUser) {
        // const code = generateVerificationCode(); // Generar código único
        // setSentCode(code);
        // console.log('este correo si existe: ', code)
        const recoverPassword = JSON.stringify({
            email: email,
        })

        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: recoverPassword,
            redirect: 'follow'
        }

        await fetch(urlRecoveryPassword, requesoption).then(response => response.json())
            .then(recoverPasswordResponse => {
                console.log('recoverPasswordResponse', recoverPasswordResponse)
                if (recoverPasswordResponse.msj === 'invalid email') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Lo sentimos no pudimos encotrar este correo',
                    })
                } else {
                    Swal.fire({
                        title: 'Codigo de verificacion',
                        text: 'hemos enviado un codigo de verificacion para que puedas cambiar tu contraseña',
                    })
                    setVerificationCode(true);
                }
            })
        // } else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Lo sentimos no pudimos encotrar este correo',
        //     })
        // }
    }

    const handleVerificationCode = e => {
        setCode(e.target.value);
    };

    const handleNewPassword = e => {
        setNewPassword(e.target.value);
    };

    const handleCheckNewPassword = e => {
        setConfirmNewPassword(e.target.value);
    };

    const handleVerifyCode = async () => {
        const recoverPassword = JSON.stringify({
            email: email,
            code: code
        })

        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: recoverPassword,
            redirect: 'follow'
        }

        await fetch(urlcodechecking, requesoption).then(response => response.json())
            .then(codeResponse => {
                console.log('codeResponse: ', codeResponse)
                if (codeResponse.msj === 'Código correcto') {
                    Swal.fire({
                        title: 'Codigo de verificacion',
                        text: 'Tu codigo de verificacion es correcta, ahora puedes cambiar tu contraseña',
                    })
                    setValidCheck(true)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos',
                        text: 'Lo sentimos el codigo ingresado no es el correcto o el codigo ingresado ya no esta disponible',
                    })
                    setValidCheck(false)
                }
            })
    };

    const handleChangerPassword = async () => {
        if (newPassword === confirmNewPassword) {
            const changerPassword = JSON.stringify({
                email: email,
                newPassword: newPassword,
                checkNewPassword: confirmNewPassword
            })

            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const requestOptions = {
                method: 'PUT',
                headers: registerHeaders,
                body: changerPassword,
                redirect: 'follow'
            }

            fetch(urlchangerPassword, requestOptions)
                .then(response => response.json())
                .then(changerPasswordResponse => {
                    console.log('changerPasswordResponse: ', changerPasswordResponse)
                    if (changerPasswordResponse.msj === 'password changed') {
                        Swal.fire({
                            icon: "success",
                            // title: "Good job!",
                            text: "Has cambiado tu contraseña correctamente",
                        }).then((result) => {
                            // Redireccionar a la página de inicio de sesión después de cerrar el modal
                            window.location.replace('./Login');
                        }).catch((err) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'ha ocurrido un error con la redireccion al inicio de sesion',
                            })
                        });

                    }
                })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Las contraseñas no coinciden',
            })
        }
    }


    const startTimer = () => {
        setIsButtonDisabled(true);
        setCounter(60);
        const interval = setInterval(() => {
            setCounter(prevCounter => {
                if (prevCounter === 0) {
                    clearInterval(interval);
                    setIsButtonDisabled(false);
                    return 0;
                }
                return prevCounter - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        if (counter === 0) {
            setIsButtonDisabled(false);
        }
    }, [counter]);


    return (
        <>
            <div className="containerLogoLoginAdd">
                <p className='titleWithoutCompany'><img src={logo} alt='Logo' className='logoAppCotiza' /></p>
            </div>
            {(validCheck) ? (
                <div id='overallContainerLogin' className='overallContainerLogin imageBackground'>
                    <div className='containerGifAndLogin'>
                        <div className='containerLoginRight'>
                            <div className='containerMainLogin'>
                                <div className='drawerFormLogin'>
                                    <p className='titleWithoutCompany'>Recuperar Contraseña</p>
                                    <form className='ContainerProfile' autoComplete="off">
                                        {!verificationCode && (
                                            <div className="inputUsersContainer">
                                                <input
                                                    type={visibleKey1 ? 'password' : 'text'}
                                                    name="password"
                                                    id="password"
                                                    onChange={handleNewPassword}
                                                    className="inputUsers"
                                                    placeholder=" "
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="name">Contraseña</label>
                                                <NavLink onClick={() => { visibleKey1 ? setVisibleKey1(false) : setVisibleKey1(true) }}><img src={visibleKey1 ? invisible : visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                                            </div>
                                        )}


                                        <div className="inputUsersContainer">
                                            <input
                                                type={visibleKey1 ? 'password' : 'text'}
                                                name="password"
                                                id="password"
                                                onChange={handleNewPassword}
                                                className="inputUsers"
                                                placeholder=" "
                                                autoComplete="off"
                                            />
                                            <label htmlFor="name">Contraseña</label>
                                            <NavLink onClick={() => { visibleKey1 ? setVisibleKey1(false) : setVisibleKey1(true) }}><img src={visibleKey1 ? invisible : visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                                        </div>

                                        <div className="inputUsersContainer">
                                            <input
                                                type={visibleKey2 ? 'password' : 'text'}
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                onChange={handleCheckNewPassword}
                                                autoComplete="off"
                                                className="inputUsers"
                                                placeholder=" "
                                            />
                                            <label htmlFor="name">Confirmar contraseña</label>
                                            <NavLink onClick={() => { visibleKey2 ? setVisibleKey2(false) : setVisibleKey2(true) }}><img src={visibleKey2 ? invisible : visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                                        </div>
                                    </form>
                                    <div className="buttonContainer">
                                        <NavLink to="/Login" className="button neon_EffectLogin"><p>Volver</p></NavLink>
                                        <button onClick={handleChangerPassword} className="button neon_EffectLogin"><p>Actualizar</p></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div id='overallContainerLogin' className='overallContainerLogin imageBackground'>
                    <div className='containerGifAndLogin'>
                        <div className='containerLoginRight'>
                            <div className='containerMainLogin'>
                                <div className='drawerFormLogin'>
                                    <p className='titleWithoutCompany'>Recuperar Contraseña</p >
                                    <form className='ContainerProfile' autoComplete="off">
                                        <div className="inputLoginContainer">
                                            <input
                                                type="email"
                                                name="user-email"
                                                id="user-email"
                                                onChange={handleEmail}
                                                className="inputLogin"
                                                placeholder=" "
                                                autoComplete="off"
                                                readOnly={verificationCode}
                                            />
                                            <label htmlFor="email">Correo electrónico</label>
                                        </div>
                                        {verificationCode && (
                                            <>
                                                <div className="inputLoginContainer">
                                                    <input
                                                        type="text"
                                                        onChange={handleVerificationCode}
                                                        value={code}
                                                        placeholder="Código de verificación"
                                                        className="inputLogin"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </form>
                                    {
                                        verificationCode && (
                                            <button
                                                onClick={handleVerifyCode}
                                                className="button neon_EffectLogin">Verificar</button>
                                        )
                                    }
                                    <div className="buttonContainer">
                                        <NavLink to="/Login" className="button neon_EffectLogin"><p>Volver</p></NavLink>
                                        {!verificationCode ? (
                                            <button onClick={() => { startTimer(); handleValidatingEmail(); }} className="button neon_EffectLogin"><p>Enviar</p></button>
                                        ) : (
                                            <button onClick={isButtonDisabled ? null : () => { startTimer(); handleValidatingEmail(); }} className={`${isButtonDisabled ? "button neon_EffectLogin2" : "button neon_EffectLogin"}`}>
                                                {isButtonDisabled ? `Espere ${counter}` : "Enviar"}
                                            </button>
                                        )}
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            )}
        </>
    )
}

export default ForgottenPassword