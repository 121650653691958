
export const convertJson = (props) => {
    return {
        "nro": props.nro,
        "company": props.company,
        "nitocc": props.nit,
        "client": props.company,
        "typedocument": props.typeIdentification,
        "document": props.identification,
        "address": props.address,
        "phone": props.phone,
        "city": props.city,
        "date": "21-04-2014",
        "items": props.products,
        "bankAccount": props.bankAccount,
        "bank": props.bank,
        "positionCompany": props.positionCompany,
        "socialSecurity": "8956425050",
        "companyClient": props.companyClient,
        "nameClient": props.nameClient,
        "signature": props.signature,
        "manure": props.manure,
        "nitClient": props.nitocc,
        "billPage": props.billPage,
    }
}