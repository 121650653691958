import Nav1 from '../Component/Nav/Nav1'
import React, { useContext, useEffect, useState } from 'react'
import { columnsClient, columnsClientAdmin } from '../Component/Hooks/ColumTables'
import LoginContext from '../Context/Logincontext';
import { urlApiServer } from '../Config/Config'
import Table from '../Component/Tabla/Tabla';
import "../styles/Users.css"
import EditClient from '../Component/Hooks/EditClient';
import { NavLink } from 'react-router-dom';
import { ImExit } from 'react-icons/im'
import CloseButton from '../Component/CloseButton';
import LogoComponent from '../Component/LogoComponent';

let splitImg = []

const Client = () => {

    const urlgetClient = urlApiServer + "client"
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [clientGetTable, setClientGetTable] = useState([])
    const [powerEdit, setPowerEdit] = useState(true)
    const [state, setState] = useState("0")
    const [id, setId] = useState()
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const urlgetConfig = urlApiServer + "getConfig";

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }


    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlgetClient, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setClientGetTable(quotesResponse.reverse());
                })
                .catch(error => {
                });
        }())

    }, [state])

    async function handleName() {
        if (state === "0") {
            setState("1")
        } else {
            setState("0")
        }
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    async function edit() {
        if (powerEdit === true) {
            setPowerEdit(false)
        } else {
            setPowerEdit(true)
        }
    }

    async function handleData(e) {
        setId(e)
    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    (powerEdit === true) ? (
                        <>

                            {(infoUser.role === 'Administrador') ? (
                                <>
                                    <Table columns={columnsClientAdmin} data={clientGetTable} edit={edit} function={handleName} id={handleData} />
                                </>
                            ) : (
                                <>
                                    <Table columns={columnsClient} data={clientGetTable} edit={edit} function={handleName} id={handleData} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="containerHeardANDTable">
                                <div className="HeardANDFilter">
                                    <LogoComponent />
                                    <div className='containerButtonAdd'>
                                        <div className="navigationContainer">
                                            <div className="pageTitle">EDITAR CLIENTE</div>
                                        </div>
                                    </div>
                                    <div className='closeIntContainer'>
                                        {/* <NavLink onClick={Cerrar} className='closeButton'>
                                            <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                            <ImExit  className='iconNav'/>
                                        </NavLink> */}
                                        <CloseButton />

                                    </div>
                                </div>
                            </div>
                            <div id='overallContainer' className='overallContainer'>
                                <Nav1 />
                                <EditClient data={clientGetTable} id={id} function={handleName} edit={edit} />
                            </div>
                        </>
                    )
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )
}
export default Client