import '../styles/Quotes.css'
import { columnsQuotes, columnsQuotesAdmin } from '../Component/Hooks/ColumTables'
import Table from '../Component/Tabla/Tabla'
import { useContext, useEffect, useState } from 'react'
import LoginContext from '../Context/Logincontext';
import { urlApiServer } from '../Config/Config'
import Pdf from '../Component/Hooks/PdfMarmol/Pdf';
import EditQuotes from '../Component/Hooks/EditQuotes'
import Remission from '../Component/Hooks/Remission'
import Loader from '../Component/Loader';
import GenericPdf from '../Component/Hooks/PdfGeneric/GenericPdf';
import Contribution from '../Component/Hooks/Bill/Contribution';
import { convertJson } from '../Component/Hooks/Bill/functions/ConvertJson';

let splitImg = []
let splitSignature = []

const Quotes = () => {

    const urlGetClient = urlApiServer + "historyproduct"
    const urlgetquotes = urlApiServer + "quotes"
    const urlgetConfig = urlApiServer + "getConfig";
    const urlGetRemission = urlApiServer + "getRemission"
    const urlGetCompany = urlApiServer + "company"

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [quotesGetTable, setQuotesGetTable] = useState([])
    const [filterTable, setFilterTable] = useState([])
    const [state, setState] = useState("0")
    const [powerEdit, setPowerEdit] = useState(true)
    const [powerandoff, setPowerandoff] = useState(true)
    const [remissionValidate, setRemissionValidate] = useState(true)
    const [historyProduct, setHistoryProduct] = useState([])
    const [dataImportant, setDataImportant] = useState()
    const [id, setId] = useState()
    const [oldConfig, setOldConfig] = useState("")
    const [signature, setSignature] = useState("")
    const [logo, setLogo] = useState("")
    const [dataRemission, setDateRemission] = useState([])
    const [company, setCompany] = useState([])
    const [disabledBottom, setDisabledBottom] = useState(false);
    const [showBill, setShowBill] = useState(false);
    const [dataBill, setDataBill] = useState({});


    useEffect(() => {
        (async function () {

            if (infoUser.login !== true) {
                window.location.href = './Login'
            }

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())


    }, [])


    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo, signature } = foundConfig;
                setLogo(logo);
                setSignature(signature)
            }
        }
    }, [oldConfig, infoUser])


    if (logo) {
        splitImg = logo.split("|")
    }

    if (signature) {
        splitSignature = signature.split("|")
    }

    useEffect(() => {
        (async function () {
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlgetquotes, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setQuotesGetTable(quotesResponse.reverse());
                })
                .catch(error => {
                });

            const InicioHeaders2 = new Headers();
            InicioHeaders2.append("Content-Type", "application/json");
            InicioHeaders2.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption2 = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            };

            await fetch(urlGetClient, requesoption2)
                .then(response => response.json())
                .then(quotesResponse => {

                    setHistoryProduct(quotesResponse);
                })
                .catch(error => {
                });

            const InicioHeaders3 = new Headers();
            InicioHeaders3.append("Content-Type", "application/json");
            InicioHeaders3.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption3 = {
                method: 'GET',
                headers: InicioHeaders3,
                redirect: 'follow'
            };

            await fetch(urlGetRemission, requesoption3)
                .then(response1 => response1.json())
                .then(remission => {
                    setDateRemission(remission.reverse());
                });

            const InicioHeaders4 = new Headers();
            InicioHeaders4.append("Content-Type", "application/json");
            InicioHeaders4.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption4 = {
                method: 'GET',
                headers: InicioHeaders4,
                redirect: 'follow'
            };

            // await fetch(urlGetCompany, requesoption4)
            //     .then(response => response.json())
            //     .then(company => {
            //         setCompany(company.reverse());
            //     });
        }())

    }, [state, urlGetClient, urlgetquotes, powerEdit])


    async function Cerrar() {
        if (Cerrar) {
            setInfoUser({
                login: false
            })
        } else {
        }
    }


    useEffect(() => {
    }, [filterTable])

    async function handleName() {
        if (state === "0") {
            setState("1")
        } else {
            setState("0")
        }
    }

    async function power() {
        // setDisabledBottom(true)
        if (powerandoff === true) {
            setPowerandoff(false)
        } else {
            setPowerandoff(true)
        }
    }

    async function remission() {
        if (remissionValidate === true) {
            setRemissionValidate(false)
        } else {
            setRemissionValidate(true)
        }
    }

    async function edit() {
        if (powerEdit === true) {
            setPowerEdit(false)
        } else {
            setPowerEdit(true)
        }
    }

    async function handleData(e) {
        setDataImportant(quotesGetTable[e])
    }

    async function handleId(e) {
        setId(e)
    }

    async function handleBill(_) {
        console.log("_: ", _)
        let historyValidation = []
        historyProduct.map((history) => {
            if (_.nrocotizacion == history.nrocotizacion) {
                historyValidation.push(history)
            }
        })
        let data = {
            nro: _.nrocotizacion,
            name: infoUser.name,
            company: infoUser.company,
            identification: infoUser.identification,
            typeIdentification: infoUser.typeIdentification,
            nit: infoUser.nit,
            address: infoUser.address,
            phone: infoUser.phone,
            city: infoUser.city,
            bankAccount: infoUser.bankAccount,
            bank: infoUser.bank,
            positionCompany: infoUser.positionCompany,
            products: historyValidation,
            manure: _.abono,
            signature: splitSignature[0],
            companyClient: _.cliente,
            nitocc: _.nitocc,
            billPage: false
        }
        await setDataBill(convertJson(data))
        setId(_.nrocotizacion)
        handleShowBill()
    }

    async function handleShowBill(e) {
        setShowBill(!showBill)
    }

    async function showLoader(data1, data2) {
        setId(data2)
        setDataImportant(quotesGetTable[data1])
        setDisabledBottom(true)
    }

    async function dontShowLoader() {
        setDisabledBottom(false)
    }

    useEffect(() => {
        if (disabledBottom) {
            if (powerandoff === true) {
                setPowerandoff(false)
            } else {
                setPowerandoff(true)
            }
        }
    }, [disabledBottom])

    return (
        <>
            {
                (infoUser.companyType === "Marmol") && (
                    (disabledBottom) && (
                        <div className="backgroundLoader">
                            <Loader />
                        </div>
                    )
                )
            }
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    (!showBill) ? (
                        (powerandoff === true) ? (
                            (powerEdit === true) ? (
                                (remissionValidate === true) ? (
                                    <>

                                        {(infoUser.role === 'Administrador') ? (
                                            <>

                                                <Table columns={columnsQuotesAdmin} data={quotesGetTable} function={handleName} power={power} edit={edit} remission={remission} importantData={handleData} id={handleId} showLoader={showLoader} product={historyProduct} handleShowBill={handleBill} />
                                            </>
                                        ) : (
                                            <>
                                                <Table columns={columnsQuotes} data={quotesGetTable} function={handleName} power={power} edit={edit} remission={remission} importantData={handleData} id={handleId} showLoader={showLoader} product={historyProduct} />
                                            </>
                                        )}
                                    </>
                                ) : (<Remission pdf={dataImportant} remission={remission} dataRemission={dataRemission} historyResponse={historyProduct} logo={splitImg} signature={splitSignature} id={id} company={company} />)
                            ) : (<EditQuotes pdf={dataImportant} edit={edit} function={handleName} historyResponse={historyProduct} type="edit" id={id} />)
                        ) : (
                            (infoUser.companyType === "Generica") ? (<GenericPdf pdf={dataImportant} power={power} historyResponse={historyProduct} logo={splitImg} signature={splitSignature} id={id} dontShowLoader={dontShowLoader} />) : (<Pdf pdf={dataImportant} power={power} historyResponse={historyProduct} logo={splitImg} signature={splitSignature} id={id} dontShowLoader={dontShowLoader} />)
                        )
                    ) : (<Contribution data={dataBill} infoComapny={infoUser} historyResponse={historyProduct} signature={splitSignature} handleShowBill={handleShowBill}/>)
                )
            ) : (<></>)}
        </>
    )
}

export default Quotes