import React, { useContext } from 'react';
import { ImExit } from 'react-icons/im';
import LoginContext from '../../Context/Logincontext';
import { useNavigate } from 'react-router-dom';
import { urlApiServer } from '../../Config/Config';

const LogoComponent = () => {
    const { infoUser, setInfoUser } = useContext(LoginContext)

    const navigate = useNavigate();


    function returnHome() {
        navigate('/Cotizaciones');
    }

    return (
        <div className="logoIntContainer" onClick={returnHome}>
            <img src={urlApiServer + "Image/" + infoUser.logo} alt="Logo" className='logoAdd' />
        </div>
    );
}

export default LogoComponent