import { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import Swal from 'sweetalert2'
import invisible from '../Assest/invisibleKey.png'
import visible from '../Assest/visibleKey.png'
import '../styles/addCompany.css'
import LoginContext from "../Context/Logincontext"
import { urlApiServer } from "../Config/Config"
import { ImExit } from 'react-icons/im'
import CloseButton from "../Component/CloseButton"
import { TbGeometry } from 'react-icons/tb'

const AddCompany = () => {

    const urlCreate = urlApiServer + "createCompany";
    const urlGetUser = urlApiServer + "users"
    const urlGetCompanys = urlApiServer + "getCompanys";
    const urlGetCompanysData = urlApiServer + "getUserCompany";
    const urlUpdateCompanysData = urlApiServer + "updateUserCompany";

    const [name, setName] = useState()
    const [surNames, setSurnames] = useState()
    const [type, setType] = useState(null)
    const [numbreDocument, setNumbreDocument] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [position, setPosition] = useState('')
    const [emailValidation, setEmailValidation] = useState("");
    const [password, setPassword] = useState()
    const [checkPassword, setCheckpassword] = useState()
    const [companyName, setCompanyName] = useState()
    const [companyNit, setCompanyNit] = useState()
    const [companyPhone, setCompanyPhone] = useState()
    const [companyCity, setCompanyCity] = useState()
    const [companyAddres, setCompanyAddres] = useState()
    const [companyType, setCompanyType] = useState()
    const [companyAccountBank, setCompanyAccountBank] = useState('')
    const [companyBank, setCompanyBank] = useState()
    const [companyId, setCompanyId] = useState(null)
    const [userId, setUserId] = useState(null)

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [visibleKey1, setVisibleKey1] = useState(true)
    const [visibleKey2, setVisibleKey2] = useState(true)
    const [userGetTable, setUserGetTable] = useState([])
    const [companys, setCompanys] = useState([])
    const [company, setCompany] = useState(null)
    const [edit, setEdit] = useState(false)




    useEffect(() => {
        (async function () {

            console.log("Info user: ", position)

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlGetCompanys, requesoption).then(response => response.json()).then(quotesResponse => {
                console.log("Respuesta de companys: ", quotesResponse)
                setCompanys(quotesResponse)
            })
        }())
    }, [edit])

    async function handleName(e) {
        const name = e.target.value
        if (name.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Nombre muy largo'
            })
        } else {
            await setName(name)
        }
    }
    async function handleSurName(e) {
        const surNames = e.target.value
        if (surNames.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Apillos muy largo'
            })
        } else {
            await setSurnames(surNames)
        }
    }
    async function handleType(e) {
        const type = e.target.value
        if (type === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Seleccione un tipo de documento'
            })
        } else {
            await setType(type)
        }
    }

    async function handleTypeCompany(e) {
        const type = e.target.value
        if (type === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Seleccione un tipo de empresa'
            })
        } else {
            await setCompanyType(type)
        }
    }

    async function handleNumberDocument(e) {
        const numbreDocument = e.target.value
        if (surNames.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Documento muy larga'
            })
        } else {
            await setNumbreDocument(numbreDocument)
        }
    }

    async function handlePhone(e) {
        const phone = e.target.value
        if (phone.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Celular es muy largo'
            })
        } else {
            await setPhone(phone)
        }
    }
    async function handlePosition(e) {
        // const position = e.target.value
        // if (position.length > 30) {
        //     Swal.fire({
        //         icon: 'warning',
        //         text: 'Describelo en una palabra'
        //     })
        // } else {
            setPosition(e.target.value)
        // }
    }
    async function handleEmail(e) {
        const email = e.target.value
        const validation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
        if (validation.test(email)) {
            setEmailValidation("correo valido")
            setEmail(email);
        } else {
            setEmailValidation("correo invalido")
        }
    }
    async function handlePassword(e) {
        const password = e.target.value
        if (password.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Contraseña muy larga'
            })
        } else {
            await setPassword(password)
        }
    }
    async function handleCheckPassword(e) {
        const checkPassword = e.target.value
        if (checkPassword === password) {
            setCheckpassword(checkPassword);
        }
    }
    async function handleNameCompany(e) {
        const companyName = e.target.value.replace(/\s+/g, '_');
        await setCompanyName(companyName)
    }
    async function handleCompanyNit(e) {
        const companyNit = e.target.value.replace(/[^0-9-_]/g, '');
        setCompanyNit(companyNit);
    }
    async function handleCity(e) {
        const companyCity = e.target.value;
        setCompanyCity(companyCity);
    }
    async function handleCompanyPhone(e) {
        const companyPhone = e.target.value
        await setCompanyPhone(companyPhone)
    }

    async function handleCompanyAddres(e) {
        const addres = e.target.value
        await setCompanyAddres(addres)
    }

    async function handleAccountBank(e) {
        const accountBank = e.target.value
        await setCompanyAccountBank(accountBank)
    }

    async function handleBank(e) {
        const Bank = e.target.value
        await setCompanyBank(Bank)
    }

    async function handleCompany(e) {
        let valueType = e.target.value
        // console.log("valueType: ", valueType)
        // if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
        setCompany(valueType)
        // }
    }

    // useEffect(() => {
    //     (async function () {

    //         const InicioHeaders = new Headers();
    //         InicioHeaders.append("Content-Type", "application/json");
    //         // InicioHeaders.append("company", infoUser.company);

    //         const requesoption = {
    //             method: 'GET',
    //             headers: InicioHeaders,
    //             redirect: 'follow'
    //         }

    //         await fetch(urlGetUser, requesoption)
    //             .then(response => response.json())
    //             .then(quotesResponse => {
    //                 setUserGetTable(quotesResponse);
    //             })
    //             .catch(error => {
    //                 // console.log(error);
    //             });


    //     }())
    //     // console.log("rfffnnf",infoUser)

    // }, [])

    async function Registerprofile() {
        console.log('Entrando Registerprofile')
        const adminExists = userGetTable.some(user => user.correo === email);
        if (adminExists) {
            Swal.fire({
                icon: 'warning',
                title: 'Lo sentimos',
                text: 'No fue posible continuar con la creacion debido a que el usuario ya existe.'
            });
            return;
        }
        if (checkPassword === password) {
            if (emailValidation === "correo valido") {
                const data = JSON.stringify({
                    nameCompany: companyName,
                    companyNit: companyNit,
                    companyPhone: companyPhone,
                    companyMobile: companyPhone,
                    companyAddres: companyAddres,
                    companyCity: companyCity,
                    companyAccountBank: companyAccountBank,
                    companyBank: companyBank,
                    name: name,
                    surNames: surNames,
                    type: type,
                    numbreDocument: numbreDocument,
                    phone: phone,
                    email: email,
                    position: position,
                    password: password,
                    checkPassword: checkPassword,
                    companyType: companyType,
                    rol: "Administrador"
                })
                const registerHeaders = new Headers();
                registerHeaders.append("Content-Type", "application/json")

                const requesoption = {
                    method: 'POST',
                    headers: registerHeaders,
                    body: data,
                    redirect: 'follow'
                }
                await fetch(urlCreate, requesoption)
                    .then(response => {
                        console.log("RESPONSE = ", response)
                        if (response.status === 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Registro correcto',
                                text: 'has registrado correctamente una empresa',
                                confirmButtonText: 'Aceptar',
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error!',
                                text: response.message,
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Tu correo esta en un formato invalido',
                    confirmButtonText: 'Aceptar',
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Las claves no coinciden',
                confirmButtonText: 'Aceptar',
            })
        }
    }

    async function updateCompanyAndUser() {
        console.log('Entrando Registerprofile')
                const data = JSON.stringify({
                    nameCompany: companyName,
                    companyNit: companyNit,
                    companyPhone: companyPhone,
                    companyMobile: companyPhone,
                    companyAddres: companyAddres,
                    companyCity: companyCity,
                    companyAccountBank: companyAccountBank,
                    companyBank: companyBank,
                    companyId: companyId,
                    name: name,
                    surNames: surNames,
                    type: type,
                    numbreDocument: numbreDocument,
                    phone: phone,
                    email: email,
                    position: position,
                    password: password,
                    checkPassword: checkPassword,
                    companyType: companyType,
                    userId: userId,
                    rol: "Administrador"
                })
                const registerHeaders = new Headers();
                registerHeaders.append("Content-Type", "application/json")

                const requesoption = {
                    method: 'PUT',
                    headers: registerHeaders,
                    body: data,
                    redirect: 'follow'
                }
                await fetch(urlUpdateCompanysData, requesoption)
                    .then(response => {
                        console.log("RESPONSE = ", response)
                        if (response.status === 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Ediccion correcta',
                                text: response.msj,
                                confirmButtonText: 'Aceptar',
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error!',
                                text: response.message,
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });


    }

    async function findCompanyData() {
        console.log('Entrando Registerprofile')
        const adminExists = userGetTable.some(user => user.correo === email);
        const data = JSON.stringify({
            nameUser: infoUser.name,
            nameCompany: company,
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(urlGetCompanysData, requesoption)
              .then(response => response.json())
                .then(response => {
                    console.log("RESPONSE = ", response)
                    if (response.status === 200) {
                        console.log("response.data :" ,response.data.company[0])
                        // USER
                        setName(response.data.user[0].nombre)
                        setSurnames(response.data.user[0].apellidos)
                        setType(response.data.user[0].tipo)
                        setNumbreDocument(response.data.user[0].cc)
                        setEmail(response.data.user[0].correo)
                        setPhone(response.data.user[0].telefono)
                        setPosition(response.data.user[0].positionCompany)
                        setUserId(response.data.user[0].Id)
                        // COMPANY
                        setCompanyName(response.data.company[0].nameCompany.split("_")[1])
                        setCompanyNit(response.data.company[0].companyNit)
                        setCompanyCity(response.data.company[0].companyCity)
                        setCompanyPhone(response.data.company[0].companyPhone)
                        setCompanyAddres(response.data.company[0].companyAddress)
                        setCompanyType(response.data.company[0].companyType)
                        setCompanyAccountBank(response.data.company[0].companyAccountBank)
                        setCompanyBank(response.data.company[0].companyBank)
                        setCompanyId(response.data.company[0].id)

                        setEdit(!edit)
                        Swal.fire({
                            icon: 'success',
                            title: 'Comienza a editar',
                            text: response.msj,
                            confirmButtonText: 'Aceptar',
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: response.message,
                            confirmButtonText: 'Aceptar',
                        })
                    }
                }).catch(error => {
                console.log(error);
            });
    }


    return (
        <>
            <div className="containerLogoAdd containerLogoAddFixed">
                <NavLink to="/Menu_Configuracion" className='closeButton'>
                    <span className="tooltipProductMenos">Configuracion</span>
                    <TbGeometry className='iconNav' />
                </NavLink>
                <p className='titleWithoutCompany'>Crear empresa </p>
                <div className="inputClientContainer">
                    <input
                        type="text"
                        name="nitSelect"
                        id="nitSelect"
                        className="inputCLient"
                        placeholder=""
                        list="drawfemails2"
                        onChange={handleCompany}
                        value={(company === '') ? ('') : (company)}
                    />
                    <label htmlFor="name">Empresa: <em className='Obligatory'>*</em>  </label>
                    <datalist id="drawfemails2" value="nitSelect">
                        {/* <option value="NIT">NIT</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option> */}
                        {
                            companys.map((item) => {
                                return <option value={item.nameCompany} >{item.nameCompany.split("_")[1]}</option>
                            })
                        }
                    </datalist>
                    <div className="buttonContainer">
                        <div onClick={findCompanyData} className="button neon_EffectLogin"><p>Buscar para editar</p></div>
                    </div>
                </div>
                <div className='closeIntContainer'>
                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                        <ImExit  className='iconNav'/>
                    </NavLink> */}
                    <CloseButton />
                </div>
            </div>
            <div id='overallContainerLogin' className='overallContainerLogin'>
                <div className='drawerFormAddCuotes drawerFormAddCuotesAdmin'>
                    <form className='ContainerCompanyAddForm'>
                        <div className='containerInfo'>
                            <div className='infoOne'>
                                <p className='personalInformation'>Informacion del administrador</p>
                                <div className='containerNameClient'>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={handleName}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={name}
                                        />
                                        <label htmlFor="name">Nombre</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="surNames"
                                            id="surNames"
                                            onChange={handleSurName}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={surNames}
                                        />
                                        <label htmlFor="name">Apellidos</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <select
                                            name="Type"
                                            id="Type"
                                            onChange={handleType}
                                            className="inputSelectNit"
                                            value={type}
                                        >
                                            <option value="Type">Tipo de documento:</option>
                                            <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                            <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                            <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                        </select>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="citizenshipCard"
                                            id="citizenshipCard"
                                            onChange={handleNumberDocument}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={numbreDocument}
                                        />
                                        <label htmlFor="name">Numero del documento</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="Phone"
                                            id="Phone"
                                            onChange={handlePhone}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={phone}
                                        />
                                        <label htmlFor="name">Numero de Telefono</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            onWheel={(e) => e.target.blur()}
                                            name="Phone"
                                            id="Phone"
                                            onChange={handlePosition}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={position}
                                        />
                                        <label htmlFor="name">Puesto en la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleEmail}
                                            className="inputUsers"
                                            placeholder=" "
                                            autoComplete="off"
                                            value={email}
                                        />
                                        <label htmlFor="name">Correo electronico</label>
                                    </div>
                                    <div className="inputPasswordContainer">
                                        <input
                                            type={visibleKey1 ? 'password' : 'text'}
                                            name="password"
                                            id="password"
                                            onChange={handlePassword}
                                            className="inputPasswordText"
                                            placeholder=" "
                                            autoComplete="new-password"

                                        />
                                        <label htmlFor="name">Contraseña</label>
                                        <NavLink onClick={() => { visibleKey1 ? setVisibleKey1(false) : setVisibleKey1(true) }}><img src={visibleKey1 ? invisible : visible} alt="Key" className='password4'></img></NavLink>
                                    </div>
                                    <div className="inputPasswordContainer">
                                        <input
                                            type={visibleKey2 ? 'password' : 'text'}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            onChange={handleCheckPassword}
                                            className="inputPasswordText"
                                            placeholder=" "
                                            autoComplete="new-password"

                                        />
                                        <label htmlFor="name">Confirmar contraseña</label>
                                        <NavLink onClick={() => { visibleKey2 ? setVisibleKey2(false) : setVisibleKey2(true) }}><img src={visibleKey2 ? invisible : visible} alt="Key" className='password4'></img></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='infoTwo'>
                                <p className='personalInformation'>Informacion de la empresa</p>
                                <div className='containerContactClient'>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyName"
                                            id="companyName"
                                            onChange={handleNameCompany}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyName}
                                        />
                                        <label htmlFor="name">Nombre de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyNit"
                                            id="companyNit"
                                            onChange={handleCompanyNit}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyNit}
                                        />
                                        <label htmlFor="name">Nit de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyNit"
                                            id="companyNit"
                                            onChange={handleCity}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyCity}
                                        />
                                        <label htmlFor="name">Ciudad de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="CompanyPhone"
                                            id="CompanyPhone"
                                            onChange={handleCompanyPhone}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyPhone}
                                        />
                                        <label htmlFor="name">Telefono de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="CompanyAddres"
                                            id="CompanyAddres"
                                            onChange={handleCompanyAddres}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyAddres}
                                        />
                                        <label htmlFor="name">Direccion de la empresa</label>
                                    </div>

                                    <div className="inputUsersContainer">
                                        <select
                                            name="Type"
                                            id="Type2"
                                            onChange={handleTypeCompany}
                                            className="inputSelectNit"
                                            value={companyType}
                                        >
                                            <option value="Type">Tipo de empresa:</option>
                                            <option value="Marmol">Marmol</option>
                                            <option value="Generica">Generica</option>
                                        </select>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyNit"
                                            id="companyNit"
                                            onChange={handleAccountBank}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyAccountBank}
                                        />
                                        <label htmlFor="name">Numero de cuenta de banco</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyNit"
                                            id="companyNit"
                                            onChange={handleBank}
                                            className="inputUsers"
                                            placeholder=" "
                                            value={companyBank}
                                        />
                                        <label htmlFor="name">Banco de numero de cuenta</label>
                                    </div>
                                    <div className="buttonContainer">
                                        <div onClick={edit?updateCompanyAndUser:Registerprofile} className="button neon_EffectLogin"><p>{edit?"Editar":"Crear"}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AddCompany;