import React, { useState, useEffect, useContext } from "react"
import { NavLink } from "react-router-dom"
import { urlApiServer } from "../../../Config/Config"
import jsPDF from "jspdf"
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa'
import PlanosPdf from "./PlanosPdf"
import './style.css'
import LoginContext from "../../../Context/Logincontext"
import ModelPdf from "./modelPdf"

// import generarPDF from "./example1"

let DataForTheQuote = []
let counter = 0
const urlObservation = urlApiServer + "getFooter";

let arrayPlan2d = []

const Pdf = (props) => {
    let historyValidation = []

    const [historyProduct, setHistoryProduct] = useState(props.historyResponse)
    const [sizeMain, setSizeMain] = useState(1866)
    const [scale, setScale] = useState(1);
    const [disabledBottom, setDisabledBottom] = useState(false);
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [observation, setObservation] = useState("")

    const [toggle, setToggle] = useState("false");
    const [toggle2d, setToggle2d] = useState("false");
    const [showImage, setShowImage] = useState(false);
    const [Image2d, setImage2d] = useState([]);
    const [img2d, setimg2d] = useState(false)
    const [counterModels, setCounterModels] = useState(0)
    const [showSeparation, setShowSeparation] = useState(true)
    const [pdf, setPdf] = useState(null);
    const [reload, setReload] = useState(false)
    const [imgArr, setImgArr] = useState([])
    const [donwloadPdf, setDownloadPdf] = useState(false)
    const [loadPdf, setLoadPdf] = useState(true)
    const [modelId, setModelId] = useState(null)
    const [firtsObservation, setFirtsObservation] = useState("")



    let totalDivs = 450 + 35 + 32
    let ren = []
    let sizeHeader = 352.22
    let sizeFooter = 516.39
    const altoPdf = 1848
    let sizeTotalContainer = altoPdf
    let sizeItem = 127.5
    let sizeItemImage3d = 463 + 10
    let sizeItemImage2d = 890 + 110
    let sizeContainerImage = sizeItemImage3d + sizeItemImage2d
    let pageDelete = 1
    let i = 0
    let page = 1
    let total = sizeHeader
    let altoDiv = 0
    let lineaOrItem = "Item"
    let arrayImg = []

    async function off() {
        historyValidation = []
        props.power()
        DataForTheQuote = []
    }

    useEffect(() => {
        if (donwloadPdf) {
            createPDF()
        }
    }, [reload])

    useEffect(() => {

        (async function () {

            const newTextObservation = props.pdf.observation.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index !== props.pdf.observation.split('\n').length - 1 && <br />}
                </React.Fragment>
            ));
            setFirtsObservation(newTextObservation)
            // const InicioHeaders = new Headers();
            // InicioHeaders.append("Content-Type", "application/json")

            // console.log("reload: ", reload)
            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const data = JSON.stringify({
                empresa: infoUser.company,
            })

            const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            }
            await fetch(urlObservation, requesoption).then(response => response.json()).then(item => {
                // console.log("quotesResponse: ", item);
                if (item && item.footerpdf) {
                    const newText = item.footerpdf.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index !== item.footerpdf.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ));
                    setObservation(newText);
                }

            })
        }())
    }, [])

    function changeToggle() {
        setToggle("true"); // Cambia el estado de toggle a su opuesto
        setDownloadPdf(true)
        setDisabledBottom(true)
        setScale(1);
    }

    const getImage = async (img) => {
        arrayImg.push(img)
        if (arrayImg.length === historyValidation.length) {
            setImgArr(arrayImg)
            setReload(true)

        }
    }

    const getCounter = async (count) => {
        if (count === historyValidation.length - 1) {
            setTimeout(() => {
                props.dontShowLoader()
            }, 5000);
        }
    }


    const createPDF = async () => {

        var margin = 10;
        var doc = new jsPDF('p', 'pt', 'letter'); // 995.28 es la altura, 1000.89 es lo ancho
        var scale = (doc.internal.pageSize.width - margin * 2) / document.querySelector("#conect").scrollWidth;
        // setNoModel(true);

        doc.html(document.querySelector("#conect"), {
            x: margin,
            y: margin,
            html2canvas: {
                scale: scale,
            },
            callback: function (pdf) {
                pdf.save("Cotizacion.pdf");
                setDisabledBottom(false)
                setShowImage(false)
                setimg2d(false)
                setDownloadPdf(false)
                arrayImg = []
                setReload(!reload)
                setToggle("false");
            }
        });

    };

    const img = props.pdf.image;
    const logo = props.logo
    const signature = props.signature

    if (props.pdf.fecha === null) {
    } else {
        var deliveryDate = props.pdf.fecha;
        var dateDelivery = new Date(deliveryDate);

        var day = dateDelivery.getDate();
        var month = dateDelivery.getMonth() + 1;
        var year = dateDelivery.getFullYear();
        var hours = dateDelivery.getHours();
        var minutes = dateDelivery.getMinutes();

        var formattedDateDelivery = day + '/' + month + '/' + year + ', ' + hours + ':' + minutes;
    }


    var currentDate = props.pdf.fecha;
    var date = new Date(currentDate);
    var formattedDate = date.toLocaleString();
    const handleFontSizeChange = () => {
        setSizeMain(sizeMain + totalDivs)
    };

    historyProduct.map((history) => {
        if (props.id == history.nrocotizacion) {
            // console.log("Product: ", history)
            historyValidation.push(history)
        }
    })



    // const changeToggle = (item) => {
    //     setToggle2d(!toggle2d)
    // }

    function rendered() {

        ren.push(header)
        const content = (
            historyValidation.map((data, index) => {

                const model = JSON.parse(data.modeljson)
                const name = model.name
                let thickess = 0
                if (name === "Mesa en L") {
                    thickess = Math.abs((model.measure.thickness) / 10).toFixed(2)
                } else if (name === "Barra con salpicadero") {
                    thickess = Math.abs((model.measure.barThickness) / 10).toFixed(2)
                } else if (name === "Barra") {
                    thickess = Math.abs(model.measure.barThickness / 10).toFixed(2)
                } else {
                    thickess = Math.abs(model.measure.depth / 10).toFixed(2)
                }

                while (i < page) {
                    // console.log("total + sizeItem: ", total + sizeItem, " sizeTotalContainer: ", sizeTotalContainer)
                    if (total + sizeItem < sizeTotalContainer) {
                        lineaOrItem = "Item"
                        total = total + sizeItem
                    } else {
                        altoDiv = sizeTotalContainer - total
                        lineaOrItem = "linea"
                        sizeTotalContainer = sizeTotalContainer + altoPdf
                        total = total + altoDiv + 50 + sizeItem - 24
                        page = page + 1
                        altoDiv = Math.abs(altoDiv)
                    }

                    if (lineaOrItem === "linea") {
                        pageDelete++
                        return (<>
                            <div className="divSeparationMarmol" style={{ height: `${altoDiv}px`, marginBottom: "50px", borderBottom: (!reload) ? ("2px solid rgba(0, 0, 0, 0.274)") : ("2px solid rgba(0, 0, 0, 0)"), width: "100%" }}></div>
                            <div className="containerItem">
                                <div className="tableItemPdf3">
                                    <div className="itemNumberPdfHeard">ITEM</div>
                                    <div className="itemNumberPdf">{index + 1}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">DESCRIPCION:</div>
                                    <div className="itemNumberPdfDescription">{data.producto}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">MATERIAL:</div>
                                    <div className="itemNumberPdfDescription">{data.material}</div>
                                </div>
                                <div className="containerItemValue">
                                    <div id="tab_customers" className="tableItemPdf4">
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdf">GROSOR (M):</div>
                                            <div className="specificationsPdf">{(name === "Mesa redonda") ? ("DIAMETRO (M):") : ("LARGO (M):")}</div>
                                            <div className="specificationsPdf">ANCHO (M):</div>
                                            <div className="specificationsPdf" style={{ letterSpacing: '0.01px' }}>PRECIO ITEM:</div>
                                            <div className="specificationsPdf">IVA:</div>
                                        </div>
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfBody">{thickess}</div>
                                            <div className="specificationsPdfBody">{(name === "Mesa redonda") ? (Math.abs((model.measure.circleRadius * 2) / 10).toFixed(2)) : (data.largo)}</div>
                                            <div className="specificationsPdfBody">{data.ancho}</div>
                                            <div className="specificationsPdfBody">${parseFloat(data.precio).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                            <div className="specificationsPdfBody">{data.iva}%</div>
                                        </div>
                                    </div>
                                    <div id="tab_customers" className="tablePdf6">
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>CANTIDAD ITEM:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>SUB TOTAL:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>TOTAL IVA:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>TOTAL ITEM:</div>
                                        </div>
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfTotalBody">{new Intl.NumberFormat('es-MX').format(data.cantidadItem).replace(/,/g, '.')}</div>
                                            <div className="specificationsPdfTotalBody">${parseFloat(data.precio * data.cantidadItem).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                            <div className="specificationsPdfTotalBody">$ {parseFloat(data.precio * data.iva * 0.01).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                            <div className="specificationsPdfTotalBody">$ {((parseFloat(data.precio) * parseFloat(data.cantidadItem)) + (parseFloat(data.precio) * parseFloat(data.cantidadItem) * parseFloat(data.iva * 0.01))).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    }

                    if (lineaOrItem === "Item") {
                        return (
                            <div className="containerItem">
                                <div className="tableItemPdf3">
                                    <div className="itemNumberPdfHeard">ITEM</div>
                                    <div className="itemNumberPdf">{index + 1}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">DESCRIPCION:</div>
                                    <div className="itemNumberPdfDescription">{data.producto}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">MATERIAL:</div>
                                    <div className="itemNumberPdfDescription">{data.material}</div>
                                </div>
                                <div className="containerItemValue">
                                    <div id="tab_customers" className="tableItemPdf4">
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdf">GROSOR (M):</div>
                                            <div className="specificationsPdf">{(name === "Mesa redonda") ? ("DIAMETRO (M):") : ("LARGO (M):")}</div>
                                            <div className="specificationsPdf">ANCHO (M):</div>
                                            <div className="specificationsPdf" style={{ letterSpacing: '0.01px' }}>PRECIO ITEM:</div>
                                            <div className="specificationsPdf">IVA:</div>
                                        </div>
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfBody">{thickess}</div>
                                            <div className="specificationsPdfBody">{(name === "Mesa redonda") ? (Math.abs((model.measure.circleRadius * 2) / 10).toFixed(2)) : (data.largo)}</div>
                                            <div className="specificationsPdfBody">{data.ancho}</div>
                                            <div className="specificationsPdfBody">${parseFloat(data.precio).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                            <div className="specificationsPdfBody">{data.iva}%</div>
                                        </div>
                                    </div>
                                    <div id="tab_customers" className="tablePdf6">
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>CANTIDAD ITEM:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>SUB TOTAL:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>TOTAL IVA:</div>
                                            <div className="specificationsPdfTotal" style={{ letterSpacing: '0.01px' }}>TOTAL ITEM:</div>
                                        </div>
                                        <div className="formSpecificationsPdf">
                                            <div className="specificationsPdfTotalBody">{new Intl.NumberFormat('es-MX').format(data.cantidadItem).replace(/,/g, '.')}</div>
                                            <div className="specificationsPdfTotalBody">${parseFloat(data.precio * data.cantidadItem).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                            <div className="specificationsPdfTotalBody">${parseFloat(data.precio * data.cantidadItem * data.iva * 0.01).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}   </div>
                                            <div className="specificationsPdfTotalBody">$ {((parseFloat(data.precio) * parseFloat(data.cantidadItem)) + (parseFloat(data.precio) * parseFloat(data.cantidadItem) * parseFloat(data.iva * 0.01))).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                }
            })
        )
        ren.push(content)

        if (total + sizeFooter < sizeTotalContainer) {
            total = total + sizeFooter
            ren.push(footer)
        } else {
            altoDiv = sizeTotalContainer - total
            lineaOrItem = "linea"
            altoDiv = Math.abs(altoDiv)
            total = total + altoDiv + 50 + sizeFooter - 24
            sizeTotalContainer = sizeTotalContainer + altoPdf
            ren.push(<div className="divSeparationMarmol" style={{ height: `${altoDiv}px`, marginBottom: "50px", borderBottom: (!reload) ? ("2px solid rgba(0, 0, 0, 0.274)") : ("2px solid rgba(0, 0, 0, 0)"), width: "100%" }}></div>)
            ren.push(footer)
        }

        let prePlans
        let Plans
        const photoContainer = (



            historyValidation.map((data, index) => {
                // console.log("Redenrizando el modelo #"+index )
                counter = index
                const getImg2d = async (img) => {
                    // console.log("Imagenes: ", img)
                    setToggle2d("false");
                    setImage2d([
                        ...Image2d,
                        img
                    ])
                    setimg2d(true)
                    // createPDF()

                }


                prePlans = JSON.parse(data.modeljson)
                Plans = JSON.parse(data.modeljson)
                const preCoordinate = prePlans.coordinates;
                for (const key in preCoordinate) {
                    if (preCoordinate[key].type === "circle") {
                        const coordinateProperty = preCoordinate[key];
                        const depthExpression = coordinateProperty.depth;
                        const evaluatedDepth = eval(depthExpression);
                        Plans.coordinates[key].depth = evaluatedDepth;
                        const circleRadiusExpression = coordinateProperty.circleRadius;
                        const evaluatedCircleRadius = eval(circleRadiusExpression);
                        Plans.coordinates[key].circleRadius = evaluatedCircleRadius;
                        const circleBezierExpression = coordinateProperty.circleBezier;
                        const evaluatedCircleBezier = eval(circleBezierExpression);
                        Plans.coordinates[key].circleBezier = evaluatedCircleBezier;
                    } else {
                        const coordinateProperty = preCoordinate[key];
                        const depthExpression = coordinateProperty.depth;
                        const evaluatedDepth = eval(depthExpression);
                        const points = coordinateProperty.points.map(poin => {
                            const point = poin;
                            const evaluatedExpr = eval(point);
                            return evaluatedExpr;
                        });
                        Plans.coordinates[key].points = points;
                        Plans.coordinates[key].depth = evaluatedDepth;
                    }
                }

                const preText = prePlans.Texts;

                for (const key in preText) {

                    const preTextProperty = preText[key];
                    const PositionY = preTextProperty.PositionY
                    const evaluatedPositionY = eval(PositionY);
                    Plans.Texts[key].PositionY = evaluatedPositionY

                    const PositionX = preTextProperty.PositionX
                    const evaluatedPositionX = eval(PositionX);
                    Plans.Texts[key].PositionX = evaluatedPositionX

                    const PositionZ = preTextProperty.PositionZ
                    const evaluatedPositionZ = eval(PositionZ);
                    Plans.Texts[key].PositionZ = evaluatedPositionZ

                    const RotateX = preTextProperty.RotateX
                    const evaluatedRotateX = eval(RotateX);
                    Plans.Texts[key].RotateX = evaluatedRotateX

                    const RotateY = preTextProperty.RotateY
                    const evaluatedRotatey = eval(RotateY);
                    Plans.Texts[key].RotateY = evaluatedRotatey

                    const measureM = preTextProperty.measureM
                    const evaluatedmeasureM = eval(measureM);
                    Plans.Texts[key].measureM = evaluatedmeasureM
                }

                const preLines = prePlans.Lines;

                for (const key in preLines) {
                    const preLinesProperty = preLines[key];

                    const depth = preLinesProperty.depth
                    const evaluateddepth = eval(depth);
                    Plans.Lines[key].depth = evaluateddepth

                    const lines = preLinesProperty.line.map(lin => {
                        const line = lin;
                        const evaluatedExpr = eval(line);
                        return evaluatedExpr;
                    });
                    Plans.Lines[key].line = lines
                }

                const preHoles = prePlans.Holes;

                for (const key in preHoles) {
                    const preHolesProperty = preHoles[key].points;
                    const holes = preHolesProperty.map(hol => {
                        const hole = hol;
                        const evaluatedExpr = eval(hole);
                        return evaluatedExpr;
                    })
                    Plans.Holes[key].points = holes
                }

                let mobileStyles = false
                const mobile = (data.image_2d) ? (data.image_3d.split("_")[1]) : (null)
                let imageUpload = null
                if (mobile === "Mobile") {
                    imageUpload = urlApiServer + "preview/" + "size_" + data.image_3d
                    mobileStyles = true
                } else {
                    imageUpload = urlApiServer + "preview/" + data.image_3d
                }

                while (i < page) {
                    if (total + sizeContainerImage < sizeTotalContainer) {
                        lineaOrItem = "Item"
                        total = total + sizeContainerImage
                    } else {
                        altoDiv = sizeTotalContainer - total
                        lineaOrItem = "linea"
                        sizeTotalContainer = sizeTotalContainer + altoPdf
                        altoDiv = Math.abs(altoDiv)
                        total = total + altoDiv + 50 + sizeContainerImage - 24
                        page = page + 1
                    }

                    if (lineaOrItem === "linea") {
                        return (
                            <div key={index}>
                                <div className="divSeparationMarmol" style={{ height: `${altoDiv}px`, marginBottom: "50px", borderBottom: (!reload) ? ("2px solid rgba(0, 0, 0, 0.274)") : ("2px solid rgba(0, 0, 0, 0)"), width: "100%" }}></div>
                                <div className="secondConatinerModel3d" key={index}>


                                    <div className="addPhotoModel3d" key={index}>
                                        {
                                            (!reload) && (
                                                // <LazyLoad key={index} height={200} offset={100}>
                                                <ModelPdf setShowImage={setShowImage} counter={getCounter} getImage={getImage} toggle={toggle} modeljson={JSON.parse(data.modeljson)} setToggle={setToggle} id={index} modelId={modelId} />
                                                // </LazyLoad>
                                            )
                                        }
                                        {
                                            (reload) && (
                                                <img src={imgArr[index]} className="imgArr" alt="ModelPdf Capturado" key={index} />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="addPhotoModel2d">
                                    <div className="secondConatinerModel2d">
                                        <PlanosPdf toggle={toggle} getImage={getImg2d} Plans={Plans} prePlans={prePlans} />

                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            })
        )

        ren.push(photoContainer)


        let send = (
            <div id='conect' className="containerGeneralPdf" style={{
                height: `${sizeTotalContainer}px`,
                transform: `scale(${scale})`
            }}>
                {ren}
            </div>
        )

        return send
    }

    const header = (
        // <div className="containerHeaderPdf"></div>
        <>
            {/* <div className="containerHeaderPdfNew"></div> */}

            <div className="containerHeaderPdf">
                <div className='containerLogoPdf'>
                    <div className='containerImageLogo'>
                        {logo.map((item, index) => {
                            if (item !== "") {
                                const url = urlApiServer + "Image/" + item
                                return (
                                    <img src={url} alt="Logo" className='logoAdd2' key={index} />
                                )
                            }
                        })}
                    </div>
                    <div className="containerTableLogoPdf">
                        <div className="containerClientPdf">
                            <div className="clientPdf">
                                <p className="paragraphInfoClient">Cotización No: {props.pdf.nrocotizacion}</p>
                                <p className="paragraphInfoClient"> Fecha: {formattedDate}</p>
                                <p className="paragraphInfoClient">Tiempo de entrega: {props.pdf.tiempo}</p>
                                <p className="paragraphInfoClient">Vendedor: {props.pdf.asesor}</p>
                            </div>
                            <div className="clientPdf10">
                                <p className="paragraphInfoClient"> NIT o CC: {props.pdf.nitocc}</p>
                                <p className="paragraphInfoClient"> Dirección: {props.pdf.direccion}</p>
                                <p className="paragraphInfoClient">Contacto: {props.pdf.contacto}</p>
                                <p className="paragraphInfoClient">Telefono: {props.pdf.telefono !== "no existe" && props.pdf.telefono}</p>
                            </div>
                        </div>
                        <div className="containerClientPdf2">
                            <div className="clientPdf2">
                                <p className="paragraphInfoClient">Cliente:</p>
                                <p className="paragraphInfoClient">Correo:</p>
                            </div>
                            <div className="clientPdf3">
                                <p className="paragraphInfoClient" style={{ letterSpacing: '0.01px' }}>{props.pdf.cliente}</p>
                                <p className="paragraphInfoClient">{props.pdf.correo !== "no existe" && props.pdf.correo}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="textDetail">DETALLE</p>
            </div>
        </>
    )

    const footer = (
        <div className="containerFoooter">
            <div className="ContainerTablePdfObservation">
                <div className="observation">
                    <p className="withoutMargin">
                        <strong>Observaciones:</strong>
                        <p className="withoutMargin sizeLetter">{firtsObservation}</p>
                    </p>
                </div>
                <div id="tab_customers" className="tablePdf7">
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications" style={{ letterSpacing: '0.01px' }}><strong>SUB TOTAL COTIZACIÓN:</strong></div>
                        <div className="observatioSpecifications">${historyValidation.reduce((total, current) => total + parseFloat(current.precio) * parseFloat(current.cantidadItem), 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>IVA:</strong></div>
                        <div className="observatioSpecifications">${historyValidation.reduce((total, current) => total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) * parseFloat(current.iva * 0.01)), 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}
                        </div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications" style={{ letterSpacing: '0.01px' }}><strong>TOTAL COTIZACIÓN:</strong></div>
                        <div className="observatioSpecifications">${(historyValidation.reduce((total, current) => total + parseFloat(current.precio * current.cantidadItem) + parseFloat(current.precio * current.cantidadItem) * current.iva * 0.01, 0)).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>Abono:</strong></div>
                        <div className="observatioSpecifications">${((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')))}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>Debe:</strong></div>
                        <div className="observatioSpecifications">${(historyValidation.reduce((total, current) => total + parseFloat(current.precio * current.cantidadItem) + parseFloat(current.precio * current.cantidadItem) * current.iva * 0.01, 0) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono)))).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}
                            {/* {
                                historyValidation.reduce((total, current) => {
                                    if (parseFloat(current.iva) !== 0) {
                                    // Si current.iva no es igual a cero, agrega el producto al total
                                        return total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) * parseFloat(current.iva * 0.01) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono))));
                                    } else {
                                    // Si current.iva es igual a cero, no agregues nada al total
                                        return total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono))));
                                    }
                                }, 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="containerFirma">
                <div className="firmaGeneric">
                    {signature.map((item) => {
                        if (item !== "") {
                            const url = urlApiServer + "Image/" + item
                            return (
                                <img src={url} alt="we can't find the signature" className='logoAdd2' />
                            )
                        }
                    })}
                    <div className="textFirmaLineGeneric"></div>
                    <p className="textFirma"><strong>Responsable</strong></p>
                </div>
                {/* <div className="firma">
                    <p className="textFirmaLine">_________________________</p>
                    <p className="textFirmaLine">_________________________</p>
                </div>
                <div className="firma">
                    <p className="textFirma"><strong>Elaborado</strong></p>
                    <p className="textFirma"><strong>Firma</strong></p>
                </div> */}
                <div className="containerTextDescription">
                    <p className="textDescription" style={{ letterSpacing: '0.01px' }}>{observation}</p>
                    {/* <p className="textDescription" style={{ letterSpacing: '0.01px' }}>*Se responde únicamente por medidas y especificaciones anotadas. Una vezaceptada ésta cotizacion no se harán devoluciones.</p>
                    <p className="textDescription" style={{ letterSpacing: '0.01px' }}>*Para realizar cualquier trabajo es necesario abonar el 50% del costo estipulado.</p>
                    <p className="textDescription" style={{ letterSpacing: '0.01px' }}>*Para retirar el trabajo es indispensable presentar este recibo.</p>
                    <p className="textDescription" style={{ letterSpacing: '0.01px' }}>*Pasados 30 dias no se responde por material no retirado.</p> */}

                </div>
            </div>
        </div>
    )

    const zoomOut = (event) => {
        event.preventDefault();
        setScale(scale / 1.5); // Zoom out
    };

    const zoomIn = (event) => {
        event.preventDefault();
        setScale(scale * 1.5); // Zoom in
    };

    return (
        <div className="mainContainerPdf">
            {
                (disabledBottom) && (
                    <div className="backgroundLoader">
                        <div id="page">
                            <div id="container">
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="h3">Descargando..</div>
                            </div>
                        </div>
                    </div>


                )
            }
            <div className="containerScrollPdf">

                <div id='overallContainerAdd' className='overallContainerPdf containerZoom'>
                    <div className="containerZoom" style={{
                    }}>
                        {
                            rendered()
                        }
                    </div>
                </div>
            </div>
            <div className='buttonContainerPdfTop'>
                <button className="buttonLeftZoom neon_Effect" onClick={zoomOut}><FaSearchMinus></FaSearchMinus></button>
                <button className="buttonRigthZoom neon_Effect" onClick={zoomIn}><FaSearchPlus></FaSearchPlus></button>
            </div>
            <div className="buttonContainerPdf">
                <NavLink to='/Cotizaciones' className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                <button className="button neon_Effect" onClick={changeToggle}>Descargar</button>
            </div>
        </div>
    )
}

export default Pdf