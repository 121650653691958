import React, { useState, useEffect, useContext } from "react"
import { NavLink } from "react-router-dom"
import { urlApiServer } from "../../../Config/Config"
import jsPDF from "jspdf"
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa'
import Loader from "../../Loader"
import Resizer from 'react-image-file-resizer';
import LoginContext from "../../../Context/Logincontext"
import './styles.css'

// import generarPDF from "./example1"
const urlObservation = urlApiServer + "getFooter";

let DataForTheQuote = []

const GenericPdf = (props) => {
    props.dontShowLoader()

    let historyValidation = []

    const [historyProduct, setHistoryProduct] = useState(props.historyResponse)
    const [sizeMain, setSizeMain] = useState(1866)
    const [scale, setScale] = useState(1);
    const [disabledBottom, setDisabledBottom] = useState(false);
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [observation, setObservation] = useState("")
    const [firtsObservation, setFirtsObservation] = useState("")

    const [reload, setReload] = useState(false)
    let totalDivs = 450 + 35 + 32
    let ren = []
    let sizeHeader = 354
    let sizeFooter = 516.39
    const altoPdf = 1848
    let sizeTotalContainer = altoPdf
    let sizeItem = 117 + 20
    let sizeItemImage3d = 463 + 10
    let sizeItemImage2d = 500 + 110
    let sizeContainerImage = sizeItemImage3d + sizeItemImage2d

    let i = 0
    let page = 1
    let total = sizeHeader
    let altoDiv = 0
    let lineaOrItem = "Item"

    async function off() {
        props.power()
        DataForTheQuote = []
    }

    useEffect(() => {
        (async function () {

            // const InicioHeaders = new Headers();
            // InicioHeaders.append("Content-Type", "application/json")
            const newTextObservation = props.pdf.observation.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index !== props.pdf.observation.split('\n').length - 1 && <br />}
                </React.Fragment>
            ));
            setFirtsObservation(newTextObservation)

            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const data = JSON.stringify({
                empresa: infoUser.company,
            })

            const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            }
            await fetch(urlObservation, requesoption)
                .then(response => response.json())
                .then(item => {
                    if (item && item.footerpdf) {
                        // const res = item.footerpdf.replace(/\n/g, '\n');
                        // console.log("res: ", res);
                        // const lines = item.footerpdf.split("*");

                        // const formattedLines = lines.map((line) => {
                        //     console.log("line: ", line + "<br></br>")
                        //     return line + "<br></br>";
                        // });

                        // const newText = item.footerpdf.replace(/\n/g, '<br/>');
                        const newText = item.footerpdf.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                {index !== item.footerpdf.split('\n').length - 1 && <br />}
                            </React.Fragment>
                        ));
                        setObservation(newText);
                    }
                });
        }())
    }, [])


    const createPDF = async () => {
        setReload(true)
        setDisabledBottom(true)
        setScale(1)
        var margin = 10
        var doc = new jsPDF('p', 'pt', 'letter')//995.28 es la altura, 1000.89 es lo ancho
        var scale = (doc.internal.pageSize.width - margin * 2) / document.querySelector("#conect").scrollWidth
        doc.html(document.querySelector("#conect"), {
            x: margin,
            y: margin,
            html2canvas: {
                scale: scale,
            },
            callback: function (pdf) {
                pdf.save("Cotizacion.pdf");
                setDisabledBottom(false)
                setReload(!reload)

            }
        }
        )

    };
    const img = props.pdf.image;
    const logo = props.logo
    const signature = props.signature

    if (props.pdf.fecha === null) {
    } else {
        var deliveryDate = props.pdf.fecha;
        var dateDelivery = new Date(deliveryDate);

        var day = dateDelivery.getDate();
        var month = dateDelivery.getMonth() + 1;
        var year = dateDelivery.getFullYear();
        var hours = dateDelivery.getHours();
        var minutes = dateDelivery.getMinutes();

        var formattedDateDelivery = day + '/' + month + '/' + year + ', ' + hours + ':' + minutes;
    }


    var currentDate = props.pdf.fecha;
    var date = new Date(currentDate);
    var formattedDate = date.toLocaleString();
    const handleFontSizeChange = () => {
        setSizeMain(sizeMain + totalDivs)
    };

    historyProduct.map((history) => {
        if (props.id == history.nrocotizacion) {
            // console.log("Product: ", history)
            historyValidation.push(history)
        }
    })

    useEffect(() => {
        // historyValidation = []
    }, [scale])

    const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,

    });

    function rendered() {

        ren.push(header)
        // const content = (



        const content = (
            historyValidation.map((data, index) => {
                while (i < page) {
                    if (total + sizeItem < sizeTotalContainer) {
                        lineaOrItem = "Item"
                        total = total + sizeItem
                    } else {
                        altoDiv = sizeTotalContainer - total
                        lineaOrItem = "linea"
                        sizeTotalContainer = sizeTotalContainer + altoPdf
                        total = total + altoDiv + 50 + sizeItem - 24
                        page = page + 1
                        altoDiv = Math.abs(altoDiv)
                    }

                    if (lineaOrItem === "linea") {
                        return (<>
                            <div className="divSeparation" style={{ height: `${altoDiv}px`, marginBottom: "50px", borderBottom: (!reload) ? ("2px solid rgba(0, 0, 0, 0.274)") : ("2px solid rgba(0, 0, 0, 0)"), width: "100%" }}></div>
                            <div className="containerItemGeneric">
                                <div className="tableItemPdf3">
                                    <div className="itemNumberPdfHeard">ITEM</div>
                                    <div className="itemNumberPdf">{index + 1}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">DETALLE</div>
                                    <div className="itemNumberPdfDescription" style={{ letterSpacing: '0.01px' }}>{data.producto}</div>
                                </div>
                                <div className="tableItemPdfDPriceUnit">
                                    <div className="itemNumberPdfHeardDescription" style={{ letterSpacing: '0.01px' }}>PRECIO UNITARIO</div>
                                    <div className="itemNumberPdfDescription">${data.precioitem}</div>
                                </div>
                                <div className="tableItemPdfDPrice">
                                    <div className="itemNumberPdfHeardDescription">CANTIDAD</div>
                                    <div className="itemNumberPdfDescription">{data.cantidadItem} {data.unit}</div>
                                </div>
                                <div className="tableItemPdfIva">
                                    <div className="itemNumberPdfHeardDescription">IVA</div>
                                    <div className="itemNumberPdfDescription">{data.iva}%</div>
                                </div>
                                <div className="tableItemPdfDescriptionTotal">
                                    <div className="itemNumberPdfHeardDescription">TOTAL</div>
                                    <div className="itemNumberPdfDescription">${data.total}</div>
                                </div>
                            </div>
                        </>)
                    }
                    console.log("data: ", data)
                    if (lineaOrItem === "Item") {
                        return (
                            <div className="containerItemGeneric">
                                <div className="tableItemPdf3">
                                    <div className="itemNumberPdfHeard">ITEM</div>
                                    <div className="itemNumberPdf">{index + 1}</div>
                                </div>
                                <div className="tableItemPdfDescription">
                                    <div className="itemNumberPdfHeardDescription">DETALLE</div>
                                    <div className="itemNumberPdfDescription" style={{ letterSpacing: '0.01px' }}>{data.producto}</div>
                                </div>
                                <div className="tableItemPdfDPriceUnit">
                                    <div className="itemNumberPdfHeardDescription" style={{ letterSpacing: '0.01px' }}>PRECIO UNITARIO</div>
                                    <div className="itemNumberPdfDescription">{formatter.format(data.precio)}</div>
                                </div>
                                <div className="tableItemPdfDPrice">
                                    <div className="itemNumberPdfHeardDescription">CANTIDAD</div>
                                    <div className="itemNumberPdfDescription">{data.cantidadItem} {data.unit}</div>
                                </div>
                                <div className="tableItemPdfIva">
                                    <div className="itemNumberPdfHeardDescription">IVA</div>
                                    <div className="itemNumberPdfDescription">{data.iva}%</div>
                                </div>
                                <div className="tableItemPdfDescriptionTotal">
                                    <div className="itemNumberPdfHeardDescription">TOTAL</div>
                                    <div className="itemNumberPdfDescription">{formatter.format(data.total)}</div>
                                </div>
                            </div>
                        )
                    }

                }
            })
        )

        ren.push(content)

        if (total + sizeFooter < sizeTotalContainer) {
            total = total + sizeFooter
            ren.push(footer)
        } else {
            altoDiv = sizeTotalContainer - total
            lineaOrItem = "linea"
            total = total + altoDiv + 50 + sizeFooter - 24
            sizeTotalContainer = sizeTotalContainer + altoPdf
            ren.push(<div className="divSeparation" style={{ height: `${altoDiv}px`, marginBottom: "50px", borderBottom: (!reload) ? ("2px solid rgba(0, 0, 0, 0.274)") : ("2px solid rgba(0, 0, 0, 0)"), width: "100%" }}></div>)
            ren.push(footer)
        }

        // ren.push(footer)

        let send = (
            <div id='conect' className="containerGeneralPdf" style={{
                height: `${sizeTotalContainer}px`,
                transform: `scale(${scale})`
            }}>
                {ren}
            </div>
        )

        return send
    }

    const header = (
        <div className="containerHeaderPdf">
            <div className='containerLogoPdf'>
                <div className='containerImageLogo'>
                    {logo.map((item) => {
                        if (item !== "") {
                            const url = urlApiServer + "Image/" + item
                            return (
                                <img src={url} alt="Logo" className='logoAdd2' />
                            )
                        }
                    })}
                    {/* <img src={urlApiServer + "Image/" + "juanpabloruiz.jpeg"} alt="Logo" className='logoAdd2' /> */}
                </div>
                <div className="containerTableLogoPdf">
                    <div className="containerClientPdf">
                        <div className="clientPdf">
                            <p className="paragraphInfoClient">Cotización No: {props.pdf.nrocotizacion}</p>
                            <p className="paragraphInfoClient">Fecha: {formattedDate}</p>
                            <p className="paragraphInfoClient">Tiempo de entrega: {props.pdf.tiempo}</p>

                            <p className="paragraphInfoClient">Responsable: {props.pdf.asesor}</p>
                        </div>
                        <div className="clientPdf10">
                            <p className="paragraphInfoClient" style={{ letterSpacing: '0.02px' }}> NIT o CC: {props.pdf.nitocc}</p>
                            <p className="paragraphInfoClient" >Dirección: {props.pdf.direccion}</p>
                            <p className="paragraphInfoClient">Contacto: {props.pdf.contacto}</p>
                            <p className="paragraphInfoClient">Telefono: {props.pdf.telefono !== "no existe" && props.pdf.telefono}</p>
                        </div>
                    </div>
                    <div className="containerClientPdf2">
                        <div className="clientPdf2">
                            <p className="paragraphInfoClient">Cliente:</p>
                            <p className="paragraphInfoClient">Correo:</p>
                        </div>
                        <div className="clientPdf3">
                            <p className="paragraphInfoClient" style={{ letterSpacing: '0.01px' }}>{props.pdf.cliente}</p>
                            <p className="paragraphInfoClient">{props.pdf.correo !== "no existe" && props.pdf.correo}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="textDetail">DETALLE</p>
        </div>
    )


    const footer = (
        <div className="containerFoooter">
            <div className="ContainerTablePdfObservation">
                <div className="observation">
                    <p className="withoutMargin">
                        <strong>Observaciones</strong>
                        <p className="withoutMargin sizeLetter">{firtsObservation}</p>
                    </p>
                </div>
                <div id="tab_customers" className="tablePdf7">
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications" style={{ letterSpacing: '0.01px' }}><strong>SUB TOTAL COTIZACIÓN</strong></div>
                        <div className="observatioSpecifications">${historyValidation.reduce((total, current) => total + parseFloat(current.precio) * parseFloat(current.cantidadItem), 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>IVA</strong></div>
                        <div className="observatioSpecifications">${historyValidation.reduce((total, current) => total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) * parseFloat(current.iva * 0.01)), 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}
                        </div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications" style={{ letterSpacing: '0.01px' }}><strong>TOTAL COTIZACIÓN</strong></div>
                        <div className="observatioSpecifications">${(historyValidation.reduce((total, current) => total + parseFloat(current.precio * current.cantidadItem) + parseFloat(current.precio * current.cantidadItem) * current.iva * 0.01, 0)).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>Abono</strong></div>
                        <div className="observatioSpecifications">${((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')))}</div>
                    </div>
                    <div className="formSpecificationsPdf">
                        <div className="observatioSpecifications"><strong>Debe</strong></div>
                        <div className="observatioSpecifications">${(historyValidation.reduce((total, current) => total + parseFloat(current.precio * current.cantidadItem) + parseFloat(current.precio * current.cantidadItem) * current.iva * 0.01, 0) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono)))).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')}
                            {/* {
                                historyValidation.reduce((total, current) => {
                                    if (parseFloat(current.iva) !== 0) {
                                    // Si current.iva no es igual a cero, agrega el producto al total
                                        return total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) * parseFloat(current.iva * 0.01) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono))));
                                    } else {
                                    // Si current.iva es igual a cero, no agregues nada al total
                                        return total + (parseFloat(current.precio) * parseFloat(current.cantidadItem) - ((props.pdf.abono === "") ? (0) : (parseFloat(props.pdf.abono))));
                                    }
                                }, 0).toLocaleString('es-MX', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="containerFirmaGeneric">
                <div className="firmaGeneric">
                    {signature.map((item) => {
                        if (item !== "") {
                            const url = urlApiServer + "Image/" + item
                            return (
                                <img src={url} alt="we can't find the signature" className='logoAdd2' />
                            )
                        }
                    })}
                    <div className="textFirmaLineGeneric"></div>
                    <p className="textFirma"><strong>Responsable</strong></p>
                </div>
                {/* <div className="firma">
                    <p className="textFirma"><strong>Responsable</strong></p>
                </div> */}
                <div className="containerTextDescription">
                    <p className="textDescription" style={{ letterSpacing: '0.02px' }}>{observation}</p>

                    {/* <p className="textDescription" style={{ letterSpacing: '0.02px' }}>JPR REFORMAS Y RECONSTRUCCIONES S.A.S.</p> */}
                    {/* <p className="textDescription" style={{ letterSpacing: '0.02px' }}>correo electronico: juanpabloruiz933@gmail.com</p>
                    <p className="textDescription" style={{ letterSpacing: '0.02px' }}>NIT: 901702209</p>
                    <p className="textDescription" style={{ letterSpacing: '0.02px' }}>telefono: 3126533772</p> */}



                </div>
            </div>
        </div>
    )

    const zoomOut = (event) => {
        event.preventDefault();
        setScale(scale / 1.5); // Zoom out
    };

    const zoomIn = (event) => {
        event.preventDefault();
        setScale(scale * 1.5); // Zoom in
    };

    return (
        <div className="mainContainerPdf">
            {
                (disabledBottom) && (
                    <div className="backgroundLoader">
                        <div id="page">
                            <div id="container">
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="h3">Descargando..</div>
                            </div>
                        </div>
                    </div>


                )
            }
            <div className="containerScrollPdf">

                <div id='overallContainerAdd' className='overallContainerPdf containerZoom'>
                    <div className="containerZoom" style={{
                        // transform: `scale(${scale})`
                    }}>
                        {
                            rendered()
                        }
                    </div>
                    {/* <div className="rePrueba"></div> */}
                </div>
                {/* <div className="Holis"></div> */}
            </div>
            <div className='buttonContainerPdfTop'>
                <button className="buttonLeftZoom neon_Effect" onClick={zoomOut}><FaSearchMinus></FaSearchMinus></button>
                <button className="buttonRigthZoom neon_Effect" onClick={zoomIn}><FaSearchPlus></FaSearchPlus></button>
            </div>
            <div className="buttonContainerPdf">
                <NavLink to='/Cotizaciones' className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                <button className="button neon_Effect" onClick={createPDF}>Descargar</button>
            </div>
        </div>
    )
}

export default GenericPdf