import React, { useContext, useEffect, useState } from 'react'
import Nav1 from '../Component/Nav/Nav1'
import logo from '../Assest/logo.png'
import LoginContext from '../Context/Logincontext';
import { columnsUsers } from '../Component/Hooks/ColumTables'
import { urlApiServer } from '../Config/Config'
import Table from '../Component/Tabla/Tabla';
import EditUsers from '../Component/Hooks/EditarUsers';
import { NavLink } from 'react-router-dom';
import {ImExit} from 'react-icons/im'
let splitImg = []


const Users = () => {

    const urlGetClient = urlApiServer + "users"
    const urlgetConfig = urlApiServer + "getConfig";

    const {infoUser, setInfoUser} = useContext(LoginContext)
    const [clientGetTable, setClientGetTable] = useState([])
    const [state, setState] = useState("0")
    const [powerEdit, setPowerEdit] = useState(true)
    const [id, setId] = useState()
    const [oldConfig, setOldConfig]=useState("")  
    const [logo, setLogo]=useState("")  
    const [datosCoincidentes, setDatosCoincidentes] = useState([]);

    useEffect(()=>{
        (async function (){
            
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")
    
            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse=>{
                setOldConfig(quotesResponse)
            })
        }())
    
    
    },[])

    useEffect(()=>{
        if (oldConfig) {
          const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
          if (foundConfig) {
            const {logo} = foundConfig;
            setLogo(logo);

          }
    }
    },[oldConfig, infoUser])

    if(logo){
        splitImg = logo.split("|")
    }


    useEffect(()=>{
        (async function (){

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlGetClient, requesoption)
            .then(response => response.json())
            .then(quotesResponse => {
                setClientGetTable(quotesResponse);
            })
            .catch(error => {
            });
        }())

    },[state])

    useEffect(() => {
      }, [infoUser, clientGetTable]); 

      useEffect(()=>{
        if (clientGetTable) {
            const datosCoincidentes = clientGetTable.filter(
                (objeto) => objeto.empresa === infoUser.company.replace('empresa_', '')
            );
            setDatosCoincidentes(datosCoincidentes);
          
        }
      },[clientGetTable, infoUser.company ])


    async function handleName(){
        if(state === "0"){
            setState("1")
        }else{
            setState("0")
        }
    }

    async function Cerrar(){
        if(Cerrar){
            setInfoUser({
                login : false
            })
        }else{
        }
      }


    async function handleData(e){
        setId(e)
    }

    async function edit(){
        if(powerEdit === true){
            setPowerEdit(false)
        }else{
            setPowerEdit(true)
        }
    }

    return(
        <>
                {(infoUser.login === true)?(
                    (infoUser.role === 'SuperUsuario')?
                    (window.location.href = './Crear_Empresa')
                    :(
                        (powerEdit === true)?(
                            <>
                                <div id='overallContainerUsers' className='overallContainerUsers'>
                                    <Table columns={columnsUsers} data={datosCoincidentes} function={handleName} edit={edit} id={handleData}/>
                                </div>
                            </>
                        ):(
                            <>
                                <EditUsers  data={datosCoincidentes} edit={edit} function={handleName} id={id}/>
                        </>
                        )
                    )
                ):(window.location.href = './Iniciar_Sesión')}
        </>
    )
}
export default Users