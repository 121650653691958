import { useState } from "react";
import {useAsyncDebounce} from 'react-table'

export default function Filter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const totalAvailable = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);

    const onFilterChange = useAsyncDebounce(
    (value) => setGlobalFilter(value || undefined),
    200
    );

    const handleInputChange = (e) => {
    setValue(e.target.value);
    onFilterChange(e.target.value);
    };

    return (
    <div className="filter">
        <div className="spanFilter">Filtrar: </div>
        <input
            size={50}
            className="form-control short"
            value={value || ""}
            onChange={handleInputChange}
            placeholder={` ${totalAvailable} registros...`}
        />
    </div>
    );
}