import { useContext, useState, useEffect } from 'react';
import { urlApiServer } from '../../Config/Config';
import LoginContext from '../../Context/Logincontext';
import '../../styles/Nav.css'

let splitImg = []

const UserConfiguredSettings = () =>{
  const urlgetConfig = urlApiServer + "getConfig";
  
  const {infoUser, setInfoUser} = useContext(LoginContext)
  const [fontColor, setFontColor]=useState('white')  
  const [iconColor, setIconColor]=useState('white')  
  const [mainColor, setMainColor]=useState([82,81,81,0.691])  
  const [innerIconColor, setInnerIconColor]=useState('#02440a86')  
  const [neon, setNeon]=useState('#db3434') 
  const [secondaryColor ,setSecondaryColor]=useState([82, 81, 81, 0.3])
  const [tittle, setTittle] = useState('')
  const [config, setConfig] = useState('')
  const [ImageSelectionOld, setImageSelectionOld] = useState('')

  // console.log('infoUser: ', infoUser)


  useEffect(()=>{
    // setIsLoading(true);

    (async function (){
        const InicioHeaders = new Headers();
        InicioHeaders.append("Content-Type", "application/json")

        const requesoption ={
            method: 'GET',
            headers: InicioHeaders,
            redirect: 'follow'
        }
        await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse=>{
          // const timeoutID = setTimeout(() => {
          //   setIsLoading(false);
          // },800)
          // console.log("response: ", quotesResponse)
            setConfig(quotesResponse)

          // return () => {
            //   clearTimeout(timeoutID);
            // };
        })
    }())
  },[])
  
  useEffect(()=>{
    if (config) {
      const foundConfig = config.find(obj => obj.empresa === infoUser.company);
      // console.log("foundConfig: ", foundConfig)
      if (foundConfig) {
        const { fontcolor, iconcolor, innericoncolor, maincolor, neon, secondarycolor,  backgrounddefault, tittle} = foundConfig;
        setFontColor(fontcolor);
        setIconColor(iconcolor);
        setInnerIconColor(innericoncolor);
        setMainColor(JSON.parse(maincolor));
        setNeon(neon);
        setSecondaryColor(JSON.parse(secondarycolor));
        setTittle(tittle)
        if(backgrounddefault){
          splitImg = backgrounddefault.split("|")
          // console.log("splitImg: " + splitImg)
          // setInfoUser({
          //   ...infoUser,
          //   backgroundImg: splitImg
          // })
        }
        // setImageSelectionOld(backgrounddefault) 
      }
    }
  },[config, infoUser])

  // useEffect(()=>{
  //   {splitImg.map((item)=>{
  //     console.log("item: ", item)
  //     if(item !== ""){
  //       // return setImageSelectionOld(urlApiServer + "Image/" + item)
  //       // setInfoUser({
  //       //   ...infoUser,
  //       //   backgroundImg: item
  //       // })
  //     }
  //   })}
  // },[splitImg])

  // console.log("ImageSelectionOld: ", ImageSelectionOld)
//--------------------------------------------------------------------------------------//
//----------------------------Estilos de la configuracion-------------------------------//
//--------------------------------------------------------------------------------------//
  useEffect(() => {
    const activeNavStyle = document.createElement('style');
    const boxShadow = `0 0 10px 0 ${infoUser.colorNeonAndButtons} inset, 0 0 10px 4px ${infoUser.colorNeonAndButtons}`;
    const backgroundColor = `${infoUser.backgroundColorIcons}`;
    const color = `${infoUser.colorIcons}`;
    const color2 = `${infoUser.letterColor}`;
    const colorMain = `${infoUser.mainColor}`;//`${mainColor}`;
    const neonColor = `${infoUser.colorNeonAndButtons}`;
    const colorTittle = `${infoUser.tittleColor}`
    const colorSecundary = `${infoUser.secondaryColor}`
    const backgroundImageStyle =  `background-image: url(${infoUser.login === false ?  urlApiServer + "Image/" + "default.jpeg" : urlApiServer + "Image/" + infoUser.backgroundImg});`;
    // console.log('backgroundImageStyle antes de innerHTML: ', backgroundImageStyle)

    activeNavStyle.innerHTML = `
      body {
        ${backgroundImageStyle}
      }
      nav a{
        color: ${color};
      }
      nav a:hover{
        box-shadow: ${boxShadow};
        background-color: ${backgroundColor};
      }
      .activeNav.active {
        box-shadow: ${boxShadow};
        background-color: ${backgroundColor};
      }
      .activeNav.active:hover {
        background-color: ${backgroundColor};
      }
      .optionsBottonNav.active{
        background-color:  ${backgroundColor};
        box-shadow: ${boxShadow};
      }
      .textNavLink{
        color: ${color2};
      }
      nav a .tooltipProduct {
        color: ${color2};
      }
      .tittleAdd{
        color: ${colorTittle};
      }
      .tittleForm{
        color: ${colorTittle};
      }
      .upload-text{
        color: ${color2};
      }
      .textForm{
        color: ${color2};
      }
      .closeButton{
        color: ${color2};
      }
      .button {
        border: ${color2};
        color: ${color2};
      }
      .neon_Effect {
        border-color: ${colorMain};
        color: ${color2};
        box-shadow: 0 0 40px 40px ${neonColor} inset, 0 0 0 0 ${neonColor};
      }
      .neon_Effect:hover {
        box-shadow: 0 0 10px 0 ${neonColor} inset, 0 0 10px 4px ${neonColor};
      }
      .iconAdd{
        color: ${color2};
      }
      .navIconAddproducts{
        color: ${color2};
      }
      .navIconAddproducts:hover{
        box-shadow: 0 0 10px 0 ${neonColor} inset, 0 0 10px 4px ${neonColor};
      }
      .orientationBotton{
        background: linear-gradient(to left, ${colorSecundary} 70%,  ${colorMain});
      }
      .inputQuotesContainer .inputquotes:focus {
        border-bottom-color: ${color2};
      }
      .inputQuotesContainer .inputquotes:focus + label,
      .inputQuotesContainer .inputquotes:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputQuotesContainer .inputTimerQuotes:focus {
        border-bottom-color: ${color2};
      }
      .inputQuotesContainer .inputTimerQuotes:focus + label,
      .inputQuotesContainer .inputTimerQuotes:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputQuotesContainer .textareaQuotes:focus {
        border-bottom-color: ${color2};
      }
      .inputQuotesContainer .textareaQuotes:focus + label,
      .inputQuotesContainer .textareaQuotes:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputQuotesContainer .inputSelectClientQuotes:focus {
        border-bottom-color: ${color2};
      }
      .inputQuotesContainer .inputSelectClientQuotes + label,
      .inputQuotesContainer .inputSelectClientQuotes:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .file-upload:hover {
        border: 2px dashed ${color2};
      }
      .inputProductContainer .inputProducts:focus {
        border-bottom-color: ${color2};
      }
      .inputProductContainer .inputProducts:focus + label,
      .inputProductContainer .inputProducts:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputProductContainer .inputSelectIva:focus {
        border-bottom-color: ${color2};
      }
      .inputProductContainer .inputSelectIva + label,
      .inputProductContainer .inputSelectIva:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputClientContainer .inputCLient:focus {
        border-bottom-color: ${color2};
      }
      .inputClientContainer .inputCLient:focus + label,
      .inputClientContainer .inputCLient:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputClientContainer .inputSelectNT:focus {
        border-bottom-color: ${color2};
      }
      .inputClientContainer .inputSelectNT + label,
      .inputClientContainer .inputSelectNT:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputClientContainer2 .inputCLient2:focus {
        border-bottom-color: ${color2};
      }
      .inputClientContainer2 .inputCLient2:focus + label,
      .inputClientContainer2 .inputCLient2:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputUsersContainer .inputUsers:focus {
        border-bottom-color: ${color2};
      }
      .inputPasswordContainer .inputPasswordText:focus {
        border-bottom-color: ${color2};
      }
      .inputUsersContainer .inputUsers:focus + label,
      .inputUsersContainer .inputUsers:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputPasswordContainer .inputPasswordText:focus + label,
      .inputPasswordContainer .inputPasswordText:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .inputUsersContainer .inputSelectNit:focus {
        border-bottom-color: ${color2};
      }
      .inputUsersContainer .inputSelectNit + label,
      .inputUsersContainer .inputSelectNit:not(:placeholder-shown) + label {
        color: ${color2};
      }
      .createButton {
        background-color: ${neonColor};
        color: ${color2};
      }
      .button:hover, :focus {
        color: ${color2};
      }
      .HeardANDFilter{
        background-color: rgba(${infoUser.mainColor?infoUser.mainColor[0]:82}, ${infoUser.mainColor?infoUser.mainColor[1]:81}, ${infoUser.mainColor?infoUser.mainColor[2]:81}, ${infoUser.mainColor?infoUser.mainColor[3]:0.691});
      }
      .containerLogoAdd{
        background-color: rgba(${infoUser.mainColor?infoUser.mainColor[0]:82}, ${infoUser.mainColor?infoUser.mainColor[1]:81}, ${infoUser.mainColor?infoUser.mainColor[2]:81}, ${infoUser.mainColor?infoUser.mainColor[3]:0.691});
      }
      .containerNav{
        background-color: rgba(${infoUser.mainColor?infoUser.mainColor[0]:82}, ${infoUser.mainColor?infoUser.mainColor[1]:81}, ${infoUser.mainColor?infoUser.mainColor[2]:81}, ${infoUser.mainColor?infoUser.mainColor[3]:0.691});
      }
      .drawerFormLogin{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .containerQuotes{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .containerQuotes2{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .containerQuote3{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .containerInfo{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .containerInfoProduct{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .drawerFormRegister{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .card{
        background-color: rgba(${infoUser.secondaryColor?infoUser.secondaryColor[0]:82}, ${infoUser.secondaryColor?infoUser.secondaryColor[1]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[2]:81}, ${infoUser.secondaryColor?infoUser.secondaryColor[3]:0.3});
      }
      .cardHeaderRow{
        background-color: rgba(${infoUser.mainColor?infoUser.mainColor[0]:82}, ${infoUser.mainColor?infoUser.mainColor[1]:81}, ${infoUser.mainColor?infoUser.mainColor[2]:81}, ${infoUser.mainColor?infoUser.mainColor[3]:0.691});
      }
      .pageTitle {
        color: ${colorTittle};
      }
      .cardContentCell{
        color: ${color2};
      }
      .cardHeaderCell{
        color: ${colorTittle};
      }
      .inputProductContainer .inputProducts {
        color: ${color2};
        border-bottom: 1px solid ${color2};
      }
      .inputProductContainer label {
        color: ${color2};
      }
      .inputClientContainer .inputCLient {
        color: ${color2};
        border-bottom: 1px solid ${color2};
      }
      .inputClientContainer label {
        color: ${color2};
      }
      .personalInformation{
        color: ${colorTittle};
      }
      .inputClientContainer2 .inputCLient2 {
        color: ${color2};
        border-bottom: 1px solid ${color2};
      }
      .inputClientContainer2 label {
        color: ${color2};
      }
      .inputQuotesContainer .inputquotes {
        color: ${color2};
        border-bottom: 1px solid ${color2};
      }
      .inputQuotesContainer label {
        color: ${color2};
      }
      .inputQuotesContainer .textareaQuotes {
        border-bottom: 1px solid ${color2};
      }
      .texProducttitule{
        color: ${colorTittle};
      }
      .inputUsersContainer .inputUsers {
        border-bottom: 1px solid ${color2};
        color: ${color2};
      }
      .inputUsersContainer label {
        color: ${color2};
      }
      .password3 {
        color: ${color2};
      }
      .inputQuotesContainer .textareaQuotes+label {
        color: ${color2};
      }
      .inputQuotesContainer .inputTimerQuotes {
        border-bottom: 1px solid ${color2};
        color: ${color2};
      }
      .matText{
        color: ${color2};
      }
    }
    `;
    document.head.appendChild(activeNavStyle);
  }, [neon, innerIconColor, iconColor, fontColor, mainColor, ImageSelectionOld, secondaryColor,ImageSelectionOld, infoUser]);
//--------------------------------------------------------------------------------------//
//----------------------------Estilos de la configuracion-------------------------------//
//--------------------------------------------------------------------------------------//

  return(
    <p className='HideComponent'>Hola</p>
  )
}
export default UserConfiguredSettings