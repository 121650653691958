import Nav1 from '../Component/Nav/Nav1'
import logo from '../Assest/logo.png'
import React, { useContext, useEffect, useState } from 'react'
import { columnsTexture } from '../Component/Hooks/ColumTables'
import LoginContext from '../Context/Logincontext';
import { urlApiServer } from '../Config/Config'
import Table from '../Component/Tabla/Tabla';
import "../styles/Users.css"
import EditTexture from '../Component/Hooks/EditTexture';


let splitImg = []

const Texture = () => {

    const urlgetTexture = urlApiServer + "getTexture"
    const {infoUser, setInfoUser} = useContext(LoginContext)
    const [textureGetTable, setTextureGetTable] = useState([])
    const [powerEdit, setPowerEdit] = useState(true)
    const [state, setState] = useState("0")
    const [id, setId] = useState()
    const [oldConfig, setOldConfig]=useState("")  
    const [logo, setLogo]=useState("")  
    const urlgetConfig = urlApiServer + "getConfig";

    useEffect(()=>{
        (async function (){
            
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")
    
            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse=>{
                setOldConfig(quotesResponse)
            })
        }())
    
    
    },[])

    useEffect(()=>{
        if (oldConfig) {
          const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
          if (foundConfig) {
            const {logo} = foundConfig;
            setLogo(logo);

          }
        }
    },[oldConfig, infoUser])

    if(logo){
        splitImg = logo.split("|")
    }


    useEffect(()=>{
        (async function (){
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado
    
            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlgetTexture, requesoption)
            .then(response => response.json())
            .then(quotesResponse => {
                setTextureGetTable(quotesResponse.reverse());
            })
            .catch(error => {
            });
        }())

    },[state])

    async function handleName(){
        if(state === "0"){
            setState("1")
        }else{
            setState("0")
        }
    }

    async function Cerrar(){
        if(Cerrar){
            setInfoUser({
                login : false
            })
        }else{
        }
      }

    async function edit(){
        if(powerEdit === true){
            setPowerEdit(false)
        }else{
            setPowerEdit(true)
        }
    }

    async function handleData(e){
        setId(e)
    }

    return(
        <>
            {(infoUser.login === true)?(
                (infoUser.role === 'SuperUsuario')?
                (window.location.href = './Crear_Empresa'):
                (
                    (powerEdit === true)?(
                        <>
                            <Table function={handleName} edit={edit} columns={columnsTexture} data={textureGetTable} id={handleData}/>
                        </>
                    ):(
                        <EditTexture id={id} data={textureGetTable} edit={edit} function={handleName}/>
                    )
                )
            ):(window.location.href = './Iniciar_Sesión')}
        </>
    )
}
export default Texture