import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { urlApiServer } from '../../Config/Config'
import Swal from 'sweetalert2'
import '../../styles/Register.css'
import Nav1 from '../../Component/Nav/Nav1'
import LoginContext from '../../Context/Logincontext'
import CloseButton from '../CloseButton'
import LogoComponent from '../LogoComponent'
import invisible from '../../Assest/invisibleKey.png'
import visible from '../../Assest/visibleKey.png'

let splitImg = []

const EditUsers = (props) => {

    const urlCreate = urlApiServer + "editUsers";
    const urlgetConfig = urlApiServer + "getConfig";

    const [name, setName] = useState()
    const [surNames, setSurnames] = useState()
    const [type, setType] = useState(null)
    const [rol, setRol] = useState(null)
    const [numbreDocument, setNumbreDocument] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [users, setUsers] = useState([])
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [newPassword, setNewPassword] = useState(null)
    const [confirmNewPassword, setConfirmNewPassword] = useState(null)
    const [visibleKey1, setVisibleKey1] = useState(true)
    const [visibleKey2, setVisibleKey2] = useState(true)

    const urlGetClient = urlApiServer + "users"

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())

    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);

            }
        }
    }, [oldConfig, infoUser])

    async function off() {
        props.edit()
    }

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company); // Enviar el nombre de la empresa como encabezado

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            await fetch(urlGetClient, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setUsers(quotesResponse);
                })
                .catch(error => {
                });
        }())
    }, [email])

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    if (logo) {
        splitImg = logo.split("|")
    }

    useEffect(() => {
        const productData = props.data.find(data => data.Id === props.id);
        if (productData) {
            const { nombre, apellidos, cc, correo, roles, telefono, tipo } = productData;
            setName(nombre);
            setSurnames(apellidos);
            setType(tipo);
            setRol(roles);
            setNumbreDocument(cc);
            setPhone(telefono);
            setEmail(correo)
        }
    }, [props.data, props.id]);

    async function handleName(e) {
        const name = e.target.value
        setName(name)
    }
    async function handleSurName(e) {
        const surNames = e.target.value
        setSurnames(surNames)
    }
    async function handleType(e) {
        const type = e.target.value
        setType(type)

    }
    async function handleRol(e) {
        const rol = e.target.value
        setRol(rol)
    }
    async function handleNumberDocument(e) {
        const numbreDocument = e.target.value
        setNumbreDocument(numbreDocument)
    }
    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }
    async function handleEmail(e) {
        const email = e.target.value
        setEmail(email);
    }

    const handleNewPassword = e => {
        setNewPassword(e.target.value);
    };

    const handleCheckNewPassword = e => {
        setConfirmNewPassword(e.target.value);
    };

    async function Registerprofile() {
        if (newPassword !== null) {
            if (confirmNewPassword !== null) {
                if (newPassword === confirmNewPassword) {

                    const data = JSON.stringify({
                        empresa: infoUser.company.replace('empresa_', ''),
                        Id: props.id,
                        name: name,
                        surNames: surNames,
                        type: type,
                        numbreDocument: numbreDocument,
                        phone: phone,
                        email: email,
                        roles: rol,
                        newPassword: newPassword,
                        checkNewPassword: confirmNewPassword
                    })
                    const registerHeaders = new Headers();
                    registerHeaders.append("Content-Type", "application/json")

                    const requesoption = {
                        method: 'PUT',
                        headers: registerHeaders,
                        body: data,
                        redirect: 'follow'
                    }

                    fetch(urlCreate, requesoption).then(response => response.json())
                        .then(dataResponse => {
                            if ("Actualizado Usuario" === dataResponse.msj) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Usuario correcto',
                                    text: 'Has editado un usuario correctamente',

                                }).then((result) => {
                                    window.location.href = './Usuarios'
                                })
                            } else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Error',
                                    text: 'Error no se pudo editar usuario',
                                })
                            }
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Las contraseñas no coinciden',
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Las contraseñas no coinciden',
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Pon una contraseña',
            })
        }
    }

    return (
        <>
            <div className="containerHeardANDTable">
                <div className="HeardANDFilter">
                    <LogoComponent />
                    <div className='containerButtonAdd'>
                        <div className="navigationContainer">
                            <div className="pageTitle">EDITAR USUARIO</div>
                        </div>
                    </div>
                    <div className='closeIntContainer'>
                        {/* <NavLink onClick={Cerrar} className='closeButton'>
                            <span className="tooltipProductMenos">Cerrar Sesión       </span>
                            <ImExit className='iconNav' />
                        </NavLink> */}
                        <CloseButton />
                    </div>
                </div>
            </div>
            <div id='overallContainer' className='overallContainer'>
                <Nav1 />
                <div className='registerContainer'>
                    <div className='drawerFormRegister'>
                        <form className='ContainerProfile'>
                            <div className="inputUsersContainer">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={handleName}
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Nombre</label>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="text"
                                    name="surNames"
                                    id="surNames"
                                    value={surNames}
                                    onChange={handleSurName}
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Apellidos</label>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="text"
                                    name="rolSelect"
                                    id="rolSelect"
                                    value={rol}
                                    onChange={handleRol}
                                    className="inputUsers"
                                    placeholder=""
                                    list="drawfemails"
                                />
                                <label htmlFor="name">Rol:</label>
                                <datalist id="drawfemails" value="rolSelect">
                                    <option value="Administrador" />
                                    <option value="Vendedor" />
                                </datalist>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="text"
                                    name="nitSelect"
                                    id="nitSelect"
                                    value={type}
                                    onChange={handleType}
                                    className="inputUsers"
                                    placeholder=""
                                    list="drawfemails2"
                                />
                                <label htmlFor="name">Tipo de documento:</label>
                                <datalist id="drawfemails2" value="nitSelect">
                                    <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                    <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                    <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                </datalist>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    name="citizenshipCard"
                                    id="citizenshipCard"
                                    value={numbreDocument}
                                    onChange={handleNumberDocument}
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Numero del documento</label>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    name="Phone"
                                    id="Phone"
                                    value={phone}
                                    onChange={handlePhone}
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Numero de Telefono</label>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmail}
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Correo electronico</label>
                            </div>
                            <div className="inputUsersContainer">
                                <input
                                    type={visibleKey1 ? 'password' : 'text'}
                                    name="password"
                                    id="password"
                                    onChange={handleNewPassword}
                                    className="inputUsers"
                                    placeholder=" "
                                    autoComplete="off"
                                />
                                <label htmlFor="name">Contraseña</label>
                                <NavLink onClick={() => { visibleKey1 ? setVisibleKey1(false) : setVisibleKey1(true) }}><img src={visibleKey1 ? invisible : visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                            </div>

                            <div className="inputUsersContainer">
                                <input
                                    type={visibleKey2 ? 'password' : 'text'}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    onChange={handleCheckNewPassword}
                                    autoComplete="off"
                                    className="inputUsers"
                                    placeholder=" "
                                />
                                <label htmlFor="name">Confirmar contraseña</label>
                                <NavLink onClick={() => { visibleKey2 ? setVisibleKey2(false) : setVisibleKey2(true) }}><img src={visibleKey2 ? invisible : visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                            </div>
                        </form>
                        <div className="buttonContainer">
                            <NavLink className='Textregister'><button className="button neon_Effect" onClick={off}><p>Atras</p></button></NavLink>
                            <button onClick={Registerprofile} className="button neon_Effect"><p>Actualizar</p></button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUsers