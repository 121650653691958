import React, { useContext, useEffect, useState } from 'react'
import LoginContext from "../Context/Logincontext"
import Nav1 from '../Component/Nav/Nav1'
import { NavLink } from 'react-router-dom'
import "./../styles/AddProduct.css"
import { urlApiServer } from '../Config/Config'
import '../styles/AddClient.css'
import Swal from 'sweetalert2'
import { ImExit } from 'react-icons/im'
import CloseButton from '../Component/CloseButton'
import LogoComponent from '../Component/LogoComponent'
import '../styles/AddBills.css'
import { validationBills } from './validations/Validations'
import { AiTwotoneDelete, AiFillEdit } from 'react-icons/ai';
import Resizer from 'react-image-file-resizer';

let splitImg = []
let saveProduct = []
let deleteSaveProduct = []

const urlGetBillById = urlApiServer + "billById"
const urlSignatureBill = urlApiServer + "signatureBill";

const AddBills = (props) => {

    const urlBill = urlApiServer + "addBill";
    const urlUpdateBill = urlApiServer + "updateBill";

    const urlgetConfig = urlApiServer + "getConfig";
    console.log("props: ", props.id, " props2: ", props.data)
    const [name, setName] = useState(props.id ? props.data.nombre : '')
    const [DNI, setDNI] = useState(props.id ? props.data.cedula : '')
    const [citizenshipCard, setCitizenshipCard] = useState(null)
    const [phone, setPhone] = useState(props.id ? props.data.telefono : null)
    const [city, setCity] = useState(props.id ? props.data.ciudad : '')
    const [quantity, setQuantity] = useState(props.id ? props.data.cantidad : '')
    const [unitValue, setUnitValue] = useState(props.id ? props.data.valorUnit : null)
    const [reason, setReason] = useState(props.id ? props.data.razon : null)
    const [detail, setDetail] = useState(props.id ? props.data.detalle : null)
    const [direction, setDirection] = useState(props.id ? props.data.direccion : null)
    const [typeDocument, setTypeDocument] = useState(props.id ? props.data.tipo_identificacion : null)
    const [date, setDate] = useState(props.id ? props.data.fecha : null)
    const [nameClient, setNameClient] = useState(props.id ? props.data.nombre_cliente : '')
    const [companyClient, setCompanyClient] = useState(props.id ? props.data.empresa_cliente : '')
    const [nitClient, setNitClient] = useState(props.id ? props.data.nit_cliente : '')
    const [accountBank, setAccountBank] = useState(props.id ? props.data.cuenta_banco : '')
    const [bank, setBank] = useState(props.id ? props.data.banco : '')
    const [position, setPosition] = useState(props.id ? props.data.posicion : '')
    const [dataShow, setDataShow] = useState(props.id ? props.data.mostrar : '')

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [oldConfig, setOldConfig] = useState("")
    const [logo, setLogo] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [hasImage3, setHasImage3] = useState(props.id ? true : false);
    const [Imagetest3, setImageTest3] = useState("")
    const [Imagetest3Old, setImageTest3Old] = useState(props.id ? props.data.firma : '')
    const [Imagen3, setImagen3] = useState("")

    // if (lastNameOne === undefined || lastNameOne === '') {
    //     var fullName = (name !== undefined ? name : '')
    // } else {
    //     var fullName = (name !== undefined ? name : '') + ' ' + (lastNameOne !== undefined ? lastNameOne : '');
    // }
    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }

            if (props.id) {
                const InicioHeaders = new Headers();
                InicioHeaders.append("Content-Type", "application/json");
                InicioHeaders.append("company", infoUser.company);
                InicioHeaders.append("id", props.id);

                const requesoption = {
                    method: 'GET',
                    headers: InicioHeaders,
                    redirect: 'follow'
                };
                await fetch(urlGetBillById, requesoption).then(response => response.json())
                    .then(dataResponse => {
                        console.log("dataResponse: ", dataResponse.data)
                        saveProduct = dataResponse.data
                        // if (200 === dataResponse.status) {
                        //     return Swal.fire({
                        //         icon: 'success',
                        //         title: '¡Hecho!',
                        //         text: dataResponse.msj,
                        //         allowOutsideClick: false,
                        //         confirmButtonText: 'Aceptar',
                        //     }).then((result) => {
                        //         if (result.isConfirmed) {
                        //             window.location.href = './Facturas';
                        //         }
                        //     })
                        // }
                        // if (400 === dataResponse.status) {
                        //     return Swal.fire({
                        //         icon: 'error',
                        //         title: '¡Error!',
                        //         text: dataResponse.msj,
                        //         confirmButtonText: 'Aceptar',
                        //     })
                        // }
                    })
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                setOldConfig(quotesResponse)
            })
        }())
    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { logo } = foundConfig;
                setLogo(logo);
            }
        }
    }, [oldConfig, infoUser])

    useEffect(() => {
    }, [refresh])

    if (logo) {
        splitImg = logo.split("|")
    }

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
        })
    }

    async function handleName(e) {
        const name = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setName(name)
        // }
    }

    async function handleNameClient(e) {
        const name = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setNameClient(name)
        // }
    }

    async function handleCompanyClient(e) {
        const company = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
        setCompanyClient(company)
        // }
    }

    async function handleNitClient(e) {
        const value = e.target.value;
        // if (/^[A-Za-z.\s]+$/.test(name) || name === '') {
            setNitClient(value)
        // }
    }
    
    async function handleDNI(e) {
        const dni = e.target.value.replace(/[^A-Z a-z0-9.]/g, "");
        setDNI(dni)
    }

    async function handlePhone(e) {
        const phone = e.target.value
        setPhone(phone)
    }

    async function handleCity(e) {
        const city = e.target.value
        setCity(city)
    }

    async function handleDate(e) {
        const city = e.target.value
        setDate(city)
    }

    async function handleDirection(e) {
        const direction = e.target.value
        setDirection(direction)
    }

    async function handleUnitValue(e) {
        const unitValue = e.target.value
        setUnitValue(unitValue)
    }

    async function handleReason(e) {
        const reason = e.target.value
        setReason(reason)
    }

    async function handleDetail(e) {
        const detail = e.target.value
        setDetail(detail)
    }

    async function handleQuantity(e) {
        const quantity = e.target.value
        setQuantity(quantity)
    }

    async function handleDocumentType(e) {
        let valueType = e.target.value
        console.log("valueType: ", valueType)
        if (/^[a-zA-Z ]+$/.test(valueType) || valueType === '') {
            setTypeDocument(valueType)
        }
    }

    async function handleDataShow(e) {
        let value = e.target.value
        setDataShow(value)
    }

    async function handleAccountBank(e) {
        const accountBank = e.target.value
        setAccountBank(accountBank)
    }

    async function handleBank(e) {
        const bank = e.target.value
        setBank(bank)
    }

    async function handlePosition(e) {
        const position = e.target.value
        setPosition(position)
    }

    async function handleReturn() {
        if (props.id) {
            props.edit()
        }
    }

    async function addNewBill() {
        let validateValues = [
            { access: name, vadidate: false, msj: "Recuerda llenar el campo de 'Nombre'" },
            { access: phone, vadidate: false, msj: "Recuerda llenar el campo de 'Telefono'" },
            { access: DNI, vadidate: false, msj: "Recuerda llenar el campo de 'Numero de Documento'" },
            { access: direction, vadidate: false, msj: "Recuerda llenar el campo de 'Direccion'" },
            { access: typeDocument, vadidate: false, msj: "Recuerda llenar el campo de 'Tipo de Documento'" },
            { access: city, vadidate: false, msj: "Recuerda llenar el campo de 'Ciudad'" },
            { access: date, vadidate: false, msj: "Recuerda llenar el campo de 'Fecha'" },
            // { access: quantity, vadidate: false, msj: "Recuerda llenar el campo de 'Cantidad'" },
            // { access: unitValue, vadidate: false, msj: "Recuerda llenar el campo de 'Valor unidad'" },
            // { access: reason, vadidate: false, msj: "Recuerda llenar el campo de 'Razon'" },
            // { access: detail, vadidate: false, msj: "Recuerda llenar el campo de 'Detalle'" },
        ]

        let validate = await validationBills(validateValues)
        if (validate.status) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Advertencia!',
                text: validate.item.msj,
                allowOutsideClick: false,
            })
        }


        const data = JSON.stringify({
            empresa: infoUser.company,
            name: name,
            phone: phone,
            DNI: DNI,
            direction: direction,
            typeDocument: typeDocument,
            city: city,
            date: date,
            accountBank: accountBank,
            bank: bank,
            reason: reason,
            nameClient: nameClient,
            companyClient: companyClient,
            products: saveProduct,
            deleteProducts: deleteSaveProduct,
            userId: infoUser.Id,
            position: position,
            signature: Imagetest3,
            oldSignature: Imagetest3Old,
            nitClient: nitClient,
            dataShow: dataShow,
            id: props.id,
        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: props.id ? 'PUT' : 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(props.id ? urlUpdateBill : urlBill, requesoption).then(response => response.json())
            .then(async dataResponse => {
                await sendfile3(Imagen3)
                if (200 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'success',
                        title: '¡Hecho!',
                        text: dataResponse.msj,
                        allowOutsideClick: false,
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = './Facturas';
                        }
                    })
                }
                if (400 === dataResponse.status) {
                    return Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: dataResponse.msj,
                        confirmButtonText: 'Aceptar',
                    })
                }
            })
    }

    async function sendfile3(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlSignatureBill, requestOptions)
            .then(response => response.text())
            .then(result => console.log("respuesta al subir img fondo: ", result))
            .catch(error => console.log('error', error));

    }

    const addBillProducts = () => {
        let product = {
            detalle: detail,
            valorUnit: unitValue,
            cantidad: quantity,
        }
        saveProduct.push(product)
        setRefresh(!refresh)
    };

    const handleFirma = (e) => {
        const image3 = e.target.files[0]
        try {
            Resizer.imageFileResizer(
                image3,
                200, // nuevo ancho
                300, // nuevo alto
                'png', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    const file = new File([blob], timestamp + "_" + image3.name, { type: "image/png" });
                    setImageTest3(file.name)
                    setHasImage3(file !== undefined ? true : false);
                    setImagen3(file)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage3(false);
        }

    }

    return (
        <>
            {(infoUser.login === true) ? (
                (infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                    <>
                        <div className="containerHeardANDTable">
                            <div className="HeardANDFilter">
                                <LogoComponent />
                                <div className='containerButtonAdd'>
                                    <div className="navigationContainer">
                                        <div className="pageTitle">{props.id ? "EDITAR FACTURA" : "CREAR FACTURA"}</div>
                                    </div>
                                </div>
                                <div className='closeIntContainer'>
                                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                        <ImExit  className='iconNav'/>
                                    </NavLink> */}
                                    <CloseButton />
                                </div>
                            </div>
                        </div>
                        <div id='overallContainer' className='overallContainer'>
                            <Nav1 />
                            <div className='drawerFormAddCuotes'>
                                <form className='ContainerClient'>
                                    <div className='containerInfo'>
                                        <div className='infoOne infoOneAddBill'>
                                            <p className='personalInformation'>REGISTRO</p>
                                            <div className='containerNameClient'>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={name}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleName}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={reason}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleReason}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Razon social<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={DNI}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleDNI}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Numero Documento</label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="nitSelect"
                                                        id="nitSelect"
                                                        className="inputCLient"
                                                        placeholder=""
                                                        list="drawfemails2"
                                                        onChange={handleDocumentType}
                                                        value={(typeDocument === '') ? ('') : (typeDocument)}
                                                    />
                                                    <label htmlFor="name">Tipo de documento: <em className='Obligatory'>*</em>  </label>
                                                    <datalist id="drawfemails2" value="nitSelect">
                                                        <option value="NIT">NIT</option>
                                                        <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                                        <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                                        <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                                    </datalist>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={direction}
                                                        onChange={handleDirection}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Dirección <em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={phone}
                                                        onChange={handlePhone}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Telefono <em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={city}
                                                        onChange={handleCity}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Ciudad<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={date}
                                                        onChange={handleDate}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Fecha<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={accountBank}
                                                        onChange={handleAccountBank}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nuero de Cuenta de Banco<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={bank}
                                                        onChange={handleBank}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre del Banco<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="direction"
                                                        id="direction"
                                                        value={position}
                                                        onChange={handlePosition}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Cargo en la empresa<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className={`file-upload ${hasImage3 ? 'has-image' : ''}`} style={{ marginLeft: '8%' }}>
                                                    <label htmlFor="file-input" className="upload-label">
                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                        <span className="upload-text">{hasImage3 ? 'firma almacenado' : 'Haz clic para cargar un firma'}</span>
                                                    </label>
                                                    <input id="file-input" onChange={handleFirma} type="file" className="file-input" accept=".jpg, .jpeg, .png" />
                                                    <div className="progress-bar"></div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <p className='personalInformation'>DATOS DEL CLIENTE</p>
                                            <div className='containerNameClient'>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={companyClient}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleCompanyClient}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre empresa<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={nitClient}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleNitClient}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nit empresa<em className='Obligatory'>*</em> </label>
                                                </div>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        value={nameClient}
                                                        name="Name"
                                                        id="Name"
                                                        onChange={handleNameClient}
                                                        className="inputCLient"
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="name">Nombre de cliente</label>
                                                </div>
                                                {
                                                    nameClient !== '' & companyClient !== ''  && 
                                                    (<>
                                                <div className="inputClientContainer">
                                                    <input
                                                        type="text"
                                                        name="nitSelect"
                                                        id="nitSelect"
                                                        className="inputCLient"
                                                        placeholder=""
                                                        list="drawfemails4"
                                                        onChange={handleDataShow}
                                                        value={(dataShow === '') ? ('') : (dataShow)}
                                                    />
                                                    <label htmlFor="name">Dato a mostrar en factura: <em className='Obligatory'>*</em>  </label>
                                                    <datalist id="drawfemails4" value="nitSelect2">
                                                        <option value={nameClient}>{nameClient}</option>
                                                        <option value={companyClient}>{companyClient}</option>
                                                    </datalist>
                                                </div>
                                                    </>)
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </form>
                                <form className='ContainerClient'>
                                    <div className='containerInfo'>
                                        <div className='infoOne infoOneAddBill'>
                                            <p className='personalInformation'>PRODUCTOS</p>
                                            {/* <form className='ContainerAddCuotes'>
                                            <div className='containerQuotes'> */}
                                            <div className='infoOneQuotes'>
                                                <div className="containerTable">
                                                    <table id='test-table' className="minimal-table">
                                                        <tr>
                                                            <th>Detalle</th>
                                                            <th>Cantidad</th>
                                                            <th>Valor unitario</th>
                                                            <th>Total</th>
                                                            <th>Editar</th>
                                                            <th>Borrar</th>
                                                        </tr>
                                                        {saveProduct.map((product, index) => {
                                                            async function deleteProduct1(index) {
                                                                const id = saveProduct[index]
                                                                Swal.fire({
                                                                    icon: 'question',
                                                                    text: 'estás seguro que deseas eliminar este item?',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Aceptar',
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        deleteSaveProduct.push(saveProduct[index])
                                                                        delete saveProduct[index]
                                                                        saveProduct = saveProduct.filter(function (el) {
                                                                            return el != null;
                                                                        });
                                                                        setRefresh(!refresh)
                                                                    }
                                                                })

                                                            }
                                                            async function Edit(index) {
                                                                // oldData = saveProduct[index].Priceiva
                                                                // oldIva = saveProduct[index].iva
                                                                // setId(index)
                                                                // setFullScreen(true)

                                                                await Swal.fire({
                                                                    title: 'Editar',
                                                                    html:
                                                                        `
                                                            <div>
                                                                <p>Producto:</p>
                                                                <input type="text" id="detalle" value="`+ saveProduct[index].detalle + `" class="swal2-input" placeholder="Producto">
                                                            </div>
                                                            <div>
                                                                <p>Precio del producto:</p>
                                                                <input type="text" id="valorUnit" value="`+ (saveProduct[index].valorUnit) + `" class="swal2-input" placeholder="Precio del producto">
                                                            </div>
                                                            <div>
                                                            <p>Cantidad:</p>
                                                                <input type="text" id="cantidad" value="`+ saveProduct[index].cantidad + `" class="swal2-input" placeholder="Cantidad">
                                                            </div>
                                                            `,
                                                                    showCancelButton: true,
                                                                    focusConfirm: false,
                                                                    preConfirm: () => {
                                                                        return [
                                                                            document.getElementById('detalle').value,
                                                                            document.getElementById('valorUnit').value,
                                                                            document.getElementById('cantidad').value
                                                                        ]
                                                                    }
                                                                }).then(response => {

                                                                    if (response.value) {
                                                                        const [producto, material, ancho, largo, calibre, cantidadItem, precioitem, mySelect] = response.value;
                                                                    }
                                                                    const priceAndQuantity = parseFloat(response.value[2]) * parseFloat(response.value[1])
                                                                    let ivaProduct = priceAndQuantity * (parseFloat(response.value[4]) / 100)
                                                                    const total = priceAndQuantity + ivaProduct
                                                                    // const id = historyValidation[index].Id


                                                                    let obj = {
                                                                        "detalle": response.value[0],
                                                                        'valorUnit': response.value[1],
                                                                        "cantidad": response.value[2],
                                                                        "id": saveProduct[index].Id
                                                                    }
                                                                    let updateArraySaveProduct = saveProduct.slice();
                                                                    updateArraySaveProduct[index] = obj;
                                                                    saveProduct = updateArraySaveProduct;
                                                                    // props.Reload(saveProduct)
                                                                    setRefresh(!refresh)
                                                                })

                                                            }
                                                            return (
                                                                <tr>
                                                                    <td>{product.detalle}</td>
                                                                    <td>${product.valorUnit}</td>
                                                                    <td>{product.cantidad}</td>
                                                                    <td>${(parseFloat(product.valorUnit) * parseFloat(product.cantidad))}</td>
                                                                    <td><AiFillEdit className='iconEdit' onClick={() => { Edit(index) }} /></td>
                                                                    <td><AiTwotoneDelete className='deleteButtonListProduct' onClick={() => { deleteProduct1(index) }} /></td>

                                                                </tr>

                                                            )
                                                        })}
                                                    </table>
                                                </div>

                                                {
                                                    (!props.id) && (
                                                        <>
                                                            <div className='containerFormulary'>
                                                                <div className="inputQuotesContainer">
                                                                    <textarea
                                                                        rows="4"
                                                                        cols="50"
                                                                        name="observacion"
                                                                        id="observacion"
                                                                        onChange={handleDetail}
                                                                        className="textareaQuotes textareaQuotesHeight"
                                                                        placeholder=" "
                                                                        value={detail}
                                                                    ></textarea>
                                                                    <label htmlFor="quote observationHeigth">Detalle</label>
                                                                </div>

                                                                <div className="inputClientContainer inputBillContainer">
                                                                    <input
                                                                        type="text"
                                                                        name="direction"
                                                                        id="direction"
                                                                        value={unitValue}
                                                                        onChange={handleUnitValue}
                                                                        className="inputCLient"
                                                                        placeholder=" "
                                                                    />
                                                                    <label htmlFor="name">Valor unitario <em className='Obligatory'>*</em> </label>
                                                                </div>

                                                                <div className="inputClientContainer inputBillContainer">
                                                                    <input
                                                                        type="text"
                                                                        name="direction"
                                                                        id="direction"
                                                                        value={quantity}
                                                                        onChange={handleQuantity}
                                                                        className="inputCLient"
                                                                        placeholder=" "
                                                                    />
                                                                    <label htmlFor="name">Cantidad <em className='Obligatory'>*</em> </label>
                                                                </div>
                                                            </div>
                                                            <div className='buttonContainerAddQuotes2'>
                                                                <div className="buttonQuotes neon_Effect" onClick={addBillProducts}><p>Agregar producto</p></div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            {/* </div>
                                            </form> */}
                                            <div className="containerLongText">
                                                <NavLink className='Textregister' to="/Facturas"><button className="button neon_Effect" onClick={handleReturn}><p>Atras</p></button></NavLink>
                                                <NavLink onClick={addNewBill} className='Textregister'><button className="button neon_Effect"><p>{props.id ? "Actualizar" : "Crear"}</p></button></NavLink>
                                            </div>
                                        </div>
                                    </div>

                                </form>


                            </div>
                        </div>
                    </>
                )
            ) : (window.location.href = './Iniciar_Sesión')}
        </>
    )

}

export default AddBills