import React, { useContext, useEffect, useState } from 'react'
import { Await, NavLink } from 'react-router-dom'
import { ImExit } from 'react-icons/im'
import LoginContext from '../../Context/Logincontext'
import Swal from 'sweetalert2'
import { AiTwotoneDelete, AiFillEdit } from 'react-icons/ai';

const AddProductMarmol = (props) => {
    let saveProduct = props.saveProduct
    let saveItemPrice = props.saveItemPrice
    let oldData = props.oldData;
    let oldIva = props.oldIva
    let toggleFullScreen = props.toggleFullScreen

    const [closeSession, setCloseSession] = useState(false)
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [deletoglee, setDeletogle] = useState(false)
    const [priceEnd, setPriceEnd] = useState()
    const [isFullScreen, setFullScreen] = useState(false);
    const [id, setId] = useState()


    return (
        <form className='ContainerAddCuotes'>
            <div className='containerQuotes'>
                <div className='infoOneQuotes'>
                    <p className='texProducttitule'>PRODUCTOS</p>
                    <div className="containerTable">
                        <table id='test-table' className="minimal-table">
                            <tr>
                                <th>Productos</th>
                                <th>Material</th>
                                <th>Calibre</th>
                                <th>Largo(m)</th>
                                <th>Ancho(cm)</th>
                                <th>Precio Unidad</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                                <th>Iva</th>
                                <th>Total + iva</th>
                                <th>Eliminar</th>
                                <th>Editar</th>
                            </tr>
                            {saveProduct.map((product, index) => {
                                async function deleteProduct1(index) {
                                    const id = saveProduct[index]
                                    Swal.fire({
                                        icon: 'question',
                                        text: 'estás seguro que deseas eliminar este item?',
                                        showCancelButton: true,
                                        confirmButtonText: 'Aceptar',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            setDeletogle(true)
                                            if (deletoglee === true) {
                                                saveItemPrice = []
                                                setPriceEnd(priceEnd - id.total)
                                                saveItemPrice.push(priceEnd - id.total)
                                                delete saveProduct[index]
                                                saveProduct = saveProduct.filter(function (el) {
                                                    return el != null;
                                                });
                                                setDeletogle(false)
                                            }
                                        } else {
                                        }
                                    })

                                }
                                
                                async function Edit(index) {
                                    oldData = saveProduct[index].Priceiva
                                    oldIva = saveProduct[index].iva
                                    setId(index)
                                    setFullScreen(true)
                                    props.Reload(index, saveProduct)
                                }


                                return (
                                    <tr>
                                        <td>{product.producto}</td>
                                        <td>{product.material}</td>
                                        <td>{product.caliber}</td>
                                        <td>{product.long}</td>
                                        <td>{product.ancho}</td>
                                        <td>${Math.round(product.price)}</td>
                                        <td>{product.cantidadItem}</td>
                                        <td>${Math.round(product.precioitem)}</td>
                                        <td>{product.iva}%</td>
                                        <td>${Math.round(product.total)}</td>
                                        <td><AiTwotoneDelete className='deleteButtonListProduct' onClick={() => { deleteProduct1(index) }} /></td>
                                        <td><AiFillEdit className='iconEdit' onClick={() => { Edit(index) }} /></td>

                                    </tr>

                                )
                            })}
                        </table>
                    </div>
                    <div className='buttonContainerAddQuotes2'>
                        <NavLink onClick={toggleFullScreen} className='Textregister'><button className="buttonQuotes neon_Effect"><p>Agregar producto</p></button></NavLink>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AddProductMarmol