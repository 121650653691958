import React, {useContext, useEffect, useState} from 'react'
import logo from '../Assest/logo.png'
import invisible from '../Assest/invisibleKey.png'
import visible from '../Assest/visibleKey.png'
import {NavLink} from 'react-router-dom'
import {urlApiServer} from '../Config/Config'
import Swal from 'sweetalert2'
import LoginContext from '../Context/Logincontext';
import '../styles/Register.css'
import Nav1 from '../Component/Nav/Nav1'
import {ImExit} from 'react-icons/im'
import CloseButton from '../Component/CloseButton'
import { useNavigate } from 'react-router-dom' 
import LogoComponent from '../Component/LogoComponent'

let splitImg = []


const Register = () => {

    const urlCreate = urlApiServer + "Register";
    const urlgetConfig = urlApiServer + "getConfig";

    const [name, setName] = useState()
    const [surNames, setSurnames] = useState()
    const [type, setType] = useState(null)
    const [rol, setRol] = useState(null)
    const [numbreDocument, setNumbreDocument] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [users, setUsers] = useState([])
    const [emailValidation, setEmailValidation] = useState("");
    const [password, setPassword] = useState()
    const [checkPassword, setCheckpassword] = useState()
    const {infoUser, setInfoUser} = useContext(LoginContext)
    const [visibleKey1, setVisibleKey1] = useState(true)
    const [visibleKey2, setVisibleKey2] = useState(true)
    const [oldConfig, setOldConfig]=useState("")  
    const [logo, setLogo]=useState("")  
    const [closeSession, setCloseSession] = useState(false)
    const navigate = useNavigate();

    const urlGetClient = urlApiServer + "users"

    useEffect(()=>{
        (async function (){
            
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")
    
            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse=>{
                setOldConfig(quotesResponse)
            })
        }())
    
    
    },[])

    useEffect(()=>{
        if (oldConfig) {
          const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
          if (foundConfig) {
            const {logo} = foundConfig;
            setLogo(logo);

          }
        }
    },[oldConfig, infoUser])

    if(logo){
        splitImg = logo.split("|")
    }

    useEffect(()=>{
        (async function (){
            
            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json")
    
            const requesoption ={
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlGetClient, requesoption).then(response => response.json()).then(usersResponse=>{
                setUsers(usersResponse)
            })
        }())
    },[email])

    useEffect(()=>{
        if(closeSession){
            setInfoUser({
                login : false,
                name: "unknow",
                email: "unknow",
                role: "unknow",
                company: "unknow",
                colorNeonAndButtons: "Red",
                backgroundColorIcons: "Red",
                colorIcons: "white",
                tittleColor: "white",
                letterColor: "white",
                mainColor: [82, 81, 81, 0.691],
                secondaryColor: [82, 81, 81, 0.3],
                logo: "defaultLogo.png",
                backgroundImg: "default.jpeg",
            })
        }
    },[closeSession])

    async function close(){
        window.location.href = './Login';
        setCloseSession(true)
    }

    async function handleName(e){

        const name = e.target.value
        if(name.length > 30){
            Swal.fire({
                icon: 'warning',
                text: 'Nombre muy largo'
            })
        }else{
            await setName(name)
        }
    }
    async function handleSurName(e){
        const surNames = e.target.value
        if(surNames.length > 30){
            Swal.fire({
                icon: 'warning',
                text: 'Apillos muy largo'
            })
        }else{
            await setSurnames(surNames)
        }
    }
    async function handleType(e){
            const type = e.target.value
            if(type === null){
                Swal.fire({
                    icon: 'warning',
                    text: 'Seleccione un tipo de documento'
                })
            }else{
                await setType(type)
            }
    }
    async function handleRol(e){
        const rol = e.target.value
        if(rol === null){
            Swal.fire({
                icon: 'warning',
                text: 'Seleccione un tipo de documento'
            })
        }else{
            await setRol(rol)
        }
}
    async function handleNumberDocument(e){
        const numbreDocument = e.target.value
        if(surNames.length > 30){
            Swal.fire({
                icon: 'warning',
                text: 'Documento muy larga'
            })
        }else{
            await setNumbreDocument(numbreDocument)
        }
    }
    async function handlePhone(e){
        const phone = e.target.value
        if(surNames.length > 30){
            Swal.fire({
                icon: 'warning',
                text: 'Celular muy larga'
            })
        }else{
            await setPhone(phone)
        }
    }
    async function handleEmail(e){
        const email = e.target.value
        const validation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
                if(validation.test(email)){
                    setEmailValidation("correo valido")
                    setEmail(email);
                }else{
                    setEmailValidation("correo invalido")
                }
    }
    async function handlePassword(e){
        const password = e.target.value
        if(surNames.length > 30){
            Swal.fire({
                icon: 'warning',
                text: 'Contraseña muy larga'
            })
        }else{
            await setPassword(password)
        }
    }
    async function handleCheckPassword(e){
        const checkPassword = e.target.value
        if(checkPassword === password){
            setCheckpassword(checkPassword);
        }
    }

    async function Registerprofile(){
        if(checkPassword === password){
                    if(emailValidation === "correo valido"){
                        const data = JSON.stringify({
                            empresa: infoUser.company.replace('empresa_', ''),
                            name: name,
                            surNames: surNames,
                            type: type,
                            numbreDocument: numbreDocument,
                            phone: phone,
                            email: email,
                            password: password,
                            checkPassword: checkPassword,
                            rol: rol
                        })
                        const registerHeaders = new Headers();
                        registerHeaders.append("Content-Type", "application/json")
                
                        const requesoption ={
                            method: 'POST',
                            headers: registerHeaders,
                            body: data,
                            redirect: 'follow'
                        }
                
                        fetch(urlCreate, requesoption).then(response => response.json())
                        .then(dataResponse =>{
                            if(298 === dataResponse.status){
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Registro correcto',
                                    text: 'Has registrado correctamente el usuario',
                                    allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                                  }).then((result) => {
                                    window.location.href = './Usuarios'
                                  })
                            }else if(1062 === dataResponse.msj.errno){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Este email ya existe',
                                  })
                            }else if(983 === dataResponse.status){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Tienes un campo vacio',
                                  })
                            }
                        })
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Tu correo esta en un formato invalido',
                          })
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Las claves no coinciden',
                      })
                }
    }


    return (
        <>
            {(infoUser.role === 'SuperUsuario')?(
                window.location.href = './Crear_Empresa'
            ):(
                <>
                    <div className="containerHeardANDTable">
                        <div className="HeardANDFilter">
                            <LogoComponent/>
                            <div className='containerButtonAdd'>
                                <div className="navigationContainer">
                                    <div className="pageTitle">CREAR USUARIO</div>
                                </div>
                            </div>
                            <div className='closeIntContainer'>
                            <CloseButton/>
                            </div>
                        </div>
                    </div>
                    <div id='overallContainer' className='overallContainer'>
                    <Nav1/>
                    {(infoUser.login === true)?( 
                        <div className='registerContainer'>
                            <div className='drawerFormRegister' autocomplete="off">
                                <form className='ContainerProfile'>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={handleName}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Nombre</label>
                                    </div> 
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="surNames"
                                            id="surNames"
                                            onChange={handleSurName}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Apellidos</label>
                                    </div>  
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="rolSelect"     
                                            id="rolSelect"
                                            onChange={handleRol}
                                            className="inputUsers"
                                            placeholder=""
                                            list="drawfemails"
                                        />
                                        <label htmlFor="name">Rol</label>
                                        <datalist id="drawfemails" value="rolSelect">
                                            <option value="Administrador" />
                                            <option value="Vendedor" />
                                        </datalist>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="nitSelect"     
                                            id="nitSelect"
                                            onChange={handleType}
                                            className="inputUsers"
                                            placeholder=""
                                            list="drawfemails2"
                                        />
                                        <label htmlFor="name">Tipo de documento:</label>
                                        <datalist id="drawfemails2" value="nitSelect">
                                            <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                            <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                            <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                        </datalist>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="citizenshipCard"
                                            id="citizenshipCard"
                                            onChange={handleNumberDocument}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Numero del documento</label>
                                    </div> 
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="Phone"
                                            id="Phone"
                                            onChange={handlePhone}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Numero de Telefono</label>
                                    </div> 
                                    <div className="inputUsersContainer">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleEmail}
                                            className="inputUsers"
                                            placeholder=" "
                                            autocomplete="off"
                                        />
                                        <label htmlFor="name">Correo electronico</label>
                                    </div> 
                                    <div className="inputUsersContainer">
                                        <input
                                            type={visibleKey1 ? 'password' : 'text'}
                                            name="password"
                                            id="password"
                                            onChange={handlePassword}
                                            className="inputUsers"
                                            placeholder=" "
                                            autocomplete="new-password"

                                        />
                                        <label htmlFor="name">Contraseña</label>
                                        <NavLink onClick={()=>{visibleKey1?setVisibleKey1(false):setVisibleKey1(true)}}><img src={visibleKey1?invisible:visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                                    </div>

                                    <div className="inputUsersContainer">
                                        <input
                                            type={visibleKey2 ? 'password' : 'text'}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            onChange={handleCheckPassword}
                                            autocomplete="new-password"
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Confirmar contraseña</label>
                                        <NavLink onClick={()=>{visibleKey2?setVisibleKey2(false):setVisibleKey2(true)}}><img src={visibleKey2?invisible:visible} alt="Key" className='password3' id="registerPassword"></img></NavLink>
                                    </div>
                                </form>
                                <div className="buttonContainer">
                                    <button  onClick={Registerprofile} className="button neon_Effect"><p>Registrar</p></button>
                                </div>
                            </div>
                        </div>
                    ):(window.location.href = './Iniciar_Sesión')}

                </div>
            </>
            )}
        </>
    )
}

export default Register