export function validateAddQuote(info){
    if(info.citizenshipCard === "no existe" || info.citizenshipCard === ""){
        return "quit"
    }

    if(info.contact === "no existe" || info.contact === ""){
        return "quit"
    }

    if(info.direction === "no existe" || info.direction === ""){
        return "quit"
    }

    if(info.email === "no existe" || info.email === ""){
        return "quit"
    }

    if(info.client === ''){
        return "quit"
    }

    // if(info.documentType === ""){
    //     return "quit"
    // }

return "save"
}