
import { useEffect, useRef, useState } from 'react';
import './modelPlans.css'
import { handleLinesX } from './functions';
import { handleLinesY } from './functions';
import { handleLessNegativeX } from './functions';
import { handleMorePositiveX } from './functions';
import { handleLessNegativeY } from './functions';
import { handleMorePositiveY } from './functions';
import { morePositiveXFunction } from './functions';
import { lessNegativeXFunction } from './functions';
import {
    morePositiveYFunction, createArrow, splashbackCoordinates, mainFigure, thickeningOfTheMainModelCoordinates,
    createHoleStove, createHoleDishwasher
} from './functions';
import { NavLink } from 'react-router-dom';
import { urlApiServer } from '../../Config/Config';
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa'
import Resizer from 'react-image-file-resizer';


// let props = {
//     Plans: {
//         coordinates: {
//             circule: {
//                 piece: "main",
//                 type: "circle",
//                 circleRadius: 25, // Radio
//                 circleBezier: 0.551915024494,
//                 depth: 2, // Altura 
//             },
//             measure: {
//                 highsplashback: 0
//             }
//         }
//     },
//     prePlans: {
//         Holes: {

//         }
//     }
// }

const ModelPlans = (props) => {
    const [file2d, setFile2d] = useState(null)
    const [scale, setScale] = useState(1);
    const [firsTime, setFirsTime] = useState(true);


    const urlsubirPreview = urlApiServer + "Uploadfilepreview";
    let canvas
    let context


    const canvasRef = useRef(null);


    useEffect(() => {
        let initPositionX = 0.50 // 12
        const sizeValue = 0.4
        const initPositionY = 0.10
        const separationFigure2 = 0.30
        const separationFigure3 = 0.70
        canvas = canvasRef.current;
        context = canvas.getContext('2d');
        var lienzo = document.getElementById("containerCanvas");

        const coordinatesFigure = props.Plans.coordinates
        const holes = props.Plans.Holes
        const preHoles = props.prePlans.Holes
        canvas.width = 1356
        canvas.height = 1100
        context.moveTo(0.05 * canvas.width, 0.05 * canvas.height);
        let arrayLinesX = []
        let arrayLinesY = []
        let lastMeasure = [null, null]
        let lessNegativeX = null
        let morePositiveX = null
        let lessNegativeY = null
        let morePositiveY = null
        let morePositiveYSeparation = null
        let lessNegativeXMain = null



        // Configura el estilo del texto
        context.font = '15px Arial';
        context.fillStyle = 'black';
        context.strokeStyle = 'black'

        // Declaracion de variables
        let valueRef = null
        let valueRefHeigth = null
        let totalThickness = null//props.Plans.measure.thickness + props.Plans.measure.highThickness
        let thickness = null
        let highThickness = null//props.Plans.measure.highThickness //* 10
        let highsplashback = 0//props.Plans.measure.highsplashback //!se necesita descomentar al terminar pruebas
        let mainDepthTotal = null
        let mainDepth = null
        let count
        let xMayor = 0
        let yMayor = 0

        const middleDisplacement = 0.7

        const figureColor = "rgba(225, 225, 225, 0.371)"
        const type = props.Plans.coordinates.tableCircule ? true : false 
        if (!type) {

            // Creacion de figura
            for (const key in coordinatesFigure) {
                totalThickness = coordinatesFigure[key].depth + totalThickness
                if (coordinatesFigure[key].piece === "main") {
                    coordinatesFigure[key].points.map((item, index)=>{
                        if(item[0] >= xMayor){
                            xMayor = item[0];
                        }
                        if(item[1] > yMayor){
                            yMayor = item[1];
                        }
                    })

                    if(xMayor > yMayor){
                        valueRef = xMayor
                    }else{
                        valueRef = yMayor
                        // initPositionX = 0.50
                    }
                    // valueRef = coordinatesFigure[key].points[1][0]
                    // console.log("valueRef :", valueRef)
                    valueRefHeigth = coordinatesFigure[key].points[2][1]
                    mainDepthTotal = coordinatesFigure[key].depth + totalThickness
                    mainDepth = coordinatesFigure[key].depth

                }
                count++
            }

            for (const key in coordinatesFigure) {
                const figure = coordinatesFigure[key].points
                const obj = coordinatesFigure[key];
                morePositiveX = null
                lessNegativeX = null
                lessNegativeY = null
                morePositiveY = null
                lastMeasure = [null, null]
                // console.log("figure: ", figure)
                // console.log("obj: ", obj)
                context.beginPath();
                figure.map((item, index) => {
                    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * item[0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * item[1] / valueRef);

                    arrayLinesX = handleLinesX(arrayLinesX, item)
                    arrayLinesY = handleLinesY(arrayLinesY, item)
                    morePositiveX = handleMorePositiveX(morePositiveX, item, lastMeasure)
                    lessNegativeX = handleLessNegativeX(lessNegativeX, item, lastMeasure)
                    lessNegativeY = handleLessNegativeY(lessNegativeY, item, lastMeasure)
                    morePositiveY = handleMorePositiveY(morePositiveY, item, lastMeasure)
                    lastMeasure = item
                    if (obj.piece === "main") {
                        morePositiveYSeparation = morePositiveY
                        lessNegativeXMain = lessNegativeX
                    }

                });
                // console.log("morePositiveYSeparation: ", morePositiveYSeparation)
                context.save()
                context.fillStyle = figureColor;
                context.fill();
                context.restore()
                context.stroke();
                // console.log("main: ", key)
                // console.log("Piece: ", obj.piece)
                if (obj.piece === "main") {
                    mainFigure(context, canvas, sizeValue, lessNegativeX, lessNegativeY, middleDisplacement, valueRef, initPositionX, initPositionY)
                } else if (key === "splashbackCoordinates") {
                    splashbackCoordinates(context, canvas, lessNegativeX, lessNegativeY, sizeValue, initPositionX, initPositionY, valueRef, middleDisplacement, morePositiveX, morePositiveY)
                } else if (key === "thickeningOfTheMainModelCoordinates") {
                    thickeningOfTheMainModelCoordinates(context, canvas, morePositiveX, initPositionX, initPositionY, sizeValue, valueRef, middleDisplacement, lessNegativeX, morePositiveY, lessNegativeY)
                } else {
                    // console.log("morePositiveX: ", morePositiveX)
                    let midX = (morePositiveX[0] + morePositiveX[2]) / 2
                    let midY = (morePositiveX[1] + morePositiveX[3]) / 2
                    let abs = null

                    context.save()
                    let referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - 0.2) / valueRef
                    let referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY - middleDisplacement) / valueRef;
                    morePositiveXFunction(context, referenceX, referenceY, morePositiveX)

                    midX = (morePositiveY[0] + morePositiveY[2]) / 2
                    midY = (morePositiveY[1] + morePositiveY[3]) / 2
                    referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - middleDisplacement) / valueRef//initPositionX * canvas.width + sizeValue * canvas.width / 2;
                    referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + 0.2) / valueRef;
                    morePositiveYFunction(context, referenceX, referenceY, morePositiveY)
                }

            }

            for (const key in holes) {
                context.beginPath()
                let lastMeasure = [null, null]
                morePositiveX = null
                lessNegativeY = null
                lessNegativeX = null
                holes[key].points.map((item, index) => {
                    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * item[0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * item[1] / valueRef);

                    arrayLinesX = handleLinesX(arrayLinesX, item)

                    arrayLinesY = handleLinesY(arrayLinesY, item)

                    lessNegativeX = handleLessNegativeX(lessNegativeX, item, lastMeasure)
                    morePositiveX = handleMorePositiveX(morePositiveX, item, lastMeasure) //*
                    lessNegativeY = handleLessNegativeY(lessNegativeY, item, lastMeasure)
                    lastMeasure = item

                });
                context.stroke()

                for (const value in preHoles) {
                    if (value === key) {
                        if (preHoles[value].type === "stove") {
                            const separationCircule25 = 0.25
                            const coordOnePozuelo = holes[key].points[0]
                            const coordTwoPozuelo = holes[key].points[1]
                            const coordThreePozuelo = holes[key].points[2]
                            const coordFourPozuelo = holes[key].points[3]
                            const separationCircule75 = 0.75
                            // console.log("coordOnePozuelo: ", coordOnePozuelo)
                            // console.log("coordTwoPozuelo: ", coordTwoPozuelo)
                            // console.log("coordFourPozuelo: ", coordFourPozuelo)

                            createHoleStove(context, canvas, initPositionX, initPositionY, sizeValue, coordOnePozuelo, separationCircule25, coordTwoPozuelo, coordFourPozuelo, valueRef, separationCircule75)
                        } else if (preHoles[value].type === "dishwasher") {
                            createHoleDishwasher(context, canvas, initPositionX, initPositionY, sizeValue, holes, key, valueRef)
                        } else if (preHoles[value].type === "deep") {

                        }
                    }
                }

                let midX = (lessNegativeX[0] + lessNegativeX[2]) / 2
                let midY = (lessNegativeX[1] + lessNegativeX[3]) / 2
                let referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef
                let referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + middleDisplacement) / valueRef;
                lessNegativeXFunction(context, referenceX, referenceY, lessNegativeX)

                midX = (lessNegativeY[0] + lessNegativeY[2]) / 2
                midY = (lessNegativeY[1] + lessNegativeY[3]) / 2
                context.save()
                let abs = lessNegativeY[0] - lessNegativeY[2]
                context.fillText(((Math.abs(abs / 2) / 10).toFixed(2)) + 'm', initPositionX * canvas.width - sizeValue * canvas.width * (midX + middleDisplacement) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY + -0.2) / valueRef);
                context.restore()
            }


            //* // Creacion de proyecciones
            context.beginPath();
            context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * 0 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveYSeparation[1] / valueRef);
            context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lessNegativeXMain[0] / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveYSeparation[1] / valueRef);
            context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lessNegativeXMain[0] / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveYSeparation[1] + totalThickness) / valueRef);
            context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * 0, initPositionY + (separationFigure2) * canvas.height + sizeValue * canvas.width * (morePositiveYSeparation[1] + totalThickness) / valueRef);
            context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * 0 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveYSeparation[1] / valueRef);
            context.save()
            context.fillStyle = figureColor;
            context.fill();
            context.restore()
            context.stroke();

            context.beginPath();
            context.lineTo(initPositionX + separationFigure3 * canvas.width, initPositionY * canvas.height);
            context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * -totalThickness / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * 0);
            context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * -totalThickness / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * valueRefHeigth / valueRef);
            context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * 0, initPositionY * canvas.height + sizeValue * canvas.width * valueRefHeigth / valueRef);
            context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * 0, initPositionY * canvas.height + sizeValue * canvas.width * 0);
            context.save()
            context.fillStyle = figureColor;
            context.fill();
            context.restore()
            context.stroke();

            let depthDeg = 0
            let lastOperation = (initPositionX * canvas.width - sizeValue * canvas.width * 0 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveYSeparation[1] / valueRef)//(initPositionY + (separationFigure2) * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + totalThickness) / valueRef)
            const keys = Object.keys(coordinatesFigure);
            for (let i = keys.length - 1; i >= 0; i--) {
                const key = keys[i];
                const obj = coordinatesFigure[key];
                context.save()
                if (obj.piece !== "main") {
                    context.setLineDash([7, 7]);
                    context.globalAlpha = 0.2;
                }

                context.beginPath();
                context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * 0 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveYSeparation[1] + depthDeg) / valueRef); // ((morePositiveY[1] + totalThickness) - depthDeg)
                context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lessNegativeXMain[0] / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveYSeparation[1] + depthDeg) / valueRef);
                context.stroke();


                context.beginPath();
                context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * -depthDeg / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * 0);
                context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * -depthDeg / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * valueRefHeigth / valueRef);
                context.stroke();
                context.restore()
                depthDeg = depthDeg + obj.depth
                lastOperation = createArrow(context, canvas, initPositionX, initPositionY, sizeValue, valueRef, separationFigure2, depthDeg, morePositiveYSeparation, totalThickness, lastOperation, obj.depth)


            }

            context.setLineDash([7, 7]);
            context.globalAlpha = 0.2;

            arrayLinesX.map((lines, index) => {
                context.beginPath();
                context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lines[0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * lines[1] / valueRef);
                context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lines[0] / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (totalThickness + morePositiveYSeparation[1]) / valueRef);
                context.stroke();
            })

            arrayLinesY.map((lines, index) => {
                context.beginPath();
                context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * lines[0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * lines[1] / valueRef);
                context.lineTo(initPositionX + separationFigure3 * canvas.width - sizeValue * canvas.width * -totalThickness / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * lines[1] / valueRef);
                context.stroke();
            })


        } else {
            let alto = props.Plans.coordinates.tableCircule.depth
            let separationProjection = 20
            const sizeFigure = 10
            let centerX = initPositionX * canvas.width - 0.40 * canvas.width / 2;
            let centerY = initPositionY * canvas.height + 0.40 * canvas.width / 2;
            // context.moveTo(0.05 * canvas.width, 0.05 * canvas.height);


            let radius = (0.40 * canvas.width / 2) * scale;
            let radiusText = props.Plans.coordinates.tableCircule.circleRadius;
            let lineStartXRight = centerX + radius + 100; // Desde la derecha del círculo
            let lineStartXLeft = centerX - radius;
            let lineStartYDown = centerY + radius; // Desde la derecha del círculo
            let lineStartYUp = centerY - radius;
            let lineStartY = centerY; // Mismo nivel que el centro del círculo
            let lineEndX = lineStartXRight; // Misma coordenada X para la línea vertical
            let lineEndXLeft = lineStartXLeft; // Misma coordenada X para la línea vertical
            let lineEndY = centerY + radius * 2; // Hasta el doble del tamaño del círculo

            // Dibujar figura
            context.beginPath();
            context.arc(centerX, centerY, radius, 0, Math.PI * 2);
            context.stroke();

            // Lineas de separacion
            context.beginPath();
            context.save()
            context.setLineDash([7, 7]);
            context.globalAlpha = 0.2;
            context.moveTo(centerX, lineStartYUp);
            context.lineTo(lineStartXRight, lineStartYUp);
            context.stroke();
            context.restore()


            context.beginPath();
            context.save()
            context.setLineDash([7, 7]);
            context.globalAlpha = 0.2;
            context.lineTo(centerX, lineStartYDown);
            context.lineTo(lineStartXRight, lineStartYDown);
            context.stroke();
            context.restore()

            context.beginPath();
            context.save()
            context.setLineDash([7, 7]);
            context.globalAlpha = 0.2;
            context.lineTo(lineStartXLeft, lineStartY);
            context.lineTo((centerX), lineStartY);
            context.stroke();
            context.restore()

            // Texto en la mitad
            context.save()
            context.translate((centerX - 30), (lineStartY - 5));
            context.fillText((Math.abs(radiusText / 2) / 10) + 'm', 0, 0);
            context.restore()

            // Linea de indicacion del alto
            context.save()
            context.beginPath();
            context.setLineDash([7, 7]);
            context.globalAlpha = 0.2;
            context.lineTo(lineStartXRight, (lineStartYDown+ 12));
            context.lineTo((lineStartXRight + (alto * sizeFigure)),(lineStartYDown+ 12));
            context.stroke();
            context.restore()

            context.save()
            context.beginPath();
            context.lineTo(lineStartXRight, (lineStartYDown+ 5));
            context.lineTo(lineStartXRight, (lineStartYDown+ 20));
            context.stroke();
            context.restore()

            context.save()
            context.beginPath();
            context.lineTo((lineStartXRight + (alto * sizeFigure)), (lineStartYDown+ 5));
            context.lineTo((lineStartXRight + (alto * sizeFigure)), (lineStartYDown+ 20));
            context.stroke();
            context.restore()

            // Flecha y texto
            let midX = (lineStartXRight + lineStartXRight + (alto * sizeFigure)) / 2
            let midY = (lineStartYDown + lineStartYDown) / 2

            // context.save()
            // context.beginPath();
            // context.lineTo((midX + separationProjection), midY);
            // context.lineTo((midX + 50), midY);
            // context.stroke();
            // context.restore()


            // context.save()
            // context.beginPath();
            // context.lineTo((midX + separationProjection), midY);
            // context.lineTo((midX + 30), (midY + 10));
            // context.stroke();
            // context.restore()

            // context.save()
            // context.beginPath();
            // context.lineTo((midX + separationProjection), midY);
            // context.lineTo((midX + 30), (midY - 10));
            // context.stroke();
            // context.restore()

            context.save()
            context.translate((midX-10), (midY+35));
            context.fillText((Math.abs(alto / 2) / 10) + 'm', 0, 0);
            context.restore()

            // Poryecciones
            context.beginPath();
            context.lineTo(lineStartXRight, lineStartYDown);
            context.lineTo(lineStartXRight, lineStartYUp);
            context.lineTo((lineStartXRight + (alto * sizeFigure)), lineStartYUp);
            context.lineTo((lineStartXRight + (alto * sizeFigure)), lineStartYDown);
            context.lineTo(lineStartXRight, lineStartYDown);
            context.stroke();
        }

        if (firsTime) {
            takeScreenshot(context)
            setFirsTime(false)
        }

    }, [scale])



    async function takeScreenshot() {
        let imgData = canvas.toDataURL("image/png");

        const response = await fetch(imgData);
        const blob = await response.blob();
        const date = new Date();
        const timestamp = date.getTime();

        const file = new File([blob], props.edit === "Edit" ? props.request.image_2d : "Modelo2D_" + timestamp + ".jpg", { type: "image/png" });
        setFile2d(file)


    }

    async function off() {
        if (props.validate === 'addProduct') {
            props.exitBack()
        } else if (props.validate === 'historyProducts') {
            props.back()
        }
        else if (props.validate === 'editProduct') {
            props.exitBack()
        }
    }

    async function sendfilePreview(file) {

        try {
            const formdata = new FormData();

            formdata.append("file", file);

            formdata.append(file.name, "foto");
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(urlsubirPreview, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    async function createHistoryProduct() {

        if (props.validate === 'historyProducts') {
            let data = {
                id: props.edit === 'Edit' ? props.historyValidation.Id : undefined,
                empresa: props.request.empresa,
                producto: props.Plans.name,
                material: props.Plans.nameTexture,
                caliber: props.request.caliber,
                long: props.request.long,
                ancho: props.Plans.width,
                precioitem: props.request.precioitem,
                cantidadItem: props.request.cantidadItem,
                total: props.request.total,
                Priceiva: props.request.Priceiva,
                iva: props.request.iva,
                image_3d: props.request.image_3d,
                image_2d: file2d,
                price: props.request.price,
                modeljson: props.request.modeljson,
                prePlans: props.prePlans,
                Plans: props.Plans
            }
            props.functionRequest(data)

            props.fullBack()
        } else if (props.validate === 'addProduct') {
            const urlProducto = urlApiServer + "addproduct";
            const data = JSON.stringify({
                empresa: props.request.empresa,
                product: props.request.product,
                material: props.request.material,
                long: props.request.long,
                caliber: props.request.caliber,
                price: props.request.price,
                iva: props.request.iva,
                modeljson: props.prePlans,
                Model: file2d.name,
                image: props.request.image.name,
                ancho: props.request.ancho
            })
            sendfilePreview(props.request.image)

            // console.log("Imagen: ", props.request.image)
            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json")

            const requesoption = {
                method: 'POST',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            }

            await fetch(urlProducto, requesoption).then(response => response.json())

                .then(dataResponse => {
                    // sendfilePreview(file2d)
                    props.fullBack()
                })
        } else if (props.validate === 'editProduct') {
            const urlEdit = urlApiServer + "editproduct";
            const data = JSON.stringify({
                empresa: props.request.empresa,
                Id: props.id,
                product: props.request.product,
                material: props.request.material,
                long: props.request.long,
                caliber: props.request.caliber,
                price: props.request.price,
                iva: props.request.iva,
                modeljson: props.prePlans,
                Model: file2d.name,
                image: props.request.image.name,
                ancho: props.request.ancho
            });

            const registerHeaders = new Headers();
            registerHeaders.append("Content-Type", "application/json");

            const requesoption = {
                method: 'PUT',
                headers: registerHeaders,
                body: data,
                redirect: 'follow'
            };

            await fetch(urlEdit, requesoption)
                .then(response => response.json())
                .then(dataResponse => {
                    // sendfilePreview(file2d);
                    sendfilePreview(props.request.image);
                    props.fullBack();
                });
        }
    }



    // function updateButtonPosition() {
    //     const buttonContainer = document.querySelector('.button-container');
    //     const canvas = document.getElementById('myCanvas');
    //     const windowWidth = window.innerWidth;
    //     const windowHeight = window.innerHeight;

    //     buttonContainer.style.display = (windowWidth < 320 || windowHeight < 524) ? 'none' : 'block';
    // }

    // // Actualiza la posición de los botones en el evento de redimensionar la ventana
    // window.addEventListener('resize', updateButtonPosition);


    const zoomOut = (event) => {
        event.preventDefault();
        setScale(scale / 1.5); // Zoom out
    };

    const zoomIn = (event) => {
        event.preventDefault();
        setScale(scale * 1.5); // Zoom in
    };

    return (
        <div className='mainContainerPdf'>
            <div className='containerScrollPdf'>
                <div className='containerCanvas' id="containerCanvas">
                    <canvas ref={canvasRef} className='canvasModelPlans'
                        style={{
                            transform: `scale(${scale})`, // Aplica el factor de escala al contenido
                            //transformOrigin: 'top left', // Punto de origen del escalado
                        }} />
                </div>
            </div>
            <div className='buttonContainerPdfTop'>
                <button className="buttonLeftZoom neon_Effect" onClick={zoomOut}><FaSearchMinus></FaSearchMinus></button>
                <button className="buttonRigthZoom neon_Effect" onClick={zoomIn}><FaSearchPlus></FaSearchPlus></button>
            </div>
            <div className='buttonContainerPdf'>
                <NavLink className='quitButton' onClick={off}><button className="button neon_Effect" ><p>Volver</p></button></NavLink>
                <NavLink className='saveButton' onClick={createHistoryProduct}><button className="button neon_Effect" ><p>Guardar</p></button></NavLink>
            </div>
        </div>
    )
}

export default ModelPlans