import React, { useContext, useEffect, useState } from 'react'
import { Await, NavLink } from 'react-router-dom'
import { ImExit } from 'react-icons/im'
import LoginContext from '../../Context/Logincontext'

const CloseButton = () => {
    const [closeSession, setCloseSession] = useState(false)
    const {infoUser, setInfoUser} = useContext(LoginContext)


    useEffect(()=>{
        if(closeSession){
            setInfoUser({
                ...infoUser,
                login : false,
            })
        }
    },[closeSession])
    
    async function close(){
        setInfoUser({
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo.png",
            backgroundImg: "default.jpeg",
            login : false,
        })
        window.location.href = './Login';
        setCloseSession(true)
    }

    return (
        <NavLink onClick={close} className='closeButton'>
            <span className="tooltipProductMenos">Cerrar Sesión</span>
            <ImExit className='iconNav' />
        </NavLink>
    )
}

export default CloseButton