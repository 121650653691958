const validationValue = 0.09
let spacingYMoreNegative = 0

export function handleLinesX(arrayLinesX, item) {
    // se añaden coordenadas al array de lineas
    let position = null
    let addLine = false
    let firts = true

    for (let i = 0; i < arrayLinesX.length; i++) {
        firts = false
        const lines = arrayLinesX[i];
        if (item[0] === lines[0]) { // se compara si la X esta repetida
            if (item[1] > lines[1]) { // se compara con la Y
                position = i
                addLine = false
                break
            } else {
                addLine = false
                break
            }
        } else {
            addLine = true
        }
    }
    if (firts) {
        arrayLinesX.push(item)
    }

    if (position !== null) {
        arrayLinesX[position] = item
        addLine = false
    }
    if (addLine === true) {
        arrayLinesX.push(item)
    }

    return arrayLinesX
}

export function handleLinesY(arrayLinesY, item) {
    let position = null
    let addLine = false
    let firts = true

    for (let i = 0; i < arrayLinesY.length; i++) {
        firts = false
        const lines = arrayLinesY[i];
        if (item[1] === lines[1]) {
            if (item[0] < lines[0]) {
                position = i
                addLine = false
                break
            } else {
                addLine = false
                break
            }
        } else {
            addLine = true
        }
    }
    if (firts) {
        arrayLinesY.push(item)
    }

    if (position !== null) {
        arrayLinesY[position] = item
        addLine = false
    }
    if (addLine === true) {
        arrayLinesY.push(item)
    }

    return arrayLinesY
}

export function handleMorePositiveX(lessNegativeX, item, lastMeasure) {
    if (lessNegativeX !== null) {
        if ((item[0] <= lessNegativeX[0]) && (lastMeasure[0] <= lessNegativeX[0])) {
            return [item[0], item[1], lastMeasure[0], lastMeasure[1]];
        } else {
            return lessNegativeX
        }
    } else {
        return item;
    }
}

export function handleLessNegativeX(morePositiveX, item, lastMeasure) {
    if (morePositiveX !== null) {
        if ((item[0] >= morePositiveX[0]) && (lastMeasure[0] >= morePositiveX[0])) {
            return [item[0], item[1], lastMeasure[0], lastMeasure[1]];
        } else {
            return morePositiveX
        }
    } else {
        return item;
    }
}

export function handleLessNegativeY(lessNegativeY, item, lastMeasure) {
    if (lessNegativeY !== null) {
        if ((item[1] <= lessNegativeY[1]) && (lastMeasure[1] <= lessNegativeY[1])) {
            return [item[0], item[1], lastMeasure[0], lastMeasure[1]];
        } else {
            return lessNegativeY
        }
    } else {
        return item;
    }
}

export function handleMorePositiveY(morePositiveY, item, lastMeasure) {
    if (morePositiveY !== null) {
        if ((item[1] >= morePositiveY[1]) && (lastMeasure[1] >= morePositiveY[1])) {
            return [item[0], item[1], lastMeasure[0], lastMeasure[1]];
        } else {
            return morePositiveY
        }
    } else {
        return item;
    }
}

export function morePositiveXFunction(context, referenceX, referenceY, morePositiveX) {
    context.save()
    context.translate(referenceX, referenceY);
    context.rotate(Math.PI / 2)
    const abs = morePositiveX[1] - morePositiveX[3]
    context.fillText(((Math.abs(abs/2) / 10).toFixed(2)) + 'm', 0, 0);
    context.restore()
    return context
}

export function lessNegativeXFunction(context, referenceX, referenceY, lessNegativeX) {
    context.save()
    context.translate(referenceX, referenceY);
    context.rotate(-Math.PI / 2)
    const abs = lessNegativeX[1] - lessNegativeX[3]
    context.fillText(((Math.abs(abs / 2) / 10).toFixed(2)) + 'm', 0, 0);
    context.restore()
    return context
}

export function morePositiveYFunction(context, referenceX, referenceY, morePositiveY) {
    context.save()
    context.translate(referenceX, referenceY);
    context.rotate(Math.PI)
    const abs = morePositiveY[0] - morePositiveY[2]

    context.fillText(((Math.abs(abs/2) / 10).toFixed(2)) + 'm', 0, 0);

    context.restore()
    return context
}

export function createArrow(context, canvas, initPositionX, initPositionY, sizeValue, valueRef, separationFigure2, depth, morePositiveY, totalThickness, lastOperation, depthObj) {
    context.font = '13px Arial';

    context.save()
    context.setLineDash([7, 7]);
    context.globalAlpha = 0.2;
    context.beginPath()
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.4 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveY[1] / valueRef);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.4 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + depth) / valueRef);
    context.stroke()
    context.restore()

    context.beginPath()
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.1 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveY[1] / valueRef);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.8 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * morePositiveY[1] / valueRef);
    context.stroke()

    context.beginPath()
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.1 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + depth) / valueRef);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -0.8 / valueRef, initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + depth) / valueRef);
    context.stroke()

    let midY = ((initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + depth) / valueRef) + lastOperation) / 2
    lastOperation = (initPositionY + separationFigure2 * canvas.height + sizeValue * canvas.width * (morePositiveY[1] + depth) / valueRef)
    let midX = ((initPositionX * canvas.width - sizeValue * canvas.width * -0.9 / valueRef) + (initPositionX * canvas.width - sizeValue * canvas.width * -0.4 / valueRef)) / 2

    context.beginPath()
    context.lineTo(midX, midY);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * -3 / valueRef, midY);
    context.fillText(((Math.abs(depthObj / 2) / 10).toFixed(2)) + 'm', initPositionX * canvas.width - sizeValue * canvas.width * -3 / valueRef, midY);
    context.stroke()

    context.beginPath()
    context.lineTo(midX, midY);
    context.lineTo((midX + 12), (midY + 6));
    context.stroke()

    context.beginPath()
    context.lineTo(midX, midY);
    context.lineTo((midX + 12), (midY - 6));
    context.stroke()

    return lastOperation
}

export function mainFigure(context, canvas, sizeValue, lessNegativeX, lessNegativeY, middleDisplacement, valueRef, initPositionX, initPositionY) {
    let midX = (lessNegativeX[0] + lessNegativeX[2]) / 2
    let midY = (lessNegativeX[1] + lessNegativeX[3]) / 2
    let referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef
    let referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + middleDisplacement) / valueRef;
    lessNegativeXFunction(context, referenceX, referenceY, lessNegativeX)

    midX = (lessNegativeY[0] + lessNegativeY[2]) / 2
    midY = (lessNegativeY[1] + lessNegativeY[3]) / 2
    context.save()
    let abs = lessNegativeY[0] - lessNegativeY[3]
    context.fillText(((Math.abs(abs / 2) / 10).toFixed(2)) + 'm', initPositionX * canvas.width - sizeValue * canvas.width * (midX + middleDisplacement) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY + -0.2) / valueRef);
    context.restore()
}

export function splashbackCoordinates(context, canvas, lessNegativeX, lessNegativeY, sizeValue, initPositionX, initPositionY, valueRef, middleDisplacement, morePositiveX, morePositiveY) {
    let midX = (morePositiveX[0] + morePositiveX[2]) / 2
    let midY = (morePositiveX[1] + morePositiveX[3]) / 2
    let abs = null

    context.save()
    let referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - 0.2) / valueRef
    let referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY - middleDisplacement) / valueRef;
    morePositiveXFunction(context, referenceX, referenceY, morePositiveX)

    midX = (morePositiveY[0] + morePositiveY[2]) / 2
    midY = (morePositiveY[1] + morePositiveY[3]) / 2
    referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - middleDisplacement) / valueRef//initPositionX * canvas.width + sizeValue * canvas.width / 2;
    referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + 0.2) / valueRef;
    morePositiveYFunction(context, referenceX, referenceY, morePositiveY)
}

export function thickeningOfTheMainModelCoordinates(context, canvas, morePositiveX, initPositionX, initPositionY, sizeValue, valueRef, middleDisplacement, lessNegativeX, morePositiveY, lessNegativeY) {
    let midX = (morePositiveX[0] + morePositiveX[2]) / 2
    let midY = (morePositiveX[1] + morePositiveX[3]) / 2
    let abs = null

    context.save()
    let referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - 0.2) / valueRef
    let referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY - middleDisplacement) / valueRef;
    morePositiveXFunction(context, referenceX, referenceY, morePositiveX)

    midX = (morePositiveY[0] + morePositiveY[2]) / 2
    midY = (morePositiveY[1] + morePositiveY[3]) / 2
    referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX - middleDisplacement) / valueRef//initPositionX * canvas.width + sizeValue * canvas.width / 2;
    referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + 0.2) / valueRef;
    morePositiveYFunction(context, referenceX, referenceY, morePositiveY)

    midX = (lessNegativeX[0] + lessNegativeX[2]) / 2
    midY = (lessNegativeX[1] + lessNegativeX[3]) / 2
    referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef
    referenceY = initPositionY * canvas.height + sizeValue * canvas.width * (midY + middleDisplacement) / valueRef;
    let numberLessNegative = lessNegativeX[1] - lessNegativeX[3]
    if ((Math.abs(numberLessNegative / 2).toFixed(2) / 10) <= validationValue) {
        referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX + 3) / valueRef
    }
    lessNegativeXFunction(context, referenceX, referenceY, lessNegativeX)
    if ((Math.abs(numberLessNegative / 2).toFixed(2) / 10) <= validationValue) {
        referenceX = initPositionX * canvas.width - sizeValue * canvas.width * (midX + 3) / valueRef


        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * midY / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 3) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * midY / valueRef);
        context.stroke()

        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * midY / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.7) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.25) / valueRef);
        context.stroke()

        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.2) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * midY / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.7) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY + 0.25) / valueRef);
        context.stroke()

    }
    midX = (lessNegativeY[0] + lessNegativeY[2]) / 2
    midY = (lessNegativeY[1] + lessNegativeY[3]) / 2
    context.save()

    if ((Math.abs(abs / 2).toFixed(2) / 10) <= validationValue) {
        spacingYMoreNegative = 3
    }
    abs = lessNegativeY[0] - lessNegativeY[3]
    context.fillText(((Math.abs(abs / 2) / 10).toFixed(2)) + 'm', initPositionX * canvas.width - sizeValue * canvas.width * (midX + middleDisplacement) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * ((midY + -0.2) - spacingYMoreNegative) / valueRef);
    context.restore()

    if ((Math.abs(abs / 2).toFixed(2) / 10) <= validationValue) {
        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 3) / valueRef);
        context.stroke()

        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX + 0.25) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.7) / valueRef);
        context.stroke()

        context.beginPath()
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef);
        context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * (midX - 0.25) / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.7) / valueRef);
        context.stroke()


    }

}

export function createHoleStove(context, canvas, initPositionX, initPositionY, sizeValue, coordOnePozuelo, separationCircule25, coordTwoPozuelo, coordFourPozuelo, valueRef, separationCircule75) {
    let centerX = initPositionX * canvas.width - sizeValue * canvas.width * (coordOnePozuelo[0] + separationCircule25 * Math.abs(coordTwoPozuelo[0] - coordOnePozuelo[0])) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * (midX + -0.2) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef
    let centerY = initPositionY * canvas.height + sizeValue * canvas.width * (coordOnePozuelo[1] + separationCircule25 * Math.abs(coordOnePozuelo[1] - coordFourPozuelo[1])) / valueRef;//referenceY//initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef
    let radius = 14;
    let startAngle = 0;
    let endAngle = Math.PI * 2;
    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.stroke()
    context.beginPath();
    context.arc(centerX, centerY, radius - 10, startAngle, endAngle);
    context.stroke()

    centerX = initPositionX * canvas.width - sizeValue * canvas.width * (coordOnePozuelo[0] + separationCircule75 * Math.abs(coordTwoPozuelo[0] - coordOnePozuelo[0])) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * (midX + -0.2) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef
    centerY = initPositionY * canvas.height + sizeValue * canvas.width * (coordOnePozuelo[1] + separationCircule25 * Math.abs(coordOnePozuelo[1] - coordFourPozuelo[1])) / valueRef;//referenceY//initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef
    startAngle = 0;
    endAngle = Math.PI * 2;
    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.stroke()
    context.beginPath();
    context.arc(centerX, centerY, radius - 10, startAngle, endAngle);
    context.stroke()

    centerX = initPositionX * canvas.width - sizeValue * canvas.width * (coordOnePozuelo[0] + separationCircule25 * Math.abs(coordTwoPozuelo[0] - coordOnePozuelo[0])) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * (midX + -0.2) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef
    centerY = initPositionY * canvas.height + sizeValue * canvas.width * (coordOnePozuelo[1] + separationCircule75 * Math.abs(coordOnePozuelo[1] - coordFourPozuelo[1])) / valueRef;//referenceY//initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef
    startAngle = 0;
    endAngle = Math.PI * 2;
    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.stroke()
    context.beginPath();
    context.arc(centerX, centerY, radius - 10, startAngle, endAngle);
    context.stroke()

    centerX = initPositionX * canvas.width - sizeValue * canvas.width * (coordOnePozuelo[0] + separationCircule75 * Math.abs(coordTwoPozuelo[0] - coordOnePozuelo[0])) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * (midX + -0.2) / valueRef//initPositionX * canvas.width - sizeValue * canvas.width * midX / valueRef
    centerY = initPositionY * canvas.height + sizeValue * canvas.width * (coordOnePozuelo[1] + separationCircule75 * Math.abs(coordOnePozuelo[1] - coordFourPozuelo[1])) / valueRef;//referenceY//initPositionY * canvas.height + sizeValue * canvas.width * (midY - 0.2) / valueRef
    startAngle = 0;
    endAngle = Math.PI * 2;
    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.stroke()
    context.beginPath();
    context.arc(centerX, centerY, radius - 10, startAngle, endAngle);
    context.stroke()
}

export function createHoleDishwasher(context, canvas, initPositionX, initPositionY, sizeValue, holes, key, valueRef) {
    context.beginPath()
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * holes[key].points[0][0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * holes[key].points[0][1] / valueRef);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * holes[key].points[2][0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * holes[key].points[2][1] / valueRef);
    context.stroke()

    context.beginPath()
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * holes[key].points[2][0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * holes[key].points[1][1] / valueRef);
    context.lineTo(initPositionX * canvas.width - sizeValue * canvas.width * holes[key].points[3][0] / valueRef, initPositionY * canvas.height + sizeValue * canvas.width * holes[key].points[3][1] / valueRef);
    context.stroke()
}